import LocationIcon from "../../assets/img/icons/LocationIcon";
import TimeIcon from "../../assets/img/icons/TimeIcon";

import moment from "moment";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { Link } from "react-router-dom";

const MySchedulingCard = ({ item, setSideList, setMapCenter }) => {
  const handligSideAndCenter = () => {
    setSideList(true);
    setMapCenter({ lat: Number(item?.latitude), lng: Number(item?.longitude) });
  };

  return (
    <>
      {item && (
        <div className="my-schedule-wrap">
          <div className="left-content">
            <div style={{ minWidth: "50px" }}>
              <div className="day">
                {item.date === moment().format("YYYY-MM-DD") ? (
                  <span>Today</span>
                ) : (
                  moment(item.date).format("dddd").substring(0, 3)
                )}
              </div>
              <div className="date me-3">{item.date.split("-")[2]}</div>
            </div>
            <div className="vertical-divider" />
            <div>
              <Link
                to={`/events/${item.event.listing.id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="title">{item.title}</div>
              </Link>
              <div className="sub-content-wrap">
                <div className="content-items">
                  <span>
                    <LocationIcon />
                  </span>
                  <div className="t-sub-title-box">
                    <span className="sub-title">{item.address}</span>
                  </div>
                  <span className="map-link" onClick={handligSideAndCenter}>
                    Open maps
                  </span>
                </div>
                <div className="content-items">
                  <span>
                    <TimeIcon />
                  </span>
                  <span className="sub-title">
                    {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}
                  </span>
                </div>
                <div className="avatar-participants-container d-none d-md-flex">
                  <div className="avatar-container">
                    {item?.event?.bookersImage?.slice(0, 3)?.map((item) => {
                      return (
                        <Link to={`/user/${item.id}`} key={item.profilePicture}>
                          {["mp4", "mov", "avi"].includes(
                            item?.profilePicture
                              ?.split(".")
                              .splice(-1)[0]
                              .toLowerCase()
                          ) ? (
                            <>
                              <div className="avatar-item">
                                <video
                                  src={item?.profilePicture}
                                  autoPlay
                                  playsInline
                                  loop
                                  muted
                                  style={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    objectFit: "cover",
                                    verticalAlign: "middle",
                                    borderRadius: "100%",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <source src={item?.profilePicture} />
                                </video>
                              </div>
                            </>
                          ) : (
                            <div
                              key={item.profilePicture}
                              className="avatar-item"
                            >
                              <img
                                src={
                                  item.profilePicture ||
                                  "/static/avatar-dummy-1.png"
                                }
                                alt=""
                              />
                            </div>
                          )}
                        </Link>
                      );
                    })}
                  </div>
                  <span className="t-participants">+ {item.participants}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="cal-btn d-none d-md-block"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AddToCalendarButton
              name={item?.title}
              startDate={item?.date}
              startTime={item?.startTime?.substring(0, 5)}
              endTime={item?.endTime?.substring(0, 5)}
              trigger="click"
              hideCheckmark={true}
              size="5|3|2"
              options={["Apple", "Google", "Outlook.com", "iCal"]}
              styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
              styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
            ></AddToCalendarButton>

            {/* <OutlineButton icon={<CalendarAddIcon />} text="Add to Calendar" /> */}
          </div>
          <div className="d-md-none d-flex justify-content-between my-4 ">
            <div className="avatar-participants-container ">
              <div className="avatar-container">
                <div className="avatar-item">
                  <img src="/static/avatar-dummy-1.png" alt="" />
                </div>
                <div className="avatar-item">
                  <img src="/static/avatar-dummy-2.png" alt="" />
                </div>
                <div className="avatar-item">
                  <img src="/static/avatar-dummy-3.png" alt="" />
                </div>
              </div>
              <span className="t-participants">+ {item.participants}</span>
            </div>
            <div
              className="cal-btn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <AddToCalendarButton
                name={item?.title}
                startDate={item?.date}
                startTime={item?.startTime?.substring(0, 5)}
                endTime={item?.endTime?.substring(0, 5)}
                trigger="click"
                hideCheckmark={true}
                size="5|3|2"
                options={["Apple", "Google", "Outlook.com", "iCal"]}
                styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
                styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
              ></AddToCalendarButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MySchedulingCard;
