import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import GlobalHeader from "../Dashboard/GlobalHeader";
import ReactSimplyCarousel from "react-simply-carousel";
import { ConnectCard } from "./ConnectCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import {
  makeGetCall,
  makeNextPageCall,
  makePostCall,
} from "../../utils/Requests";
import useDebounce from "../../utils/useDebounce";
import endpoints from "../../utils/endpoints";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { USER_TYPE } from "../../types";
import MapContainer from "./MapContainer";
import { showToast } from "../../utils";
import Profile from "../UserProfile/Profile";

const Connect = () => {
  const navigate = useNavigate();
  const [activeListbtn, setActiveListbtn] = useState<boolean>(true);
  const [activeMaptbtn, setActiveMaptbtn] = useState(false);
  const [sideList, setSideList] = useState<boolean>(false);
  const [mapList, setMapList] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [hasSerachData, setHasSearchData] = useState<boolean>(true);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState<any>([]);
  const [connectid, setConnectid] = useState<any>(null);
  const [currentLat, setCurrentLat] = useState<any>(null);
  const [currentLng, setCurrentLng] = useState<any>(null);
  const [showUserProfile, setShowUserProfile] = useState({});

  useEffect(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);

  const fetchMoreData = () => {
    if (!nextUrl) return;
    makeNextPageCall({ url: nextUrl }).then((res) => {
      const tempData = [...users, ...res.results];
      setNextUrl(res?.next);
      setUsers(tempData as never);
      if (tempData.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
    });
  };
  const getData = () => {
    let params = { search: searchTerm || "" };
    if (currentLng && currentLat) {
      //dist in meter
      params["dist"] = 20000000;
      params["point"] = `${currentLng},${currentLat}`;
    }
    setIsLoading(true);
    makeGetCall({ url: endpoints.users, params }).then((res) => {
      setUsers(res?.results);
      if (res?.results.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
      setNextUrl(res?.next || null);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (searchTerm) getData();
    else {
      setUsers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, currentLng, currentLng]);

  const getProfiles = () => {
    setIsLoading(true);
    let params = {};
    // loctaion filter not required for now
    if (currentLng && currentLat) {
      //dist in meter
      params["dist"] = 20000000;
      params["point"] = `${currentLng},${currentLat}`;
      params["limit"] = 100;
    }
    makeGetCall({ url: endpoints.profiles, params }).then((res) => {
      setProfiles(res?.results);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getProfiles();
    // loctaion filter not required for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLng, currentLat]);
  // }, []);

  const connectUser = (connectid) => {
    setIsLoading(true);
    makePostCall({
      url: `auth/${connectid}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setConnectid(null);
        } else {
          showToast(res.status.message || res.error.message, "error");
          setConnectid(null);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  function CardCarousel({ setShowUserProfile }: any) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      function handleResize() {
        setWindowWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowWidth < 768;

    return (
      <div>
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={isMobile ? 1.2 : 6}
          itemsToScroll={1}
          forwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            className: "carousel-button-cutsom",
            style: {
              alignSelf: "center",
              background: "#F3F3F3",
              border: "none",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              fontSize: "20px",
              height: 44,
              lineHeight: 1,
              textAlign: "center",
              width: 44,
            },
            children: (
              <span>
                <img alt="" src="/static/arrow-right-next.svg" />
              </span>
            ),
          }}
          backwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            className: "carousel-button-cutsom",
            style: {
              alignSelf: "center",
              background: "#F3F3F3",
              border: "none",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              fontSize: "20px",
              height: 44,
              lineHeight: 1,
              textAlign: "center",
              width: 44,
            },
            children: (
              <span>
                <img alt="" src="/static/arrow-left-next.svg" />
              </span>
            ),
          }}
          responsiveProps={[
            {
              itemsToShow: isMobile ? 1.2 : 3,
              itemsToScroll: 1,
              minWidth: 768,
            },
          ]}
          speed={400}
          easing="linear"
        >
          {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
          {isLoading ? (
            <ApiLoader />
          ) : (
            profiles?.map((item, index) => {
              return (
                <ConnectCard
                  className={
                    index === activeSlideIndex + 1 ? "partial-card" : ""
                  }
                  key={item.id}
                  item={item}
                  isMarkedFavourite={item?.isMarkedFavourite}
                  status={item.connectionStatus.status}
                  setConnectid={setConnectid}
                />
              );
            })
          )}
        </ReactSimplyCarousel>
      </div>
    );
  }

  return (
    <>
      <GlobalHeader />

      {Object.keys(showUserProfile).length ? (
        <div>
          <Profile
            setShowUserProfile={setShowUserProfile}
            showUserProfile={showUserProfile}
          />
        </div>
      ) : (
        <div className="connectpage-wrap">
          <div className="content-box">
            <div className="top-section">
              <div className="left">
                <div className="title">Connect</div>
                <div className="subtitle">
                  You can connect with the people nearby you
                </div>
              </div>
              <div className="right">
                <div className="toogle-btn-wrap">
                  <div
                    className={activeListbtn ? "active-btn" : "disable-btn"}
                    onClick={() => {
                      setActiveListbtn(true);
                      setActiveMaptbtn(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        src={`/static/list-${
                          activeListbtn ? "theme" : "gray"
                        }.svg`}
                      />
                    </span>
                    <span className="hidden-text">List View</span>
                  </div>
                  <div
                    className={activeMaptbtn ? "active-btn" : "disable-btn"}
                    onClick={() => {
                      setActiveListbtn(false);
                      setActiveMaptbtn(true);
                      setMapList(true);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        src={`/static/map-${
                          activeMaptbtn ? "theme" : "gray"
                        }.svg`}
                      />
                    </span>
                    <span className="hidden-text">Map View</span>
                  </div>
                </div>
                {/* <div
                  className="filter-icon"
                  onClick={() => {
                    setSideList(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10 16H14C14.5523 16 15 16.4477 15 17C15 17.5128 14.614 17.9355 14.1166 17.9933L14 18H10C9.44772 18 9 17.5523 9 17C9 16.4872 9.38604 16.0645 9.88338 16.0067L10 16H14H10ZM8 11H16C16.5523 11 17 11.4477 17 12C17 12.5128 16.614 12.9355 16.1166 12.9933L16 13H8C7.44772 13 7 12.5523 7 12C7 11.4872 7.38604 11.0645 7.88338 11.0067L8 11H16H8ZM5 6H19C19.5523 6 20 6.44772 20 7C20 7.51284 19.614 7.93551 19.1166 7.99327L19 8H5C4.44772 8 4 7.55228 4 7C4 6.48716 4.38604 6.06449 4.88338 6.00673L5 6H19H5Z"
                      fill="white"
                    />
                  </svg>
                </div> */}
              </div>
            </div>

            <div className="carousel-box">
              <CardCarousel setShowUserProfile={setShowUserProfile} />
            </div>
          </div>
        </div>
      )}
      {sideList ? (
        <div className="fixed-sidebar">
          <div className="sidebar-box connect-search-user">
            <img
              src="/static/cross-black.svg"
              onClick={() => setSideList(false)}
              className="close-btn"
              alt=""
            />
            <div className="inner-contet">
              <div className="form-set">
                <div className="form-group">
                  <input
                    type="text"
                    id="search_input"
                    placeholder="Search user"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <label htmlFor="search_input">
                    <img
                      src="/static/search-gray.svg"
                      className="gray-search"
                      alt=""
                    />
                    <img
                      src="/static/Search.svg"
                      className="black-search"
                      alt=""
                    />
                  </label>
                  <img
                    src="/static/cross-circle-gray-dark.svg"
                    className="corss-icon"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchTerm("");
                      setUsers([]);
                      setHasSearchData(true);
                    }}
                  />
                </div>
              </div>
              <div className="search-list-user">
                {users.length > 0 ? (
                  <InfiniteScroll
                    dataLength={users?.length}
                    next={fetchMoreData}
                    hasMore={nextUrl ? true : false}
                    loader={<ApiLoader />}
                  >
                    {users.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="on-list-user"
                          onClick={() => {
                            navigate(`/user/${item.id}`);
                            setSideList(false);
                          }}
                        >
                          <div className="top-part">
                            <div className="img-box">
                              <img
                                alt=""
                                src={
                                  item?.profilePicture || "/static/dummyimg.svg"
                                }
                              />
                            </div>
                            <div>
                              <h6>{item.name}</h6>
                              <p>
                                <img
                                  src={
                                    item.userType === USER_TYPE.GUEST
                                      ? "/static/guesticon.svg"
                                      : item.userType === USER_TYPE.MEMBER &&
                                        item?.isTrainer
                                      ? "/static/membericon.svg"
                                      : "/static/briefcase.svg"
                                  }
                                  alt=""
                                />
                                {item.userType === USER_TYPE.MEMBER &&
                                item.isTrainer
                                  ? "Trainer"
                                  : item.userType}
                              </p>
                            </div>
                          </div>

                          <img src="/static/arrow-right.svg" alt="" />
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : !hasSerachData && searchTerm ? (
                  <div className="no-data">
                    <img src="/static/no_search_found.svg" alt="" />
                    <p>
                      {STRING_CONSTANTS.we_apologise_no_search_result}
                      <b style={{ color: "#25272D" }}>"{searchTerm}"</b>.
                    </p>
                  </div>
                ) : (
                  <div className="no-data">
                    <img src="/static/search_list_icon.svg" alt="" />
                    <p>{STRING_CONSTANTS.no_users_data}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {mapList ? (
        <div className="fixed-sidebar">
          <div
            className="sidebar-box connect-search-user "
            style={{ padding: "0" }}
          >
            <div
              style={{
                position: "absolute",
                top: "20px",
                zIndex: "20",
                paddingLeft: "20px",
                display: "inline-flex",
                gap: "12px",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setActiveListbtn(true);
                  setActiveMaptbtn(false);
                  setMapList(false);
                }}
                className="close-btn"
                alt=""
              />
              <span>Map View</span>
            </div>
            <div
              className="inner-contet"
              style={{
                marginTop: "0",
                maxWidth: "877px",
                position: "relative",
              }}
            >
              <MapContainer
                profiles={profiles}
                setConnectid={setConnectid}
                connectUser={() => connectUser(connectid)}
                latitude={currentLat}
                longitude={currentLng}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Connect;
