/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../global/Button";
import { makeGetCall, makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import Toggle from "../../global/Toggle";
import SubscriptionPlanCard from "../../global/SubscriptionPlanCard";
import CommonLoader from "../../global/CommonLoader";
import CommonLoaderSubscription from "../../global/CommonLoaderSubscription";


import { getItemFromStore, showToast } from "../../utils";
import { useNavigate } from "react-router";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import checked from "../../assets/img/signupWelcome/checked.svg";
import bgLeft from "../../assets/img/backgroundLeft.svg";
import bgRight from "../../assets/img/backgroundRight.svg";
import backBtn from "../../assets/img/signupWelcome/backBtn.svg";
import AddCardPopup from "../Dashboard/Popups/AddCardPopup";

const SubscriptionPlanSelector = ({ isLoading }: any) => {
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [toggleOptions, setToggleOptions] = useState([]);
  const [currentSelection, setCurrentSelection] = useState("Yearly");
  const [activeSubscription, setActiveSubscription] = useState({});
  const [myCards, setMyCards] = useState([]);
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const [purchaseSubsLoading, setPurchaseSubsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const userProfile = getItemFromStore("userProfile");

  useEffect(() => {
    if (currentSelection === "Yearly") {
      setActiveSubscription(subscriptionType[0]);
    } else {
      setActiveSubscription(subscriptionType[1]);
    }
  }, [currentSelection, subscriptionType]);

  useEffect(() => {
    if (subscriptionType?.length) {
      const option = subscriptionType.map((type) => type.recurring.interval);
      setToggleOptions(option);
    }
  }, [subscriptionType]);

  useEffect(() => {
    const fetchSubscriptionData = () => {
      setLoading(true);
      makeGetCall({
        url: endpoints.get_subscription_types,
      })
        .then((res) => {
          setSubscriptionType(res.data.data);
          setLoading(false);
        })
        .catch((err) => { });
    };

    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    makeGetCall({ url: endpoints.manage_card })
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
        }
      })
      .catch((err) => {
        console.error(err, "error");
      });
  }, []);

  const purchaseSubscription = (price_id, payment_id) => {
    const apiPayload = {
      price_id: price_id,
      payment_id: payment_id,
    };
    setPurchaseSubsLoading(true);
    makePostCall({ url: endpoints.subscription, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res?.status?.message, "success");
          setTimeout(() => {
            navigate(ROUTE_CONSTANTS?.manage_profile);
          }, 1000);
        }
        setPurchaseSubsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setPurchaseSubsLoading(false);
      });
  };

  useEffect(() => {
    if (myCards.length !== 0 && activeSubscription?.id && myCards?.[0]?.id) {
      if (!checkIsSubsPurchased()) {
        if (userProfile && userProfile?.userType === "Guest") {
          return;
        }
        purchaseSubscription(activeSubscription?.id, myCards?.[0]?.id);
      }
    }
  }, [myCards, activeSubscription?.id, myCards?.[0]?.id]);

  const checkIsSubsPurchased = () => {
    if (userProfile) {
      if (userProfile.userType === "Guest") {
        return false;
      } else {
        return true;
      }
    }
    const userData = getItemFromStore("userData");
    if (userData) {
      if (userData?.userType === "Guest") {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const features = [
    "365 days plan",
    "Create Unlimited Groups Activities",
    "Generate Fitness Activity Revenue",
    "Flexible Scheduling and Booking",
    "Access to Fitness Mates Events and Promotions",
    "Exclusive Membership Discounts",
  ];

  const FeatureList = () => (
    <div className="d-flex flex-column gap-1">
      {features.map((feature, index) => (
        <div
          key={index}
          className="feature-item d-flex align-items-center gap-1"
          style={{fontSize : '12px'}}
        >
          <img src={checked} alt="Yes" style={{height:'20px' , width:'20px'}} /> {feature}
        </div>
      ))}
    </div>
  );

  return (
    <div className="user_type_wrapper">
      <img src={bgLeft} alt="" className="background_left" />
      <img src={bgRight} alt="" className="background_right" />
      <div className="user_container">
        <div className="user_content"  style={{paddingTop:'12px'}}>
          <img
            src={backBtn}
            alt=""
            className="back_btn"
            onClick={() => navigate(-1)}
          />
          {/* heading */}
          <div className="d-flex justify-content-center flex-column">
            <div className="main_heading" style={{fontSize : '20px'}}>Membership Plan</div>
            <div className="main_sub_heading">
              Whether you're looking to find workout partners or create fitness
              events, we got you covered!
            </div>
          </div>

          <div className="plans_container">
            <div className="plans_wrapper">
              <div className="center-container">
                <Toggle
                  currentSelection={currentSelection}
                  onClick={(value: any) => setCurrentSelection(value)}
                  option2={toggleOptions[0] === "year" && "Yearly"}
                  option1={toggleOptions[1] === "month" && "Monthly"}
                />
              </div>

              <SubscriptionPlanCard
                currentSelection={currentSelection}
                activeSubscription={activeSubscription}
              />

              <div>
                <div className="feature_heading">Member Benefits</div>
                <FeatureList />
                <div className="gray-note">
                  Note:{" "}
                  <span>
                    Select the plan according to your preference, as it will be
                    changed once selected.
                  </span>
                </div>
              </div>

              <div className="center-container" style={{ position: 'sticky', bottom: '8px', zIndex: '10' }}>
                {myCards?.length !== 0 && (
                  <Button
                    btnTxt={
                      checkIsSubsPurchased()
                        ? "Subscription Purchased Successfully"
                        : "Continue and Pay"
                    }
                    onClick={() => {
                      if (checkIsSubsPurchased()) {
                        navigate(ROUTE_CONSTANTS.manage_profile);
                      } else {
                        purchaseSubscription(
                          activeSubscription?.id,
                          myCards?.[0]?.id
                        );
                      }
                    }}
                    isLoading={purchaseSubsLoading}
                    disabled={myCards?.length === 0}
                  />
                )}
                {myCards?.length === 0 && (
                  <Button
                    btnTxt="Continue and Pay"
                    className={"btn-member mx-auto mt-4"}
                    onClick={() => {
                      setShowAddCardPopup(true);
                    }}
                  />
                )}

              </div>
              {loading && <CommonLoaderSubscription />}
              {showAddCardPopup && (
                <AddCardPopup
                  show={showAddCardPopup}
                  onHide={() => setShowAddCardPopup(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanSelector;
