import React from "react";
import { Controller, useController } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import { apiConstants } from "../../../utils/Constants";
interface MultiDateSelectorProps {
  name: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  errors?: object | any;
  id?: string;
  control?: any;
  minDate?: any;
  maxDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  dobError?: boolean;
  selectsStart?: any;
  selectsEnd?: any;
  startDate?: any;
  endDate?: any;
  getValues?: any;
  watch?: any;
  selectsRange?: any;
  inline?: any;
  highlightWithRanges?: any;
  inputValue?: any;
  multiple?: any;
  format?: any;
  disabled?: boolean;
}
const MultiDateSelector = (props: MultiDateSelectorProps) => {
  const { name, control, multiple, errors, disabled = false, watch } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  console.log("object", watch(apiConstants.event_custom_date));

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <>
            <DatePicker
              multiple={multiple}
              value={value}
              onChange={onChange}
              minDate={new Date()}
              showOtherDays={true}
              calendarPosition={"top"}
              disabled={disabled}
            />

            {errors && errors[name] ? (
              <span className="text-danger">{errors[name]?.message}</span>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default MultiDateSelector;
