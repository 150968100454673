import React, { useEffect } from "react";
import "./style.scss";
import Button from "../../../global/Button";
import MeetingLinkInput from "../MeetingLinkInput/MeetingLinkInput";
import { apiConstants, meetingLinkRegex } from "../../../utils/Constants";
import { showToast } from "../../../utils";

type Props = {
  [key: string]: any;
};

const ScheduleListingMeeting = (props: Props) => {
  const { watch, handleSetValue, setScheduleLisiting, data = [] } = props;

  const handleSave = () => {
    const filterVal = watch(apiConstants.virtual_links_multiple)?.filter(
      (val) => !val?.virtual_link
    );
    if (!filterVal || filterVal?.length > 0) {
      showToast(
        "Please fill all the meeting links with respective dates",
        "error"
      );
    } else {
      const filterVal = watch(apiConstants.virtual_links_multiple)?.filter(
        (val) =>
          meetingLinkRegex?.googleMeetRegex.test(val?.virtual_link) ||
          meetingLinkRegex?.zoomRegex.test(val?.virtual_link) ||
          meetingLinkRegex?.teamsRegex.test(val?.virtual_link)
      );
      if (!filterVal || filterVal?.length > 0) {
        showToast(
          "Please include only web URLs for Google Meet, Zoom, and Microsoft Teams here.",
          "error"
        );
      } else {
        handleSetValue(apiConstants.has_schedule_date, true);
        setScheduleLisiting(false);
      }
    }
  };
  return (
    <div className="schedule_meetings_wrap">
      <div className="meeting_header">
        <div className="heading">Add meeting links</div>
        <div className="sub_heading">
          Please provide your meeting links here. Make sure to include only web
          URLs for Google Meet, Zoom, and Microsoft Teams.
        </div>
      </div>
      <div className="meeting_body">
        <div className="links_container">
          {data &&
            data?.map((item, index) => (
              <MeetingLinkInput
                key={item?.date || index}
                data={item}
                index={index}
                handleSetValue={handleSetValue}
                watch={watch}
              />
            ))}
        </div>
      </div>
      <div className="meeting_footer">
        <Button
          btnTxt="Cancel"
          className="simple"
          onClick={() => setScheduleLisiting(false)}
        />
        <Button btnTxt="Save" className="filled" onClick={handleSave} />
      </div>
    </div>
  );
};

export default ScheduleListingMeeting;
