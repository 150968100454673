import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
import CommonOutlineButton from "./CommonOutlineButton";
import { makeDeleteCall } from "../utils/Requests";
import endpoints from "../utils/endpoints";
import { showToast } from "../utils";

interface RemoveConnection {
  onOk: any;
  onCancel: any;
  userProfile: any;
  setShowRemoveConnection: any;
}
const RemoveConnectionModal = (props: RemoveConnection) => {
  const { onCancel, userProfile, setShowRemoveConnection } = props;

  const removeConnection = () => {
    makeDeleteCall({
      url: `${endpoints.remove_connection}/${userProfile?.connectionStatus?.connectionId}`,
    }).then((res) => {
      showToast("Connection Removed", "success");
      setShowRemoveConnection(false);
    });
  };

  return (
    <div className={"modal show"}>
      <div className="modal-section log_out_modal">
        <div className="modal-top-part" style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="profile-image"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                borderRadius: "100%",
                height: 101,
                width: 101,
                top: -50.5,
                outline: "5px solid #FFFFFF",
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.5)",
                backgroundImage: `url(${
                  userProfile?.profilePicture
                    ? userProfile?.profilePicture
                    : "/static/dummyimg.svg"
                })`,
              }}
            ></div>
          </div>
          <h5>{STRING_CONSTANTS.remove_connection}</h5>
          <p>{userProfile.name}</p>
        </div>
        <div className="button_option">
          <Button
            btnTxt="Confirm"
            className={"backtologin"}
            onClick={() => removeConnection()}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
            }}
          >
            <CommonOutlineButton
              onClick={onCancel}
              image=""
              text="Cancel"
              className={"common_btn_grey"}
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveConnectionModal;
