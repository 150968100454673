/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { RECURRING_TYPE, SCHEDULE_TYPE } from "../../../types";
import {
  dateFormatter,
  findDatesInNextThreeMonths,
  showToast,
} from "../../../utils";
import {
  apiConstants,
  recurFrequency,
  WeekDays,
} from "../../../utils/Constants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import EventDateSelector from "./EventDateSelector";
import EventTimeSelector from "./EventTimeSelector";
import MultiDateSelector from "./MultiDateSelector";
import ScheduleListingMeeting from "../../ListingComponents/ScheduleListingMeeting/ScheduleListingMeeting";
import {
  generateDateRangeEventsWithSkip,
  generateMonthlyEventsWithSkip,
  generateWeekdayEventsWithSkip,
} from "../../../utils/datesRange";
interface scheduleListingProps {
  control: any;
  watch?: any;
  handleSetValue?: any;
  getValues?: any;
  setScheduleLisiting?: any;
  trigger: any;
  errors: any;
  setScheduleDate: any;
  dirtyFields: any;
}
const ScheduleListingModal = (props: scheduleListingProps) => {
  const {
    control,
    watch,
    handleSetValue,
    getValues,
    setScheduleLisiting,
    trigger,
    errors,
    setScheduleDate,
    dirtyFields,
  } = props;
  const recurringType = watch(apiConstants.recurring_type);
  const scheduleType = watch(apiConstants.schedule_type);

  const [showRecFrequency, setShowRecFrequency] = useState(false);
  const [showMonthFrequency, setShowMonthFrequency] = useState(false);
  const [availableDates, setAvailableDates] = useState<any>([]);
  const [isMeetingLink, setIsMeetingLink] = useState<any>(false);

  const getDatesBetween = (
    startDate: any,
    endDate: any,
    frequency: number,
    type: string,
    recurrenceDays?: any,
    onthebasis?: any
  ) => {
    const dates = [];

    let currentDate = moment(startDate);
    const end = moment(endDate);

    while (currentDate <= end) {
      if (type === "Week") {
        const currentWeek = currentDate.clone();

        for (let i = 0; i < 7; i++) {
          if (
            currentWeek.startOf("day") <= end.startOf("day") &&
            recurrenceDays?.includes(currentWeek.format("dddd"))
          ) {
            dates.push({
              start:
                currentWeek.format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_start_time)).format(
                  "hh:mm A"
                ),
              end:
                currentWeek.format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_end_time)).format(
                  "hh:mm A"
                ),
            });
          }
          currentWeek.add(1, "day");
        }

        currentDate = currentDate.add(frequency, "weeks");
      } else if (type === "Day") {
        dates.push({
          start:
            currentDate.format("YYYY-MM-DD") +
            " " +
            moment(getValues(apiConstants.event_start_time)).format("hh:mm A"),
          end:
            currentDate.format("YYYY-MM-DD") +
            " " +
            moment(getValues(apiConstants.event_end_time)).format("hh:mm A"),
        });
        currentDate.add(frequency, "days");
      } else if (type === "Month") {
        const weekdayRegex =
          /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)/i;

        const containsWeekday = weekdayRegex.test(onthebasis);
        const result = containsWeekday ? "weekday" : "date";

        if (result === "weekday") {
          // Process month type based on weekday

          let MonthdaysBasedOnWeek: any = findDatesInNextThreeMonths(
            dateFormatter(currentDate, "YYYY-MM-DD"),
            end.diff(currentDate, "months"),
            dateFormatter(end, "YYYY-MM-DD")
          );

          for (
            let i = 0;
            i < MonthdaysBasedOnWeek?.length;
            i += Number(frequency)
          ) {
            if (i >= MonthdaysBasedOnWeek?.length) {
              break;
            }

            dates.push({
              start:
                moment(MonthdaysBasedOnWeek[i]).format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_start_time)).format(
                  "hh:mm A"
                ),
              end:
                moment(MonthdaysBasedOnWeek[i]).format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_end_time)).format(
                  "hh:mm A"
                ),
            });
          }
          break;
        } else if (result === "date") {
          // Process month type based on date
          const targetDate = moment(currentDate).date(currentDate.date()); // 6th of the month

          dates.push({
            start:
              targetDate.format("YYYY-MM-DD") +
              " " +
              moment(getValues(apiConstants.event_start_time)).format(
                "hh:mm A"
              ),
            end:
              targetDate.format("YYYY-MM-DD") +
              " " +
              moment(getValues(apiConstants.event_end_time)).format("hh:mm A"),
          });

          currentDate.add(frequency, "months");
        }
      } else {
        return;
      }
    }

    return dates;
  };

  useEffect(() => {
    //Remove leading zeros
    if (watch(apiConstants.repeat_every)) {
    }
    if (watch(apiConstants.repeat_every)) {
      handleSetValue(
        apiConstants.repeat_every,
        Number(watch(apiConstants?.repeat_every)).toString()
      );
    }
    // else {
    //   // console.log("here#1");
    //   if (watch(apiConstants.event_end_date)) {
    //     handleSetValue(apiConstants.event_end_date, null);
    //     handleSetValue(apiConstants.event_custom_date, null);
    //     handleSetValue(apiConstants.selected_days, []);
    //     handleSetValue(apiConstants.custom_dates, []);
    //   }
    // }
  }, [watch(apiConstants.repeat_every)]);

  const handleWeekDays = (item: any) => {
    const tempDays = watch(apiConstants.selected_days);
    const isSelected = tempDays?.includes(item);
    if (isSelected) {
      handleSetValue(
        apiConstants.selected_days,
        tempDays.filter((day: any) => day !== item)
      );
    } else {
      handleSetValue(apiConstants.selected_days, [...tempDays, item]);
    }
  };
  const handleScheduleListing = async () => {
    if (
      scheduleType === SCHEDULE_TYPE.R &&
      recurringType === RECURRING_TYPE.R &&
      watch(apiConstants.recur_frequency) === "Week"
    ) {
      if (availableDates.length < 1) {
        showToast(
          "Number of dates is 0 with the selected recurrence pattern.",
          "error"
        );
        return;
      }
    }
    switch (watch(apiConstants.schedule_type)) {
      // schedule: onetime
      case SCHEDULE_TYPE.O:
        let startDate = watch(apiConstants.event_date);
        let endDate = watch(apiConstants.event_date);
        handleSetValue(apiConstants.event_start_date, new Date(startDate));
        handleSetValue(apiConstants.event_end_date, new Date(endDate));

        if (!watch(apiConstants.event_date)) {
          showToast("Event date is required to proceed", "error");
          return;
        } else if (
          !watch(apiConstants.event_end_time) ||
          !watch(apiConstants.event_start_time)
        ) {
          showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
          return;
        } else if (
          moment(getValues(apiConstants.event_end_time)).isSameOrBefore(
            getValues(apiConstants.event_start_time)
          )
        ) {
          showToast(STRING_CONSTANTS.start_and_end_date_error_eql, "error");
          handleSetValue(apiConstants.event_end_time, null);
          return;
        } else if (
          moment(
            watch(apiConstants.event_start_date),
            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
          ).isSameOrBefore(moment()) &&
          moment(
            getValues(apiConstants.event_start_time),
            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
          ).isSameOrBefore(moment())
        ) {
          showToast(STRING_CONSTANTS.select_future_time, "error");
          handleSetValue(apiConstants.event_end_time, null);
          handleSetValue(apiConstants.event_start_time, null);
          handleSetValue(apiConstants.event_date, null);
          return;
        } else {
          const multi = getValues(apiConstants.virtual_links_multiple) || [];
          const virtual_links_temp = [...multi];
          const dataCheck = virtual_links_temp?.filter(
            (val) =>
              val?.date ===
              moment(
                watch(apiConstants.event_start_date),
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
              ).format("YYYY-MM-DD")
          );
          if (dataCheck?.length < 1) {
            virtual_links_temp.push({
              date: moment(
                watch(apiConstants.event_start_date),
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
              ).format("YYYY-MM-DD"),
              event_start_time: watch(apiConstants.event_start_time),
              event_end_time: watch(apiConstants.event_end_time),
              virtual_link: "",
            });
          }
          await handleSetValue(
            apiConstants.virtual_links_multiple,
            virtual_links_temp
          );
          await handleContinueClick(
            getValues(apiConstants.virtual_links_multiple)
          );
        }

        break;
      // schedule:recurring
      case SCHEDULE_TYPE.R:
        switch (watch(apiConstants.recurring_type)) {
          // recurring:custom dates
          case RECURRING_TYPE.C:
            let startDate;
            let endDate;
            if (watch(apiConstants.custom_dates).length >= 1) {
              startDate = new Date(
                watch(apiConstants.custom_dates)?.[0]?.start.split(" ")[0]
              );
              endDate = new Date(
                watch(apiConstants.custom_dates)?.[
                  watch(apiConstants.custom_dates)?.length - 1
                ]?.start.split(" ")[0]
              );

              handleSetValue(
                apiConstants.event_start_date,
                new Date(startDate)
              );
              handleSetValue(apiConstants.event_end_date, new Date(endDate));
            }

            if (
              moment(getValues(apiConstants.event_end_time)).isSameOrBefore(
                getValues(apiConstants.event_start_time)
              )
            ) {
              showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
              handleSetValue(apiConstants.event_end_time, null);
            } else if (
              moment(
                watch(apiConstants.event_start_date),
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
              ).isSameOrBefore(moment()) &&
              moment(
                getValues(apiConstants.event_start_time),
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
              ).isSameOrBefore(moment())
            ) {
              showToast(STRING_CONSTANTS.select_future_time, "error");
              handleSetValue(apiConstants.event_end_time, null);
              handleSetValue(apiConstants.event_start_time, null);
              handleSetValue(apiConstants.event_custom_date, null);
              return;
            } else {
              const virtual_links = getValues(apiConstants.custom_dates)?.map(
                (event) => {
                  return {
                    date: moment(event.start, "YYYY-MM-DD hh:mm A").format(
                      "YYYY-MM-DD"
                    ),
                    event_start_time: event.start,
                    event_end_time: event.end,
                    virtual_link: event?.virtual_link || "",
                  };
                }
              );

              await handleSetValue(
                apiConstants.virtual_links_multiple,
                virtual_links
              );
              await handleContinueClick(
                getValues(apiConstants.virtual_links_multiple)
              );
            }
            break;
          // recurring:repeat frequency
          case RECURRING_TYPE.R:
            if (
              moment(getValues(apiConstants.event_end_time)).isSameOrBefore(
                getValues(apiConstants.event_start_time)
              )
            ) {
              showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
              handleSetValue(apiConstants.event_end_time, null);
            } else if (
              moment(getValues(apiConstants.event_end_date)).isSameOrBefore(
                getValues(apiConstants.event_start_date)
              )
            ) {
              showToast(STRING_CONSTANTS.start_and_end_date_error, "error");
              handleSetValue(apiConstants.event_end_date, null);
            } else if (
              moment(
                watch(apiConstants.event_start_date),
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
              ).isSameOrBefore(moment()) &&
              moment(
                getValues(apiConstants.event_start_time),
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
              ).isSameOrBefore(moment())
            ) {
              showToast(STRING_CONSTANTS.select_future_time, "error");
              handleSetValue(apiConstants.event_end_time, null);
              handleSetValue(apiConstants.event_start_time, null);
              handleSetValue(apiConstants.event_start_date, null);
              handleSetValue(apiConstants.event_end_date, null);
              setAvailableDates([]);
            } else {
              if (watch(apiConstants.recur_frequency) === "Day") {
                const virtual_links = generateDateRangeEventsWithSkip(
                  getValues(apiConstants.event_start_date),
                  getValues(apiConstants.event_end_date),
                  getValues(apiConstants.event_start_time),
                  getValues(apiConstants.event_end_time),
                  getValues(apiConstants.repeat_every) - 1
                );

                await handleSetValue(
                  apiConstants.virtual_links_multiple,
                  virtual_links
                );
              } else if (watch(apiConstants.recur_frequency) === "Week") {
                const virtual_links = await generateWeekdayEventsWithSkip(
                  getValues(apiConstants.event_start_date),
                  getValues(apiConstants.event_end_date),
                  getValues(apiConstants.event_start_time),
                  getValues(apiConstants.event_end_time),
                  getValues(apiConstants.selected_days),
                  getValues(apiConstants.repeat_every) - 1
                );
                await handleSetValue(
                  apiConstants.virtual_links_multiple,
                  virtual_links
                );
              } else if (watch(apiConstants.recur_frequency) === "Month") {
                const virtual_links = await generateMonthlyEventsWithSkip(
                  getValues(apiConstants.event_start_date),
                  getValues(apiConstants.event_end_date),
                  getValues(apiConstants.event_start_time),
                  getValues(apiConstants.event_end_time),
                  getValues(apiConstants.on_the_basis) === "weekday"
                    ? "date"
                    : "day",
                  getValues(apiConstants.repeat_every) - 1
                );
                await handleSetValue(
                  apiConstants.virtual_links_multiple,
                  virtual_links
                );
              }
              handleContinueClick(
                getValues(apiConstants.virtual_links_multiple)
              );
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (watch(apiConstants.event_custom_date)) {
      const normalDates = getValues(apiConstants.event_custom_date)?.map(
        (date: any) => date.format("YYYY-MM-DD")
      );
      let dates = normalDates.map((item: any, index: number) => {
        let customDates = getValues(apiConstants.custom_dates);
        const observer = customDates?.filter((val) =>
          val?.start?.includes(item)
        );
        console.log("observer?.length: ", observer?.length);
        if (observer?.length > 0) {
          return {
            start:
              item +
              " " +
              moment(getValues(apiConstants.event_start_time))?.format(
                "hh:mm A"
              ),
            end:
              item +
              " " +
              moment(getValues(apiConstants.event_end_time))?.format("hh:mm A"),
            virtual_link: observer[0].virtual_link || "",
          };
        } else {
          return {
            start:
              item +
              " " +
              moment(getValues(apiConstants.event_start_time))?.format(
                "hh:mm A"
              ),
            end:
              item +
              " " +
              moment(getValues(apiConstants.event_end_time))?.format("hh:mm A"),
            virtual_link: "",
          };
        }
      });

      // Define a custom comparison function
      const compareDates = (a: any, b: any) => {
        const dateA: any = moment(a.start.replace(/ Invalid date$/, ""));
        const dateB: any = moment(b.start.replace(/ Invalid date$/, ""));
        return dateA - dateB;
      };

      // Sort the array based on the start date
      dates.sort(compareDates);

      console.log("normalDates: ", dates);

      handleSetValue(apiConstants.custom_dates, dates);
    }
  }, [
    watch(apiConstants.event_custom_date),
    watch(apiConstants.event_start_time),
    watch(apiConstants.event_end_time),
  ]);

  useEffect(() => {
    if (watch(apiConstants.recur_frequency === "Month")) {
      const dayOfWeek = dateFormatter(
        watch(apiConstants.event_start_date),
        "dddd"
      ); // Get the full name of the day of the week

      // Math.ceil(moment(dateString).date() / 7);
      const occurrence = Math.ceil(
        moment(watch(apiConstants.event_start_date)).date() / 7
      );

      // Calculate the occurrence of the day within the month

      if (dirtyFields.event_start_date) {
        handleSetValue(apiConstants.monthly_frequency, "");
      }
      handleSetValue(apiConstants.selected_month_dates_options, {
        day: dateFormatter(watch(apiConstants.event_start_date), "DD"),
        weakday: ` ${`${
          occurrence === 5
            ? "last"
            : occurrence === 1
            ? "1st"
            : occurrence === 2
            ? "2nd"
            : occurrence === 3
            ? "3rd"
            : "4th"
        }  ${dayOfWeek}`} `,
      });
    }
  }, [watch(apiConstants.event_start_date)]);

  useEffect(() => {
    if (
      watch(apiConstants.event_start_date) &&
      watch(apiConstants.event_end_date) &&
      watch(apiConstants.repeat_every) &&
      watch(apiConstants.recur_frequency)
    ) {
      if (
        watch(apiConstants.recur_frequency) === "Month" &&
        !watch(apiConstants.monthly_frequency)
      ) {
        setAvailableDates([]);
        return;
      } else {
        let preferedDates = getDatesBetween(
          watch(apiConstants.event_start_date),
          watch(apiConstants.event_end_date),
          watch(apiConstants.repeat_every),
          watch(apiConstants.recur_frequency),
          watch(apiConstants.selected_days),
          watch(apiConstants.monthly_frequency)
        );

        handleSetValue(apiConstants.custom_dates, preferedDates);

        setAvailableDates(
          preferedDates?.map((item: any) => {
            return new Date(item.start?.split(" ")?.[0]);
          })
        );
      }
    }
  }, [
    watch(apiConstants.event_start_date),
    watch(apiConstants.event_end_date),
    watch(apiConstants.repeat_every),
    watch(apiConstants.recur_frequency),
    watch(apiConstants.monthly_frequency),
    watch(apiConstants.selected_days),
  ]);

  const getInputValue = () => {
    if (watch(apiConstants.event_custom_date)?.length > 1) {
      return `${watch(apiConstants.event_custom_date)?.length} days selected`;
    } else if (watch(apiConstants.event_custom_date)?.length === 1) {
      return "1 day selected";
    } else {
      return "";
    }
  };
  useEffect(() => {
    getInputValue();
  }, [watch(apiConstants.event_custom_date)]);
  const customDayClassNames = [
    {
      "add-tick": availableDates,
    },
  ];

  const handleContinueClick = (props) => {
    if (watch(apiConstants.multiple_virtual_links_allowed) === false) {
      setIsMeetingLink(true);
    } else {
      handleSetValue(apiConstants.has_schedule_date, true);
      setScheduleLisiting(false);
    }
  };

  return (
    <div className={"modal show"}>
      <div
        className="modal-section v-modal new-schedule_modal"
        style={{ gap: "30px", maxWidth: isMeetingLink ? "998px" : "586px" }}
      >
        {isMeetingLink ? (
          <ScheduleListingMeeting
            setScheduleLisiting={setScheduleLisiting}
            watch={watch}
            handleSetValue={handleSetValue}
            data={watch(apiConstants?.virtual_links_multiple) || []}
          />
        ) : (
          <>
            <div
              className="modal-top-part"
              style={{ overflow: "unset", maxHeight: "fit-content" }}
            >
              <h4>Schedule listing</h4>
              <ul className="mytabs">
                <li
                  className={scheduleType === SCHEDULE_TYPE.O ? "active" : ""}
                  onClick={() => {
                    handleSetValue(apiConstants.schedule_type, SCHEDULE_TYPE.O);
                  }}
                >
                  <img src="/static/content-view.svg" alt="" /> One-time event
                </li>
                <li
                  className={scheduleType === SCHEDULE_TYPE.R ? "active" : ""}
                  onClick={() => {
                    handleSetValue(apiConstants.schedule_type, SCHEDULE_TYPE.R);
                  }}
                >
                  <img src="/static/arrow-repeat-all.svg" alt="" /> Recurring
                  class
                </li>
              </ul>
              {scheduleType === SCHEDULE_TYPE.R && (
                <>
                  <ul className="recurring_type">
                    <li
                      onClick={() =>
                        handleSetValue(apiConstants.recurring_type, "customday")
                      }
                      className={recurringType === "customday" ? "active" : ""}
                    >
                      <img src="/static/customday.svg" alt="" />
                      Select custom days
                    </li>
                    <li
                      onClick={() => {
                        handleSetValue(
                          apiConstants.recurring_type,
                          "recurring"
                        );
                        handleSetValue(apiConstants.event_start_time, null);
                        handleSetValue(apiConstants.event_end_time, null);
                        handleSetValue(apiConstants.event_start_date, null);
                        handleSetValue(apiConstants.event_end_date, null);
                      }}
                      className={recurringType === "recurring" ? "active" : ""}
                    >
                      <img src="/static/recurring.svg" alt="" />
                      Repeated frequency
                    </li>
                  </ul>
                </>
              )}
              {recurringType === RECURRING_TYPE.R &&
              scheduleType === SCHEDULE_TYPE.R ? (
                <div className="form_row">
                  <InputField
                    value={watch(apiConstants.repeat_every === "") || 6}
                    name={apiConstants.repeat_every}
                    type={"number"}
                    control={control}
                    inputLabel={"Repeat Every:"}
                    placeholder={"0"}
                    errors={errors}
                    className={"repeat_every"}
                    pattern={"[1-9][0-9]*"}
                  />
                  <div className="select_frequency">
                    <div className="form_group">
                      <div className="input_box" style={{ zIndex: 4 }}>
                        <span className="input_label">
                          {"Select Recur Frequency:"}
                        </span>
                        <input
                          type="text"
                          value={getValues(apiConstants.recur_frequency)}
                          onClick={() => {
                            setShowRecFrequency(!showRecFrequency);
                            handleSetValue(apiConstants.selected_days, []);
                          }}
                          name={apiConstants.recur_frequency}
                        />
                        {showRecFrequency ? (
                          <ul className="frequency_option selection">
                            {recurFrequency.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    watch(apiConstants.recur_frequency) === item
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => {
                                    handleSetValue(
                                      apiConstants.recur_frequency,
                                      item
                                    );
                                    setShowRecFrequency(!showRecFrequency);
                                  }}
                                >
                                  {item}
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                        {errors[apiConstants.recur_frequency] ? (
                          <span className="text-danger">{`${
                            errors[apiConstants.recur_frequency].message
                          }`}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="all-mid-part p-0 p-md-3">
                {scheduleType === SCHEDULE_TYPE.O && (
                  <div className="form_group">
                    <div className={"input_box"}>
                      <span className="input_label">Event Date</span>
                      <div className="one-time">
                        <MultiDateSelector
                          name={apiConstants.event_date}
                          control={control}
                          minDate={new Date()}
                          watch={watch}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {scheduleType === SCHEDULE_TYPE.R &&
                  recurringType === RECURRING_TYPE.C && (
                    <div className="form_group custom_day">
                      <div className={"input_box"}>
                        <span className="input_label">
                          Select Recur Frequency:
                        </span>
                        <div className="data-length-wrapper">
                          <div className="outer-container">
                            <MultiDateSelector
                              name={apiConstants.event_custom_date}
                              control={control}
                              minDate={new Date()}
                              multiple={true}
                              watch={watch}
                              errors={errors}
                            />
                            <div className="content-box">{getInputValue()}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {scheduleType === SCHEDULE_TYPE.R &&
                  recurringType === RECURRING_TYPE.R &&
                  getValues(apiConstants.recur_frequency) === "Week" && (
                    <>
                      <h5>Select recurrence days</h5>
                      <div
                        className="position-relative week_days"
                        style={{
                          marginBottom: errors[apiConstants.selected_days]
                            ? "20px"
                            : "",
                        }}
                      >
                        <ul className="weeklayset">
                          {WeekDays.map((item: any, index: number) => {
                            return (
                              <li
                                className={
                                  watch(apiConstants.selected_days)?.includes(
                                    item.value
                                  )
                                    ? "active"
                                    : ""
                                }
                                key={index}
                                onClick={() => handleWeekDays(item.value)}
                              >
                                {item.label}
                              </li>
                            );
                          })}
                        </ul>
                        {errors[apiConstants.selected_days] ? (
                          <span className="text-danger">
                            {"Please select atleast one recurrence day"}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                {scheduleType === SCHEDULE_TYPE.R &&
                recurringType === RECURRING_TYPE.R ? (
                  <>
                    <h5>Select dates to run this listing</h5>
                    <div className="two-row-input">
                      <div className="form_group">
                        <div className={"input_box"}>
                          <span className="input_label">Start Date:</span>
                          <div className="start-date-recurence">
                            <EventDateSelector
                              name={apiConstants.event_start_date}
                              control={control}
                              minDate={new Date()}
                              selectsStart
                              startDate={watch(apiConstants.event_start_date)}
                              shouldCloseOnSelect={true}
                              getValues={getValues}
                              watch={watch}
                              errors={errors}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form_group">
                        <div className="input_box">
                          <span className="input_label">End Date:</span>
                          <div className="end-date-recurence">
                            <EventDateSelector
                              name={apiConstants.event_end_date}
                              control={control}
                              selectsEnd
                              minDate={watch(apiConstants.event_start_date)}
                              startDate={getValues(
                                apiConstants.event_start_date
                              )}
                              endDate={watch(apiConstants.event_end_date)}
                              getValues={getValues}
                              watch={watch}
                              errors={errors}
                              shouldCloseOnSelect={true}
                              highlightDates={
                                watch(apiConstants.event_end_date)
                                  ? customDayClassNames
                                  : ""
                              }
                              disabled={watch(apiConstants.repeat_every) < 1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <h5>Select listing time</h5>
                <div className="two-row-input">
                  <div className="form_group position-relative">
                    <div className="input_box position-initial">
                      <span className="input_label">Start Time:</span>
                      <EventTimeSelector
                        name={apiConstants.event_start_time}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="form_group position-relative">
                    <div className="input_box position-initial">
                      <span className="input_label">End Time:</span>
                      <EventTimeSelector
                        name={apiConstants.event_end_time}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
                {watch(apiConstants.recur_frequency) === "Month" &&
                  scheduleType === SCHEDULE_TYPE.R &&
                  recurringType === RECURRING_TYPE.R &&
                  watch(apiConstants.event_start_date) && (
                    <div className="form_row" style={{ padding: "0" }}>
                      <div className="select_frequency month_frequency">
                        <div className="form_group">
                          <div className="input_box">
                            <span className="input_label">
                              {"Monthly Frequency:"}
                            </span>
                            <input
                              type="text"
                              value={watch(apiConstants.monthly_frequency)}
                              onClick={() => {
                                setShowMonthFrequency(!showMonthFrequency);
                              }}
                            />
                            {errors[apiConstants.monthly_frequency] ? (
                              <span className="text-danger">{`${
                                errors[apiConstants.monthly_frequency].message
                              }`}</span>
                            ) : null}
                            {showMonthFrequency ? (
                              <>
                                <ul className="frequency_option">
                                  {moment(
                                    watch(apiConstants.event_start_date)
                                  ).date() < 29 && (
                                    <li
                                      className={
                                        watch(apiConstants.on_the_basis) ===
                                        "weekday"
                                          ? "active"
                                          : ""
                                      }
                                      onClick={() => {
                                        handleSetValue(
                                          apiConstants.on_the_basis,
                                          "weekday"
                                        );
                                        setShowMonthFrequency(
                                          !showMonthFrequency
                                        );

                                        handleSetValue(
                                          apiConstants.monthly_frequency,
                                          `Monthly on day ${
                                            watch(
                                              apiConstants.selected_month_dates_options
                                            )?.day
                                          }`
                                        );
                                      }}
                                    >
                                      Monthly on day{" "}
                                      {
                                        watch(
                                          apiConstants.selected_month_dates_options
                                        )?.day
                                      }
                                    </li>
                                  )}
                                  <li
                                    className={
                                      watch(apiConstants.on_the_basis) ===
                                      "date"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      handleSetValue(
                                        apiConstants.on_the_basis,
                                        "date"
                                      );
                                      setShowMonthFrequency(
                                        !showMonthFrequency
                                      );
                                      handleSetValue(
                                        apiConstants.monthly_frequency,
                                        `Monthly on the ${
                                          watch(
                                            apiConstants.selected_month_dates_options
                                          )?.weakday
                                        }`
                                      );
                                    }}
                                  >
                                    Monthly on the
                                    {
                                      watch(
                                        apiConstants.selected_month_dates_options
                                      )?.weakday
                                    }
                                    {/* week of the month */}
                                  </li>
                                </ul>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="all-button">
              <div className="all-button-top">
                <Button
                  btnTxt="Cancel"
                  className="first-parts"
                  onClick={() => {
                    setScheduleLisiting(false);
                  }}
                />
                <Button
                  btnTxt="Add and continue"
                  className={"backtologin"}
                  onClick={handleScheduleListing}
                />
              </div>
              <p>By cancelling, your schedule progress won’t be saved</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleListingModal;
