import { Modal } from "react-bootstrap";
import Button from "../../../global/Button";
import OutlineButton from "../../../global/OutlineButton";
import Contact from "../../../assets/img/icons/Contact";
import RibbonStar from "../../../assets/img/icons/RibbonStar";
import Time from "../../../assets/img/icons/Time";
import PinnedLocation from "../../../assets/img/icons/PinnedLocation";
import {
  BookingTable,
  BookingTableResponsive,
} from "../../../global/BookingsDetailsTable";
import Community from "../../../assets/img/icons/Community";
import Dismiss from "../../../assets/img/icons/Dismiss";
import Sessions from "../../../assets/img/icons/Sessions";
import moment from "moment";

const BookingSessions = (props) => {
  const { bookingDetails ,setMapCenter,setMapSidebar} = props;
  const tableHeader = [
    {
      id: "name",
      label: "Name",
      highlight: true,
    },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone Number" },
  ];

  const tableData = [
    {
      name: "Laura Dzene",
      email: "lauradzene@gmail.com",
      phone: "+13 3343 4343",
    },
    {
      name: "Laura Dzene",
      email: "lauradzene@gmail.com",
      phone: "+13 3343 4343",
    },
    {
      name: "Laura Dzene",
      email: "lauradzene@gmail.com",
      phone: "+13 3343 4343",
    },
  ];

  const tableDataResponsive = [
    {
      name: "Laura Dzene",
      age: "27Yr",
      gender: "M",
      email: "lauradzene@gmail.com",
      phone: "+13 3343 4343",
    },
    {
      name: "Laura Dzene",
      age: "27Yr",
      gender: "M",
      email: "lauradzene@gmail.com",
      phone: "+13 3343 4343",
    },
    {
      name: "Laura Dzene",
      age: "27Yr",
      gender: "M",
      email: "lauradzene@gmail.com",
      phone: "+13 3343 4343",
    },
  ];

  return (
    <Modal
      {...props}
      centered
      dialogClassName="all_bookings_modal booking_sessions_modal"
    >
      <Modal.Body
        style={{ height: "fit-content" }}
        className=" all_bookings_modal_body"
      >
        <div
          className="emodal_wrapper mx-auto"
          style={{ height: "fit-content" }}
        >
          <div className="heading_bookings_container">
            <div className="heading_bookings d-flex flex-column justify-content-center align-items-center ">
              <Sessions />
              Sessions
            </div>
            <div className="dismiss_container">
              <Dismiss onClick={props?.onHide} />
            </div>
          </div>
          <div className="sub_heading_bookings">
            Please check all the available events
          </div>
          <div className="d-flex justify-content-between gap-3 bookings_stamp">
            <div className=" d-flex align-items-center ">
              <RibbonStar />
              <span className="ml-2 booking_text">
                Booking date:{" "}
                {moment(bookingDetails?.bookingDate).format("Do MMMM YYYY")}
              </span>
            </div>
            <div>
              <OutlineButton text="Download Ticket" icon={<Contact />} />
            </div>
          </div>
          <div className="max_modal_container mb-3 ">
            <div className="d-flex flex-column gap-3">
              {bookingDetails?.events?.map((item, index) => (
                <div
                  className="sessions_list_container"
                  key={"section" + index}
                >
                  <div className="date_wrapper">
                    <div className="date_month">
                      {moment(item?.date).format("MMMM")}
                    </div>
                    <div className="date_">
                      {moment(item?.date).format("D")}
                    </div>
                    <div className="date_day">
                      {moment(item?.date).format("ddd")}
                    </div>
                  </div>
                  <div className="details_wrapper">
                    <div className="list_heading">{item?.title}</div>
                    <div className="d-flex flex-column  gap-2 ">
                      <div className="location_container">
                        <div>
                          <PinnedLocation />
                        </div>
                        {item?.address}
                        <span className="location_link" onClick={(e) => {
                                                setMapCenter({
                                                  lat: Number(item?.location?.[1]),
                                                  lng: Number(item?.location?.[0]),
                                                });
                                                setMapSidebar(true);
                                                props?.onHide();
                                              }}> Open maps</span>
                      </div>
                      <div className="time_container">
                        <div>
                          <Time />
                        </div>
                        {moment(item?.startTime, "HH:mm:ss.SSSSSS").format(
                          "h:mm A"
                        )}{" "}
                        -{" "}
                        {moment(item?.endTime, "HH:mm:ss.SSSSSS").format(
                          "h:mm A"
                        )}
                        {/* 4:00 - 5:00 PM */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="button_wrapper_modal">
              <Button btnTxt="Back" onClick={props?.onHide} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BookingSessions;
