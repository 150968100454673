/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { googleKey } from "../utils/Constants";
import axios from "axios";
import InputField from "./InputField";
import { apiConstants } from "../utils/Constants";
import { LocationGreyFillIcon, LocationGreyIcon, RightArrowStickIcon } from "../utils/Icons/svgs";
//import Button from "./Button";
import Button from "../global/Button";

class GooglePlacesSearchBarFilterNew extends Component<Readonly<any>, any> {
    autocompleteInput: any = React.createRef();
    autocomplete: any = null;
    mapContainer: any = React.createRef();
    marker: any = null;
    map: any = null;

    constructor(props: Readonly<any>) {
        super(props);
        this.state = { radius: 5000 };
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
    }

    componentDidMount() {
        if (googleKey) {
            const options = {};
            this.autocomplete = new google.maps.places.Autocomplete(
                this.autocompleteInput.current,
                options
            );
            this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
            /// this.autocomplete.addListener("value_changed", this.handleAutocomplete);
        }
        // this.map = new google.maps.Map(this.mapContainer.current, {
        //     zoom: 15, // Or any default zoom level you prefer
        //     center: { lat: 51.6143684, lng: -0.1426624 }, // Default center, can be any lat-lng
        //     draggable: false,
        //     fullscreenControl: false,
        //     mapTypeControl: false,
        //     zoomControl: false,
        //     streetViewControl: false,
        // });
        //this.createRadiusSelectorControl();
    }

    componentDidUpdate(prevProps, prevState) {
        // Compare the previous props/state with current props/state
        // For example, if you want to check if a specific prop 'value' has changed
        console.log(this.autocompleteInput?.current?.value);
        if (this.autocompleteInput?.current?.value === '' && this.state.recentLoc === true) {
            // Call your function here
            this.setState({ recentLoc: false })
        }
    }

    componentWillUnmount() {
        if (this.autocomplete) {
            google.maps.event.clearInstanceListeners(this.autocomplete);
        }
    }

    handlePlaceChanged() {
        this.setState({ recentLoc: true })
        const place = this.autocomplete.getPlace();
        if (place && place.geometry) {
            let postalCode = 2000,
                region = "";
            const address_components = place.address_components;
            // eslint-disable-next-line array-callback-return
            address_components.map((component: any) => {
                if (component.types.includes("postal_code")) {
                    postalCode = component.short_name || component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                    region = component.short_name || component.long_name;
                }
                if (component.types.includes("country")) {
                    region = component.long_name || component.short_name;
                }
            });
            // Update the map's center to the selected place's location
            // this.map.setCenter(place.geometry.location);
            // this.map.setZoom(15); // Optional: Set a preferred zoom level

            // If a marker already exists, set its position to the new location
            if (this.marker) {
                this.marker.setPosition(place.geometry.location);
            } else {
                // Otherwise, create a new marker
                this.marker = new google.maps.Marker({
                    map: this.map,
                    position: place.geometry.location,
                });
            }

            this.props.onChangeLocation(
                place.geometry.location.lat(),
                place.geometry.location.lng(),
                place.formatted_address,
                address_components
            );
        }

    }

    getCurrentLocation() {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    // Center the map to the current location
                    //    this.map.setCenter({ lat, lng });
                    // If a marker already exists, set its position to the current location
                    if (this.marker) {
                        this.marker.setPosition({ lat, lng });
                    } else {
                        // Otherwise, create a new marker for the current location
                        this.marker = new google.maps.Marker({
                            map: this.map,
                            position: { lat, lng },
                        });
                    }

                    axios
                        .get(`https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}`)
                        .then((res) => {
                            if (res.data.display_name) {
                                this.setState({ address: res.data.display_name });
                                let location = this.props.handleLocation(
                                    lat,
                                    lng,
                                    res.data.display_name,
                                    [
                                        {
                                            long_name: res.data?.address?.city,
                                            short_name: res.data?.address?.city,
                                            types: ["administrative_area_level_1", "political"],
                                        },
                                        {
                                            long_name: res.data?.address?.state,
                                            short_name: res.data?.address?.state,
                                            types: ["administrative_area_level_1", "political"],
                                        },
                                        {
                                            long_name: res.data?.address?.country,
                                            short_name: res.data?.address?.country,
                                            types: ["administrative_area_level_1", "political"],
                                        },
                                    ]
                                );
                                this.props.setValue(
                                    apiConstants.address,
                                    res.data.display_name
                                );
                                this.props.setValue(apiConstants.lat, lat);
                                this.props.setValue(apiConstants.lng, lng);
                                this.props.setValue(apiConstants.postCode, location.zipcode);
                                this.props.setValue(apiConstants.state, location.state);
                                this.props.setValue(apiConstants.city, location.locality);
                                this.props.setValue(apiConstants.country, location.country);
                                this.props.onChangeLocation(lat, lng, res.data.display_name, [
                                    {
                                        long_name: res.data?.address?.city,
                                        short_name: res.data?.address?.city,
                                        types: ["administrative_area_level_1", "political"],
                                    },
                                    {
                                        long_name: res.data?.address?.state,
                                        short_name: res.data?.address?.state,
                                        types: ["administrative_area_level_1", "political"],
                                    },
                                    {
                                        long_name: res.data?.address?.country,
                                        short_name: res.data?.address?.country,
                                        types: ["administrative_area_level_1", "political"],
                                    },
                                ]);
                            }
                        })
                        .catch((err) => { });
                },
                (error) => {
                    console.error("Error fetching geolocation:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }

    createRadiusSelectorControl = () => {
        const radiusSelectorDiv = document.createElement("div");
        radiusSelectorDiv.className = "radius-selector";
        radiusSelectorDiv.innerHTML = `
      <span><img alt="" src ="/static/mapicon.svg"></img> </span>
      <select class="radiusSelect">
        <option value="5000" selected >5 KM</option>
        <option value="10000">10 KM</option>
        <option value="15000">15 KM</option>
        <option value="20000">20 KM</option>
      </select>
    `;

        radiusSelectorDiv
            .querySelector(".radiusSelect")
            .addEventListener("change", (e) => {
                const selectedRadius = e.target.value;
                this.setState({ radius: selectedRadius });
                this.props.onRadiusChange(selectedRadius);
            });

        // this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
        //     radiusSelectorDiv
        // );
    };

    handleAutocomplete() {
        const service = new window.google.maps.places.AutocompleteService();
        const input = this.autocompleteInput.current.value;
        if (input) {
            service.getQueryPredictions({ input }, (predictions, status) => {
                if (
                    status === window.google.maps.places.PlacesServiceStatus.OK &&
                    predictions
                ) {
                    // Clear previous results
                    const resultsList = document.getElementById("results");
                    resultsList.innerHTML = "";

                    // Display new predictions
                    predictions.forEach((prediction) => {
                        const li = document.createElement("div");
                        li.appendChild(document.createTextNode(prediction.description));
                        resultsList.appendChild(li);
                    });
                } else {
                    console.error(status);
                }
            });
        } else {
            const resultsList = document.getElementById("results");
            resultsList.innerHTML = "";
        }
    }

    render() {
        return (
            <div className="form_group google_search_bar" style={{ width: "100%" }}>
                <div className="" >
                    <div className="input_box input_box_new">
                        <InputField
                            reference={this.autocompleteInput}
                            id="autocomplete"
                            name={this.props.name}
                            value={this.props.address || this.props.value || ""}
                            control={this.props.control}
                            type="text"
                            inputLabel={this.props.label}
                            errors={this.props.errors}
                            className={this.props.className + " pad-class"}
                            placeholder={this.props.placeholder}
                            setValue={this.props.setValue}
                        />
                        <img
                            src="/static/currentlocation.svg"
                            alt=""
                            className="current_location_icon"
                            onClick={this.getCurrentLocation.bind(this)}
                        />
                        {/* <Button
                            disabled={!this.props.address}
                            // style={{ marginTop: "180px" }}
                            btnTxt="Select this place"
                            className={"backtologin d-none d-md-block"}
                            onClick={() => {
                                this.props.handleFilter();
                                this.props.hidePopup();
                            }}
                        /> */}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            fontSize: "14px",
                            color: "#8F939A",
                            gap: "4px",
                            alignItems: "center",
                        }}
                    >
                        {this.props.address ? (
                            <>
                                <span>
                                    <img alt="" src="/static/Location.svg" />
                                </span>
                                <span>{this.props.address}</span>
                            </>
                        ) : null}
                    </div>
                    {/*  */}
                    <div id="results" style={{ zIndex: "5" }}></div>

                    {
                        // this.state.recentLoc ?
                        //     <div className="selected-recent-loc">
                        //         <img className="recent-img" src="static/barina.svg" alt='' />
                        //         <div className="recent-full-loc mt-2">
                        //             <div className="d-flex">
                        //                 <LocationGreyFillIcon />
                        //             </div>
                        //             <p className="mb-0">{this.autocomplete.getPlace().formatted_address}</p>
                        //         </div>
                        //     </div>
                        //     : ""
                    }

                </div>
                {/* <div ref={this.mapContainer} className="maps-container"></div> */}
            </div>
        );
    }
}

export default GooglePlacesSearchBarFilterNew;
