import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { isUserAuthenticated, showToast } from "../../utils";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { enquiryForm } from "../../utils/FormConfig";
import { EnquiryValidations } from "../Auth/AuthValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiConstants } from "../../utils/Constants";
import Loader from "../../global/Loader";

const BookEvents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: enquiryForm,
    resolver: yupResolver(EnquiryValidations),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    let formData: any = new FormData();
    formData.append("name", data.name);
    formData.append("phone_number", data.phone_number);
    formData.append("email", data.email.toLowerCase());
    formData.append("message", data.message);
    setIsLoading(true);
    makePostCall({
      url: endpoints.enquiry_form,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };
  return (
    <section>
      <div className="book-event-section">
        <div className="content-box">
          <div className="content-left">
            <div className="start-heading">
              Start your Fitness Mates journey
            </div>
            <div className="title">Get updates & stay in the loop.</div>

            <div className="desc">
              Join the fitness mates family, create new mates and join on-going
              events and activities.
            </div>
          </div>
          <div className="content-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="book-event-top">
                <div className="small-title">Tell us who you are?</div>
                <div className="title orange-span">
                  Book events, find mates, business or trainer?
                </div>
              </div>

              <div className="form-input-box">
                <div className="name-phone-box">
                  <div className="text-field">
                    <Controller
                      name={apiConstants.name}
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className={`text-base ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          placeholder=" "
                        />
                      )}
                    />
                    <span>Full Name *</span>
                    {errors.name && (
                      <p className="text-danger" style={{ marginBottom: "0" }}>
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  {/* Phone Number input */}
                  <div className="text-field">
                    <Controller
                      name={apiConstants.phone_number}
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          maxLength={12}
                          placeholder=""
                          className={`text-base  ${
                            errors.phone_number ? "is-invalid" : ""
                          }`}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      )}
                    />
                    <span>Mobile Number*</span>
                    {errors.phone_number && (
                      <p
                        className="text-danger ml"
                        style={{ marginBottom: "0" }}
                      >
                        {errors.phone_number.message}
                      </p>
                    )}
                  </div>
                </div>
                {/* Email Address input */}
                <div className="text-field ">
                  <Controller
                    name={apiConstants.email}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="email"
                        placeholder=" "
                        className={`text-base wid ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  <span>Email Address*</span>
                  {errors.email && (
                    <p className="text-danger" style={{ marginBottom: "0" }}>
                      {errors.email.message}
                    </p>
                  )}
                </div>

                {/* Message textarea */}
                <div className="text-field">
                  <Controller
                    name={apiConstants.message}
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        placeholder=" "
                        className={`text-base wid ${
                          errors.name ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  <span>Add a message*</span>
                  {errors.message && (
                    <p className="text-danger" style={{ marginBottom: "0" }}>
                      {errors.message.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-32">
                <div className="button-box">
                  <div className="agree-text">
                    By continuing I agree to the storage & processing of my
                    data. For more info visit our{" "}
                    <Link
                      to={
                        isUserAuthenticated()
                          ? ROUTE_CONSTANTS.policies
                          : ROUTE_CONSTANTS.policy
                      }
                      style={{ textDecoration: "none" }}
                      className="PrivacyTerms-text"
                    >
                      privacy policy
                    </Link>
                    .
                  </div>
                  <button className="orange-btn" type="submit">
                    {isLoading ? (
                      <div style={{ minWidth: "128px" }}>
                        <Loader />
                      </div>
                    ) : (
                      <>send</>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookEvents;
