import React from "react";
import { Controller, useController } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface EventDateSelectorProps {
  [key: string]: any;
  name: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  errors?: object | any;
  id?: string;
  control?: any;
  minDate?: any;
  maxDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  dobError?: boolean;
  selectsStart?: any;
  selectsEnd?: any;
  startDate?: any;
  endDate?: any;
  getValues?: any;
  watch?: any;
  selectsRange?: any;
  inline?: any;

  highlightDates?: any;
  shouldCloseOnSelect: boolean;
  disabled?: boolean;
}
const EventDateSelector = (props: EventDateSelectorProps) => {
  const {
    name,
    control,
    errors,
    minDate,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
    disabled = false,
    shouldCloseOnSelect = true,
    highlightDates,
  } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <>
            <DatePicker
              selected={value}
              onChange={onChange}
              closeOnScroll={true}
              selectsStart={selectsStart}
              selectsEnd={selectsEnd}
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              dateFormat={"dd-MM-yyyy"}
              highlightDates={highlightDates}
              shouldCloseOnSelect={shouldCloseOnSelect}
              disabled={disabled}
            />
            {errors && errors[name] ? (
              <span className="text-danger">{errors[name]?.message}</span>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default EventDateSelector;
