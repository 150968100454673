/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../global/Button";
import InputField from "../../global/InputField";
import {
  FREQUENCY_TYPE,
  GendersType,
  GENDER_PREFERENCE,
  LISTING_TYPE,
  RECURRING_TYPE,
  SCHEDULE_TYPE,
  USER_TYPE,
  GendersTypeSpecific,
} from "../../types";
import {
  dateFormatter,
  fetchMoreIntrestList,
  getIntrestList,
  getItemFromStore,
  handleFileUpload,
  handleLocation,
  isEmptyOrNullOrUndefined,
  setLocalStorage,
  showToast,
} from "../../utils";
import {
  apiConstants,
  difficultyLevelPreferenceNew,
  filterOption,
  ListingTypesForGuest,
  ListingTypesForMember,
  meetingLinkRegex,
} from "../../utils/Constants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import GlobalHeader from "../Dashboard/GlobalHeader";

import useDebounce from "../../utils/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";

import ScheduleListingModal from "../Dashboard/CreateListing.tsx/ScheduleListingModal";
import {
  makeDeleteCall,
  makeGetCall,
  makePatchCall,
  makePostCall,
} from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import GooglePlacesSearchBar from "../../global/GooglePlaceSelector";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ListingFormDefaultValue } from "../../utils/FormConfig";
import DraftPopup from "../Dashboard/CreateListing.tsx/DraftPopup";
import { DateObject } from "react-multi-date-picker";
import { OutlineYellowButton } from "../../global/OutlineButton";
import AddIconYellow from "../../assets/img/icons/AddIconYellow";
import ScheduleListingSidebar from "../Dashboard/CreateListing.tsx/ScheduleListingSidebar";
import SaveEventPopup from "../Dashboard/CreateListing.tsx/SaveEventPopup";
import EventCard from "../Dashboard/CreateListing.tsx/EventCard";
import { useOutsideClick } from "../../hooks";
import { Table } from "react-bootstrap";
import "../../assets/scss/createListing.scss";
import FilesUploadComponent from "../Dashboard/FilesUploadComponent";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import PaymentOption from "../Dashboard/CreateListing.tsx/PaymentOption";
import SelectPayout from "../Dashboard/CreateListing.tsx/SelectPayout";
import MeetingLink from "../ListingComponents/MeetingLink/MeetingLink";
import LocationSection from "../ListingComponents/LocationSection/LocationSection";

const EditListing = () => {
  const [isDraft, setIsDraft] = useState(false);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
    trigger,
  } = useForm({
    defaultValues: ListingFormDefaultValue,
    // resolver: yupResolver(isDraft ? NoValidation : CreateListingValidation),
    mode: "onChange",
  });
  const [scheduleListing, setScheduleLisiting] = useState(false);
  const [isFeeInfoShown, setIsFeeInfoShown] = useState(false);

  const [showDraftPopUp, setShowDraftPopUp] = useState(false);
  const [filter, setFilter] = useState("popularity");
  const [showFilter, setShowFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [intrestList, setIntrestList] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [notSaved, setNotSaved] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<any>({
    isDraft: false,
    isCreate: false,
  });
  const [isRecommend, setIsRecommend] = useState<boolean>(true);
  const [paymentMode, setPaymentMode] = useState<string>(null);

  const [isLocationAccessible, setIsLocationAccessible] = useState<any>(false);
  const [showLocationPopup, setShowLocationPopup] = useState(false);

  const [isListingInfo, setIsListingInfo] = useState<any>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(false);
  const [isBookingInfo, setIsisBookingInfo] = useState<any>(false);
  const [showAddEventSidebar, setShowAddEventSidebar] = useState<any>(false);
  const [, setScheduleDate] = useState<any>("");
  const [editEvent, setEditEvent] = useState<any>(false);
  const [editEventId, setEditEventId] = useState<any>(null);
  const [userType] = useState(getItemFromStore("userProfile")?.userType);

  const userDetail = useState(getItemFromStore("userProfile"));
  const [commisionAmounts, setCommisionAmounts] = useState<any>(null);

  const [listingTypes, setListingType] = useState(
    userType === USER_TYPE.GUEST ? ListingTypesForGuest : ListingTypesForMember
  );
  const [currUserInfo, setCurrUserInfo] = useState(
    getItemFromStore("userProfile")
  );

  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };
  const [images, setImages] = useState([]);

  const Navigate = useNavigate();
  const location = useLocation();
  const listId = location.pathname.split("/")[2];

  const [multipleScheduleListing, setMultipleScheduleLisiting] =
    useState(false);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [, setAddEventOpen] = useState<boolean>(true);
  const [showAllEventSidebar, setShowAllEventSidebar] =
    useState<boolean>(false);
  const [showStripeError, setShowStripeError] = useState<boolean>(false);
  // create temp array for multiple event
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  var tempEventArray = [];

  const ref = useRef(null);
  const refInfo = useRef(null);

  const [isPaymentOpen, setIsPaymentOpen] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    if (searchTerm) {
      getIntrestList(
        { search: searchTerm, sort: filter },
        setIntrestList,
        setNextUrl,
        setIsLoading
      );
    }
  }, [debouncedSearchTerm, filter]);

  useEffect(() => {
    makeGetCall({ url: endpoints.draft })
      .then((res) => {
        if (res.status.code === 200) {
          setListingType(
            listingTypes.map((type) => {
              const matchingItem = res.data.find(
                (matchedItem: any) => matchedItem.listingType === type?.type
              );
              if (matchingItem) {
                return { ...type, ...matchingItem, hasDraft: true };
              } else {
                return { ...type, hasDraft: false };
              }
            })
          );
        } else {
          showToast(res?.status?.message, "error", "errRes");
        }
      })
      .catch((err) => showToast(err, "error", "errcatch"));
  }, [watch(apiConstants?.listingDetails), userType]);

  const handleIntrest = (intrest: any) => {
    const tempList = watch(apiConstants.selectedInterest) || [];
    let index = tempList.findIndex((intr: any) => intr.id === intrest.id);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(intrest);
    }

    handleSetValue(apiConstants.selectedInterest, [...tempList]);
  };

  useEffect(() => {
    if (!searchTerm) {
      setIntrestList([]);
    }
  }, [searchTerm]);

  const handleNext = ({ isDraft }: any) => {
    if (!isDraft) {
      if (watch(apiConstants.media).length < 1) {
        showToast("Minimum 1 media file is required", "error");
        return;
      } else if (!watch(apiConstants.listing_name)) {
        showToast("Listing name is required", "error");
        return;
      } else if (watch(apiConstants.listing_name)?.length < 3) {
        showToast("Listing name must be at least 3 characters long", "error");
        return;
      } else if (!watch(apiConstants.listing_description)) {
        showToast("Description is required", "error");
        return;
      } else if (watch(apiConstants.listing_description)?.length < 10) {
        showToast("Description must be at least 10 characters long", "error");
        return;
      } else if (
        !watch(apiConstants.selectedInterest) ||
        watch(apiConstants.selectedInterest)?.length < 1
      ) {
        showToast("Please add at least 1 interest", "error");
        return;
      }
      //  else if (
      //   watch(apiConstants.listing_event_type) === "V" &&
      //   (watch(apiConstants.multiple_virtual_links_allowed) !== true ||
      //     watch(apiConstants.multiple_virtual_links_allowed) !== false)
      // ) {
      //   showToast("Please select same virtual link or not", "error");
      //   return;
      // }
      else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        !watch(apiConstants.virtual_links_single)
      ) {
        showToast("Virtual meeting link is required", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        !watch(apiConstants.virtual_links_single) &&
        !meetingLinkRegex.googleMeetRegex.test(
          watch(apiConstants.virtual_links_single)
        ) &&
        !meetingLinkRegex.zoomRegex.test(
          watch(apiConstants.virtual_links_single)
        ) &&
        !meetingLinkRegex.teamsRegex.test(
          watch(apiConstants.virtual_links_single)
        )
      ) {
        showToast("Virtual meeting link is not valid", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "P" &&
        watch(apiConstants.listingDetails)?.type === LISTING_TYPE.S &&
        !watch(apiConstants.address)
      ) {
        showToast("Location is required", "error");
        return;
      } else if (
        !watch(apiConstants.max_participants) ||
        watch(apiConstants.max_participants) < 1
      ) {
        showToast("Minimum 1 participant is required", "error");
        return;
      } else if (
        watch(apiConstants.max_participants) > 4 &&
        currUserInfo?.userType === "Guest"
      ) {
        showToast("Max 4 participant is allowed", "error");
        return;
      } else if (
        (!watch(apiConstants.listing_cost) ||
          watch(apiConstants.listing_cost) < 1) &&
        currUserInfo?.userType !== "Guest" &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        showToast("Minimum cost allowed is 1", "error");
        return;
      } else if (
        watch(apiConstants.multipleEvents)?.length < 2 &&
        watch(apiConstants.listingDetails)?.type === LISTING_TYPE.M
      ) {
        showToast("Please add minimum 2 events", "error");
        return;
      } else if (
        !watch(apiConstants.has_schedule_date) &&
        watch(apiConstants.listingDetails)?.type === LISTING_TYPE.S
      ) {
        showToast("Schedule dates is required", "error");
        return;
      } else if (
        !watch(apiConstants.payment_mode) &&
        currUserInfo?.userType !== "Guest" &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        showToast("Please select the required payment method", "error");
        return;
      } else if (
        watch(apiConstants.isCancellationAllowed) &&
        !watch(apiConstants.cancellation_allowed_till_hours) &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        showToast("Please select allowed cancellation range", "error");
        return;
      } else if (
        watch(apiConstants.is_specific_age) &&
        (!watch(apiConstants.min_age) || !watch(apiConstants.max_age))
      ) {
        showToast("Min and max age is required", "error");
        return;
      } else if (
        !watch(apiConstants.selectedGender) ||
        watch(apiConstants.selectedGender)?.length < 1
      ) {
        showToast("Gender is required", "error");
        return;
      } else if (
        !watch(apiConstants.selecteddifficulty) ||
        watch(apiConstants.selecteddifficulty)?.length < 1
      ) {
        showToast("Difficulty level is required", "error");
        return;
      }
    }

    let tempIntrestList: any = [];
    if (watch(apiConstants.selectedInterest)?.length > 0) {
      tempIntrestList = watch(apiConstants.selectedInterest).map(
        (item: any) => {
          return item.id;
        }
      );
      handleSetValue(apiConstants.interests, tempIntrestList);
    }

    let tempSelectedInstruction: any = [];
    if (watch(apiConstants.selectedInstruction)?.length > 0) {
      tempSelectedInstruction = watch(apiConstants.selectedInstruction).map(
        (item: any) => {
          return item;
        }
      );
      handleSetValue(apiConstants.selectedInstruction, tempSelectedInstruction);
    }

    handleSubmit(async (data) => {
      if (
        currUserInfo?.userType !== "Guest" &&
        !watch(apiConstants.payment_mode) &&
        !Object.keys(errors).length &&
        !isDraft &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        setShowStripeError(true);
      } else {
        let reqData: {
          [key: string]: any;
        } = {};
        let addressObject: {
          [key: string]: any;
        } = {};
        let scheduleDetails: {
          [key: string]: any;
        } = {};

        // Set reqData values
        reqData.isActive = !watch(apiConstants.isDraft);
        reqData.listingType = getValues(apiConstants.listingDetails)?.type;

        if (data?.isListingPrivate) {
          reqData.isPrivate = getValues(apiConstants.is_listing_private);
        }

        reqData.isDraft = watch(apiConstants.isDraft);

        if (data?.title) {
          reqData.title = data?.title;
        }
        if (data?.description) {
          reqData.description = data?.description;
        }
        if (data?.interests) {
          reqData.interests = data?.interests;
        }

        if (data?.selected_instruction?.length > 0) {
          reqData.special_instruction = data?.selected_instruction;
        }
        if (data?.lat) {
          addressObject.location = `POINT (${data?.lng} ${data.lat})`;
        } else {
          addressObject.location = watch(apiConstants.listing_location);
        }

        addressObject.city = "";
        addressObject.state = "";
        addressObject.postCode = "";
        addressObject.address = data?.address;
        addressObject.country = "";

        if (watch("eventListingType") === "P") {
          scheduleDetails.address = addressObject;
        }

        if (watch(apiConstants.selectedGender)?.length) {
          reqData.gender = watch(apiConstants.selectedGender);
        }
        // else if (
        //   !watch(apiConstants.selectedGender)?.length &&
        //   GENDER_PREFERENCE.A
        // ) {
        //   reqData.gender = GendersType.map((g) => g.value);
        // }

        if (watch(apiConstants.selecteddifficulty)?.length) {
          reqData.difficultyLevel = watch(apiConstants.selecteddifficulty).map(
            ([key, value]) => key
          );
        }
        reqData.is_age_limit = data.minAge ? true : false;
        reqData["listing_event_type"] = getValues("eventListingType");

        if (dirtyFields["minAge"] && getValues(apiConstants.min_age) > 0) {
          reqData.minAge = data?.minAge ? parseInt(data?.minAge) : null;
        }
        if (dirtyFields["maxAge"] && getValues(apiConstants.max_age) > 0) {
          reqData.maxAge = data?.maxAge ? parseInt(data?.maxAge) : null;
        }
        setLoadingState({ isDraft, isCreate: !isDraft });

        const media = await getValues(apiConstants.media);
        const uploadResponse = await handleUploadFiles(media);
        reqData["document_details"] = uploadResponse;

        if (data.schedule_type === SCHEDULE_TYPE.R) {
          scheduleDetails.single_listing_type =
            data?.recurring_type === RECURRING_TYPE.R ? "R" : "C";
        } else if (data.schedule_type === SCHEDULE_TYPE.O) {
          scheduleDetails.single_listing_type = "O";
        }
        if (data.event_date || data.event_start_date || data.custom_dates) {
          if (data.schedule_type === SCHEDULE_TYPE.R) {
            if (data.recurring_type === RECURRING_TYPE.R) {
              scheduleDetails.start_date = moment(data.event_start_date).format(
                "YYYY-MM-DD"
              );
            } else if (data.recurring_type === RECURRING_TYPE.C) {
              scheduleDetails.dates = data?.custom_dates?.map((i) =>
                i.start ? moment(i.start).format("YYYY-MM-DD") : i
              );
            }
          } else if (data.schedule_type === SCHEDULE_TYPE.O) {
            scheduleDetails.date = dateFormatter(
              data?.event_start_date,
              "YYYY-MM-DD"
            );
          }
        }

        if (getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.M) {
          if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === true
          ) {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData["virtual_links"] = getValues(
              apiConstants.virtual_links_single
            );
          } else if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          ) {
            const newData = getValues(apiConstants.multipleEvents)?.map(
              ({ virtual_links_package, date, ...others }) => {
                return { virtual_link: virtual_links_package, date };
              }
            );
            reqData["multiple_virtual_links_allowed"] = true;
            reqData["virtual_links"] = newData;
          }
        } else if (
          getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.S
        ) {
          if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === true
          ) {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData["virtual_links"] = getValues(
              apiConstants.virtual_links_single
            );
          } else if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          ) {
            const newData = getValues(apiConstants.virtual_links_multiple);
            reqData["multiple_virtual_links_allowed"] = true;
            reqData["virtual_links"] = newData.map((val) => {
              const { event_start_time, event_end_time, ...rest } = val;
              return { ...rest };
            });
          }
        } else {
          if (watch("eventListingType") === "V") {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData["virtual_links"] = getValues(
              apiConstants.virtual_links_single
            );
          }
        }

        if (data?.event_date || data?.event_end_date || data?.custom_dates) {
          if (
            data.schedule_type === SCHEDULE_TYPE.R &&
            data.recurring_type === RECURRING_TYPE.R
          ) {
            scheduleDetails.end_date = moment(data?.event_end_date).format(
              "YYYY-MM-DD"
            );
          }
        }

        if (data?.event_start_time) {
          scheduleDetails.start_time =
            moment(data?.event_start_time).format("HH:mm:ss.SSSSSS") || null;
        }
        if (data?.event_end_time) {
          scheduleDetails.end_time =
            moment(data?.event_end_time).format("HH:mm:ss.SSSSSS") || null;
        }

        if (
          data.totalParticipants &&
          getValues(apiConstants.max_participants) > 0
        ) {
          reqData.max_participants = data.totalParticipants;
        }
        if (data?.price) {
          reqData.price = data.price;
        }

        if (watch(apiConstants.payment_mode)) {
          if (
            getValues(apiConstants.payment_mode) === "Through Stripe" &&
            getValues(apiConstants.stripe_account_id)
          ) {
            reqData["existing_or_new"] =
              getValues(apiConstants.existing_or_new) || null;
            reqData["stripe_connect_bank"] = getValues(
              apiConstants.stripe_account_id
            );
            reqData["payment_mode"] = getValues(apiConstants.payment_mode);
          }
          if (watch(apiConstants.payment_mode) === "Direct Bank Transfer") {
            reqData.payment_mode = watch(apiConstants.payment_mode);
            reqData.direct_bank_account_name = watch(apiConstants.bank_name);
            reqData.direct_bank_bsb_number = watch(apiConstants.bsb_number);
            reqData.direct_bank_account_number = watch(
              apiConstants.account_number
            );
          } else {
            reqData.payment_mode = watch(apiConstants.payment_mode);
          }
        }

        if (
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.C &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.M
        ) {
          if (watch(apiConstants.recurring_type) === RECURRING_TYPE.R) {
            scheduleDetails.isRecurring =
              watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O
                ? false
                : true;
            scheduleDetails.frequency_number =
              watch(apiConstants.repeat_every) || null;
            scheduleDetails.frequency =
              data?.recur_frequency === FREQUENCY_TYPE.D
                ? "D"
                : data?.recur_frequency === FREQUENCY_TYPE.M
                ? "M"
                : data?.recur_frequency === FREQUENCY_TYPE.W
                ? "W"
                : "";

            scheduleDetails.recurringType =
              data?.recurring_type === "recurring" ? "R" : "C";

            scheduleDetails.monthly_frequency = /on\s+Day/i.test(
              getValues(apiConstants.monthly_frequency)
            )
              ? "day"
              : "week" || "";
            scheduleDetails.monthly_frequency_desc = getValues(
              apiConstants.monthly_frequency
            );
            scheduleDetails.week_days = watch(apiConstants.selected_days);
          }
        }

        if (
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.C &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.M
        ) {
          reqData.schedule_details = scheduleDetails;
        } else if (
          getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.M
        ) {
          let tempMultipleEvents = getValues(apiConstants.multipleEvents).map(
            (i) => ({
              address: i.address,
              date: i.date,
              end_time: i.event_end_time || i.endTime,
              start_time: i.event_start_time || i.startTime,
              title: i.title,
            })
          );
          reqData.schedule_details = tempMultipleEvents;
        } else {
          reqData.schedule_details = scheduleDetails;
        }

        if (getValues(apiConstants.cancellation_allowed_till_hours)) {
          reqData.cancellation_allowed_till_hours = getValues(
            apiConstants.cancellation_allowed_till_hours
          );
        }

        reqData.is_cancellation_allowed = getValues(
          apiConstants.isCancellationAllowed
        );

        reqData.payment_mode =
          getValues(apiConstants.payment_mode) || "Direct Bank Transfer";

        const listingId = getValues(apiConstants.listingDetails)?.id;
        if (listingId) {
          setLoadingState({ isDraft, isCreate: !isDraft });
          setIsDisable(true);
          makePatchCall({
            url: `${endpoints.listing}${listingId}`,
            apiPayload: reqData,
            content_type: "application/json",
          })
            .then((res) => {
              setShowDraftPopUp(false);
              if (res?.status?.code === 200) {
                showToast(res?.status?.message, "success");
                Navigate("/drafts");
              } else {
                showToast(res?.status?.message, "error", "errmess2");
              }
              setLoadingState({ isDraft: false, isCreate: false });
              setIsDraft(false);
              setIsDisable(false);
              if (!isDraft) {
                Navigate("/view-listing");
              } else {
                Navigate("/drafts");
              }
            })
            .catch((err) => {
              setLoadingState({ isDraft: false, isCreate: false });
              setShowDraftPopUp(false);
              setIsDisable(false);
              showToast(err?.status?.message, "error", "errmes21");
            });
        } else {
          handleSetValue(apiConstants.isDraft, false);
          setLoadingState({ isDraft, isCreate: !isDraft });
          setIsDisable(true);

          makePostCall({
            url: endpoints.listing,
            apiPayload: reqData,
            content_type: "application/json",
          })
            .then((res) => {
              setShowDraftPopUp(false);
              if (res?.status?.code === 200) {
                showToast(res.status.message, "success");
              } else {
                showToast(res?.status?.message, "error", "errmess23");
              }
              setLoadingState({ isDraft: false, isCreate: false });
              setIsDraft(false);
              setIsDisable(false);
              if (!isDraft) {
                Navigate("/view-listing");
              } else {
                Navigate("/drafts");
              }
            })
            .catch((err) => {
              setShowDraftPopUp(false);
              setLoadingState({ isDraft: false, isCreate: false });
              setIsDisable(false);
              showToast(err?.status?.message, "error", "errmess234");
            });
        }
      }
    })();
  };

  useEffect(() => {
    getListData();
  }, [listId]);

  useEffect(() => {
    let intervalId;

    if (isPaymentOpen) {
      getStripeInfo();
      intervalId = setInterval(getStripeInfo, 5000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPaymentOpen]);

  const getListData = () => {
    if (listId) {
      makeGetCall({ url: endpoints.listing + `/${listId}` }).then((res) => {
        const temp: any = {};
        const item = res.data;
        const pointString = item?.scheduleDetails?.address?.location;
        const coordinates = pointString?.match(/-?\d+\.\d+/g);
        const [lng, lat] = coordinates?.map(parseFloat) || [
          "-25.363",
          "131.044",
        ];
        tempEventArray = res?.data?.scheduleDetails;
        temp[apiConstants.is_listing_private] = res?.data?.isPrivate;
        temp[apiConstants.listing_event_type] =
          res?.data?.listingEventType || "V";
        temp["listingType"] = res?.data?.listingType;
        temp[apiConstants.listingDetails] = {
          type: res?.data?.listingType,
          id: res?.data?.id,
          singleListingType: res.data?.scheduleDetails?.singleListingType,
          hasDraft: res?.data?.isDraft,
        };
        temp[apiConstants.isDraft] = res.data?.isDraft;
        setImages(res.data?.documents || []);
        temp[apiConstants.listing_name] = res.data?.title;
        temp[apiConstants.listing_description] = res?.data?.description;
        temp[apiConstants.selectedInterest] = res.data?.interests;
        temp[apiConstants.selectedInstruction] = res.data?.specialInstruction;
        temp[apiConstants.listing_location] =
          res.data?.scheduleDetails?.address?.location;

        temp[apiConstants.payment_mode] = res.data?.paymentMode;
        temp["eventListingType"] = res.data?.listingEventType;
        setPaymentMode(res.data?.paymentMode);
        temp[apiConstants.stripe_account_id] = res.data?.stripeConnectBank;
        temp[apiConstants.existing_or_new] = res.data?.existingOrNew;
        temp[apiConstants.bank_name] = res.data?.directBankAccountName;
        temp[apiConstants.bank_name] = res.data?.directBankAccountName;
        temp[apiConstants.bsb_number] = res.data?.directBankBsbNumber;
        temp[apiConstants.account_number] = res.data?.directBankAccountNumber;

        //new field
        temp[apiConstants.multiple_virtual_links_allowed] =
          !res.data?.multipleVirtualLinksAllowed;

        temp[apiConstants.max_participants] =
          item?.type !== LISTING_TYPE.C
            ? res?.data?.maxParticipants
            : res.data?.clubScheduleDetails?.slot;

        temp[apiConstants.listing_cost] =
          item?.type !== LISTING_TYPE.C && userType !== "Guest"
            ? res.data?.price
            : res.data?.clubScheduleDetails?.monthlyCost;

        temp[apiConstants.multipleEvents] = res?.data?.scheduleDetails;
        //new changes
        // temp[apiConstants.event_custom_date] =
        //   res?.data?.scheduleDetails.dates.map((val) => new Date(val));
        // temp[apiConstants.custom_dates] = res?.data?.scheduleDetails.dates.map(
        //   (val) => new Date(val)
        // );

        temp[apiConstants.lat] = lat;
        temp[apiConstants.lng] = lng;
        temp[apiConstants.city] = res.data?.scheduleDetails?.address?.city;
        temp[apiConstants.state] = res.data?.scheduleDetails?.address?.state;
        temp[apiConstants.country] =
          res.data?.scheduleDetails?.address?.country;
        temp[apiConstants.postCode] =
          res.data?.scheduleDetails?.address?.postCode;
        temp[apiConstants.listing_location] =
          res.data?.scheduleDetails?.address?.location;
        temp[apiConstants.gender_preference] = res.data?.gender;
        temp[apiConstants.difficulty_level] = res.data?.difficultyLevel;
        temp[apiConstants.upload_image] = res.data?.media;
        temp[apiConstants.address] =
          res.data?.scheduleDetails?.address?.address;
        temp[apiConstants.image] = res.data?.media;
        temp[apiConstants.min_age] = res.data?.minAge;
        temp[apiConstants.max_age] = res.data?.maxAge;
        temp[apiConstants.is_specific_age] =
          res.data?.minAge || res.data?.maxAge ? true : false;
        temp[apiConstants.isCancellationAllowed] =
          res.data?.isCancellationAllowed;
        temp[apiConstants.payment_mode] = res.data?.paymentMode;
        temp[apiConstants.cancellation_allowed_till_hours] =
          res.data?.cancellationAllowedTillHours;

        temp[apiConstants.virtual_links_single] =
          res.data?.scheduleDetails?.virtualLinks || "";

        if (item?.type !== LISTING_TYPE.C) {
          temp[apiConstants.has_schedule_date] =
            handleShowScheduleDate() !== null || undefined ? true : false;

          if (res.data.scheduleDetails?.singleListingType === "O") {
            temp[apiConstants.schedule_type] = SCHEDULE_TYPE.O;

            temp[apiConstants.event_date] =
              res.data?.scheduleDetails?.date !== null
                ? moment(res.data?.scheduleDetails?.date).isBefore(moment())
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.date)
                : "";
            temp[apiConstants.event_start_date] =
              res.data?.scheduleDetails?.date !== null
                ? moment(res.data?.scheduleDetails?.date).isBefore(moment())
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.date)
                : undefined;

            temp[apiConstants.event_end_date] =
              res.data?.scheduleDetails?.date !== null
                ? moment(res.data?.scheduleDetails?.date).isBefore(moment())
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.date)
                : undefined;

            temp[apiConstants.event_end_time] = res.data?.scheduleDetails
              ?.endTime
              ? new Date(
                  res.data?.scheduleDetails?.date +
                    " " +
                    res.data?.scheduleDetails?.endTime
                )
              : "";
            temp[apiConstants.event_start_time] = res.data?.scheduleDetails
              ?.startTime
              ? new Date(
                  res.data?.scheduleDetails?.date +
                    " " +
                    res.data?.scheduleDetails.startTime
                )
              : "";
          } else if (res.data.scheduleDetails?.singleListingType === "C") {
            temp[apiConstants.event_date] = res.data?.scheduleDetails?.dates
              ? moment(res.data?.scheduleDetails?.dates[0]).isBefore(moment())
                ? new Date()
                : new Date(res.data?.scheduleDetails?.dates[0])
              : "";
            temp[apiConstants.event_start_date] = res.data?.scheduleDetails
              ?.dates
              ? moment(res.data?.scheduleDetails?.dates[0]).isBefore(moment())
                ? new Date()
                : new Date(res.data?.scheduleDetails?.dates[0])
              : undefined;
            temp[apiConstants.event_end_date] = res.data?.scheduleDetails?.dates
              ? moment(res.data?.scheduleDetails?.dates[0]).isBefore(moment())
                ? new Date()
                : new Date(res.data?.scheduleDetails?.dates[0])
              : undefined;

            temp[apiConstants.event_end_time] =
              res.data?.scheduleDetails?.dates &&
              res.data?.scheduleDetails?.endTime
                ? new Date(
                    res.data?.scheduleDetails?.dates[0] +
                      " " +
                      res.data?.scheduleDetails?.endTime
                  )
                : "";
            temp[apiConstants.event_start_time] =
              res.data?.scheduleDetails?.dates &&
              res.data?.scheduleDetails?.startTime
                ? new Date(
                    res.data?.scheduleDetails.dates[0] +
                      " " +
                      res.data?.scheduleDetails.startTime
                  )
                : "";
            temp[apiConstants.schedule_type] = SCHEDULE_TYPE.R;
            const virtual_links = res.data?.scheduleDetails?.virtualLinks?.map(
              (event) => {
                return {
                  date: moment(event.date, "YYYY-MM-DD hh:mm A").format(
                    "YYYY-MM-DD"
                  ),
                  start: moment(
                    new Date(
                      event.date + " " + res.data?.scheduleDetails.startTime
                    )
                  ).format("YYYY-MM-DD hh:mm A"),
                  end: moment(
                    new Date(
                      event.date + " " + res.data?.scheduleDetails.endTime
                    )
                  ).format("YYYY-MM-DD hh:mm A"),
                  virtual_link: event.virtualLink,
                };
              }
            );

            temp[apiConstants.virtual_links_multiple] = virtual_links || [];
            temp[apiConstants.recurring_type] = RECURRING_TYPE.C;
            temp[apiConstants.custom_dates] =
              res?.data?.scheduleDetails.virtualLinks?.map((event) => {
                return {
                  start: moment(
                    new Date(
                      event.date + " " + res.data?.scheduleDetails.startTime
                    )
                  ).format("YYYY-MM-DD hh:mm A"),
                  end: moment(
                    new Date(
                      event.date + " " + res.data?.scheduleDetails.endTime
                    )
                  ).format("YYYY-MM-DD hh:mm A"),
                  virtual_link: event.virtualLink,
                };
              });

            if (res.data?.scheduleDetails?.dates) {
              temp[apiConstants.event_custom_date] =
                // res?.data?.scheduleDetails.dates.map((val) => new Date(val));
                res.data?.scheduleDetails?.dates.map((item: any) => {
                  return new DateObject().setDay(
                    Number(dateFormatter(item, "DD"))
                  );
                  // .add(
                  //   !moment(item).isSame(moment().toDate(), "month")
                  //     ? moment(item).diff(moment(), "months") + 1
                  //     : 0,
                  //   "month"
                  // );
                });
            }
          } else if (res.data.scheduleDetails?.singleListingType === "R") {
            temp[apiConstants.event_date] =
              res.data?.scheduleDetails?.event_date !== null
                ? moment(res.data?.scheduleDetails?.startDate).isBefore(
                    moment()
                  )
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.startDate)
                : "";
            temp[apiConstants.event_start_date] =
              res.data?.scheduleDetails?.startDate !== null
                ? moment(res.data?.scheduleDetails?.startDate).isBefore(
                    moment()
                  )
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.startDate)
                : undefined;
            temp[apiConstants.event_end_date] =
              res.data?.scheduleDetails?.endDate !== null
                ? moment(res.data?.scheduleDetails?.date).isBefore(moment())
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.endDate)
                : undefined;

            temp[apiConstants.event_end_time] = res.data?.scheduleDetails
              ?.endTime
              ? new Date(
                  res.data?.scheduleDetails?.endDate +
                    " " +
                    res.data?.scheduleDetails?.endTime
                )
              : "";
            temp[apiConstants.event_start_time] = res.data?.scheduleDetails
              ?.startTime
              ? new Date(
                  res.data?.scheduleDetails.startDate +
                    " " +
                    res.data?.scheduleDetails.startTime
                )
              : "";
            temp[apiConstants.recurring_type] = RECURRING_TYPE.R;
            if (
              res.data?.scheduleDetails?.singleListingType === "R" &&
              res.data?.scheduleDetails?.singleListingType
            ) {
              temp[apiConstants.repeat_every] =
                res.data?.scheduleDetails?.frequencyNumber >= 1
                  ? res.data?.scheduleDetails?.frequencyNumber
                  : 1;
              temp[apiConstants.selected_days] =
                res.data?.scheduleDetails?.weekDays;
              temp[apiConstants.monthly_frequency] =
                res.data?.scheduleDetails?.monthlyFrequencyDesc;
              temp[apiConstants.recur_frequency] =
                res.data?.scheduleDetails?.frequency === "D"
                  ? FREQUENCY_TYPE.D
                  : res.data?.scheduleDetails?.frequency === "M"
                  ? FREQUENCY_TYPE.M
                  : FREQUENCY_TYPE.W;
            }
            temp[apiConstants.selected_month_dates] = {};
            temp[apiConstants.schedule_type] = SCHEDULE_TYPE.R;
            temp[apiConstants.on_the_basis] = "";
          } else if (res?.data?.listingType === "P") {
            temp[apiConstants.schedule_type] = SCHEDULE_TYPE.O;
          }
          temp[apiConstants.isDraft] = false;
        }
        reset(temp);
        temp[apiConstants.user_type] = userType;
      });
    }
  };

  function calculatePercentageOfNumber(percentage: any, number: any) {
    const value = (percentage / 100) * number;

    const stringValue = value.toString();
    return Number(parseFloat(stringValue).toFixed(2));
  }

  const handleUploadFiles = async (files = []) => {
    let finalMedia = [];

    for (const val of files) {
      if (!val?.uploadDocumentUrl) {
        const res = await makeGetCall({
          url: `${endpoints.signed_url}/${val.file.name}`,
        });

        if (res.status.code === 200) {
          const {
            AWSAccessKeyId = "",
            key = "",
            policy = "",
            signature = "",
          } = res?.data?.fields;
          const uploadedResponse = await handleFileUpload(
            val.file,
            res?.data?.url,
            key,
            AWSAccessKeyId,
            policy,
            signature
          );
          finalMedia.push(uploadedResponse);
        }
      }
    }

    return finalMedia;
  };

  const handleMediaFIles = (media: any) => {
    setValue(apiConstants.media, media);
  };

  const handleShowScheduleDate = () => {
    if (
      watch(apiConstants.event_start_time) &&
      watch(apiConstants.event_end_time)
    ) {
      if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O &&
        watch(apiConstants.event_date)
      ) {
        return (
          <>
            <p>
              {dateFormatter(
                watch(apiConstants.event_start_date),
                "MMMM Do, YYYY"
              )}
            </p>
            <small>
              {`${moment(
                getValues(apiConstants.event_start_time),
                "HH:mm"
              ).format("hh:mm A")} - ${moment(
                getValues(apiConstants.event_end_time),
                "HH:mm"
              ).format("hh:mm A")}`}
              {/* {dateFormatter(getValues(apiConstants.event_start_time), "hh:mm")}{" "}
              -
              {dateFormatter(getValues(apiConstants.event_end_time), "hh:mm A")} */}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.C &&
        watch(apiConstants.event_custom_date)
      ) {
        return (
          <>
            <p>
              {getValues(apiConstants.event_custom_date)?.length > 1
                ? `${
                    getValues(apiConstants.event_custom_date)?.length
                  } days selected`
                : getValues(apiConstants.event_custom_date)?.length === 1
                ? `${
                    getValues(apiConstants.event_custom_date)?.length
                  } day selected`
                : ""}
            </p>
            <small>
              {dateFormatter(
                getValues(apiConstants?.event_start_time),
                "hh:mm"
              )}{" "}
              -
              {dateFormatter(
                getValues(apiConstants?.event_end_time),
                "hh:mm A"
              )}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.R &&
        watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.W &&
        watch(apiConstants.event_start_date) &&
        watch(apiConstants.event_end_date) &&
        watch(apiConstants.recur_frequency) &&
        watch(apiConstants.selected_days) &&
        watch(apiConstants.repeat_every)
      ) {
        return (
          <>
            <p>
              Every {getValues(apiConstants.repeat_every)}{" "}
              {getValues(apiConstants.recur_frequency)}{" "}
            </p>
            <small>
              {watch(apiConstants?.selected_days)?.length > 0
                ? watch(apiConstants?.selected_days)?.map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {" "}
                          {item.slice(0, 3) + " "}
                        </Fragment>
                      );
                    }
                  )
                : null}
              {dateFormatter(getValues(apiConstants.event_start_time), "hh:mm")}{" "}
              -
              {dateFormatter(getValues(apiConstants.event_end_time), "hh:mm A")}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.R &&
        watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.D &&
        watch(apiConstants.event_start_date) &&
        watch(apiConstants.event_end_date) &&
        watch(apiConstants.recur_frequency) &&
        watch(apiConstants.selected_days) &&
        watch(apiConstants.repeat_every)
      ) {
        return (
          <>
            <p>
              Every {getValues(apiConstants.repeat_every)}{" "}
              {getValues(apiConstants.recur_frequency)}{" "}
            </p>
            <small>
              {watch(apiConstants.selected_days)?.length > 0
                ? watch(apiConstants.selected_days)?.map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {" "}
                          {item.slice(0, 3) + " "}
                        </Fragment>
                      );
                    }
                  )
                : null}
              {dateFormatter(
                getValues(apiConstants?.event_start_time),
                "hh:mm"
              )}{" "}
              -
              {dateFormatter(
                getValues(apiConstants?.event_end_time),
                "hh:mm A"
              )}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.R &&
        watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.M &&
        watch(apiConstants.event_start_date) &&
        watch(apiConstants.event_end_date) &&
        watch(apiConstants.monthly_frequency) &&
        watch(apiConstants.repeat_every)
      ) {
        return (
          <>
            <p>
              Every {getValues(apiConstants.repeat_every)}{" "}
              {getValues(apiConstants.recur_frequency)}{" "}
              {getValues(apiConstants.monthly_frequency)
                ?.split(" ")
                ?.splice(1)
                ?.join(" ")}
            </p>
            <small>
              {dateFormatter(
                getValues(apiConstants?.event_start_time),
                "hh:mm"
              )}{" "}
              -
              {dateFormatter(
                getValues(apiConstants?.event_end_time),
                "hh:mm A"
              )}
            </small>
          </>
        );
      } else {
        return null;
      }
    } else {
      return (
        <p style={{ color: "#8F939A" }}>
          {STRING_CONSTANTS.schedule_date_placeholder}
        </p>
      );
    }
  };

  useOutsideClick(ref, () => {
    if (searchTerm) {
      setSearchTerm("");
    }
  });

  useOutsideClick(refInfo, () => {
    setIsListingInfo(false);
    setPaymentInfo(false);
    setIsisBookingInfo(false);
  });

  const tempObj = {
    id: new Date().getTime(),
    title: watch(apiConstants?.event_title),
    address: {
      location:
        watch(apiConstants?.listing_location) ||
        `POINT (${watch(apiConstants.lng)} ${watch(apiConstants.lat)})`,
      address: watch(apiConstants?.address),
      city: watch(apiConstants?.city),
      state: watch(apiConstants?.state),
      country: watch(apiConstants?.country),
      post_code: watch(apiConstants?.postCode),
    },
    date: dateFormatter(getValues(apiConstants.event_start_date), "YYYY-MM-DD"),
    startTime:
      moment(getValues(apiConstants.event_start_time)).format(
        "HH:mm:ss.SSSSSS"
      ) || null,
    endTime:
      moment(getValues(apiConstants.event_end_time)).format(
        "HH:mm:ss.SSSSSS"
      ) || null,
  };

  const onDeleteEvent = (index) =>
    setValue(
      apiConstants.multipleEvents,
      getValues(apiConstants.multipleEvents).filter((_, idx) => idx !== index)
    );

  useEffect(() => {
    if (watch(apiConstants.image)) {
      delete errors["image"];
    }
    if (watch(apiConstants.selectedInterest)) {
      delete errors["selectedInterest"];
    }
    if (watch(apiConstants.selectedGender)) {
      delete errors["selectedGender"];
    }
    if (watch(apiConstants.selecteddifficulty)) {
      delete errors["selecteddifficulty"];
    }
  }, [watch(apiConstants.image), watch(apiConstants.selectedInterest)]);

  function isItInt(n) {
    return Number(n) % 1 === 0;
  }

  const listingCostCalculator = (listingCost, platformFee, merchantFee) => {
    let actualAmount: any = 0;
    let merchantFeeDeduction: any = (listingCost * merchantFee) / 100;
    let platformFeeDeduction: any =
      (listingCost - merchantFeeDeduction) * (platformFee / 100);
    actualAmount = listingCost - merchantFeeDeduction - platformFeeDeduction;

    if (!isItInt(platformFeeDeduction)) {
      platformFeeDeduction = parseFloat(String(platformFeeDeduction)).toFixed(
        2
      );
    }
    if (!isItInt(merchantFeeDeduction)) {
      merchantFeeDeduction = parseFloat(String(merchantFeeDeduction)).toFixed(
        2
      );
    }
    if (!isItInt(actualAmount)) {
      actualAmount = parseFloat(String(actualAmount)).toFixed(2);
    }

    return {
      actualAmount,
      platformFeeDeduction,
      merchantFeeDeduction,
    };
  };

  const handleDiscardListing = () => {
    makeDeleteCall({
      url: `${endpoints.listing}?listing_id=${
        getValues(apiConstants.listingDetails)?.id
      }`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setShowDraftPopUp(false);
          showToast(res.status.message, "success");
          reset({
            ListingFormDefaultValue,
          });
        }
      })
      .catch((err) => showToast(err, "error", "errmess43"));
  };
  const getStripeInfo = () => {
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
          };
          setLocalStorage("userProfile", data);
          setCurrUserInfo(data);
        }
      })
      .catch((err) => {
        showToast(err, "error", "errmess2645");
      });
  };

  const handleInstructions = (inst: any) => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    tempList.push(inst);
    handleSetValue(apiConstants.selectedInstruction, [...tempList]);
    setValue(apiConstants.eventInstruction, "");
  };
  const handleRemoveInstructions = (indexToRemove: any) => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    tempList = tempList.filter((item, index) => index !== indexToRemove);
    handleSetValue(apiConstants.selectedInstruction, [...tempList]);
    setValue(apiConstants.eventInstruction, "");
  };

  const handleConnectStripe = () => {
    makePostCall({ url: endpoints.connect_stripe })
      .then((res) => {
        if (res?.status.code === 200) {
          window.open(res?.data, "_blank", "rel=noopener noreferrer");
          showToast(res.status.message, "success");
        }
      })
      .catch((err) => {
        showToast(err?.status?.message, "error");
      });
  };

  useEffect(() => {
    let intervalId;

    if (isPaymentOpen) {
      getStripeInfo();
      intervalId = setInterval(getStripeInfo, 5000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPaymentOpen]);

  const handleBankTransfer = () => {
    if (watch(apiConstants.payment_mode) === "Direct Bank Transfer") {
      if (
        !watch(apiConstants.bank_name) ||
        !watch(apiConstants.bsb_number) ||
        !watch(apiConstants.account_number)
      ) {
        handleNext({ isDraft: false });
        showToast("Please enter required bank details", "error", "bankerror1");
      } else if (
        watch(apiConstants.payment_mode) === "Direct Bank Transfer" &&
        !(
          errors[apiConstants.bank_name] ||
          errors[apiConstants.bsb_number] ||
          errors[apiConstants.account_number]
        )
      ) {
        setIsPaymentOpen(false);
      }
    } else {
      if (watch(apiConstants.payment_mode) === "Through Stripe") {
        handleConnectStripe();
        // if (
        //   currUserInfo?.userType !== "Guest" &&
        //   watch(apiConstants.payment_mode) === "Through Stripe" &&
        //   currUserInfo?.stripeAccountId
        //   // && currUserInfo?.bankAccountLastFourDigit
        // ) {
        //   setIsPaymentOpen(false);
        // } else {
        //   handleConnectStripe();
        // }
      }
    }
  };

  const selectedPreferences = Array.isArray(watch("gender_preference"))
    ? watch("gender_preference")
    : watch(apiConstants.gender_preference);

  const selectedPreferencesDifficulty = Array.isArray(watch("difficulty_level"))
    ? watch("difficulty_level")
    : watch(apiConstants.difficulty_level);

  const handleGender = (gender: any) => {
    const tempList = watch(apiConstants.selectedGender) || [];
    let index = tempList.findIndex((gen: any) => gen === gender);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(gender);
    }
    handleSetValue(apiConstants.selectedGender, [...tempList]);
  };
  const handleDifficulty = (level: any) => {
    const tempList = watch(apiConstants.selecteddifficulty) || [];
    let index = tempList.findIndex((lev: any) => lev === level);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(level);
    }
    handleSetValue(apiConstants.selecteddifficulty, [...tempList]);
  };

  useEffect(() => {
    handleSetValue(apiConstants.selectedGender, selectedPreferences);
    handleSetValue(
      apiConstants.selecteddifficulty,
      selectedPreferencesDifficulty
    );
  }, [selectedPreferences, selectedPreferencesDifficulty]);

  useEffect(() => {
    if (watch(apiConstants.selectedGender)) {
      delete errors["selectedGender"];
    }
  }, [watch(apiConstants.selectedGender)]);

  useEffect(() => {
    if (watch(apiConstants.selecteddifficulty)) {
      delete errors["selecteddifficulty"];
    }
  }, [watch(apiConstants.selecteddifficulty)]);

  useEffect(() => {
    makeGetCall({ url: endpoints.commission_amounts })
      .then((res) => {
        if (res.status.code === 200) {
          setCommisionAmounts(res.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  return (
    <>
      <GlobalHeader2 />
      {isPaymentOpen ? (
        <PaymentOption
          setIsPaymentOpen={setIsPaymentOpen}
          isRecommend={isRecommend}
          setIsRecommend={setIsRecommend}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          setValue={setValue}
          control={control}
          errors={errors}
          handleConnectStripe={handleConnectStripe}
        />
      ) : (
        <div className="user-profile create_listing create_listing_main">
          <div className="user-white-part-width p-0">
            <div className="user-white-part-large listing_types">
              <div className="lsiting-detials">
                <img
                  src="/static/leftarrow.svg"
                  alt=""
                  className="back_arrow"
                  onClick={() => {
                    //   if (!isObjEmpty(dirtyFields) && notSaved) {
                    if (Object.keys(dirtyFields).length > 2) {
                      setShowDraftPopUp(true);
                    } else {
                      setShowDraftPopUp(false);
                      Navigate(-1);
                    }
                  }}
                />
                <div className="d-flex justify-content-between d-md-none mb-1">
                  <div
                    className="mb-1  position-absolute pe-3 "
                    style={{ cursor: "pointer", right: "0px" }}
                    onClick={() => {
                      setIsDraft(true);
                      handleSetValue(apiConstants.isDraft, true);
                      setTimeout(() => {
                        handleNext({ isDraft: true });
                      }, 500);
                    }}
                  >
                    <img src="/static/saveResDraft.png" alt="" />
                  </div>
                </div>
                <img
                  src="/static/leftarrow.svg"
                  alt=""
                  className="back_arrow d-none d-md-block"
                  onClick={() => {
                    if (Object.keys(dirtyFields).length > 2) {
                      setShowDraftPopUp(true);
                    } else {
                      setShowDraftPopUp(false);
                      Navigate(-1);
                    }
                  }}
                />
                <div className="listing_heading_main">
                  {watch(apiConstants.listingDetails)?.type === LISTING_TYPE.M
                    ? "Create a package"
                    : watch(apiConstants.listingDetails)?.type ===
                      LISTING_TYPE.C
                    ? "Create a team"
                    : "Create a single session"}
                </div>

                <FilesUploadComponent
                  handleMediaFIles={handleMediaFIles}
                  images={images}
                  setImages={setImages}
                />
                {watch(apiConstants.media)?.length < 1 && errors?.media ? (
                  <div className="mb-4">
                    <span className="text-danger">{`${errors.media.message}`}</span>
                  </div>
                ) : null}

                <div className="main-heaidng">
                  <h6>
                    {watch(apiConstants.listingDetails)?.type === LISTING_TYPE.S
                      ? STRING_CONSTANTS.listing_details
                      : watch(apiConstants.listingDetails)?.type ===
                        LISTING_TYPE.M
                      ? STRING_CONSTANTS.multiple_details
                      : STRING_CONSTANTS.team_details}
                  </h6>
                  <img
                    src="/static/info.svg"
                    alt=""
                    onClick={() => setIsListingInfo(!isListingInfo)}
                  />
                </div>
                {!apiConstants.isDraft ? (
                  <Button
                    btnTxt="Save to draft"
                    className="draft_btn d-none d-md-block"
                    isLoading={loadingState.isDraft}
                    onClick={() => {
                      setNotSaved(false);
                      setIsDraft(true);
                      handleSetValue(apiConstants.isDraft, true);
                      setTimeout(() => {
                        handleNext({ isDraft: true });
                      }, 500);
                    }}
                    disabled={isDisable}
                  />
                ) : null}

                <InputField
                  type={"text"}
                  name={apiConstants.listing_name}
                  inputLabel={"Listing Name"}
                  placeholder={"Name your listing"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <InputField
                  isListingTextArea={true}
                  type={"textarea"}
                  name={apiConstants.listing_description}
                  inputLabel={
                    watch(apiConstants.listingDetails)?.type === LISTING_TYPE.C
                      ? "Team Description & Benefits"
                      : "Listing Description"
                  }
                  className={"listing_desc"}
                  placeholder={"Tell others more about this listing"}
                  control={control}
                  maxlength={"300"}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                />
                {isListingInfo && (
                  <div className="info_box" ref={refInfo}>
                    <img src={"/static/greyinfo.svg"} alt="" />{" "}
                    {STRING_CONSTANTS.listing_info}
                  </div>
                )}
                <div
                  className="wth-dorpwodn"
                  ref={ref}
                  style={{ marginTop: isListingInfo ? "64px" : "auto" }}
                >
                  <div className="form_group with-button">
                    <div className="search-interest input_box">
                      <span className="input_label">Interest Category</span>

                      <input
                        type="text"
                        value={searchTerm}
                        placeholder="Search for an interest"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <img
                        src="/static/cross-circle-gray.svg"
                        alt=""
                        onClick={() => setSearchTerm("")}
                        className={"corss-icon"}
                      />
                    </div>

                    <div
                      className="filter-outer"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={
                          filter
                            ? "/static/filterbtnselect.svg"
                            : "/static/filterbtnunselect.svg"
                        }
                        alt=""
                        className="filter-icon"
                        onClick={() => setShowFilter(!showFilter)}
                      />
                      {showFilter ? (
                        <ul className="filter-dropdown">
                          {filterOption.map((item, index) => {
                            return (
                              <li
                                className={
                                  filter === item.value ? "active" : ""
                                }
                                key={index}
                                onClick={() => {
                                  setFilter(
                                    filter === item.value ? "" : item.value
                                  );
                                  setShowFilter(!showFilter);
                                }}
                              >
                                <div>
                                  {filter === item.value ? (
                                    <img src="/static/victory.svg" alt="" />
                                  ) : (
                                    <img
                                      src="/static/recently-added.svg"
                                      alt=""
                                    />
                                  )}
                                  <span>{item.label}</span>
                                  {filter === item.value ? (
                                    <img
                                      src={"/static/orange-radio-btn.svg"}
                                      alt=""
                                      className="select_img"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                  {errors?.selectedInterest?.message && (
                    <span className="text-danger interest-error">
                      {STRING_CONSTANTS.please_add_interest}
                    </span>
                  )}
                  {watch(apiConstants.selectedInterest)?.length > 0 && (
                    <ul className="selected_title">
                      {watch(apiConstants.selectedInterest).map(
                        (item: any, index: number) => {
                          return index < 3 ? (
                            <li key={index}>
                              {item.title}{" "}
                              <img
                                src={"static/cross-circle-gray-dark.svg"}
                                alt=""
                                onClick={(e) => {
                                  handleIntrest(item);
                                }}
                              />{" "}
                            </li>
                          ) : null;
                        }
                      )}
                      {watch(apiConstants.selectedInterest)?.length > 3 && (
                        <span className="more">
                          +{" "}
                          {watch(apiConstants.selectedInterest)?.length -
                            3 +
                            " " +
                            "more"}
                        </span>
                      )}
                    </ul>
                  )}
                  {!isEmptyOrNullOrUndefined(searchTerm) ? (
                    <div
                      className="data-setup"
                      style={{
                        maxHeight: "500px",
                        overflowY: "scroll",
                        scrollbarWidth: "thin",
                        paddingRight: "6px",
                      }}
                    >
                      {isLoading ? (
                        <ApiLoader />
                      ) : intrestList?.length > 0 ? (
                        isLoading ? (
                          <ApiLoader />
                        ) : (
                          <InfiniteScroll
                            dataLength={intrestList?.length}
                            next={() =>
                              fetchMoreIntrestList(
                                nextUrl,
                                intrestList,
                                setNextUrl,
                                setIntrestList,
                                setIsLoading
                              )
                            }
                            hasMore={nextUrl ? true : false}
                            loader={<ApiLoader />}
                          >
                            <ul>
                              {intrestList?.map((item: any, index: number) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={(e) => {
                                      handleIntrest(item);
                                    }}
                                    className={
                                      watch(
                                        apiConstants.selectedInterest
                                      )?.some(
                                        (intr: any) => intr.id === item.id
                                      )
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <img src={item?.image} alt="" />

                                    <p>
                                      {item.title}{" "}
                                      {watch(
                                        apiConstants.selectedInterest
                                      )?.some(
                                        (intr: any) => intr.id === item.id
                                      ) ? (
                                        <img
                                          className="tick"
                                          src="/static/checked.svg"
                                          alt=""
                                        />
                                      ) : (
                                        ""
                                      )}{" "}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          </InfiniteScroll>
                        )
                      ) : (
                        <div className="no_data">
                          <img src="/static/Search.svg" alt="" />
                          <h3>{STRING_CONSTANTS.no_result_found}</h3>
                          <p>{STRING_CONSTANTS.please_try_another_term}</p>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="listing_type_container">
                  <div className="listing_type_heading">Listing Type</div>
                  <div>
                    <div className="listing_type_radio">
                      <img
                        src={
                          watch("eventListingType") === "P"
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() => handleSetValue("eventListingType", "P")}
                      />
                      <label
                        className={
                          watch("eventListingType") === "P"
                            ? "listing_ype_label active"
                            : "listing_ype_label"
                        }
                        onClick={() => handleSetValue("eventListingType", "P")}
                        style={{ cursor: "pointer" }}
                      >
                        Physical
                      </label>
                      <img
                        src={
                          "/static/createListingIcon/physicalListingType.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="listing_type_radio">
                      <img
                        src={
                          watch("eventListingType") === "V"
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() => handleSetValue("eventListingType", "V")}
                      />
                      <label
                        className={
                          watch("eventListingType") === "V"
                            ? "listing_ype_label active"
                            : "listing_ype_label"
                        }
                        onClick={() => handleSetValue("eventListingType", "V")}
                        style={{ cursor: "pointer" }}
                      >
                        Virtual
                      </label>
                      <img
                        src={"/static/createListingIcon/virtualListingType.svg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {watch(apiConstants.listing_event_type) === "V" && (
                  <MeetingLink
                    watch={watch}
                    handleSetValue={handleSetValue}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    isTeam={watch(apiConstants.listingDetails)?.type === "C"}
                  />
                )}

                <div className="main-had mt-3">
                  <h3 className="headis">
                    {STRING_CONSTANTS.special_instructions}
                  </h3>
                  <div
                    className="add-upto"
                    onClick={() => {
                      if (
                        getValues(apiConstants.eventInstruction)?.length !==
                          0 &&
                        watch(apiConstants.selectedInstruction)?.length < 5
                      ) {
                        handleInstructions(
                          getValues(apiConstants.eventInstruction)
                        );
                      }
                    }}
                  >
                    <h3
                      className={`${
                        watch(apiConstants.selectedInstruction)?.length >= 5
                          ? "headis-grey"
                          : "headis-orange"
                      }`}
                    >
                      {`Added ${
                        watch(apiConstants.selectedInstruction)?.length || 0
                      } of 5`}
                    </h3>
                    {watch(apiConstants.selectedInstruction)?.length >= 5 ? (
                      <img src="/static/grey_plus.png" alt="" />
                    ) : (
                      <img src="/static/orange_plus.png" alt="" />
                    )}
                  </div>
                </div>

                <InputField
                  isSpecialInstruction={true}
                  type={"textarea"}
                  name={apiConstants.eventInstruction}
                  className={"input-special"}
                  placeholder={"Type event instructions here"}
                  control={control}
                  value={watch(apiConstants.eventInstruction)}
                  getValues={getValues}
                  errors={errors}
                  maxlength={"50"}
                  setValue={setValue}
                  disabled={
                    watch(apiConstants.selectedInstruction)?.length >= 5 ||
                    false
                  }
                />

                {watch(apiConstants.selectedInstruction)?.length > 0 && (
                  <div className="instructions-wrapper">
                    {watch(apiConstants.selectedInstruction).map(
                      (item: any, index: number) => {
                        return (
                          <div key={index} className="instruction ">
                            {item}{" "}
                            <img
                              src={"static/cross-circle-gray-dark.svg"}
                              alt=""
                              onClick={() => {
                                handleRemoveInstructions(index);
                              }}
                            />{" "}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

                {watch(apiConstants.listingDetails)?.type ===
                  LISTING_TYPE.M && (
                  <div className="main-had" style={{ marginTop: "24px" }}>
                    <h3
                      className="headis"
                      onClick={() => {
                        if (watch(apiConstants.multipleEvents)?.length > 0) {
                          setShowAddEventSidebar(true);
                          setAddEventOpen(true);
                        }
                      }}
                      style={{
                        cursor: `${
                          watch(apiConstants.multipleEvents)?.length > 0
                        }`
                          ? "pointer"
                          : "text",
                      }}
                    >
                      Add Event
                    </h3>
                    <img src="/static/info.svg" alt="" />
                  </div>
                )}
                {getValues(apiConstants.multipleEvents)?.length > 0 ? (
                  <div
                    style={{
                      border: "1px solid #f3f3f3",
                    }}
                  >
                    {watch(apiConstants.multipleEvents)
                      ?.slice(0, 3)
                      ?.map((i, idx) => {
                        return (
                          <EventCard
                            isDivider={idx === 2 ? false : true}
                            style={{ border: "none", marginBottom: "0" }}
                            key={idx}
                            title={i?.title}
                            date={i.date}
                            location={i?.address?.address}
                            end_time={moment(i?.endTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                            start_time={moment(i?.startTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                            onDeleteClick={() => onDeleteEvent(idx)}
                            onEditClick={() => {
                              setEditEvent(true);
                              setEditEventId(i.id);
                              setValue(apiConstants.event_title, i?.title);
                              setValue(apiConstants.city, i?.address?.city);
                              setValue(apiConstants.state, i?.address?.state);
                              setValue(
                                apiConstants.country,
                                i?.address?.country
                              );
                              setValue(
                                apiConstants.postCode,
                                i?.address?.postCode
                              );
                              setValue(
                                apiConstants.address,
                                i?.address?.address
                              );
                              setValue(
                                apiConstants.listing_location,
                                i?.address?.location
                              );
                              setValue(
                                apiConstants.event_date,
                                new Date(i.date)
                              );
                              setValue(
                                apiConstants.event_start_date,
                                new Date(i.date)
                              );
                              setValue(
                                apiConstants.event_start_time,
                                new Date(i?.date + " " + i?.startTime)
                              );
                              setValue(
                                apiConstants.event_end_time,
                                new Date(i?.date + " " + i?.endTime)
                              );

                              setShowAddEventSidebar(true);
                            }}
                          />
                        );
                      })}
                    {getValues(apiConstants.multipleEvents)?.length > 3 ? (
                      <div className="view-all-box">
                        <span onClick={() => setShowAllEventSidebar(true)}>
                          View All Events{" "}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="11"
                              viewBox="0 0 12 11"
                              fill="none"
                            >
                              <path
                                d="M6.94902 10.3478C6.72332 10.5612 6.3673 10.5513 6.15383 10.3256C5.94037 10.0999 5.95028 9.74391 6.17598 9.53044L10.1053 5.81411L0.5625 5.81411C0.25184 5.81411 2.43135e-07 5.56227 2.29555e-07 5.25161C2.15976e-07 4.94095 0.25184 4.68911 0.5625 4.68911L10.107 4.68911L6.17598 0.971168C5.95028 0.757699 5.94037 0.401682 6.15383 0.175982C6.3673 -0.0497193 6.72332 -0.0596349 6.94902 0.153834L11.7619 4.70591C11.8867 4.82388 11.9617 4.97396 11.9869 5.13055C11.9955 5.16955 12 5.21005 12 5.25161C12 5.29481 11.9951 5.33686 11.9859 5.37726C11.9597 5.53161 11.885 5.67929 11.7619 5.79569L6.94902 10.3478Z"
                                fill="#25272D"
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {watch(apiConstants.listingDetails)?.type ===
                  LISTING_TYPE.M && (
                  <div className="mt-3">
                    <OutlineYellowButton
                      text="Add Event"
                      icon={<AddIconYellow />}
                      onClick={() => {
                        setShowAddEventSidebar(true);
                        setAddEventOpen(true);
                      }}
                    />
                  </div>
                )}

                {watch("eventListingType") === "P" && (
                  <LocationSection
                    watch={watch}
                    setIsLocationAccessible={setIsLocationAccessible}
                    setShowLocationPopup={setShowLocationPopup}
                    isLocationAccessible={isLocationAccessible}
                    handleLocation={handleLocation}
                    handleSetValue={handleSetValue}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                  />
                )}

                <div className="main-had" style={{ marginTop: "28px" }}>
                  <h3 className="headis">
                    {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
                      ? "Payment and scheduling"
                      : "Participants"}
                  </h3>
                  <img
                    src="/static/info.svg"
                    alt=""
                    className="info_icon"
                    onClick={() => setPaymentInfo(!paymentInfo)}
                  />
                </div>

                <div
                  className={`${
                    userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
                      ? ""
                      : "two-row"
                  }no_cross`}
                >
                  <div
                    style={{
                      position: "relative",
                      marginBottom: paymentInfo ? "64px" : "auto",
                      flexGrow: 1,
                    }}
                  >
                    <InputField
                      type={"number"}
                      name={apiConstants.max_participants}
                      inputLabel={"Max Participants"}
                      placeholder={"Add your max participants"}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                    />
                    {paymentInfo && (
                      <div className="info_box" ref={refInfo}>
                        <img src={"/static/greyinfo.svg"} alt="" />{" "}
                        {STRING_CONSTANTS.max_participant_info}
                      </div>
                    )}
                  </div>

                  {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingDetails)?.type !==
                      LISTING_TYPE.C && (
                      <div
                        style={{
                          position: "relative",
                          display: userType === USER_TYPE.GUEST ? "none" : "",
                          marginBottom: paymentInfo ? "64px" : "auto",
                        }}
                      >
                        <InputField
                          type={"number"}
                          name={apiConstants.listing_cost}
                          inputLabel={
                            watch(apiConstants.listingDetails)?.type ===
                            LISTING_TYPE.C
                              ? "Team Listing Cost"
                              : "Event Cost"
                          }
                          placeholder={"Add your joining cost"}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                        />
                        {paymentInfo && (
                          <div className="info_box" ref={refInfo}>
                            <img src={"/static/greyinfo.svg"} alt="" />{" "}
                            {STRING_CONSTANTS.listing_cost_info}
                          </div>
                        )}
                      </div>
                    )}
                </div>

                {userType !== USER_TYPE.GUEST ? (
                  <>
                    {watch(apiConstants.listing_cost) ? (
                      <>
                        <div className="earning-box-wrap">
                          <div className="earning-title">
                            Your Earnings per Participant
                          </div>
                          <div className="amount_earning">
                            $
                            {(Number(watch(apiConstants.listing_cost)) || 0) -
                              calculatePercentageOfNumber(
                                Number(currUserInfo?.merchantFee || 0),
                                Number(watch(apiConstants.listing_cost)) || 0
                              )}
                          </div>
                          <div className="info_main">
                            <div>
                              <img alt="" src="/static/smallinfo.svg" />
                            </div>
                            <div>
                              Your earnings per participants will be dependent
                              on number of attendees added per booking.
                            </div>
                          </div>
                          <div className="divider" />
                          <div className="fee-box">
                            <div>
                              <div className="fee-title">Merchant Fee: </div>
                              <div className="desc">
                                $
                                {calculatePercentageOfNumber(
                                  currUserInfo?.merchantFee || 0,
                                  watch(apiConstants.listing_cost)
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="info">
                            <span>Note:</span> Your earnings are subjective to
                            deduction of platform fee as well as merchant fee,
                            in order to gain more knowledge{" "}
                            <Link
                              to="#"
                              //target="_blank"
                              className="link_terms"
                              onClick={() => setIsFeeInfoShown(!isFeeInfoShown)}
                            >
                              {isFeeInfoShown ? "Hide" : "Click here."}
                            </Link>
                          </div>
                          {isFeeInfoShown ? (
                            <>
                              <Table responsive borderless bsPrefix="pay-table">
                                <thead className="pay-t-header">
                                  <tr>
                                    <th className="first">
                                      Number of percipients
                                    </th>
                                    <th className="second">
                                      Platform fixed Fee per booking per person
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {commisionAmounts?.map((item, index) => {
                                    return (
                                      <tr>
                                        <td>
                                          {item.minimumSlots}{" "}
                                          {item.minimumSlots ===
                                          item.maximumSlots
                                            ? ""
                                            : item.maximumSlots === null
                                            ? "plus"
                                            : `to ${item.maximumSlots}`}
                                        </td>
                                        <td>${item.commissionAmount}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                              <div className="fee-box">
                                <div>
                                  <div className="fee-title">
                                    Merchant Fee:{" "}
                                  </div>
                                  <div className="desc">
                                    $
                                    {calculatePercentageOfNumber(
                                      currUserInfo?.merchantFee || 0,
                                      watch(apiConstants.listing_cost)
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="divider" />
                              <div className="info">
                                <span>Note:</span>
                                {watch(apiConstants.listingDetails)?.type ===
                                LISTING_TYPE.C
                                  ? " You will be charged on monthly basis per booking"
                                  : watch(apiConstants.listingDetails)?.type ===
                                    LISTING_TYPE.M
                                  ? "You will be charged per package booking"
                                  : "You will be charged as per the number of events"}
                              </div>
                              <div className="terms-info">
                                To gain a better understanding of the platform
                                fees charges, please review the{" "}
                                <Link
                                  className="terms-link"
                                  to="/policies"
                                  target="_blank"
                                >
                                  terms and conditions.
                                </Link>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C &&
                watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.M ? (
                  <div className="form_group with-button">
                    <div className="input_box">
                      <span className="input_label">Schedule Dates</span>
                      <div
                        className="schedule_date_input"
                        onClick={() => setScheduleLisiting(true)}
                      >
                        {handleShowScheduleDate()}
                      </div>
                      {errors[apiConstants.has_schedule_date] ? (
                        <span className="text-danger">
                          {`${errors[apiConstants.has_schedule_date]?.message}`}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {userType !== USER_TYPE.GUEST &&
                watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C ? (
                  <div className="mb-4">
                    <div className="main-had">
                      <h3 className="headis">
                        How do you want to receive the payment
                      </h3>
                      <img
                        src="/static/info.svg"
                        alt=""
                        className="info_icon"
                      />
                    </div>
                    <SelectPayout
                      type={
                        watch(apiConstants.payment_mode) &&
                        watch(apiConstants.stripe_account_id)
                          ? "stripe"
                          : watch(apiConstants.payment_mode) &&
                            watch(apiConstants.bsb_number)
                          ? "direct"
                          : ""
                      }
                      getValues={getValues}
                      setIsPaymentOpen={setIsPaymentOpen}
                    />

                    {errors[apiConstants.bank_name] ||
                    errors[apiConstants.bsb_number] ||
                    errors[apiConstants.account_number] ? (
                      <span className="text-danger">
                        {"Please enter required bank details"}
                      </span>
                    ) : null}

                    {showStripeError && !watch(apiConstants.payment_mode) ? (
                      <span className="text-danger">
                        {"Please select the required payment method."}
                      </span>
                    ) : null}
                  </div>
                ) : null}

                {userType !== USER_TYPE.GUEST &&
                  watch(apiConstants.listingDetails)?.type !==
                    LISTING_TYPE.C && (
                    <div className="main-heaidng">
                      <h6>Allow booking to cancel</h6>
                      <img
                        src="/static/info.svg"
                        alt=""
                        onClick={() => setIsisBookingInfo(!isBookingInfo)}
                      />
                    </div>
                  )}
                {userType !== USER_TYPE.GUEST &&
                  watch(apiConstants.listingDetails)?.type !==
                    LISTING_TYPE.C && (
                    <div className="all-radio-button">
                      <div className="form_radio_button ">
                        <img
                          className="cursor-pointer"
                          src={
                            !watch(apiConstants.isCancellationAllowed)
                              ? "/static/radiocheck.svg"
                              : "/static/radiouncheck.svg"
                          }
                          alt=""
                          onClick={() => {
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              false
                            );
                            handleSetValue(
                              apiConstants.cancellation_allowed_till_hours,
                              ""
                            );
                          }}
                        />
                        <label
                          className={
                            watch(apiConstants.isCancellationAllowed) === false
                              ? "form-check-label cursor-pointer active"
                              : "form-check-label cursor-pointer"
                          }
                          htmlFor="bookingdontallow"
                          onClick={() => {
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              false
                            );
                            handleSetValue(
                              apiConstants.cancellation_allowed_till_hours,
                              ""
                            );
                          }}
                        >
                          Don’t allow
                        </label>
                      </div>
                      <div className="form_radio_button">
                        <img
                          className="cursor-pointer"
                          src={
                            watch(apiConstants.isCancellationAllowed)
                              ? "/static/radiocheck.svg"
                              : "/static/radiouncheck.svg"
                          }
                          alt=""
                          onClick={() =>
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              true
                            )
                          }
                        />
                        <label
                          className={
                            watch(apiConstants.isCancellationAllowed) === true
                              ? "form-check-label cursor-pointer active"
                              : "form-check-label cursor-pointer"
                          }
                          htmlFor="bookingallow"
                          onClick={() =>
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              true
                            )
                          }
                        >
                          Allow
                        </label>
                      </div>
                      {watch(apiConstants.cancellation_allowed_till_hours) ? (
                        <div className="booking-chip">
                          Allowed till{" "}
                          {watch(apiConstants.cancellation_allowed_till_hours)}{" "}
                          hours
                          <span
                            onClick={() => {
                              handleSetValue(
                                apiConstants.cancellation_allowed_till_hours,
                                ""
                              );
                            }}
                          >
                            <img alt="" src="/static/dismisscircle.svg" />
                          </span>
                        </div>
                      ) : null}
                      {watch(apiConstants.listingDetails)?.type !==
                        LISTING_TYPE.C &&
                      watch(apiConstants.isCancellationAllowed) === true ? (
                        <div className="form_group">
                          <div className="select-box-booking">
                            <span className="input_label">
                              Allow cancellation till
                            </span>
                            <select
                              value={watch(
                                apiConstants.cancellation_allowed_till_hours
                              )}
                              onChange={(e) => {
                                handleSetValue(
                                  apiConstants.cancellation_allowed_till_hours,
                                  e.target.value
                                );
                              }}
                              className="select-wrap"
                            >
                              <option value={""} disabled>
                                select an option
                              </option>
                              <option value={24}>Allow till 24 hours</option>
                              <option value={48}>Allow till 48 hours</option>
                              <option value={72}>Allow till 72 hours</option>
                              <option value={96}>Allow till 96 hours</option>
                              <option value={120}>Allow till 120 hours</option>
                            </select>
                            <img
                              src={
                                watch(
                                  apiConstants.cancellation_allowed_till_hours
                                )
                                  ? "/static/dismisscircle.svg"
                                  : "/static/cross-circle-gray.svg"
                              }
                              className="clear-icon"
                              alt=""
                              onClick={() => {
                                handleSetValue(
                                  apiConstants.cancellation_allowed_till_hours,
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="mb_24 terms-cancel-allow">
                        Please read{" "}
                        <Link
                          to="/policies?activeTab=terms"
                          target="_blank"
                          style={{ color: "#ee762f" }}
                        >
                          {" "}
                          T&C
                        </Link>{" "}
                        to understand the{" "}
                        <Link
                          to="/policies?activeTab=privacy"
                          target="_blank"
                          style={{ color: "#ee762f" }}
                        >
                          Cancellation Terms{" "}
                        </Link>
                      </div>
                    </div>
                  )}

                {isBookingInfo && (
                  <div className="info_box" ref={refInfo}>
                    <img src={"/static/greyinfo.svg"} alt="" />{" "}
                    {STRING_CONSTANTS.booking_info}
                  </div>
                )}

                <div
                  className="main-had"
                  style={{ marginTop: isBookingInfo ? "70px" : "0" }}
                >
                  <h3 className="headis">{STRING_CONSTANTS.age_prefrence}</h3>
                </div>
                <div className="all-radio-button">
                  <div className="form_radio_button ">
                    <img
                      className="cursor-pointer"
                      src={
                        !watch(apiConstants.is_specific_age)
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, false)
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.is_specific_age) === false
                          ? "form-check-label cursor-pointer active"
                          : "form-check-label cursor-pointer"
                      }
                      htmlFor="allages"
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, false)
                      }
                    >
                      {STRING_CONSTANTS.all_age_listing}
                    </label>
                  </div>
                  <div className="form_radio_button mb_24">
                    <img
                      className="cursor-pointer"
                      src={
                        watch(apiConstants.is_specific_age)
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, true)
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.is_specific_age) === true
                          ? "form-check-label cursor-pointer active"
                          : "form-check-label cursor-pointer"
                      }
                      htmlFor="specificage"
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, true)
                      }
                    >
                      {STRING_CONSTANTS.specific_age_listing}
                    </label>
                  </div>
                </div>
                {watch(apiConstants.is_specific_age) && (
                  <div className="two-row no_cross">
                    <InputField
                      type={"number"}
                      name={apiConstants.min_age}
                      inputLabel={"Minimum Age"}
                      placeholder={"Minimum Age"}
                      control={control}
                      errors={errors}
                    />
                    <InputField
                      type={"number"}
                      name={apiConstants.max_age}
                      inputLabel={"Maximum Age"}
                      placeholder={"Maximum Age"}
                      control={control}
                      errors={errors}
                      disabled={watch(apiConstants.min_age) ? false : true}
                    />
                  </div>
                )}
                <div className="main-had ">
                  <h3 className="headis">
                    {STRING_CONSTANTS.gender_preference}
                  </h3>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div className="listing_type_radio">
                    <img
                      src={
                        watch(apiConstants.selectedGender)?.length === 3
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [
                          "M",
                          "F",
                          "N",
                        ])
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.selectedGender)?.length === 3
                          ? "listing_ype_label cursor-pointer active"
                          : "listing_ype_label cursor-pointer"
                      }
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [
                          "M",
                          "F",
                          "N",
                        ])
                      }
                    >
                      This listing is for all genders
                    </label>
                  </div>
                  <div className="listing_type_radio">
                    <img
                      src={
                        watch(apiConstants.selectedGender)?.length < 3
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [])
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.selectedGender)?.length < 3
                          ? "listing_ype_label cursor-pointer active"
                          : "listing_ype_label cursor-pointer"
                      }
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [])
                      }
                    >
                      This listing is for a specific gender
                    </label>
                  </div>
                </div>
                <div className="all-checkbox-button_with-check m-0">
                  {watch(apiConstants.selectedGender)?.length < 3 &&
                    GendersTypeSpecific?.map((g) => (
                      <div
                        key={g.value}
                        className="form_checkbox_button_with_check"
                      >
                        <Controller
                          name={`gender_preference.${g.value}`}
                          control={control}
                          render={({ field }) => (
                            <div className="form_group">
                              <input
                                {...field}
                                type="checkbox"
                                id={g.title}
                                value={g.value}
                                checked={watch(
                                  apiConstants.selectedGender
                                ).includes(g.value)}
                                onClick={(e: any) =>
                                  handleGender(e.target.value)
                                }
                              />
                              <label
                                htmlFor={g.title}
                                style={{ justifyContent: "normal" }}
                                className={
                                  watch(apiConstants.selectedGender).includes(
                                    g.value
                                  )
                                    ? "checked"
                                    : "checkbox-gender-label"
                                }
                              >
                                <img
                                  src={g.activeImg}
                                  style={{
                                    background: "#ffffff",
                                    padding: "8px 8.5px",
                                    borderRadius: "50%",
                                  }}
                                  alt=""
                                />
                                <span>{g.title}</span>
                                {watch(apiConstants.selectedGender).includes(
                                  g.value
                                ) && (
                                  <img
                                    src="/static/checkmark-circle.svg"
                                    className="checkmark-circle"
                                    alt=""
                                  />
                                )}
                              </label>
                            </div>
                          )}
                        />
                      </div>
                    ))}
                  <span
                    className="text-danger"
                    style={{ position: "absolute", bottom: "0" }}
                  >
                    <>
                      {errors?.selectedGender
                        ? errors.selectedGender?.message
                        : ""}
                    </>
                  </span>
                </div>

                <div className="main-had ">
                  <h3 className="headis">Difficulty level</h3>
                </div>
                <div className="all-checkbox-button_with-check">
                  {difficultyLevelPreferenceNew?.map((g) => (
                    <div
                      key={g.value}
                      className="form_checkbox_button_with_check"
                    >
                      <Controller
                        name={`difficulty_level.${g.value}`}
                        control={control}
                        render={({ field }) => (
                          <div className="form_group">
                            <input
                              {...field}
                              type="checkbox"
                              id={g.title}
                              value={g.value}
                              checked={selectedPreferencesDifficulty.includes(
                                g.value
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedDifficulty = e.target.checked
                                  ? [...selectedPreferencesDifficulty, value]
                                  : selectedPreferencesDifficulty.filter(
                                      (pref) => pref !== value
                                    );

                                setValue("difficulty_level", updatedDifficulty);
                              }}
                              onClick={(e: any) =>
                                handleDifficulty(e.target.value)
                              }
                            />
                            <label
                              htmlFor={g.title}
                              style={{ justifyContent: "normal" }}
                              className={
                                selectedPreferencesDifficulty.includes(g.value)
                                  ? "checked"
                                  : "checkbox-gender-label"
                              }
                            >
                              <img
                                src={
                                  selectedPreferencesDifficulty.includes(
                                    g.value
                                  )
                                    ? g.activeImg
                                    : g.img
                                }
                                style={{
                                  background: "#ffffff",
                                  padding: "8px 8.5px",
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                              <span>{g.title}</span>
                              {selectedPreferencesDifficulty.includes(
                                g.value
                              ) && (
                                <img
                                  src="/static/checkmark-circle.svg"
                                  className="checkmark-circle"
                                  alt=""
                                />
                              )}
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  ))}
                  <span
                    className="text-danger"
                    style={{ position: "absolute", bottom: "0" }}
                  >
                    <>
                      {errors?.selecteddifficulty
                        ? errors.selecteddifficulty?.message
                        : ""}
                    </>
                  </span>
                </div>

                <div className="d-flex gap-2 flex-column flex-sm-row">
                  <Button
                    btnTxt={
                      // watch(apiConstants.isDraft)
                      // ? "Create listing"
                      // :
                      "Save as Draft"
                    }
                    style={{
                      background: "#ed772e3e",
                      color: "#ed772e",
                      border: "1px solid #ed772e",
                    }}
                    onClick={async () => {
                      await handleSetValue(apiConstants.isDraft, true);
                      await setIsDraft(true);
                      await setTimeout(() => {
                        handleNext({ isDraft: true });
                      }, 500);
                    }}
                    isLoading={loadingState?.isDraft}
                  />
                  <Button
                    btnTxt={
                      // watch(apiConstants.isDraft)
                      // ? "Create listing"
                      // :
                      "Update listing"
                    }
                    onClick={async () => {
                      await getStripeInfo();
                      await handleNext({ isDraft: false });
                      handleSetValue(apiConstants.isDraft, false);
                      setIsDraft(false);
                      setNotSaved(false);
                    }}
                    isLoading={loadingState.isCreate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {scheduleListing && (
        <ScheduleListingModal
          setScheduleLisiting={setScheduleLisiting}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          setScheduleDate={setScheduleDate}
          dirtyFields={dirtyFields}
        />
      )}
      {showDraftPopUp && (
        <DraftPopup
          title={
            watch(apiConstants.isDraft) ? "Save as a draft" : "Save Listing?"
          }
          btnTxt="Save"
          // discard={handleDiscardListing}
          discard={() => Navigate("/drafts")}
          onClose={() => setShowDraftPopUp(false)}
          showDiscard={watch(apiConstants.listingDetails)?.hasDraft}
          draft={() => {
            setNotSaved(false);
            handleSetValue(apiConstants.isDraft, true);
            setIsDraft(true);
            setTimeout(() => {
              handleNext({ isDraft: true });
            }, 500);
          }}
        />
      )}
      {/* Right sidebar for multiple listing type  */}

      {showAddEventSidebar ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box-event">
            <div className="event-title">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowAddEventSidebar(false);
                  setEditEvent(false);
                  setEditEventId(null);
                }}
                className="close-btn"
                alt=""
              />
              <div className="filter-heading">
                {editEvent ? "Update Event" : "Add Event"}
              </div>
            </div>
            <div className="inner-contet">
              <>
                <InputField
                  type={"text"}
                  name={apiConstants.event_title}
                  inputLabel={"Event Name"}
                  placeholder={"Type event name"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <GooglePlacesSearchBar
                  hideMap={true}
                  onChangeLocation={(
                    lat: string,
                    lng: string,
                    address: string,
                    address_components: any
                  ) => {
                    let location = handleLocation(
                      lat,
                      lng,
                      address,
                      address_components
                    );
                    handleSetValue(apiConstants.address, address);
                    handleSetValue(apiConstants.lat, lat);
                    handleSetValue(apiConstants.lng, lng);
                    handleSetValue(apiConstants.postCode, location.zipcode);
                    handleSetValue(apiConstants.state, location.state);
                    handleSetValue(apiConstants.city, location.locality);
                    handleSetValue(apiConstants.country, location.country);
                  }}
                  setValue={setValue}
                  handleLocation={handleLocation}
                  control={control}
                  name={apiConstants.address}
                  label={"Search location"}
                  placeholder="Include a location"
                />
                <div className="form_group with-button">
                  <div className="input_box">
                    <span className="input_label">Schedule Dates</span>
                    <div
                      className="schedule_date_input"
                      onClick={() => {
                        setShowAddEventSidebar(false);
                        setMultipleScheduleLisiting(true);
                      }}
                    >
                      {handleShowScheduleDate()}
                    </div>
                    {errors[apiConstants.has_schedule_date] ? (
                      <span className="text-danger">
                        {`${errors[apiConstants.has_schedule_date]?.message}`}
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
            </div>
            <div>
              <Button
                btnTxt={editEvent ? "Update Event" : "Save Event"}
                className="save_btn"
                onClick={() => {
                  setShowAddEventSidebar(false);
                  setShowEventPopup(true);
                  setAddEventOpen(true);
                }}
                disabled={
                  !watch(apiConstants.event_start_time) &&
                  !watch(apiConstants.event_end_time)
                }
              />
              <Button
                btnTxt={editEvent ? "Update Event" : "Save Event"}
                className=" d-md-none"
                onClick={() => {
                  setShowAddEventSidebar(false);
                  setShowEventPopup(true);
                  setAddEventOpen(true);
                }}
                disabled={
                  !watch(apiConstants.event_start_time) &&
                  !watch(apiConstants.event_end_time)
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      {multipleScheduleListing ? (
        <ScheduleListingSidebar
          setMultipleScheduleLisiting={() => {
            setMultipleScheduleLisiting(false);
            setShowAddEventSidebar(true);
          }}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          setScheduleDate={setScheduleDate}
          dirtyFields={dirtyFields}
        />
      ) : null}
      {showEventPopup ? (
        <SaveEventPopup
          title="Save Event"
          onClose={() => {
            setShowEventPopup(false);
            setEditEvent(false);
          }}
          reCheck={() => {
            setShowEventPopup(false);
            setShowAddEventSidebar(true);
          }}
          onContinue={() => {
            setShowEventPopup(false);
            if (editEvent) {
              setEditEvent(false);
              const newMultipleEvents = watch(apiConstants.multipleEvents).map(
                (event) => (event.id === editEventId ? tempObj : event)
              );
              setValue(apiConstants.multipleEvents, newMultipleEvents);
              setEditEventId(null);
            } else {
              if (getValues(apiConstants.multipleEvents).length > 0) {
                setValue(apiConstants.multipleEvents, [
                  ...getValues(apiConstants.multipleEvents),
                  tempObj,
                ]);
              } else {
                setValue(apiConstants.multipleEvents, [tempObj]);
              }
            }
            setValue(apiConstants.event_title, "");
            setValue(apiConstants.city, "");
            setValue(apiConstants.state, "");
            setValue(apiConstants.country, "");
            setValue(apiConstants.postCode, "");
            setValue(apiConstants.address, "");
            setValue(apiConstants.listing_location, "");
            setValue(apiConstants.event_start_date, "");
            setValue(apiConstants.event_start_time, "");
            setValue(apiConstants.event_end_time, "");
            setValue(apiConstants.event_end_time, "");
            setValue(apiConstants.event_end_date, "");
            setValue(apiConstants.schedule_date, "");
            setValue(apiConstants.event_date, "");
          }}
        />
      ) : null}

      {showAllEventSidebar ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box-event">
            <div className="event-title">
              <img
                src="/static/cross-black.svg"
                onClick={() => setShowAllEventSidebar(false)}
                className="close-btn d-none d-md-block"
                alt=""
              />

              <span
                className="d-md-none"
                onClick={() => {
                  setShowAllEventSidebar(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M8.73464 16.7917C9.03557 17.0763 9.51026 17.0631 9.79489 16.7621C10.0795 16.4612 10.0663 15.9865 9.76536 15.7019L4.52632 10.7468L17.25 10.7468C17.6642 10.7468 18 10.411 18 9.99678C18 9.58256 17.6642 9.24678 17.25 9.24678L4.52405 9.24678L9.76536 4.28952C10.0663 4.00489 10.0795 3.53021 9.79489 3.22927C9.51026 2.92834 9.03557 2.91512 8.73464 3.19974L2.31741 9.26918C2.15112 9.42647 2.05112 9.62658 2.01741 9.83537C2.00601 9.88736 2 9.94136 2 9.99678C2 10.0544 2.00649 10.1104 2.01878 10.1643C2.05377 10.3701 2.15331 10.567 2.31741 10.7222L8.73464 16.7917Z"
                    fill="#25272D"
                  />
                </svg>
              </span>
              <div className="filter-heading pe-3">All Event</div>
            </div>
            <div className="inner-contet">
              {watch(apiConstants.multipleEvents)?.map((i, idx) => {
                return (
                  <EventCard
                    isDivider={idx === 2 ? false : true}
                    style={{ border: "none", marginBottom: "0" }}
                    key={idx}
                    title={i?.title}
                    date={i.date}
                    location={i?.address?.address}
                    // start_time={
                    //   i?.startTime?.substring(0, 5) ||
                    //   i?.event_start_time?.substring(0, 5)
                    // }
                    // end_time={
                    //   i?.endTime?.substring(0, 5) ||
                    //   i?.event_end_time?.substring(0, 5)
                    // }
                    end_time={moment(i?.endTime, "HH:mm").format("hh:mm A")}
                    start_time={moment(i?.startTime, "HH:mm").format("hh:mm A")}
                    onDeleteClick={() => onDeleteEvent(idx)}
                    onEditClick={() => {
                      setShowAllEventSidebar(false);
                      setEditEvent(true);
                      setEditEventId(i.id);
                      setValue(apiConstants.event_title, i?.title);
                      setValue(apiConstants.city, i?.address?.city);
                      setValue(apiConstants.state, i?.address?.state);
                      setValue(apiConstants.country, i?.address?.country);
                      setValue(apiConstants.postCode, i?.address?.postCode);
                      setValue(apiConstants.address, i?.address?.address);
                      setValue(
                        apiConstants.listing_location,
                        i?.address?.location
                      );
                      setValue(apiConstants.event_date, new Date(i.date));
                      setValue(apiConstants.event_start_date, new Date(i.date));
                      setValue(
                        apiConstants.event_start_time,
                        new Date(i?.date + " " + i?.startTime)
                      );
                      setValue(
                        apiConstants.event_end_time,
                        new Date(i?.date + " " + i?.endTime)
                      );

                      setShowAddEventSidebar(true);
                    }}
                  />
                );
              })}

              <div>
                <OutlineYellowButton
                  text="Add Event"
                  icon={<AddIconYellow />}
                  onClick={() => {
                    setShowAllEventSidebar(false);
                    setShowAddEventSidebar(true);
                    setAddEventOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EditListing;
