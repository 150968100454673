/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { LISTING_TYPE, USER_TYPE } from "../../types";
import {
  dateFormatter,
  downloadFile,
  formatAmount,
  getItemFromStore,
  showToast,
} from "../../utils";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
} from "../../utils/Requests";
import GlobalHeader from "../Dashboard/GlobalHeader";
import endpoints from "../../utils/endpoints";
import ActiveListIcon from "../../assets/img/icons/ActiveListIcon";
import InActiveListIcon from "../../assets/img/icons/InActiveListIcon";
import MoreIcon from "../../assets/img/icons/MoreIcon";
import DeleteIcon from "../../assets/img/icons/DeleteIcon";
import ActivateIcon from "../../assets/img/icons/ActivateIcon";
import EditIcon from "../../assets/img/icons/EditIcon";
import Button from "../../global/Button";
import MySchedulingCard from "./MySchedulingCard";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import ApiLoader from "../../global/ApiLoader";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import {
  LeftArrowStickIcon,
  LocationGreyFillIcon,
  SpClockIcon,
} from "../../utils/Icons/svgs";
import TimeIcon from "../../assets/img/icons/TimeIcon";
import LocationIcon from "../../assets/img/icons/LocationIcon";
import { useOutsideClick } from "../../hooks";
import InfiniteScroll from "react-infinite-scroll-component";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const currentYear = new Date().getFullYear();

const ViewListing = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listing, setListing] = useState<any>([]);
  const [, setNextUrl] = useState<any>(null);
  const [moreNextUrl, setMoreNextUrl] = useState<any>(null);
  const [openIndex, setOpenIndex] = useState<null | number>(null);
  const [scheduleData, setScheduleData] = useState<any>();
  const [sideList, setSideList] = useState<Boolean>(false);
  const [mapCenter, setMapCenter] = useState<any>();
  const [schedulLoading, setScheduleLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<String>(null);
  const [earningMonthData, setearningMonthData] = useState<any>([]);
  const [selctedYear, setSelctedYear] = useState<any>(currentYear);
  const [showCsvPopup, setShowCsvPopup] = useState<boolean>(false);
  const [showEarningCsvPopup, setShowEarningCsvPopup] =
    useState<boolean>(false);
  const [earningInfo, setearningInfo] = useState<any>({});
  const [selectedBookingtype, setSelectedBookingType] = useState("upcoming");
  const [showInactivePopup, setShowInactivePopup] = useState(false);
  const ref = useRef(null);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isMobile } = useCheckDeviceScreen();
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [active, setActive] = useState<any>("active");

  useEffect(() => {
    // Assuming `listing` is the array used to render slides
    const totalSlides = listing.length;
    const slidesToShow = isMobile ? 1.3 : 3.2; // or use Math.ceil for whole numbers

    // Check if on the last slide whenever currentSlide changes
    setIsLastSlide(currentSlide >= totalSlides - Math.ceil(slidesToShow));
  }, [currentSlide, listing.length, isMobile]);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(currentSlide);
  }, [currentSlide]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1.3 : 3.2,
    slidesToScroll: isMobile ? 1 : 3,
    autoplay: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    afterChange: (current) => {
      // Assuming `listing` is the array used to render slides
      const totalSlides = listing.length;
      const slidesToShow = isMobile ? 1.3 : 3.2; // or use Math.ceil for whole numbers

      // Set isLastSlide state based on the current slide
      setIsLastSlide(current >= totalSlides - Math.ceil(slidesToShow));
    },
  };

  const options = [];
  for (let year = 2023; year <= currentYear; year++) {
    options.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const earningData = {
    labels,
    datasets: [
      {
        data: earningMonthData,
        label: "Earnings",
        backgroundColor: "#EE762F",
      },
    ],
  };

  let monthDateArr = [];
  const navigate = useNavigate();

  if (scheduleData) {
    if (scheduleData !== undefined && scheduleData.length > 0) {
      monthDateArr.push({
        month: moment(scheduleData?.[0]?.date).format("MMMM"),
        data: [scheduleData?.[0]],
      });
      let mocount = 0;
      for (let m = 1; m < scheduleData.length; m++) {
        if (
          monthDateArr?.[mocount].month ===
          moment(scheduleData?.[m]?.date).format("MMMM")
        ) {
          monthDateArr[mocount].data.push(scheduleData?.[m]);
        } else {
          mocount++;
          monthDateArr.push({
            month: moment(scheduleData?.[m].date).format("MMMM"),
            data: [scheduleData?.[m]],
          });
        }
      }
    }
  }

  const getListingData = () => {
    setListing([]);
    setIsLoading(true);
    makeGetCall({
      url:
        endpoints.mylisting_new +
        `?is_active=${active === "active" ? true : false}`,
    })
      .then((res) => {
        if (res?.results) {
          setListing(res?.results);
          setNextUrl(res?.next);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getListingData();
  }, [active]);

  useEffect(() => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_earning })
      .then((res) => {
        if (res.status.code === 200) {
          setearningInfo(res.data);
          setIsLoading(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  }, []);

  useEffect(() => {
    //get earning graph data
    setIsLoading(true);
    makeGetCall({
      url: `${endpoints.listing_monthly_earning}?year=${selctedYear}`,
    })
      .then((res) => {
        if (res.data) {
          const earningList = res.data;
          const data = earningList?.map((obj) => obj.totalNetAmount);
          setearningMonthData(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  }, [selctedYear]);

  const handleBookingType = () => {
    setScheduleLoading(true);
    makeGetCall({
      url:
        endpoints.listingSchedule +
        `${
          selectedBookingtype !== "upcoming"
            ? `?type=${selectedBookingtype}`
            : ""
        }`,
    })
      .then((res) => {
        setScheduleData(res.results);
        setMoreNextUrl(res?.next);
        setScheduleLoading(false);
      })
      .catch((err) => {
        setScheduleLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    handleBookingType();
  }, [selectedBookingtype]);

  const handleMapCenter = (centerData) => {
    setMapCenter(centerData);
  };

  const fetchMoreData = () => {
    if (!moreNextUrl) return;
    setIsLoading(true);
    makeNextPageCall({ url: moreNextUrl }).then((res) => {
      const tempData = [...scheduleData, ...res.results];
      setIsLoading(false);
      setMoreNextUrl(res?.next);
      setScheduleData(tempData as never);
    });
  };

  const deleteDraftItem = () => {
    setShowDeletePopup(false);
    if (deleteId) {
      makeDeleteCall({
        url: endpoints.listing + `${deleteId}`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);
            getListingData();
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const downloadCsv = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_booking_csv })
      .then((res) => {
        //not getting any status code yet
        downloadFile(res);
        setShowCsvPopup(false);
        setIsLoading(false);
        if (res.status.code === 200) {
          setShowCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  const downloadEarningCsv = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_earning_csv })
      .then((res) => {
        //not getting any status code yet
        downloadFile(res);
        setShowEarningCsvPopup(false);
        setIsLoading(false);
        if (res.status.code === 200) {
          setShowEarningCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const makeInactiveList = () => {
    if (deleteId) {
      makePatchCall({
        url: endpoints.listing + `${deleteId}/mark-inactive/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);

            getListingData();
            setShowInactivePopup(false);
          } else {
            showToast(res.status?.message, "error");
            setShowInactivePopup(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setShowInactivePopup(false);
        });
    }
  };

  useOutsideClick(ref, () => {
    setOpenIndex(null);
  });

  return (
    <>
      <GlobalHeader />
      <div className="view-listing-page-wrap">
        <div className=" upper-title d-flex d-md-none justify-content-between bg-white p-3">
          <span className="listing-title">My Listings</span>
          <div onClick={() => setShowCsvPopup(true)}>
            <img src="/static/doc-arrow-down.png" alt="" />
          </div>
        </div>
        {getItemFromStore("userProfile")?.userType !== "Guest" ? (
          <div className="view-linsting-white">
            <div className="graph-section-top">
              <div className="left">
                <div className="section-title">
                  ${earningInfo.totalEarnings || 0}YTD
                </div>
                <div className="section-sub-title">View earnings by month</div>
              </div>

              <div className="right d-none d-md-flex">
                <select
                  className="year-select-box"
                  value={selctedYear}
                  onChange={(e) => {
                    setSelctedYear(e.target.value);
                  }}
                >
                  {options}
                </select>
                <Button
                  btnTxt="Export"
                  className="export-btn"
                  btnImg="/static/white-export.svg"
                  onClick={() => setShowEarningCsvPopup(true)}
                />
              </div>
            </div>

            <Bar options={options} data={earningData} />
          </div>
        ) : null}

        <div
          className="view-linsting-white mt-28"
          style={{ paddingRight: "0", overflowX: "hidden" }}
        >
          <div style={{ position: "relative", marginBottom: "16px" }}>
            <div className="view-title-box-wrap">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "24px",
                }}
              >
                <div>
                  <div className="section-title">My Created Listings </div>
                  <div className="section-sub-title">
                    Track your classes with Active Listings - all in one place.
                  </div>
                </div>
                <div className="see-all-listing-button-new d-none d-md-block">
                  <Link to="/my-listings">
                    <img alt="" src="/static/see-all-button.svg" />
                  </Link>
                </div>

                <div className="d-none d-md-block">
                  <Button
                    btnImg="/static/plusadd.svg"
                    btnTxt="Create Listing"
                    className="add_listing_btn align-items-center"
                    onClick={() => {
                      // if (
                      //   getItemFromStore("userProfile")?.userType ===
                      //   USER_TYPE.GUEST
                      // ) {
                      //   navigate(ROUTE_CONSTANTS.create_listing);
                      // } else if (
                      //   getItemFromStore("userProfile")?.userType !==
                      //     USER_TYPE.GUEST &&
                      //   getItemFromStore("userProfile")?.listingBalance > 0
                      // ) {
                      navigate(ROUTE_CONSTANTS.create_listing);
                      // } else {
                      //   navigate(ROUTE_CONSTANTS.listing_block);
                      // }
                    }}
                  />
                </div>
                <div
                  className=" d-flex d-md-none  plus-btn "
                  onClick={() => {
                    // if (
                    //   getItemFromStore("userProfile")?.userType ===
                    //   USER_TYPE.GUEST
                    // ) {
                    //   navigate(ROUTE_CONSTANTS.create_listing);
                    // } else if (
                    //   getItemFromStore("userProfile")?.userType !==
                    //     USER_TYPE.GUEST &&
                    //   getItemFromStore("userProfile")?.listingBalance > 0
                    // ) {
                    navigate(ROUTE_CONSTANTS.create_listing);
                    // } else {
                    //   navigate(ROUTE_CONSTANTS.listing_block);
                    // }
                  }}
                >
                  <img src="/static/white-add.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="active-inactive-listing-box">
            <div
              className="item cursor-pointer"
              onClick={() => setActive("active")}
            >
              <div className={active === "active" ? "active" : ""}>Active</div>
            </div>
            <div
              className="item cursor-pointer"
              onClick={() => setActive("inactive")}
            >
              <div className={active === "inactive" ? "active" : ""}>
                Inactive
              </div>
            </div>
          </div>
          {isLoading ? (
            <ApiLoader />
          ) : listing.length > 0 ? (
            <>
              <Slider {...settings} ref={sliderRef}>
                {isLoading ? (
                  <ApiLoader />
                ) : (
                  listing?.map((item, index) => (
                    <div
                      className="carousel-card"
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/events/${item?.id}`);
                      }}
                    >
                      <div
                        className="listing_box"
                        key={index}
                        style={{
                          marginLeft: "7px",
                        }}
                      >
                        {item.isSpecialListing ? (
                          <img
                            src="/static/fitfest.svg"
                            alt=""
                            className="tag"
                          />
                        ) : item.listingType === LISTING_TYPE.M ? (
                          <img
                            src="/static/package-label.svg"
                            alt=""
                            className="tag"
                          />
                        ) : item.listingType === LISTING_TYPE.C ? (
                          <img
                            src="/static/team-label.svg"
                            alt=""
                            className="tag"
                          />
                        ) : item.listingType === LISTING_TYPE.S ? (
                          <img
                            src="/static/session-label.svg"
                            alt=""
                            className="tag"
                          />
                        ) : null}
                        {["mp4", "mov", "avi"].includes(
                          item?.media?.split(".").splice(-1)[0].toLowerCase()
                        ) ? (
                          <>
                            <video
                              src={item?.media}
                              className="event_img"
                              style={{ cursor: "pointer" }}
                              id={`eventvideo${index}`}
                              autoPlay
                              playsInline
                              loop
                              muted
                            >
                              <source src={item?.media} />
                            </video>
                          </>
                        ) : (
                          <img
                            src={item?.media || "/static/expertise2.png"}
                            className="event_img"
                            alt=""
                          />
                        )}
                        <div
                          className="more-popup-listing"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (openIndex === index) {
                              setOpenIndex(null);
                            } else {
                              setOpenIndex(index);
                            }
                          }}
                        >
                          <MoreIcon />
                        </div>
                        {openIndex === index ? (
                          <div
                            className="more-popup-item-wrap "
                            style={{ zIndex: "999999" }}
                            ref={ref}
                          >
                            {item.isListingEditable ? (
                              <Link
                                className="event-link-title"
                                to={`/edit-listing/${item.id}`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="more-icon-item">
                                  <span>
                                    <EditIcon />
                                  </span>
                                  Edit Listing
                                </div>
                              </Link>
                            ) : null}
                            {item.isActive ? (
                              <div
                                className="more-icon-item mb-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteId(item.id);
                                  setShowInactivePopup(true);
                                }}
                              >
                                <span>
                                  <ActivateIcon />
                                </span>
                                {item.isActive ? "Inactive" : "Activate"}
                              </div>
                            ) : null}
                            {item.isActive ? null : (
                              <div
                                style={{ marginBottom: "0px" }}
                                className="more-icon-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteId(item.id);
                                  setShowDeletePopup(true);
                                }}
                              >
                                <span>
                                  <DeleteIcon />
                                </span>
                                Delete Listing
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="span_box">
                          {item?.distance ? (
                            <span className="distance">
                              {item.distance?.toFixed(2)} km away...
                            </span>
                          ) : null}
                          {item?.price || item?.monthlyCost ? (
                            <span className="price">
                              $ {formatAmount(item?.price || item?.monthlyCost)}
                            </span>
                          ) : (
                            <span className="price">$$$</span>
                          )}
                        </div>
                        <div className="title">{item?.title}</div>
                        <div
                          className="others"
                          style={{
                            minHeight: !item.isActive ? "43px" : "",
                            justifyContent: !item.isActive
                              ? "end"
                              : "space-between",
                          }}
                        >
                          {item.isActive && (
                            <div className="time">
                              <img src="/static/calendar.svg" alt="" />
                              {dateFormatter(item?.createdAt)}
                            </div>
                          )}
                          <div className="remaining">
                            <>
                              <img src="/static/countlogo.svg" alt="" />
                              <span>
                                {item?.maxParticipants -
                                  (item?.listingType === LISTING_TYPE.S
                                    ? item?.events?.[0]?.slotsAvailable
                                    : item?.slotsAvailable)}
                              </span>{" "}
                              / {item?.maxParticipants || item?.slot}
                            </>
                          </div>
                        </div>
                        {item.isActive ? (
                          <div className="total-session">
                            Total Sessions:{" "}
                            {item?.totalSession || item?.events?.length || 1}
                          </div>
                        ) : null}
                        {item.isActive ? (
                          <div className="activeList">
                            <span>
                              <ActiveListIcon />
                            </span>
                            Active
                          </div>
                        ) : (
                          <div className="inactiveList">
                            <span>
                              <InActiveListIcon />
                            </span>
                            Inactive
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
                <div>
                  <Link to="/my-listings">
                    <div className="carousel-card-see-all">
                      <img alt="" src="/static/see-all-listing.svg" />
                    </div>
                  </Link>
                </div>
              </Slider>
              <div
                className="see-all-button-mobile"
                style={{ paddingTop: "16px", textAlign: "center" }}
              >
                <Link to="/my-listings">
                  <img alt="" src="/static/see-all-button.svg" />
                </Link>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => sliderRef?.current?.slickPrev()}
                  className="carousel-button created-listing-btn "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.2955 19.7159C10.6889 20.1036 11.322 20.0989 11.7097 19.7055C12.0973 19.3121 12.0926 18.6789 11.6992 18.2913L6.3289 13L20 13C20.5523 13 21 12.5522 21 12C21 11.4477 20.5523 11 20 11L6.33539 11L11.6992 5.71501C12.0926 5.32739 12.0973 4.69424 11.7097 4.30083C11.322 3.90743 10.6889 3.90274 10.2955 4.29036L3.3713 11.1127C2.87439 11.6024 2.87439 12.4039 3.3713 12.8936L10.2955 19.7159Z"
                      fill={currentSlide < 1 ? "#BDBDBD" : "#25272D"}
                    />
                  </svg>
                </div>
                <div
                  onClick={() => {
                    sliderRef?.current?.slickNext();
                  }}
                  className="carousel-button created-listing-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                  >
                    <path
                      d="M10.7045 15.7159C10.3111 16.1036 9.67795 16.0989 9.29033 15.7055C8.90271 15.3121 8.9074 14.6789 9.3008 14.2913L14.6711 8.99996L1 8.99996C0.447715 8.99996 3.73713e-07 8.55224 3.49572e-07 7.99996C3.25431e-07 7.44767 0.447716 6.99996 1 6.99996L14.6646 6.99996L9.3008 1.71501C8.9074 1.32739 8.90271 0.69424 9.29033 0.300834C9.67795 -0.0925715 10.3111 -0.0972598 10.7045 0.290361L17.6287 7.11275C18.1256 7.60236 18.1256 8.40395 17.6287 8.89356L10.7045 15.7159Z"
                      fill={isLastSlide ? "#BDBDBD" : "#25272D"}
                    />
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <div className="empty-data mx-auto d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <img src="/static/no-lisitng.svg" alt="" />
                <p>No listings available</p>
                <p style={{ fontSize: 12 }}>
                  Your listings are all clear today! Check back here later, or
                  explore new listings.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="view-linsting-white">
          <div className="row gx-0 align-items-center">
            <div
              className="col-1 d-md-none  cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <LeftArrowStickIcon />
            </div>
            <div className="col-9 col-md-10">
              <div className="section-title">My Bookings</div>
              <div className="section-sub-title d-none d-md-block">
                Stay organized with event details, attendees, and native
                calendar integration.
              </div>
            </div>
            <Button
              btnTxt="Export"
              className="sp-export-btn col-2 d-none d-md-block"
              btnImg="/static/white-export.svg"
              onClick={() => setShowCsvPopup(true)}
            />
            <div
              className="d-inline-block col-1 d-md-none cursor-pointer"
              onClick={() => setShowCsvPopup(true)}
            >
              <img src="/static/shape.png" alt="" />
            </div>
          </div>
          <hr
            style={{ marginLeft: "-16px", marginRight: "-16px" }}
            className="d-md-none "
          ></hr>

          <div className="s-booking-types">
            <div
              className={`sb-type ${
                selectedBookingtype === "upcoming" ? "active" : ""
              }`}
              onClick={() => setSelectedBookingType("upcoming")}
            >
              <p>Upcoming</p>
              {selectedBookingtype === "upcoming" && (
                <div className="s-active-line"></div>
              )}
            </div>
            <div
              className={`sb-type ${
                selectedBookingtype === "archive" ? "active" : ""
              }`}
              onClick={() => setSelectedBookingType("archive")}
            >
              <p>Archive</p>
              {selectedBookingtype === "archive" && (
                <div className="s-active-line"></div>
              )}
            </div>
            <div
              className={`sb-type ${
                selectedBookingtype === "cancelled" ? "active" : ""
              }`}
              onClick={() => setSelectedBookingType("cancelled")}
            >
              <p>Cancelled</p>
              {selectedBookingtype === "cancelled" && (
                <div className="s-active-line"></div>
              )}
            </div>
          </div>

          <div className="mt-28 sb-booking-container">
            {schedulLoading ? (
              <ApiLoader />
            ) : (
              <>
                {selectedBookingtype === "upcoming" ? (
                  monthDateArr?.length ? (
                    <InfiniteScroll
                      dataLength={scheduleData?.length}
                      next={fetchMoreData}
                      hasMore={moreNextUrl ? true : false}
                      loader={<ApiLoader />}
                      style={{ overflow: "unset" }}
                    >
                      {monthDateArr?.map((item, index) => {
                        return (
                          <div key={index}>
                            {item?.data?.length > 0 && (
                              <div
                                style={{ marginBottom: "1rem " }}
                                className="mt-28"
                              >
                                <span className="t-schedule-month">
                                  {item.month}
                                </span>
                              </div>
                            )}
                            {item?.data?.map((it, ind) => {
                              return (
                                <MySchedulingCard
                                  key={ind}
                                  item={it}
                                  setMapCenter={handleMapCenter}
                                  setSideList={() => setSideList(true)}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  ) : (
                    <div className="empty-data d-flex justify-content-center">
                      <div className="d-flex flex-column align-items-center">
                        <img src="/static/EmptyBooking.svg" alt="" />
                        <p>No bookings yet</p>
                        <p style={{ fontSize: 12 }}>
                          Explore more listing and start booking now.
                        </p>
                      </div>
                    </div>
                  )
                ) : selectedBookingtype === "archive" ? (
                  <div className="sb-archives-container">
                    {scheduleData?.length ? (
                      <InfiniteScroll
                        dataLength={scheduleData?.length}
                        next={fetchMoreData}
                        hasMore={moreNextUrl ? true : false}
                        loader={<ApiLoader />}
                      >
                        {scheduleData?.map((item, index) => {
                          return (
                            <div>
                              <Link
                                to={`/events/${item.event.listing.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "#8f939a",
                                }}
                              >
                                <div className="sb-archive">
                                  <div className="sb-date-cont">
                                    <p className="sb-day mb-0">
                                      {moment(item?.date).format(
                                        "YYYY-MM-DD"
                                      ) ===
                                      moment(Date.now()).format("YYYY-MM-DD")
                                        ? "Today"
                                        : moment(item?.date).format("ddd")}
                                    </p>
                                    <p className="sb-date mb-0">
                                      {moment(item?.date).format("DD")}
                                      <sup>
                                        {moment(item?.date)
                                          .format("Do")
                                          .slice(-2)}
                                      </sup>
                                    </p>

                                    <p className="sb-day mb-0">
                                      {moment(item?.date).format("MMM")}
                                    </p>
                                  </div>
                                  <div className="sb-archive-card">
                                    <div className="sb-card-title">
                                      <p>{item?.title}</p>
                                    </div>
                                    <div className="sb-card-location">
                                      <p>
                                        <LocationGreyFillIcon />
                                      </p>
                                      <p>{item?.address}</p>
                                    </div>
                                    <div className="sb-card-time-participants">
                                      <div className="sb-time">
                                        <SpClockIcon width="20" height="20" />
                                        <p>
                                          {moment(
                                            item?.startTime?.substring(0, 5),
                                            ["HH:mm"]
                                          ).format("hh:mmA")}{" "}
                                          -{" "}
                                          {moment(
                                            item?.endTime?.substring(0, 5),
                                            ["HH:mm"]
                                          ).format("hh:mmA")}
                                        </p>
                                      </div>
                                      <div className="avatar-participants-container ">
                                        <div className="avatar-container">
                                          {item?.event?.bookersImage
                                            ?.slice(0, 3)
                                            ?.map((item) => {
                                              return (
                                                <Link
                                                  to={`/user/${item.id}`}
                                                  key={item.profilePicture}
                                                >
                                                  {[
                                                    "mp4",
                                                    "mov",
                                                    "avi",
                                                  ].includes(
                                                    item?.profilePicture
                                                      ?.split(".")
                                                      .splice(-1)[0]
                                                      .toLowerCase()
                                                  ) ? (
                                                    <>
                                                      <div className="avatar-item">
                                                        <video
                                                          src={
                                                            item?.profilePicture
                                                          }
                                                          autoPlay
                                                          playsInline
                                                          loop
                                                          muted
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "20px",
                                                            height: "20px",
                                                            objectFit: "cover",
                                                            verticalAlign:
                                                              "middle",
                                                            borderRadius:
                                                              "100%",
                                                          }}
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                          }}
                                                        >
                                                          <source
                                                            src={
                                                              item?.profilePicture
                                                            }
                                                          />
                                                        </video>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div
                                                      key={item.profilePicture}
                                                      className="avatar-item"
                                                    >
                                                      <img
                                                        src={
                                                          item.profilePicture ||
                                                          "/static/avatar-dummy-1.png"
                                                        }
                                                        alt=""
                                                      />
                                                    </div>
                                                  )}
                                                </Link>
                                              );
                                            })}
                                        </div>
                                        <span className="t-participants">
                                          + {item?.participants}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <div className="divider"></div>
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    ) : (
                      <div className="empty-data d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <img src="/static/EmptyBooking.svg" alt="" />
                          <p>No Archive bookings</p>
                          <p style={{ fontSize: 12 }}>
                            No archive history to show
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {scheduleData.length > 0 ? (
                      <InfiniteScroll
                        dataLength={scheduleData?.length}
                        next={fetchMoreData}
                        hasMore={moreNextUrl ? true : false}
                        loader={<ApiLoader />}
                      >
                        {scheduleData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="upcoming-schedule-row-outer"
                            >
                              <div className="sb-date-cont">
                                <p className="sb-day mb-0">
                                  {moment(item?.date).format("YYYY-MM-DD") ===
                                  moment(Date.now()).format("YYYY-MM-DD")
                                    ? "Today"
                                    : moment(item?.date).format("ddd")}
                                </p>
                                <p className="sb-date mb-0">
                                  {moment(item?.date).format("DD")}
                                  <sup>
                                    {moment(item?.date).format("Do").slice(-2)}
                                  </sup>
                                </p>

                                <p className="sb-day mb-0">
                                  {moment(item?.date).format("MMM")}
                                </p>
                              </div>

                              {item && (
                                <div className="upcoming-schedule-wrap">
                                  <Link
                                    to={`/events/${item.event.listing.id}`}
                                    style={{
                                      textDecoration: "none",
                                    }}
                                  >
                                    <div className="card-content cancelled-card-wrap">
                                      <div className="left">
                                        <div className="title">
                                          {item.title}
                                        </div>

                                        <div className="content-items">
                                          <span>
                                            <LocationIcon />
                                          </span>
                                          <div className="t-sub-title-box">
                                            <span className="sub-title">
                                              {item.address}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="content-items">
                                          <span>
                                            <TimeIcon />
                                          </span>
                                          <span className="sub-title">
                                            {moment(
                                              item?.startTime?.substring(0, 5),
                                              ["HH:mm"]
                                            ).format("hh:mmA")}{" "}
                                            -{" "}
                                            {moment(
                                              item?.endTime?.substring(0, 5),
                                              ["HH:mm"]
                                            ).format("hh:mmA")}
                                          </span>
                                          <div className="avatar-participants-container d-none d-md-flex">
                                            <div className="avatar-container">
                                              {item?.event?.bookersImage
                                                ?.slice(0, 3)
                                                ?.map((item) => {
                                                  return (
                                                    <Link
                                                      to={`/user/${item.id}`}
                                                      key={item.profilePicture}
                                                    >
                                                      {[
                                                        "mp4",
                                                        "mov",
                                                        "avi",
                                                      ].includes(
                                                        item?.profilePicture
                                                          ?.split(".")
                                                          .splice(-1)[0]
                                                          .toLowerCase()
                                                      ) ? (
                                                        <>
                                                          <div className="avatar-item">
                                                            <video
                                                              src={
                                                                item?.profilePicture
                                                              }
                                                              autoPlay
                                                              playsInline
                                                              loop
                                                              muted
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                width: "20px",
                                                                height: "20px",
                                                                objectFit:
                                                                  "cover",
                                                                verticalAlign:
                                                                  "middle",
                                                                borderRadius:
                                                                  "100%",
                                                              }}
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                              }}
                                                            >
                                                              <source
                                                                src={
                                                                  item?.profilePicture
                                                                }
                                                              />
                                                            </video>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div
                                                          key={
                                                            item.profilePicture
                                                          }
                                                          className="avatar-item"
                                                        >
                                                          <img
                                                            src={
                                                              item.profilePicture ||
                                                              "/static/avatar-dummy-1.png"
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      )}
                                                    </Link>
                                                  );
                                                })}
                                            </div>
                                            <span className="t-participants">
                                              + {item.participants}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="content-items d-none d-md-flex">
                                          <div>
                                            <span className="sub-title">
                                              Total amount:{" "}
                                            </span>
                                            <span className="title">
                                              ${item?.refundedTotalAmount || 0}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="content-items d-none d-md-flex">
                                          <span className="sub-title">
                                            Cancelled on{" "}
                                            {moment(item?.cancelDate).format(
                                              "ddd D MMM"
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="refund-status-box d-none d-md-block "
                                        style={{
                                          border: `1px solid ${
                                            item?.status === "RF"
                                              ? "#29CC6A"
                                              : "#EE7830"
                                          }`,
                                        }}
                                      >
                                        <div className="title">Refund:</div>
                                        <div
                                          className="status"
                                          style={{
                                            color: `${
                                              item?.status === "RF"
                                                ? "#29CC6A"
                                                : "#EE7830"
                                            }`,
                                          }}
                                        >
                                          {item?.status === "RF"
                                            ? "Done"
                                            : "In Progress"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-md-none d-flex justify-content-between">
                                      <div className="avatar-participants-container ">
                                        <div className="avatar-container">
                                          {item?.event?.bookersImage
                                            ?.slice(0, 3)
                                            ?.map((item) => {
                                              return (
                                                <div
                                                  key={item.profilePicture}
                                                  className="avatar-item"
                                                >
                                                  <Link
                                                    to={`/user/${item.id}`}
                                                    key={item.profilePicture}
                                                  >
                                                    {[
                                                      "mp4",
                                                      "mov",
                                                      "avi",
                                                    ].includes(
                                                      item?.profilePicture
                                                        ?.split(".")
                                                        .splice(-1)[0]
                                                        .toLowerCase()
                                                    ) ? (
                                                      <>
                                                        <div className="avatar-item">
                                                          <video
                                                            src={
                                                              item?.profilePicture
                                                            }
                                                            autoPlay
                                                            playsInline
                                                            loop
                                                            muted
                                                            style={{
                                                              cursor: "pointer",
                                                              width: "20px",
                                                              height: "20px",
                                                              objectFit:
                                                                "cover",
                                                              verticalAlign:
                                                                "middle",
                                                              borderRadius:
                                                                "100%",
                                                            }}
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                          >
                                                            <source
                                                              src={
                                                                item?.profilePicture
                                                              }
                                                            />
                                                          </video>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <div
                                                        key={
                                                          item?.profilePicture
                                                        }
                                                        className="avatar-item"
                                                      >
                                                        <img
                                                          src={
                                                            item.profilePicture ||
                                                            "/static/avatar-dummy-1.png"
                                                          }
                                                          alt=""
                                                        />
                                                      </div>
                                                    )}
                                                  </Link>
                                                </div>
                                              );
                                            })}
                                        </div>
                                        <span className="t-participants">
                                          + {item.participants}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="d-md-none d-flex refund-status-box-mob">
                                      <div style={{ textAlign: "left" }}>
                                        <div className="content-items">
                                          <span className="sub-title">
                                            Total amount:{" "}
                                          </span>
                                          <span className="title">
                                            ${item?.refundedTotalAmount || 0}
                                          </span>
                                        </div>
                                        <div className="content-items">
                                          <span className="sub-title">
                                            Cancelled on Wed 23 Jun
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="refund-status-box"
                                        style={{
                                          border: `1px solid ${
                                            item?.status === "RF"
                                              ? "#29CC6A"
                                              : "#EE7830"
                                          }`,
                                        }}
                                      >
                                        <div className="title">Refund:</div>
                                        <div
                                          className="status"
                                          style={{
                                            color: `${
                                              item?.status === "RF"
                                                ? "#29CC6A"
                                                : "#EE7830"
                                            }`,
                                          }}
                                        >
                                          {item?.status === "RF"
                                            ? "Done"
                                            : "In Progress"}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    ) : (
                      <div className="empty-data d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <img src="/static/EmptyBooking.svg" alt="" />
                          <p>No Cancelled bookings</p>
                          <p style={{ fontSize: 12 }}>
                            Glad you do not have any cancelled events
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {sideList ? (
        <div className="map-fixed-sidebar">
          <div className="map-sidebar-box">
            <div className="map-heading d-none d-md-flex">
              <img
                src="/static/cross-black.svg"
                onClick={() => setSideList(false)}
                className="close-btn"
                alt=""
              />
              <p>Maps</p>
            </div>
            <div className="sb-map-container">
              <div className="d-md-none">
                <GlobalHeader />
              </div>
              <div
                className="map-back-icon d-md-none"
                onClick={() => setSideList(false)}
              >
                <LeftArrowStickIcon />
              </div>
              <GoogleMapContainer height="100%" center={mapCenter} />
            </div>
          </div>
        </div>
      ) : null}

      {showDeletePopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal">
            <div className="modal-top-part">
              <h5>Are you sure?</h5>
              {/* <p>{STRING_CONSTANTS.logout_desc}</p> */}
            </div>
            <div className="button_option">
              <Button
                btnTxt="Delete"
                className={"backtologin"}
                onClick={deleteDraftItem}
              />
              <Button
                btnTxt="Cancel"
                className={"backtologin cancel"}
                onClick={() => setShowDeletePopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showCsvPopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal csv-modal-wrap">
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowCsvPopup(false);
                }}
                className="close-btn"
                alt=""
              />
            </div>
            <div className="modal-top-part">
              <div>
                <img alt="" src="/static/file-type-excel.svg" />
              </div>

              <h5>Export Your Bookings</h5>
              <p>
                Effortlessly transfer your listings to an Excel spreadsheet for
                easy organization and analysis.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Download as Excel(.csv)"
                className={"backtologin"}
                onClick={downloadCsv}
              />
              <Button
                btnTxt="Not now"
                className={"backtologin notnow"}
                onClick={() => setShowCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showEarningCsvPopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal csv-modal-wrap">
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowEarningCsvPopup(false);
                }}
                className="close-btn"
                alt=""
              />
            </div>
            <div className="modal-top-part">
              <div>
                <img alt="" src="/static/file-type-excel.svg" />
              </div>

              <h5>Export Your Listings</h5>
              <p>
                Effortlessly transfer your listings to an Excel spreadsheet for
                easy organization and analysis.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Download as Excel(.csv)"
                className={"backtologin"}
                onClick={downloadEarningCsv}
              />
              <Button
                btnTxt="Not now"
                className={"backtologin notnow"}
                onClick={() => setShowEarningCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showInactivePopup ? (
        <div className="modal-cancel-event show">
          <div className="modal-section" style={{ gap: "20px" }}>
            <div className="modal-top-part">
              <button className="close">
                <img
                  src="/static/dismiss-cross-round-black.svg"
                  alt=""
                  onClick={() => setShowInactivePopup(false)}
                />
              </button>

              <h5>Are You Sure</h5>
              <p>
                Once the listing is inactivated we won’t be able to recover the
                information.
              </p>
            </div>

            <Button
              btnTxt="Yes, Inactive"
              className={"backtologin"}
              onClick={() => {
                makeInactiveList();
              }}
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => setShowInactivePopup(false)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ViewListing;
