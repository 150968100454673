import { forwardRef, useEffect, useState } from "react";
import { Controller, useController } from "react-hook-form";
import { emptyFunction } from "../utils";
interface InputFieldProps {
  name: string;
  type: React.HTMLInputTypeAttribute;
  inputLabel?: string;
  as?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  errors?: object | any;
  value?: string | boolean | any;
  maxlength?: number | any;
  id?: string;
  control?: any;
  extramsg?: string;
  isChecked?: boolean;
  customOnChange?: any;
  reference?: any;
  onlyPastDates?: boolean;
  onlyFuturedates?: boolean;
  onKeyDown?: any;
  fileOnChange?: any;
  getValues?: any;
  setValue?: any;
  pattern?: any;
  handleAge?: any;
  handleNotes?: any;
  isListingTextArea?: any;
  isSpecialInstruction?: any;
}

const InputField = forwardRef((props: InputFieldProps, ref: any) => {
  const {
    name,
    type,
    extramsg,
    inputLabel,
    placeholder,
    style,
    control,
    className,
    errors,
    id,
    setValue,
    customOnChange,
    fileOnChange,
    reference,
    maxlength,
    getValues,
    disabled,
    pattern,
    onKeyDown = emptyFunction,
    handleAge = emptyFunction,
    handleNotes = emptyFunction,
    isListingTextArea = false,
    isSpecialInstruction = false,
  } = props;

  const [isFocus] = useState(false);
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const handleOnchange = (e: any) => {
    if (customOnChange) {
      customOnChange(e.target);
    } else if (fileOnChange) {
      fileOnChange(e);
    }
    onChange(e);
  };
  const renderMainInput = () => {
    switch (type) {
      case "radio":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  type={type}
                  onChange={onChange}
                  id={value}
                  checked={value === id}
                  placeholder={placeholder}
                  value={id}
                  disabled={disabled}
                  onKeyDown={onKeyDown || {}}
                />
              );
            }}
          />
        );
      case "textarea":
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => {
              return (
                <textarea
                  onChange={(e) => {
                    if (!maxlength) {
                      onChange(e);
                      handleNotes(e);
                    } else if (
                      e.target.value?.split(" ")?.length <= maxlength
                    ) {
                      onChange(e);
                      handleNotes(e);
                    }
                  }}
                  placeholder={placeholder}
                  value={value || ""}
                  // maxLength={maxlength}
                  disabled={disabled}
                />
              );
            }}
          />
        );
      case "checkbox":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  name={name}
                  type="checkbox"
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  id={id || ""}
                  value={value}
                  checked={value}
                  onKeyDown={onKeyDown || {}}
                />
              );
            }}
          />
        );
      case "number":
        return (
          <input
            onChange={(e) => {
              if (!maxlength) {
                handleOnchange(e);
                handleAge();
              } else if (e.target.value?.length <= maxlength) {
                handleOnchange(e);
                handleAge();
              }
            }}
            type={type}
            id={id || ""}
            ref={reference}
            placeholder={placeholder}
            value={value ? value : value === 0 ? value : undefined}
            min={1}
            onKeyDown={onKeyDown || {}}
            pattern={pattern}
            max={maxlength}
            disabled={disabled}
            onWheel={(e) => e.currentTarget.blur()} //prevent value change on scroll
          />
        );
      case "file":
        return (
          <input
            onChange={(e) => {
              handleOnchange(e);
            }}
            type={type}
            ref={reference}
            id={id || ""}
            value={value || ""}
          />
        );
      default:
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  type={type}
                  ref={reference}
                  maxLength={maxlength}
                  id={id || ""}
                  placeholder={placeholder}
                  value={value || ""}
                  onKeyDown={onKeyDown || {}}
                  autoComplete={"off"}
                  disabled={disabled}
                />
              );
            }}
          />
        );
    }
  };
  useEffect(() => {
    if (id === "autocomplete") {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <div
      style={style}
      className={`form_group ${props?.value || isFocus ? "focused" : ""} ${
        className ? className : ""
      } `}
    >
      <div
        className={
          errors && errors[name] ? "input_box input_error" : "input_box"
        }
      >
        {inputLabel && <span className="input_label">{inputLabel}</span>}
        {renderMainInput()}
        {type === "textarea" ? (
          <>
            {isListingTextArea ? (
              <span className="count">
                {`${
                  getValues(name)
                    ?.split(/\s+/)
                    ?.filter((word) => word.trim() !== "").length || 0
                }/${maxlength}
                `}
              </span>
            ) : isSpecialInstruction ? (
              <span className="count">
                {`${
                  getValues(name)
                    ?.split(/\s+/)
                    ?.filter((word) => word.trim() !== "").length || 0
                }/${maxlength}
                `}
              </span>
            ) : (
              <span className="count">
                {getValues(name)?.trim()?.split(" ")?.length || 0}/ {maxlength}
              </span>
            )}
          </>
        ) : null}

        {extramsg && <p className="extramsg">{extramsg}</p>}
        {value?.length > 0 && (
          <img
            src="/static/cross-circle-gray-dark.svg"
            className={disabled ? "disabled cross-icon " : "cross-icon"}
            alt=""
            onClick={() => setValue(name, "")}
          />
        )}
      </div>
      {errors && errors[name] && (
        <span className="text-danger">{errors[name]?.message}</span>
      )}
    </div>
  );
});

export default InputField;
