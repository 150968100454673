import { DateMinus, DatePlus } from "../../utils/Icons/svgs";

const DateCounter = (props) => {
  const { counter, setCounter, maxSlot } = props;

  const handleMinusCounter = () => {
    if (counter > 1) {
      setCounter((prev) => prev - 1);
    }
  };

  const handlePlusCounter = () => {
    if (counter < Number(maxSlot)) {
      setCounter((prev) => prev + 1);
    }
  };
  return (
    <>
      <div className=" date_book_counter">
        <span
          className="d-flex align-items-center rounded-circle cursor-pointer"
          style={{ backgroundColor: "rgba(238, 120, 48, 0.4)" }}
          onClick={handleMinusCounter}
        >
          <DateMinus />
        </span>
        <p className="mb-0">{counter}</p>
        <span
          className="d-flex align-items-center rounded-circle cursor-pointer"
          style={{ backgroundColor: "rgba(238, 120, 48, 0.4)" }}
          onClick={handlePlusCounter}
        >
          <DatePlus />
        </span>
      </div>
    </>
  );
};
export default DateCounter;
