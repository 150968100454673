import React from "react";
import laugh from "../../assets/img/signupWelcome/laugh.svg";
import dumbbell from "../../assets/img/signupWelcome/dumbbell.svg";
type Props = {
  compareData?: any;
};

const ResponsiveCompareTable = ({ compareData = [] }: Props) => {
  return (
    <div className="resp_table">
      <div className="header_resp">
        <div className="guest_heading">
          <img src={laugh} alt="" />
          Guest
        </div>
        <div className="special member_heading">
          <img src={dumbbell} alt="" />
          Member
        </div>
      </div>
      <div className="container_comparison">
        {compareData.map((item, id) => (
          <div className="content_comparison">
            <div className="text-center name_resp">{item.name}</div>
            <div className="row justify-content-between">
              <div className="col-4 text-center feature">{item.guest}</div>
              <div className="col-4 text-center feature">{item.member}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ResponsiveCompareTable;
