import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeGetCall, makePatchCall } from "../../../utils/Requests";
import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import TeamMembershipCard from "./TeamMembershipCard";
import endpoints from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import ApiLoader from "../../../global/ApiLoader";
import Button from "../../../global/Button";

const MyTeamListing = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>("Accepted");
  const tabs = ["Accepted", "Rejected", "Pending"];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamMemberShipsList, setTeamMemberShipsList] = useState<any>([]);
  const [listingType, setListingType] = useState("A");
  const [showPopup, setShowPopup] = useState(false);
  const [listingId, setListingId] = useState(null);

  const cancelMembership = () => {
    const formData = new FormData();
    formData.append("status", "C");
    makePatchCall({
      url: `${endpoints.team_listing}${listingId}/`,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        setListingId(null);
        setShowPopup(false);
        showToast(res.status.message, "success");
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const getMyMemberships = () => {
    setIsLoading(true);
    makeGetCall({
      url: `${endpoints.team_listing}?status=${listingType}`,
    })
      .then((res) => {
        setTeamMemberShipsList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyMemberships();
  }, [activeTab]);

  return (
    <>
      <DashboardWrapper showTab={true}>
        <div className="personal-details-wrapper">
          <div className="favourite-new-contenet team-membership-new-page">
            <div className="page-title">My Team Listings</div>
            <div className="tabs-container-wrap">
              <div className="tabs-container">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    className={`tab-button ${
                      activeTab === tab ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTab(tab);
                      setListingType(
                        tab === "Accepted"
                          ? "A"
                          : tab === "Rejected"
                          ? "D"
                          : "R"
                      );
                    }}
                  >
                    {tab.toUpperCase()}
                  </button>
                ))}
              </div>
              <div className="team-membership-card-listing">
                {activeTab === "Accepted" ? (
                  <>
                    {teamMemberShipsList.length > 0 && !isLoading ? (
                      <>
                        {teamMemberShipsList.map((i) => (
                          <TeamMembershipCard
                            listingTitle={i.listingData.title}
                            listingMedia={
                              i.listingData?.photos[0]?.uploadDocument
                            }
                            modifiedAt={i.listingData?.modifiedAt}
                            rejected={i.status === "D"}
                            status={i.status}
                            listingEventType={i.listingData.listingEventType}
                            onCancelPayCut={() => {
                              setListingId(i.id);
                              setShowPopup(true);
                            }}
                            handleViewDetails={() => {
                              navigate(`/view-team-details/${i.id}`, {
                                state: i,
                              });
                            }}
                          />
                        ))}
                      </>
                    ) : isLoading ? (
                      <ApiLoader />
                    ) : (
                      <div className="no-content-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <img alt="" src="/static/no-team-content-new.svg" />
                          <div className="title">No data available</div>
                          <div className="subtitle">
                            There is no team listings here to show, please{" "}
                            <Link to="/events?type=C">click here</Link> to join.
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : activeTab === "Rejected" ? (
                  <>
                    {teamMemberShipsList.length > 0 && !isLoading ? (
                      <>
                        {teamMemberShipsList.map((i) => (
                          <TeamMembershipCard
                            listingTitle={i.listingData.title}
                            listingMedia={
                              i.listingData?.photos[0]?.uploadDocument
                            }
                            modifiedAt={i.listingData?.modifiedAt}
                            rejected={i.status === "D"}
                            status={i.status}
                            listingEventType={i.listingData.listingEventType}
                            onCancelPayCut={() => {}}
                            handleViewDetails={() => {
                              navigate(`/view-team-details/${i.id}`, {
                                state: i,
                              });
                            }}
                          />
                        ))}
                      </>
                    ) : isLoading ? (
                      <ApiLoader />
                    ) : (
                      <div className="no-content-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <img alt="" src="/static/no-team-content-new.svg" />
                          <div className="title">No data available</div>
                          <div className="subtitle">
                            There is no team listings here to show, please{" "}
                            <Link to="/events?type=C">click here</Link> to join.
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {teamMemberShipsList.length > 0 && !isLoading ? (
                      <>
                        {teamMemberShipsList.map((i) => (
                          <TeamMembershipCard
                            listingTitle={i.listingData.title}
                            listingMedia={
                              i.listingData?.photos[0]?.uploadDocument
                            }
                            modifiedAt={i.listingData?.modifiedAt}
                            rejected={i.status === "D"}
                            status={i.status}
                            listingEventType={i.listingData.listingEventType}
                            onCancelPayCut={() => {
                              setListingId(i.id);
                              setShowPopup(true);
                            }}
                            handleViewDetails={() => {
                              navigate(`/view-team-details/${i.id}`, {
                                state: i,
                              });
                            }}
                          />
                        ))}
                      </>
                    ) : isLoading ? (
                      <ApiLoader />
                    ) : (
                      <div className="no-content-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <img alt="" src="/static/no-team-content-new.svg" />
                          <div className="title">No data available</div>
                          <div className="subtitle">
                            There is no team listings here to show, please{" "}
                            <Link to="/events?type=C">click here</Link> to join.
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {showPopup ? (
          <div className="modal show">
            <div
              className="modal-section"
              style={{ gap: "20px", maxWidth: "500px" }}
            >
              <div className="modal-top-part">
                <button className="close">
                  <img
                    src="/static/dismiss-cross-round-black.svg"
                    alt=""
                    onClick={() => setShowPopup(false)}
                  />
                </button>
                <h5>
                  {activeTab === "Accepted"
                    ? "Are you sure you want to cancel this accepted request?"
                    : "Are you sure you want to cancel this pending request?"}
                </h5>
              </div>

              <Button
                btnTxt="Yes"
                className={"backtologin"}
                onClick={() => cancelMembership()}
              />
              <Button
                btnTxt="Cancel"
                className={"gobackmodalbtn backtologin"}
                onClick={() => setShowPopup(false)}
              />
            </div>
          </div>
        ) : null}
      </DashboardWrapper>
    </>
  );
};

export default MyTeamListing;
