import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { apiConstants } from "../../../utils/Constants";
interface DraftPopUpProps {
  onClose?: any;
  handleBankDetails?: any;
  control?: any;
  errors?: any;
  setValue?: any;
  isEditMode?: boolean;
}
const AddBankAccountPopup = (props: DraftPopUpProps) => {
  const {
    onClose,
    handleBankDetails,
    control,
    errors,
    setValue,
    isEditMode = false,
  } = props;

  return (
    <div className={"modal show"}>
      <div className="stripe_acc_pop">
        <div className="modal-top-part">
          <div className="header_container">
            <img src="/static/closeBorder.svg" alt="" onClick={onClose} />
          </div>

          <div className="modal_stripe_heading">Add bank details</div>
          <div className="bank_details_form">
            <InputField
              type={"text"}
              name={apiConstants.bank_name}
              inputLabel={"Bank Name"}
              placeholder={"Enter the Bank name"}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <InputField
              type={"number"}
              name={apiConstants.bsb_number}
              inputLabel={"BSB Number"}
              placeholder={"123-456"}
              control={control}
              errors={errors}
              setValue={setValue}
              maxlength={6}
            />
            <InputField
              type={"number"}
              name={apiConstants.account_number}
              inputLabel={"Bank Account Number"}
              placeholder={"0111-334-344"}
              control={control}
              maxlength={12}
              errors={errors}
              setValue={setValue}
            />
          </div>
        </div>
        <Button
          btnTxt={isEditMode ? "Edit bank details" : "Add bank details"}
          className={"stripe_existing"}
          onClick={() => handleBankDetails()}
        />
        <Button btnTxt="Cancel" className={"stripe_new"} onClick={onClose} />
      </div>
    </div>
  );
};

export default AddBankAccountPopup;
