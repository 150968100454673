import cardImg from "../../assets/img/expertise2.png";

const DraftCard = ({
  url,
  title = "Health Cycling Event",
  date = "24 Mar, 2023",
  address = "HealthFit, H1-H2 Second floor, Jaipur",
  timing = "4:00 - 5:00 PM",
  extension = "image/jpg",
  onClick,
  onDeleteClick,
}) => {
  //alert(extension)
  return (
    <>
      <div className="draft-card-wrap" onClick={onClick}>
        <div className="content-box">
          <div>
            {/* <img alt="" src={url || cardImg} className="card-img" /> */}
            {
              // ["mp4", "mov", "avi"].includes(
              //   url?.split(".").splice(-1)[0].toLowerCase()
              // )
              (extension && /\/(mp4|mov|avi)$/.test(extension.toLowerCase()))
                ? (
                  <>
                    <video
                      style={{
                        cursor: "pointer",
                        width: "91px",
                        height: "91px",
                        objectFit: "cover",
                        verticalAlign: "middle",
                        borderRadius: "4px",
                      }}
                      src={url}
                      autoPlay playsInline
                      loop
                      muted
                    >
                      <source src={url} />
                    </video>
                  </>
                ) : (
                  <img src={url || cardImg} className="card-img" alt="" />
                )}
          </div>
          <div className="content">
            <div className="title">{title}</div>
            <div className="sub-content d-none d-md-block">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M5.5 3C4.11929 3 3 4.11929 3 5.5V6L17 6V5.5C17 4.11929 15.8807 3 14.5 3L5.5 3ZM17 7L3 7L3 14.5C3 15.8807 4.11929 17 5.5 17H14.5C15.8807 17 17 15.8807 17 14.5V7ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9C7.55228 9 8 9.44771 8 10ZM7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13C8 13.5523 7.55228 14 7 14ZM11 10C11 10.5523 10.5523 11 10 11C9.44771 11 9 10.5523 9 10C9 9.44771 9.44771 9 10 9C10.5523 9 11 9.44771 11 10ZM10 14C9.44771 14 9 13.5523 9 13C9 12.4477 9.44771 12 10 12C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14ZM14 10C14 10.5523 13.5523 11 13 11C12.4477 11 12 10.5523 12 10C12 9.44771 12.4477 9 13 9C13.5523 9 14 9.44771 14 10Z"
                    fill="#8F939A"
                  />
                </svg>
              </span>
              <span>{date}</span>
            </div>
          </div>
          <div className="delete-icon" onClick={onDeleteClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.5 6C21.5 6.51284 21.114 6.93551 20.6166 6.99327L20.5 7H19.6553L18.4239 19.5192C18.2854 20.9269 17.1016 22 15.6871 22H8.31293C6.8984 22 5.7146 20.9269 5.57614 19.5192L4.34474 7H3.5C2.94772 7 2.5 6.55228 2.5 6C2.5 5.44772 2.94772 5 3.5 5H8.5C8.5 3.067 10.067 1.5 12 1.5C13.933 1.5 15.5 3.067 15.5 5H20.5C21.0523 5 21.5 5.44772 21.5 6ZM14.25 9.25C13.8703 9.25 13.5565 9.53215 13.5068 9.89823L13.5 10V17L13.5068 17.1018C13.5565 17.4678 13.8703 17.75 14.25 17.75C14.6297 17.75 14.9435 17.4678 14.9932 17.1018L15 17V10L14.9932 9.89823C14.9435 9.53215 14.6297 9.25 14.25 9.25ZM9.75 9.25C9.3703 9.25 9.05651 9.53215 9.00685 9.89823L9 10V17L9.00685 17.1018C9.05651 17.4678 9.3703 17.75 9.75 17.75C10.1297 17.75 10.4435 17.4678 10.4932 17.1018L10.5 17V10L10.4932 9.89823C10.4435 9.53215 10.1297 9.25 9.75 9.25ZM12 3.5C11.1716 3.5 10.5 4.17157 10.5 5H13.5C13.5 4.17157 12.8284 3.5 12 3.5Z"
                fill="url(#paint0_linear_872_42910)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_872_42910"
                  x1="-0.958975"
                  y1="-1.54828"
                  x2="8.74357"
                  y2="37.6161"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#EE7830" />
                  <stop offset="1" stop-color="#EE762F" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="d-md-none">
          <div className="sub-content">
            <span>
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.042 2.042C4.76467 -0.680667 9.17899 -0.680667 11.9017 2.042C14.6243 4.76467 14.6243 9.17899 11.9017 11.9017L10.9513 12.8416C10.2508 13.5291 9.34187 14.413 8.22424 15.4936C7.52584 16.1689 6.41783 16.1688 5.71954 15.4934L2.92426 12.7744C2.57294 12.4294 2.27888 12.1385 2.042 11.9017C-0.680667 9.17899 -0.680667 4.76467 2.042 2.042ZM11.0524 2.89126C8.79877 0.637623 5.1449 0.637623 2.89126 2.89126C0.637623 5.1449 0.637623 8.79877 2.89126 11.0524L4.08194 12.2274C4.73763 12.8691 5.56191 13.6701 6.55451 14.6301C6.78727 14.8552 7.15661 14.8553 7.38941 14.6302L10.1076 11.9867C10.4831 11.6182 10.7981 11.3067 11.0524 11.0524C13.306 8.79877 13.306 5.1449 11.0524 2.89126ZM6.97183 4.78889C8.29914 4.78889 9.37514 5.86489 9.37514 7.1922C9.37514 8.5195 8.29914 9.5955 6.97183 9.5955C5.64452 9.5955 4.56852 8.5195 4.56852 7.1922C4.56852 5.86489 5.64452 4.78889 6.97183 4.78889ZM6.97183 5.98992C6.30783 5.98992 5.76956 6.5282 5.76956 7.1922C5.76956 7.85619 6.30783 8.39447 6.97183 8.39447C7.63583 8.39447 8.17411 7.85619 8.17411 7.1922C8.17411 6.5282 7.63583 5.98992 6.97183 5.98992Z"
                  fill="#8F939A"
                />
              </svg>
            </span>
            <h6 className="mb-0">{address}</h6>
          </div>
          <div className="sub-content">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5.5 3C4.11929 3 3 4.11929 3 5.5V6L17 6V5.5C17 4.11929 15.8807 3 14.5 3L5.5 3ZM17 7L3 7L3 14.5C3 15.8807 4.11929 17 5.5 17H14.5C15.8807 17 17 15.8807 17 14.5V7ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9C7.55228 9 8 9.44771 8 10ZM7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13C8 13.5523 7.55228 14 7 14ZM11 10C11 10.5523 10.5523 11 10 11C9.44771 11 9 10.5523 9 10C9 9.44771 9.44771 9 10 9C10.5523 9 11 9.44771 11 10ZM10 14C9.44771 14 9 13.5523 9 13C9 12.4477 9.44771 12 10 12C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14ZM14 10C14 10.5523 13.5523 11 13 11C12.4477 11 12 10.5523 12 10C12 9.44771 12.4477 9 13 9C13.5523 9 14 9.44771 14 10Z"
                  fill="#8F939A"
                />
              </svg>
            </span>
            <span>{date}</span>
          </div>
          <div className="sub-content">
            <span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99961 0.599609C10.5342 0.599609 13.3996 3.46499 13.3996 6.99961C13.3996 10.5342 10.5342 13.3996 6.99961 13.3996C3.46499 13.3996 0.599609 10.5342 0.599609 6.99961C0.599609 3.46499 3.46499 0.599609 6.99961 0.599609ZM6.59961 2.99961C6.3787 2.99961 6.19961 3.1787 6.19961 3.39961V7.39961L6.20605 7.47151C6.23992 7.65811 6.40324 7.79961 6.59961 7.79961L8.99961 7.79961L9.07151 7.79316C9.25811 7.7593 9.39961 7.59598 9.39961 7.39961C9.39961 7.1787 9.22052 6.99961 8.99961 6.99961L6.99961 6.99961V3.39961L6.99317 3.32771C6.9593 3.14111 6.79598 2.99961 6.59961 2.99961Z"
                  fill="#8F939A"
                />
              </svg>
            </span>
            <span>{timing}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftCard;
