/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  dateFormatter,
  getItemFromStore,
  handleLocation,
  isObjEmpty,
  showToast,
} from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import endpoints from "../../../utils/endpoints";
import { makeGetCall, makePatchCall } from "../../../utils/Requests";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { PersonalDetailsValidationGuest } from "../../Profile/ProfileValidations";

import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import MobileInput from "../../../global/MobileInput";
import { USER_TYPE } from "../../../types";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";

const BusinessDetails = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    resolver: yupResolver(PersonalDetailsValidationGuest),
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const [userUpdate, setUserUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserProfileChange, setIsUserProfileChange] = useState(false);
  const [abnResponse, setAbnResponse] = useState<any>("");
  const { isMobile } = useCheckDeviceScreen();

  const updateprofile = (formData: any) => {
    setIsLoading(true);
    makePatchCall({
      url: endpoints.profile,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setUserUpdate(true);
          setIsUserProfileChange(!isUserProfileChange);
        } else {
          showToast(
            res.status.message || STRING_CONSTANTS.wrong_data_message,
            "error"
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const formData = new FormData();
  const updatedValues: any = {};

  useEffect(() => {
    if (
      getItemFromStore("userProfile")?.userType === USER_TYPE.MEMBER &&
      watch(apiConstants.abn)?.split(" ")?.join("")?.length === 11
    ) {
      makeGetCall({
        url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setAbnResponse(res.data);
            if (res.data.AbnStatus === "Active") {
              setAbnResponse(res.data);
              setValue(apiConstants.abnDetails, res.data);
            } else {
              setAbnResponse(res.data.Message);
            }
          } else {
            setAbnResponse(STRING_CONSTANTS.invalid_abn);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else {
      setAbnResponse(null);
    }
  }, [watch(apiConstants.abn)]);

  const handleNext = () => {
    handleSubmit(onSubmit, onError)();
  };

  const onError = () => {
    showToast(STRING_CONSTANTS.general_form_error_message, "error");
  };
  const onSubmit = (data: any) => {
    Object.keys(dirtyFields).map((item: any) => {
      updatedValues[item] = getValues(item);
      if (item === "dob" && getValues(apiConstants.dob) !== null) {
        formData.append(
          apiConstants.dob,
          dateFormatter(data.dob, "YYYY-MM-DD")
        );
      } else if (
        item === "businessContactNumber" &&
        getValues(apiConstants.businessContactNumber).length > 3
      ) {
        formData.append(
          apiConstants.businessContactNumber,
          `+61${data.businessContactNumber}`
        );
      } else if (item === "abn") {
        formData.append(
          apiConstants.abn,
          getValues(apiConstants.abn).replaceAll(" ", "")
        );
        if (watch(apiConstants?.abnDetails)) {
          formData.append(
            "businessContactName",
            watch(apiConstants.abnDetails)?.EntityName
          );
          formData.append(
            "businessAddress",
            `${watch(apiConstants.abnDetails)?.AddressState} ${
              watch(apiConstants.abnDetails)?.AddressPostcode
            }`
          );
        }
      } else {
        if (getValues(item) !== null) formData.append(item, getValues(item));
      }
    });
    if (!isObjEmpty(dirtyFields)) {
      updateprofile(formData);
    }
  };

  return (
    <DashboardWrapper
      watch={watch}
      userUpdate={userUpdate}
      reset={reset}
      showTab={true}
      isUserProfileChange={isUserProfileChange}
    >
      <div className="personal-details-wrapper">
        <div className="profile_box">
          <div className="title">
            {STRING_CONSTANTS.update_business_details}
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow d-md-none"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
        <div className="details-box">
          <div className="detail-title">Business Details</div>
          <InputField
            type="email"
            name={apiConstants.tradingName}
            control={control}
            errors={errors}
            inputLabel={"Trading Name *"}
            placeholder={"Add your trading name"}
            setValue={setValue}
          />
          <MobileInput
            name={apiConstants.abn}
            watch={watch}
            setValue={setValue}
            control={control}
            inputLabel={"ABN Number (11 Digits)"}
            placeholder={"Add your ABN number"}
            mask={"99 999 999 999"}
            errors={errors}
          />
          {!errors.abn &&
            abnResponse &&
            (abnResponse?.AbnStatus === "Active" ? (
              <div className="api_response mb_24">
                <div className="wrapper">
                  <>
                    <label>Business Name</label>
                    <p className="mb-0">{abnResponse?.EntityName}</p>
                    {isMobile && <label>Address</label>}
                    <p className="mb-0">{abnResponse.AddressState}</p>
                    <span>
                      `Registered on` {abnResponse.AbnStatusEffectiveFrom}
                    </span>
                  </>
                </div>
              </div>
            ) : (
              <div className="text-danger abn-not-active mb_24">
                {abnResponse}
              </div>
            ))}
          <MobileInput
            name={apiConstants.businessContactNumber}
            watch={watch}
            setValue={setValue}
            control={control}
            className={"mobile"}
            errors={errors}
          />
          <InputField
            type="email"
            name={apiConstants.businessContactEmail}
            control={control}
            errors={errors}
            inputLabel={"Contact Email *"}
            placeholder={"Add your email address"}
            setValue={setValue}
          />

          {getItemFromStore("userProfile")?.userType !== USER_TYPE.GUEST ? (
            <GooglePlacesSearchBar
              hideMap={true}
              onChangeLocation={(
                lat: string,
                lng: string,
                address: string,
                address_components: any
              ) => {
                let location = handleLocation(
                  lat,
                  lng,
                  address,
                  address_components
                );
                setValue(apiConstants.address, address);
                setValue(apiConstants.lat, lat);
                setValue(apiConstants.lng, lng);
                setValue(apiConstants.postCode, location.zipcode);
                setValue(apiConstants.state, location.state);
                setValue(apiConstants.city, location.locality);
                setValue(apiConstants.country, location.country);
              }}
              setValue={setValue}
              handleLocation={handleLocation}
              control={control}
              errors={errors}
              name={apiConstants.address}
              label={"Business Address"}
              placeholder="Address"
            />
          ) : null}

          <div className="continue_btn">
            <div className="main-section-form-button">
              <div className="main-section-form"></div>
              <Button
                btnTxt="Save"
                className={"backtologin"}
                onClick={handleNext}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default BusinessDetails;
