import Button from "../../../global/Button";
import ArrowForward from "../../../assets/img/icons/ArrowForward";
import moment from "moment";

const TeamMembershipCard = (props) => {
  const {
    listingMedia,
    listingTitle,
    onCancelPayCut,
    modifiedAt,
    status = "A",
    listingEventType,
    handleViewDetails,
  } = props;

  return (
    <div className="my-teamlisting-card-new-wrap">
      <div className="card-wrapper">
        <div className="top-section">
          <div className="left">
            {["mp4", "mov", "avi"].includes(
              listingMedia?.split(".").splice(-1)[0].toLowerCase()
            ) ? (
              <>
                <div className="avatar-item">
                  <video
                    src={listingMedia}
                    autoPlay
                    playsInline
                    loop
                    muted
                    style={{
                      cursor: "pointer",
                      width: "130px",
                      height: "90px",
                      objectFit: "cover",
                      verticalAlign: "middle",
                      borderRadius: "12px 0px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <source src={listingMedia} />
                  </video>
                </div>
              </>
            ) : (
              <img
                alt=""
                src={listingMedia || "/static/membership-card.png"}
                className="my-memberships-img"
              />
            )}
          </div>
          <div className="right">
            <div className="title">{listingTitle}</div>
            <div className="sub-title">
              Requested date: {moment(modifiedAt).format("LL")}
            </div>
            <div className="action-box">
              <div className="event-type-box">
                {listingEventType !== "V" ? (
                  <img alt="" src="/static/virtualmob.svg" />
                ) : (
                  <img alt="" src="/static/physicalmob.svg" />
                )}
              </div>
              <span className="view-all" onClick={handleViewDetails}>
                <span>View all</span>
                <ArrowForward />
              </span>
            </div>
          </div>
        </div>
        <div className="auto-paycut-btn-box">
          <Button
            btnTxt={
              status === "D"
                ? "Rejected"
                : status === "R"
                ? "Cancel Request"
                : "Cancel My Participation"
            }
            className={`${
              status === "D" ? "auto-paycut-btn" : "accept-pending "
            }`}
            onClick={() => {
              onCancelPayCut();
            }}
          />
        </div>
        {status === "R" ? (
          <div className="info-box-cancel-wrap">
            <div className="info-box-cancel">
              <img src="/static/greyinfo.svg" alt="" />
              <span>
                You will be notified once your team request will be accepted or
                rejected.
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TeamMembershipCard;
