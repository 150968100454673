import { STRING_CONSTANTS } from "../utils/StringConstant";
import CommonOutlineButton from "./CommonOutlineButton";
interface UserFlagged {
  onOk: any;
  onCancel: any;
}
const UserFlaggedModal = (props: UserFlagged) => {
  const { onCancel } = props;
  return (
    <div className={"modal show"}>
      <div className="modal-section log_out_modal">
        <div className="modal-top-part" style={{ position: "relative" }}>
          <img src="/static/UserFlagged.svg" alt="" />
          <h5>{STRING_CONSTANTS.user_flagged}</h5>
          <p>{STRING_CONSTANTS.user_flagged_description}</p>
        </div>
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <CommonOutlineButton
            image=""
            width="162px"
            text="Cancel"
            className={"common_btn_grey"}
            onClick={() => onCancel()}
          />
        </div>
      </div>
    </div>
  );
};

export default UserFlaggedModal;
