import React from "react";
import { Controller, useController } from "react-hook-form";
import { SelectDatepicker } from "react-select-datepicker";
import { STRING_CONSTANTS } from "../utils/StringConstant";
interface DateSelecterProps {
  name: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  errors?: object | any;
  id?: string;
  control?: any;
  minDate?: any;
  maxDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  dobError?: boolean;
  dobTwoError?: boolean;
}
const DateSelecter = (props: DateSelecterProps) => {
  const { name, control, dobError, dobTwoError } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <>
            <SelectDatepicker
              order="day/month/year"
              labels={{
                dayLabel: "Day",
                dayPlaceholder: "Day",
                monthLabel: "Month",
                monthPlaceholder: "Month",
                yearLabel: "Year",
                yearPlaceholder: "Year",
              }}
              className={"data_dropzone"}
              selectedDate={value}
              onDateChange={onChange}
            />

            {dobError ? (
              <div className="text-danger">{STRING_CONSTANTS.dob_error}</div>
            ) : null}
            {dobTwoError ? (
              !value ? (
                <div className="text-danger">
                  please enter the complete date of birth
                </div>
              ) : null
            ) : null}
          </>
        );
      }}
    />
  );
};

export default DateSelecter;
