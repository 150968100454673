import Button from "../../global/Button";
import { emptyFunction } from "../../utils";

export const MapConnectCard = ({
  item,
  onConnectClick,
  onClickProfile,
  mapview = false,
  onCloseClick = emptyFunction,
  status,
}) => {
  const { profilePicture, name, distance, interest, profilePictureExtension } =
    item;

  return (
    <div className="connect-card-map">
      <div className="card-content-wrap">
        <div className="content">
          <div className="card-img-wrap">
            {item?.profilePictureExtension?.includes("video") ? (
              <video
                className={`vid`}
                autoPlay
                playsInline
                loop
                muted
                src={item?.profilePicture}
              >
                <source src={"" || ""} type="video/mp4" />
              </video>
            ) : (
              <img
                className="img"
                src={profilePicture || "/static/dummyimg.svg"}
                alt=""
              />
            )}
            {mapview ? (
              <button
                style={{
                  position: "absolute",
                  float: "right",
                  background: "rgba(0, 0, 0, 0.32)",
                  borderRadius: "50%",
                  right: "20px",
                  top: "20px",
                  outline: "none",
                  border: "none",
                }}
                onClick={onCloseClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                    fill="white"
                  />
                </svg>
              </button>
            ) : null}
          </div>
          <div className="username">{name}</div>
          <div className="location-info">
            📍 {distance?.toFixed(2)} km’s away...
          </div>
          <div style={{ height: "68px" }}>
            <div className="interest-chip-box">
              {interest?.map((item) => {
                return (
                  <div className="chip" key={item.id}>
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="button-box">
            <Button
              btnTxt="Profile"
              className={"backtologin profile"}
              btnImg={"/static/profile-circle-btn.svg"}
              onClick={onClickProfile}
            />
            <Button
              disabled={status === "Pending"}
              btnTxt={status === "Pending" ? "Request sent" : "Connect"}
              className={
                status === "Pending"
                  ? "disabled-connect"
                  : "backtologin connect"
              }
              btnImg={"/static/person-add-btn.svg"}
              onClick={onConnectClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
