import GlobalHeader2 from "./GlobalHeader2";
import GlobalFooter from "./GlobalFooter";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import ListingDetailGallery from "./ListingDetailGallery";
import ListingDetailAllSlots from "./ListingDetailAllSlots";

import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { makeGetCall } from "../../utils/Requests";
import { showToast, handleVideoClick, scrollToTop } from "../../utils";
import endpoints from "../../utils/endpoints";
import { useParams, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { isUserAuthenticated, getItemFromStore } from "../../utils";
import { LISTING_TYPE } from "../../types";
import ApiLoaderNew from "../../global/ApiLoaderNew";
import { SpDateIcon, ExclusiveMembericon } from "../../utils/Icons/svgs";
import parse from "html-react-parser";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GooglePlacesSearchBarFilterSidebar from "../../global/GooglePlacesSearchBarFilterSidebar";
import FlagListingPopup from "./Popups/FlagListingPopup";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import CustomButton from "../../global/Button";
import img2 from "../../assets/img/scroll.png";
import img3 from "../../assets/img/cancel.png";
import { useInView } from 'react-intersection-observer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Vector2 = require("../../assets/img/Physical.svg").default;


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className="image-carousel-button"
      style={{
        background: "rgba(255, 255, 255, 0.4)",
        borderRadius: "50%",
        border: "none",
        cursor: "pointer",
        height: 24,
        width: 24,
        position: "absolute",
        zIndex: "0",
        top: "50%",
        right: "2px",
        translate: "0 -50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
      alt=""
      src="/static/chevron-black-right.svg"
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className="image-carousel-button"
      style={{
        background: "rgba(255, 255, 255, 0.4)",
        borderRadius: "50%",
        border: "none",
        cursor: "pointer",
        height: 24,
        width: 24,
        position: "absolute",
        zIndex: "99",
        top: "50%",
        left: "16px",
        translate: "0 -50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
      alt=""
      src="/static/chevron-black-left.svg"
    />
  );
}


const ListingDetailTwo = () => {
  const [showGallery, setShowGallery] = useState(false);
  const [showAllSlots, setShowAllSlots] = useState(false);

  const [showDates, setShowDates] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [selectdate, setSelectDate] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewData, setReviewData] = useState<any>({});
  const [rating, setRating] = useState(0);
  const [showExclusivePopup, setShowExclusivePopup] = useState(false);
  const [allShowDateModal, setAllShowDateModal] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [location, setLocation] = useState<any>(null);
  const [showFlagListingPopup, setShowFlagListingPopup] = useState(false);

  /* cancel popups state*/
  const [showCancelListingPopup, setShowCancelListingPopup] = useState(false);
  const [showCancelByUser, setShowCancelByUser] = useState(false);
  const [showCancelByAdmin, setShowCancelByAdmin] = useState(null);
  const [showRemoveByAdmin, setShowRemoveByAdmin] = useState(null);
  const [allCancelPopup, setAllCancelPopup] = useState(false);
  const [doesVideoExist, setVideoExist] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const [showFullInterest, setShowFullInterest] = useState(false);
  const { ref: mapSectionRef, inView: mapSectionIsVisible } = useInView();
  const { ref: mapSectionRefMobile, inView: mapSectionIsVisibleMobile } = useInView();
  const { ref: mapReviewsRefMobile, inView: mapReviewIsVisibleMobile } = useInView();


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const toggleText = (e) => {
    e.preventDefault();
    setShowFullText(!showFullText);
  };

  // scrollToTop();
  useEffect(() => {
    if (
      showCancelByUser &&
      detailsData?.listingType !== "SPECIAL" &&
      showCancelByAdmin === null &&
      showRemoveByAdmin === null
    ) {
      setAllCancelPopup(true);
    } else if (
      showCancelByAdmin === "L" &&
      detailsData?.listingType !== "SPECIAL"
    ) {
      setAllCancelPopup(true);
    } else if (
      detailsData?.listingType === "SPECIAL" &&
      showRemoveByAdmin === "L"
    ) {
      setAllCancelPopup(true);
    }
  }, [detailsData?.cancelledByUser]);

  useEffect(() => {
    setShowCancelByUser(detailsData?.cancelledByUser);
    setShowCancelByAdmin(detailsData?.inActiveBy);
    setShowRemoveByAdmin(detailsData?.inActiveBy);
  }, [detailsData]);

  const { id } = useParams();
  // alert(id);
  //const id = "81a6af5d-3694-436d-a4bd-7e8971ef281f";
  const navigate = useNavigate();

  const displayImages = (photos, media) => {
    let array_data = [];
    let number = 5;
    for (var i = 0; i < number; i++) {
      // if (doesVideoExist === null || doesVideoExist !== i) {
      if (photos[i] !== undefined && photos[i]) {
        array_data.push(

          <div className={photos.length == 5 ? 'list_five' + " image-style-" + i : photos.length == 4 ? 'list_img' : "listing_img"}>
            {(/\.(mp4|mov|avi)$/.test(photos[i]["uploadDocument"].toLowerCase())) ?
              <video
                src={photos[i]["uploadDocumentUrl"]}
                className={"video-grid-special box-new-shadow" + " image-style-" + i}
                //autoPlay
                playsInline
                loop
                muted
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                id={`listing_video${i}`}
              >
                <source src={photos[i]["uploadDocumentUrl"]} />
              </video> :
              <img
                className={"image-style-" + i}
                src={photos[i]["uploadDocumentUrl"]} alt="Image 02"
                onClick={() => {
                  navigate('/listing-gallery/' + id + "?open_gallery=true")
                }} />}
          </div>

        );
      } else {
        <>
        </>
        // array_data.push(
        //   <div className="listing_img">
        //     <img
        //       src="/static/fitness-logo.svg" alt="Image 02"
        //       style={{ width: '100px', height: '25px', position: "absolute", top: '50%', left: '40%' }} />
        //   </div>
        // );
      }
      // }
    }
    return array_data;
  };


  const handleShowGallery = () => {
    navigate('/listing-gallery/' + id)
    // setShowGallery(true);
  };
  const handleNotShowGallery = () => {
    setShowGallery(false);
  };
  const handleNotShowAll = () => {
    setShowAllSlots(false);
  };

  const handleShowDate = () => {
    setShowDates(true);
  };
  const handleNotShowDate = () => {
    setShowDates(false);
  };

  const handleShowShare = () => {
    setShowShare(true);
  };
  const handleNotShowShare = () => {
    setShowShare(false);
  };

  useEffect(() => {
    makeGetCall({
      url: endpoints.mylisting_new + `${id}/reviews/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setReviewCount(res?.count);
          setReviewData(res?.results);
          setRating(res?.average);
          scrollToTop();
        }
      })
      .catch((err) => {
        console.error("error", err);
        showToast(err.message, "error");
      });
  }, [id, refresh]);

  useEffect(() => {
    makeGetCall({
      url: endpoints.mylisting_new + `${id}`,
    })
      .then((res) => {
        setDetailsData(res.data);
        console.log(res, "........");
        setIsLoading(false);

        if (res.data.documents.length > 0) {
          res.data.documents.map((dc, index) => {
            if (/\.(mp4|mov|avi)$/.test(dc.uploadDocumentUrl.toLowerCase())) {
              setVideoExist(index);
            }
          })
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setIsLoading(false);

        showToast(err.message, "error");
      });
  }, [id]);
  const handleBookingError = () => {
    if (!isUserAuthenticated()) {
      showToast("You must log in first", "error");
      navigate("/login");
      return;
    } else {
      let userProfile = getItemFromStore("userProfile");
      let userAge = moment().diff(
        moment(userProfile?.dob, "YYYY-MM-DD"),
        "years"
      );
      const reqAge = userAge;
      const reqGender = userProfile?.gender;

      if (
        detailsData?.listingType === LISTING_TYPE.SPECIAL &&
        detailsData?.canBookSpecialListing === false
      ) {
        showToast(
          "You are unable to book this special listing event as you have been removed by the admin from booking this listing",
          "error"
        );
      }

      if (
        userProfile?.userType === "Guest" &&
        detailsData?.listingType === "SPECIAL"
      ) {
        setShowExclusivePopup(true);
        return;
      }

      if (detailsData?.createdBy?.id === getItemFromStore("userProfile")?.id) {
        setAllShowDateModal(true);
      } else {
        navigate(`/events-booking/${id}`);
      }
    }
  };

  //udadete isImage & isVideo according to dev data
  const isImage = /\.(jpg|jpeg|gif|png|svg|webp|avif|heic)$/.test(
    detailsData?.documents[0]?.documentFileType?.toLowerCase()
  );
  const isVideo = /\.(mp4|mov|avi)$/.test(
    detailsData?.documents[0]?.documentFileType?.toLowerCase()
  );

  const isProfileImage = /\.(jpg|jpeg|gif|png|svg|webp|avif|heic)$/.test(
    detailsData?.createdBy?.profilePicture?.toLowerCase()
  );
  const isProfileVideo = /\.(mp4|mov|avi)$/.test(
    detailsData?.createdBy?.profilePicture?.toLowerCase()
  );

  // let center;
  // if (
  //   detailsData?.scheduleDetails?.address?.location?.split("POINT ").length > 1
  // ) {
  //   center = {
  //     lng: Number(
  //       detailsData?.scheduleDetails?.address?.location
  //         ?.split("POINT ")?.[1]
  //         ?.split(" ")?.[0]
  //         ?.substring(1)
  //     ),
  //     lat: Number(
  //       detailsData?.scheduleDetails?.address?.location
  //         ?.split("POINT ")?.[1]
  //         ?.split(" ")?.[1]
  //         .slice(0, -1)
  //     ),
  //   };
  // } else {
  //   center = {
  //     lng: Number(
  //       detailsData?.scheduleDetails?.address?.location
  //         ?.split("POINT")?.[1]
  //         ?.split(" ")?.[0]
  //         ?.substring(1)
  //     ),
  //     lat: Number(
  //       detailsData?.scheduleDetails?.address?.location
  //         ?.split("POINT")?.[1]
  //         ?.split(" ")?.[1]
  //         .slice(0, -1)
  //     ),
  //   };
  // }
  let center;
  if (
    detailsData?.scheduleDetails?.address?.location?.split("POINT ").length > 1
  ) {
    center = {
      lng: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT ")?.[1]
          ?.split(" ")?.[0]
          ?.substring(1)
      ),
      lat: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT ")?.[1]
          ?.split(" ")?.[1]
          .slice(0, -1)
      ),
    };
  } else {
    center = {
      lng: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT")?.[1]
          ?.split(" ")?.[0]
          ?.substring(1)
      ),
      lat: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT")?.[1]
          ?.split(" ")?.[1]
          .slice(0, -1)
      ),
    };
  }

  if (detailsData?.listingType === LISTING_TYPE.M) {
    if (
      detailsData?.scheduleDetails?.[0]?.address?.location?.split("POINT ")
        .length > 1
    ) {
      center = {
        lng: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    } else {
      center = {
        lng: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    }
  }

  if (detailsData?.listingType === LISTING_TYPE.SPECIAL) {
    if (
      detailsData?.events?.[0]?.address?.location?.split("POINT ").length > 1
    ) {
      center = {
        lng: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    } else {
      center = {
        lng: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    }
  }

  const handleFlagClick = () => {
    showToast("You must log in first", "error");
    navigate("/login");
  };

  function checkIsProfileCompleted() {
    const userDetails = getItemFromStore("userProfile");
    if (userDetails) {
      if (!userDetails?.name) {
        navigate(ROUTE_CONSTANTS.manage_profile);
      }
    }
    return true;
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(detailsData?.dynamicLink);

      showToast("Copied!!", "success");
    } catch (err) {
      showToast("Failed to copy text.", "error");
    }
  };
  const encodedMessage = encodeURIComponent("Check out this link!");
  const encodedLink = encodeURIComponent(detailsData?.dynamicLink);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSmsShare = () => {
    const smsUrl = `sms:?body=${encodedMessage}%20${encodedLink}`;
    window.open(smsUrl, "_blank");
  };

  const handleMessengerShare = () => {
    const messengerUrl = `fb-messenger://share?link=${encodedLink}`;
    window.open(messengerUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
    window.open(twitterUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
    window.open(facebookUrl, "_blank");
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
    window.open(emailUrl, "_blank");
  };

  const scrollContainerRef = useRef(null);

  const scrollByAmount = (amount) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: amount, behavior: 'smooth' });
    }
  };
  const scrollByAmount2 = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
      // scrollContainerRef.current.scrollLeft -= 200; // Adjust the value as needed
    }
  };

  console.log(detailsData)
  return (
    <>
      <GlobalHeader2 />
      {isLoading ? (
        <div
          style={{ minHeight: "calc(100vh + 1200px)", position: "relative" }}
        >
          <ApiLoaderNew />
        </div>
      ) : (
        <div className="listing_two_layout">
          {showGallery ? (
            <ListingDetailGallery
              id={id}
              showListing={handleNotShowGallery}
              detailsData={detailsData}
            />
          ) : showAllSlots ? (
            <ListingDetailAllSlots
              showListing={handleNotShowAll}
              detailsData={detailsData}
            />
          ) : (
            <>
              {/* <img
                style={{ cursor: 'pointer' }}
                src="/static/arrow-back.svg"
                alt="back_arrow"
                className="listing_detail_back_arrow"
                onClick={() => navigate("/")}
              /> */}
              <div>
                <div className="d-flex justify-content-between listing_detail_head_content">
                  <div className=" d-sm-block d-none">

                    <div className="listing_detail_title ">
                      {detailsData.title && <h4>{detailsData.title}</h4>}

                      {detailsData?.listingType === "S" ||
                        detailsData?.listingType === "SPECIAL" ? (
                        <button className="session_btn_popup" style={{ padding: '5px 12px' }}>
                          <img src="/static/Sport-white.svg" alt="Sport Balls" />
                          Session
                        </button>
                      ) : detailsData?.listingType === "P" ? (
                        <button className="package_btn_listing" style={{ padding: '5px 12px' }}>
                          <img src="/static/box-white.svg" alt="Sport Balls" />
                          Package
                        </button>
                      ) : (
                        <button className="team_btn_listing" style={{ padding: '5px 12px' }}>
                          <img src="/static/people-white.svg" alt="Sport Balls" />
                          Team
                        </button>
                      )}
                      {detailsData?.listingEventType === "V" ? (
                        // <p className="event-type">
                        <img src="/static/event-type.svg" alt="Internet Icon" className="virtual-icon" />
                        // <span> Virtual </span>
                        // </p>
                      ) : (
                        <p className="event-type">
                          <img
                            src={Vector2}
                            alt="Internet Icon"
                            style={{ width: "20px" }}
                          />
                          {/* Event Type:{" "} */}
                          <span
                            className="event-type-orange"
                            style={{ color: "#ee762f" }}
                          >
                            {" "}
                            Physical{" "}
                          </span>

                        </p>
                      )}
                    </div>
                  </div>
                  <div className="listing_detail_buttons">

                    <button onClick={handleShowShare}>
                      <img src="/static/heart.svg" alt="Heart_icon" style={{ height: '20px', width: '20px' }} />
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>Save</span>
                    </button>

                    <button onClick={handleShowShare}>
                      <img src="/static/Share_black.svg" alt="Share_icon" style={{ height: '20px', width: '20px' }} />
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>Share</span>
                    </button>

                    <Modal
                      show={showShare}
                      onHide={handleNotShowShare}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Body>
                        <div className="share_modal_layout">
                          <img
                            src="/static/close.svg"
                            alt="Close"
                            style={{ cursor: "pointer" }}
                            onClick={handleNotShowShare}
                            className="share_modal_close_btn"
                          />
                          <h4>Share Listing</h4>
                          <div className="share_modal_details">
                            {
                              detailsData?.documents.length > 0 ?

                                /\.(mp4|mov|avi)$/.test(detailsData?.documents[0].documentName?.toLowerCase()) ? (
                                  <>
                                    <video
                                      style={{
                                        width: "110px",
                                        height: "86px",
                                        borderRadius: "12px",
                                      }}
                                      src={detailsData?.documents[0].uploadDocumentUrl}
                                      className="event_img"
                                      //preload="none"
                                      // autoPlay
                                      controls
                                      disablePictureInPicture
                                      controlsList="nodownload noplaybackrate nofullscreen"
                                      // playsInline
                                      // loop
                                      muted
                                      onClick={(e) => {
                                        e.preventDefault();
                                        scrollToTop();
                                        navigate(`listing-two/${detailsData.id}`);
                                      }}
                                    >
                                      <source src={detailsData?.documents[0].uploadDocumentUrl} />
                                    </video>

                                  </>
                                ) : (

                                  <img
                                    src={detailsData?.documents[0].uploadDocumentUrl || "/static/expertise2.png"}
                                    className="event_img"
                                    alt=""
                                    style={{
                                      width: "110px",
                                      height: "86px",
                                      borderRadius: "12px",
                                    }}
                                    loading="lazy"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      scrollToTop();
                                      navigate(`listing-two/${detailsData.id}`);
                                    }}
                                  />

                                )
                                :
                                (
                                  <img
                                    style={{
                                      width: "110px",
                                      height: "86px",
                                      borderRadius: "12px",
                                    }}
                                    src="/static/expertise2.png"
                                    alt=""
                                  />
                                )
                            }
                            {/* <div>
                                  <img src="/static/image-small.png" alt="photo" />
                                </div> */}
                            <div className="title">
                              {detailsData.title && (
                                <h5>{detailsData.title}</h5>
                              )}
                              <p className="sub-title">
                                Address:{" "}
                                {detailsData?.scheduleDetails?.address?.address}
                              </p>
                            </div>
                            <div>
                              {detailsData?.listingType === "S" ||
                                detailsData?.listingType === "SPECIAL" ? (
                                <button className="session_btn_popup" style={{ padding: '10px' }}>
                                  <img
                                    src="/static/Sport-white.svg"
                                    alt="Sport Balls"
                                  />
                                  Session
                                </button>
                              ) : detailsData?.listingType === "P" ? (
                                <button className="package_btn_popup" style={{ padding: '10px' }}>
                                  <img
                                    src="/static/box-white.svg"
                                    alt="Sport Balls"
                                  />
                                  Package
                                </button>
                              ) : (
                                <button className="team_btn_popup" style={{ padding: '10px' }}>
                                  <img
                                    src="/static/people-white.svg"
                                    alt="Sport Balls"
                                  />
                                  Team
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="share_options">
                            <span onClick={() => handleCopy()}>
                              <img src="/static/copy.svg" alt="Copy icon" />
                              Copy
                            </span>
                            <span onClick={handleEmailShare}>
                              <img src="/static/mail.svg" alt="Mail icon" />
                              Email
                            </span>
                            <span
                              className="d-block d-sm-none d-flex"
                              onClick={handleSmsShare}
                            >
                              <img
                                src="/static/message.svg"
                                alt="Message icon"
                              />
                              Message
                            </span>
                            <span onClick={handleWhatsAppShare}>
                              <img
                                src="/static/whatsapp.svg"
                                alt="Whatsapp icon"
                              />
                              Whatsapp
                            </span>
                            <span onClick={handleMessengerShare}>
                              <img
                                src="/static/messenger.svg"
                                alt="Messenger icon"
                              />
                              Messenger
                            </span>
                            <span onClick={handleFacebookShare}>
                              <img
                                src="/static/facebook.svg"
                                alt="Facebook icon"
                              />
                              Facebook
                            </span>
                            <span onClick={handleTwitterShare}>
                              <img
                                src="/static/twitter.svg"
                                alt="Twitter icon"
                              />
                              Twitter
                            </span>
                          </div>
                          <button
                            className="mt-5 d-lg-none"
                            onClick={handleNotShowShare}
                          >
                            Back
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <button
                      onClick={() => {
                        isUserAuthenticated() && checkIsProfileCompleted()
                          ? setShowFlagListingPopup(true)
                          : handleFlagClick();
                      }}
                    >
                      <img src="/static/Flag-black.svg" alt="Flag_icon" style={{ height: '20px', width: '20px' }} />
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>Flag</span>
                    </button>

                    {showFlagListingPopup && (
                      <FlagListingPopup
                        show={showFlagListingPopup}
                        onHide={() => {
                          setShowFlagListingPopup(false);
                        }}
                        listing_id={id}
                        type={detailsData?.listingType || "S"}
                      />
                    )}
                  </div>
                </div>
                <div className="listing_img_container d-none d-sm-grid"
                  style={{ gridTemplateColumns: detailsData?.documents.length == 5 || detailsData?.documents.length == 3 || detailsData?.documents.length == 2 || detailsData?.documents.length == 4 || detailsData?.documents.length == 1 ? '1fr 0fr' : '1fr 1fr' }}>

                  <div className="listing_detail_sec_img"
                    style={{
                      gridTemplateColumns: detailsData?.documents.length == 1 ? 'repeat(1, 1fr)' : detailsData?.documents.length == 5 ? 'repeat(3, 1fr)' : detailsData?.documents.length == 4 ? 'repeat(3, 1fr)' : detailsData?.documents.length == 2 ? 'repeat(2, 1fr)' : detailsData?.documents.length == 3 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',

                      gridTemplateRows: detailsData?.documents.length == 5 ? 'repeat(2, 220px)' : detailsData?.documents.length == 4 ? 'repeat(2, 220px)' : detailsData?.documents.length == 1 ? 'repeat(1, 1fr)' : detailsData?.documents.length == 2 ? 'repeat(1, 1fr)' : detailsData?.documents.length == 3 ? 'repeat(1, 450px)' : 'repeat(2, 220px)'
                    }}>
                    {displayImages(detailsData?.documents, detailsData?.media)}
                  </div>
                  <button
                    className="show_gallery_btn"
                    onClick={handleShowGallery}
                  >
                    <img src="/static/Gallery_bold.svg" alt="Icon" />
                    Show Gallery
                  </button>
                </div>
              </div>
              <div className="d-sm-none d-xs-block slick-event" >
                {detailsData?.documents && detailsData?.documents.length > 1 ?
                  <Slider {...settings}>
                    {detailsData?.documents.map((ph) =>
                      !/\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                        <img
                          src={ph?.uploadDocumentUrl || "/static/expertise2.png"}
                          className="event_img"
                          //preload="none"
                          // autoPlay
                          style={{ cursor: "pointer", width: 200, height: 200 }}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   scrollToTop();
                        //   navigate(`listing-two/${item.id}`);
                        // }}
                        />
                      )
                        :
                        (
                          <video
                            src={ph?.uploadDocumentUrl}
                            className="event_img"
                            //preload="none"
                            //autoPlay
                            controls
                            disablePictureInPicture
                            controlsList="nodownload noplaybackrate nofullscreen"
                            // playsInline
                            // loop
                            muted
                            style={{ cursor: "pointer", height: '300px' }}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   scrollToTop();
                          //   navigate(`listing-two/${item.id}`);
                          // }}
                          >
                            <source src={ph?.uploadDocumentUrl} />
                          </video>
                        ))
                    }
                  </Slider>
                  :
                  detailsData?.documents.length == 1 ?
                    /\.(mp4|mov|avi)$/.test(detailsData?.documents[0].documentName?.toLowerCase()) ? (
                      <>
                        <div className="individual-image-video-block">
                          <div className="individual-image-video-block-2">
                            <video
                              disablePictureInPicture
                              src={detailsData?.documents[0].uploadDocumentUrl}
                              className="event_img"
                              //preload="none"
                              // autoPlay
                              controls
                              controlsList="nodownload noplaybackrate nofullscreen"
                              // playsInline
                              // loop
                              muted
                              style={{ cursor: "pointer" }}
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   scrollToTop();
                            //   navigate(`listing-two/${item.id}`);
                            // }}
                            >
                              <source src={detailsData?.documents[0].uploadDocumentUrl} />
                            </video>
                          </div>

                        </div>
                      </>
                    ) : (
                      <div className="individual-image-video-block">
                        <div className="individual-image-video-block-2">
                          <img
                            src={detailsData?.documents[0].uploadDocumentUrl || "/static/expertise2.png"}
                            className="event_img"
                            alt=""
                            loading="lazy"
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   scrollToTop();
                          //   navigate(`listing-two/${item.id}`);
                          // }}
                          />
                        </div>
                      </div>
                    )
                    :
                    /\.(mp4|mov|avi)$/.test(detailsData?.media?.toLowerCase()) ? (
                      <>
                        <div className="individual-image-video-block">
                          <div className="individual-image-video-block-2">
                            <video
                              disablePictureInPicture
                              src={detailsData?.media}
                              className="event_img"
                              //preload="none"
                              // autoPlay
                              controls
                              controlsList="nodownload noplaybackrate nofullscreen"
                              // playsInline
                              // loop
                              muted
                              style={{ cursor: "pointer" }}
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   scrollToTop();
                            //   navigate(`listing-two/${item.id}`);
                            // }}
                            >
                              <source src={detailsData?.media} />
                            </video>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="individual-image-video-block">
                        <div className="individual-image-video-block-2">
                          <img
                            src={detailsData?.media || "/static/expertise2.png"}
                            className="event_img"
                            alt=""
                            loading="lazy"
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   scrollToTop();
                          //   navigate(`listing-two/${item.id}`);
                          // }}
                          />
                        </div>
                      </div>
                    )}

                {/* <div
                  className="listing_img"
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "grid",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  {displayImagesMobile(detailsData?.documents, detailsData?.media)}
                </div> */}
              </div>
              <div>
                <div className="listing_detail_information mt-4" >
                  <div className="listing_detail_title d-sm-none d-block">
                    <div className="row head-class" ref={mapSectionRefMobile}>

                      <div className="col-8 pl-0">
                        {detailsData.title && <h4>{detailsData.title}</h4>}
                      </div>
                      <div className="col-4 p-0">
                        {detailsData?.listingType === "S" ||
                          detailsData?.listingType === "SPECIAL" ? (
                          <button className="session_btn_popup" style={{ padding: '5px 12px' }}>
                            <img src="/static/Sport-white.svg" alt="Sport Balls" />
                            Session
                          </button>
                        ) : detailsData?.listingType === "P" ? (
                          <button className="package_btn_listing" style={{ padding: '5px 12px' }}>
                            <img src="/static/box-white.svg" alt="Sport Balls" />
                            Package
                          </button>
                        ) : (
                          <button className="team_btn_listing" style={{ padding: '5px 12px' }}>
                            <img src="/static/people-white.svg" alt="Sport Balls" />
                            Team
                          </button>
                        )}
                        {detailsData?.listingEventType === "V" ? (
                          // <p className="event-type">
                          <img src="/static/event-type.svg" alt="Internet Icon" className="virtual-icon" />
                          // <span> Virtual </span>
                          // </p>
                        ) : (
                          <p className="event-type">
                            <img
                              src={Vector2}
                              alt="Internet Icon"
                              style={{ width: "20px" }}
                            />
                            {/* Event Type:{" "} */}
                            <span
                              className="event-type-orange"
                              style={{ color: "#ee762f" }}
                            >
                              {" "}
                              Physical{" "}
                            </span>

                          </p>
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="listing_detail_description d-sm-none d-block" style={{ paddingBottom: '10px' }}>
                    <h4>Listing Description</h4>
                    {detailsData && detailsData.description ? (
                      detailsData.description.length > 200 ?
                        <>
                          <p style={{ whiteSpace: "pre-line" }}>
                            {" "}
                            {showFullText ? <>
                              {parse(detailsData.description)}
                              <button onClick={toggleText} className="read-more">
                                Read Less
                              </button>
                            </>
                              :
                              <>
                                {detailsData.description.slice(0, 200)}...
                                <button onClick={toggleText} className="read-more">
                                  Read More
                                </button>
                              </>
                            }
                          </p>

                        </>
                        :
                        <p style={{ whiteSpace: "pre-line" }}>
                          {parse(detailsData.description)}</p>

                    ) : (
                      <p>No description available.</p>
                    )}
                  </div>
                  <div className="listing_detail_content" ref={mapSectionRefMobile}>
                    {/* {detailsData?.listingEventType === "V" ? (
                      <p>
                        Event Type: <span> Virtual </span>
                        <img src="/static/Internet.svg" alt="Internet Icon" />
                      </p>
                    ) : (
                      <p>
                        Event Type:{" "}
                        <span
                          className="event-type-orange"
                          style={{ color: "#ee762f" }}
                        >
                          {" "}
                          Physical{" "}
                        </span>
                        <img
                          src={Vector2}
                          alt="Internet Icon"
                          style={{ width: "20px" }}
                        />
                      </p>
                    )} */}

                    {detailsData?.listingType === "SPECIAL" && (
                      <div
                        className="special-listing-event e-member-only d-none d-md-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        <div className="icon">
                          <ExclusiveMembericon color="#fff" />
                        </div>
                        <p style={{ marginBottom: "10px" }}>
                          Exclusive for Members
                        </p>
                      </div>
                    )}



                    <div
                      style={{ borderBottom: "1px solid #dcdcdc", paddingBottom: '30px' }}
                      className={`overflow-div ${detailsData?.gender?.length === 1 && !detailsData?.minAge && !detailsData?.maxAge && detailsData?.difficultyLevel?.length === 1 ? "p-l-0" : ""}`} ref={scrollContainerRef}>
                      {
                        detailsData?.gender?.length === 1 && !detailsData?.minAge && !detailsData?.maxAge && detailsData?.difficultyLevel?.length === 1
                          ? null
                          :
                          null
                        // <div className="overflow-outer-2" onClick={() => scrollByAmount2()}>
                        //   <img src={img2} className="scroll-img-2" />
                        // </div>
                      }

                      <div className="button-gender" >
                        {/* <h4>Gender Allowed </h4> */}
                        <h4 className="design-text">Designed For</h4>
                        {/* <h4>Designed For</h4> */}
                        {detailsData?.difficultyLevel?.map((d) => (
                          <>
                            {d === "I" ? (
                              <button style={{
                                border: 'none',
                                padding: 0
                              }}>
                                {/* <h4 className="design-text">Designed For</h4> */}
                                <img
                                  src="/static/ribbon-star-orange.svg"
                                  alt="Ribbon Icon"
                                  width={20}
                                  height={20}
                                />
                                Intermediate
                              </button>
                            ) : d === "B" ? (
                              <button style={{
                                border: 'none',
                                padding: 0
                              }}>
                                {/* <h4 className="design-text">Designed For</h4> */}
                                <img
                                  src="/static/ribbon-star-orange.svg"
                                  alt="Ribbon Icon"
                                  width={20}
                                  height={20}
                                />
                                Beginner
                              </button>
                            ) : (
                              <button style={{
                                border: 'none',
                                padding: 0
                              }}>
                                {/* <h4 className="design-text">Designed For</h4> */}
                                <img
                                  src="/static/ribbon-star-orange.svg"
                                  alt="Ribbon Icon"
                                  width={20}
                                  height={20}
                                />
                                Advance
                              </button>
                            )}
                          </>
                        ))}
                      </div>
                      <div className="button-gender" >
                        {/* <h4>Gender Allowed </h4> */}
                        <h4 className="design-text">Age Required</h4>
                        {detailsData?.minAge && detailsData?.maxAge ? (
                          <button style={{ padding: "0px", border: 'none' }}>
                            {detailsData?.minAge} to {detailsData?.maxAge}
                            {" Years old"}
                          </button>
                        ) : (
                          <button style={{ padding: "0px", border: 'none' }}>
                            All Ages
                          </button>
                        )}
                      </div>
                      <div className="button-gender" >
                        {/* <h4>Gender Allowed </h4> */}
                        <h4 className="design-text">Gender Allowed</h4>
                        <div style={{ margin: 0 }}>

                          {detailsData?.gender?.map((g) => (
                            <>
                              {g === "M" ? (
                                <button style={{ border: 'none', padding: 0 }}>
                                  <img
                                    src="/static/gender-m.svg"
                                    alt="Icon"
                                    width={20}
                                    height={20}
                                  />
                                  Male
                                </button>
                              ) : g === "F" ? (
                                <button style={{ border: 'none', padding: 0 }}>
                                  <img
                                    src="/static/gender-f.svg"
                                    alt="Icon"
                                    width={20}
                                    height={20}
                                  />
                                  Female
                                </button>
                              ) : (
                                <button style={{ border: 'none', padding: 0 }}>
                                  <img
                                    src="/static/gender-p.svg"
                                    alt="Icon"
                                    width={32}
                                    height={32}
                                  />
                                  Prefer not to say
                                </button>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                      {
                        detailsData?.gender?.length === 1 && !detailsData?.minAge && !detailsData?.maxAge && detailsData?.difficultyLevel?.length === 1
                          ? null
                          :
                          <div className="overflow-outer" onClick={() => scrollByAmount(250)}>
                            <img src={img2} className="scroll-img" />
                          </div>
                      }


                    </div >

                    <div className="listing_detail_description d-sm-block d-none" style={{ paddingBottom: '30px', paddingTop: '30px' }}>
                      <h4>Listing Description</h4>
                      {detailsData && detailsData.description ? (
                        detailsData.description.length > 200 ?
                          <>
                            <p style={{ whiteSpace: "pre-line" }}>
                              {" "}
                              {showFullText ? <>
                                {parse(detailsData.description)}
                                <button onClick={toggleText} className="read-more">
                                  Read Less
                                </button>
                              </>
                                :
                                <>
                                  {detailsData.description.slice(0, 200)}...
                                  <button onClick={toggleText} className="read-more">
                                    Read More
                                  </button>
                                </>
                              }
                            </p>

                          </>
                          :
                          <p style={{ whiteSpace: "pre-line" }}>
                            {parse(detailsData.description)}</p>

                      ) : (
                        <p>No description available.</p>
                      )}
                    </div>
                    {
                      detailsData?.listingType === LISTING_TYPE.C ? (
                        ""
                      ) : detailsData?.listingType === LISTING_TYPE.S ? (
                        <div className="listing_detail_dates" style={{ paddingBottom: '30px', paddingTop: '30px' }} >
                          <h4>Available Dates</h4>
                          <div className="aval-datesss">
                            {detailsData?.events?.length > 0 ?

                              (
                                <>
                                  {detailsData?.events.slice(0, 8).map((item, index) => (
                                    <span
                                      key={index}
                                      className={
                                        selectdate === index + 1 ? "active-date" : ""
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setSelectDate(index + 1)}
                                    >
                                      <h5>
                                        {moment(item?.date).format("dddd")},{" "}
                                        {moment(item?.date).date()}{" "}
                                        {moment(item?.date).format("MMMM")}
                                      </h5>
                                      <p>
                                        <img
                                          src="/static/clock.svg"
                                          alt="clock"
                                          width={16}
                                          height={16}
                                        />
                                        {moment(item?.startTime?.substring(0, 5), [
                                          "HH:mm",
                                        ]).format("hh:mmA")}{" "}
                                        -{" "}
                                        {moment(item?.endTime?.substring(0, 5), [
                                          "HH:mm",
                                        ]).format("hh:mmA")}
                                      </p>
                                      <p>
                                        <img
                                          src="/static/seat.svg"
                                          alt="seat"
                                          width={16}
                                          height={16}
                                        />
                                        <b>{item?.slotsAvailable} left </b> out of{" "}
                                        {detailsData?.maxParticipants} spots
                                      </p>
                                    </span>
                                  ))}
                                  {detailsData?.events && detailsData?.events?.length > 8 ?
                                    <>
                                      <div className="aval-box d-sm-block d-none" onClick={() => {
                                        //handleShowDate
                                        scrollToTop();
                                        setShowAllSlots(true);
                                      }}>
                                        <span style={{ margin: 'auto', border: 'none', padding: '8px' }}>
                                          Show all {detailsData?.events?.length} Available Dates
                                        </span>

                                      </div>
                                      <div className="aval-box d-sm-none d-block" onClick={() => {
                                        //handleShowDate
                                        scrollToTop();
                                        setShowAllSlots(true);
                                      }}>
                                        Show all {detailsData?.events?.length} Available Dates
                                      </div>

                                    </>
                                    : null}
                                </>
                              ) : (
                                <p>No slots available</p>
                              )}
                          </div>

                          {/* <button
                    onClick={() => {
                      //handleShowDate
                      scrollToTop();
                      setShowAllSlots(true);
                    }}
                  >
                    Show all {detailsData?.events?.length} Available Dates
                  </button> */}
                          <Modal
                            show={showDates}
                            onHide={handleNotShowDate}
                            className="custom_modal"
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Body>
                              <div className="dates_modal_layout">
                                <img
                                  src="/static/close.svg"
                                  alt="Close"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleNotShowDate}
                                  className="modal_close_btn"
                                />
                                <img
                                  src="/static/Calendar-orange.svg"
                                  alt="Calendar"
                                  className="mb-4"
                                />
                                <h4>Available Dates</h4>
                                <p>Please check all the available events</p>
                                <div className="modal_availabel_dates">
                                  {detailsData?.events?.map((item, index) => (
                                    <span key={index}>
                                      <h5>
                                        {moment(detailsData?.date).format("ddd")},{" "}
                                        {moment(detailsData?.date).date()}{" "}
                                        {moment(detailsData?.date).format("MMMM")}
                                      </h5>
                                      <p>
                                        <img
                                          src="/static/clock.svg"
                                          alt="clock"
                                          width={16}
                                          height={16}
                                        />
                                        {moment(detailsData?.startTime?.substring(0, 5), [
                                          "HH:mm",
                                        ]).format("hh:mmA")}{" "}
                                        -{" "}
                                        {moment(detailsData?.endTime?.substring(0, 5), [
                                          "HH:mm",
                                        ]).format("hh:mmA")}
                                      </p>
                                      <p>
                                        <img
                                          src="/static/seat.svg"
                                          alt="seat"
                                          width={16}
                                          height={16}
                                        />
                                        <b>{detailsData?.slotsAvailable} left</b> out of{" "}
                                        {detailsData?.maxParticipants} spots
                                      </p>
                                    </span>
                                  ))}
                                </div>
                                <button className="mt-5" onClick={handleNotShowDate}>
                                  Back
                                </button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      ) : (
                        <div className="session_detail_dates" style={{ paddingBottom: '30px', paddingTop: '30px' }}>
                          <h4>Sessions Included ({detailsData.events.length})</h4>
                          <div>
                            {detailsData?.events?.length > 0 ? (
                              detailsData?.events.slice(0, 6).map((item, index) => (
                                <span
                                  key={index}
                                  className={
                                    selectdate === index + 1 ? "active-date" : ""
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setSelectDate(index + 1)}
                                >
                                  <div className="row m-0">
                                    <h5 className="col-9">{detailsData?.title}</h5>
                                    <div
                                      className="col-2 "
                                      style={{ padding: 0, justifyContent: "end" }}
                                    >
                                      <div className="number-circle">{++index}</div>
                                    </div>
                                  </div>
                                  <p>
                                    <img src="/static/location-shape.svg" alt="" />
                                    <p style={{ width: "60%" }}>
                                      {detailsData?.address?.address}
                                    </p>
                                    <h4
                                      className="open-map"
                                      onClick={() => {
                                        setLocation(detailsData?.address?.location);
                                      }}
                                    >
                                      Open Maps
                                    </h4>
                                  </p>

                                  <div>
                                    <p>
                                      <SpDateIcon />
                                      {moment(detailsData?.date).date()}{" "}
                                      {moment(detailsData?.date).format("MMM")},{" "}
                                      {moment(detailsData?.date).year()}{" "}
                                    </p>
                                    <p>
                                      <img
                                        src="/static/clock.svg"
                                        alt="clock"
                                        width={16}
                                        height={16}
                                      />
                                      {moment(detailsData?.startTime?.substring(0, 5), [
                                        "HH:mm",
                                      ]).format("hh:mmA")}{" "}
                                      -{" "}
                                      {moment(detailsData?.endTime?.substring(0, 5), [
                                        "HH:mm",
                                      ]).format("hh:mmA")}
                                    </p>
                                  </div>
                                </span>
                              ))
                            ) : (
                              <p>No slots available</p>
                            )}
                          </div>

                          <button
                            onClick={() => {
                              // handleShowDate
                              setShowAllSlots(true);
                              scrollToTop();
                            }}
                          >
                            See all Sessions
                          </button>
                          <Modal
                            show={showDates}
                            onHide={handleNotShowDate}
                            className="custom_modal"
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Body>
                              <div className="dates_modal_layout">
                                <img
                                  src="/static/close.svg"
                                  alt="Close"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleNotShowDate}
                                  className="modal_close_btn"
                                />
                                <img
                                  src="/static/Calendar-orange.svg"
                                  alt="Calendar"
                                  className="mb-4"
                                />
                                <h4>Sessions Included ({detailsData.events.length})</h4>
                                <p>Please check all the available sessions</p>
                                <div className="modal_availabel_sessions">
                                  {detailsData?.events?.map((item, index) => (
                                    <span
                                      key={index}
                                      className={
                                        selectdate === index + 1 ? "active-date" : ""
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setSelectDate(index + 1)}
                                    >
                                      <div className="row m-0">
                                        <h5 className="col-9">{detailsData?.title}</h5>
                                        <div
                                          className="col-2 "
                                          style={{
                                            paddingLeft: 0,
                                            justifyContent: "end",
                                          }}
                                        >
                                          <div className="number-circle">1</div>
                                        </div>
                                      </div>
                                      {/* <h5>{detailsData?.title}</h5> */}
                                      <p>
                                        <img src="/static/location-shape.svg" alt="" />
                                        <p style={{ width: "60%" }}>
                                          {detailsData?.address?.address}
                                        </p>
                                        <h4
                                          className="open-map"
                                          onClick={() => {
                                            setLocation(detailsData?.address?.location);
                                          }}
                                        >
                                          Open Maps
                                        </h4>
                                      </p>

                                      <div>
                                        <p>
                                          <SpDateIcon />
                                          {moment(detailsData?.date).date()}{" "}
                                          {moment(detailsData?.date).format("MMM")},{" "}
                                          {moment(detailsData?.date).year()}{" "}
                                        </p>
                                        <p>
                                          <img
                                            src="/static/clock.svg"
                                            alt="clock"
                                            width={16}
                                            height={16}
                                          />
                                          {moment(detailsData?.startTime?.substring(0, 5), [
                                            "HH:mm",
                                          ]).format("hh:mmA")}{" "}
                                          -{" "}
                                          {moment(detailsData?.endTime?.substring(0, 5), [
                                            "HH:mm",
                                          ]).format("hh:mmA")}
                                        </p>
                                      </div>
                                    </span>
                                  ))}
                                </div>
                                <button className="mt-5" onClick={handleNotShowDate}>
                                  Back
                                </button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      )
                    }
                    <div className="listing_detail_interest" style={{ paddingBottom: '30px', paddingTop: '30px' }}>
                      <h4>Interest Covered</h4>
                      {showFullInterest == false ?
                        <div className="interent_types">
                          {detailsData?.interests?.slice(0, 12).map((item, i) => {
                            return <span key={i}>{detailsData?.title}</span>;
                          })}
                          {detailsData?.interests?.length > 12 &&
                            <div className="aval-box" style={{ padding: "8px 29.5px 8px 30.5px" }} onClick={() => {
                              setShowFullInterest(!showFullInterest)
                            }}>
                              Show all {detailsData?.interests?.length} Interests
                            </div>
                          }
                        </div>
                        : <div className="interent_types">
                          {detailsData?.interests?.map((item, i) => {
                            return <span key={i}>{detailsData?.title}</span>;
                          })}
                          <div className="aval-box" onClick={() => {
                            setShowFullInterest(!showFullInterest)
                          }}>
                            Show Less Interests
                          </div>
                        </div>}
                    </div>
                    <div className="listing_detail_instruction d-sm-none d-lg-block" style={{ borderBottom: '0px !important', width: '100%', paddingBottom: '30px', paddingTop: '30px' }}>
                      <h4>Special Instructions </h4>
                      {detailsData?.specialInstruction?.length > 0 ? (
                        <ul>
                          {detailsData?.specialInstruction.map((item, index) => (
                            <li key={item + index}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>None</p>
                      )}
                    </div>
                  </div >




                  {/* column two */}


                  < div
                    className={
                      detailsData?.listingType === LISTING_TYPE.C
                        ? " width-for-interest listing_booking_card"
                        : "listing_booking_card d-sm-block d-none"
                    }
                  >
                    {detailsData?.listingType === "S" ? (
                      <>
                        {detailsData.price && (
                          <p>
                            {(detailsData.price == "0.00") ? "Free" : <>
                              ${detailsData.price}
                              <span>/ Per Class</span>
                            </>}

                          </p>
                        )}
                        <button onClick={handleBookingError}>
                          {detailsData?.isListingBookedByMe
                            ? "Book Another Events"
                            : "Book Now"}
                        </button>
                        <div>
                          <h4>Cancellation Policy</h4>
                          {detailsData?.isCancellationAllowed === true ? (
                            <p>
                              You can cancel your booking up until {"{"}
                              {detailsData?.cancellationAllowedTillHours}
                              {"}"}hours of your event
                              <span> Click here</span> to know more
                            </p>
                          ) : (
                            <p>No cancellation allowed after booking</p>
                          )}
                        </div>
                      </>
                    ) : detailsData?.listingType === "P" ? (
                      <>
                        {detailsData.price && (
                          <p>
                            ${detailsData.price} <span>/Package</span>
                          </p>
                        )}
                        {detailsData.events && (
                          <p className="booking_card_sessions">
                            ${detailsData.events.length} Sessions Included
                          </p>
                        )}
                        <button onClick={handleBookingError}>
                          {detailsData?.isListingBookedByMe
                            ? "Book Another Events"
                            : "Book Now"}
                        </button>
                        <div>
                          <h4>Cancellation Policy</h4>
                          {detailsData?.isCancellationAllowed === true ? (
                            <p>
                              You can cancel your booking up until {"{"}
                              {detailsData?.cancellationAllowedTillHours}
                              {"}"}hours of your event
                              <span> Click here</span> to know more
                            </p>
                          ) : (
                            <p>No cancellation allowed after booking</p>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {detailsData.price && <p>$ {detailsData.price}</p>}
                        {detailsData?.listingType === LISTING_TYPE.C ? (
                          <>
                            <button onClick={handleBookingError}>
                              Register Interest
                            </button>
                          </>
                        ) : (
                          <>
                            <p className="booking_card_team">
                              Total Team Participants
                            </p>
                            <button onClick={handleBookingError}>
                              Book Now
                            </button>
                            <div>
                              <h4>Cancellation Policy</h4>
                              {detailsData?.isCancellationAllowed === true ? (
                                <p>
                                  You can cancel your booking up until {"{"}
                                  {detailsData?.cancellationAllowedTillHours}
                                  {"}"}hours of your event
                                  <span> Click here</span> to know more
                                </p>
                              ) : (
                                <p>No cancellation allowed after booking</p>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div >

                </div >

              </div >

              {/* sticky bottom starts */}
              {mapSectionIsVisibleMobile == true || mapReviewIsVisibleMobile == true ?
                <div className="d-sm-none d-block">
                  <div
                    className={
                      detailsData?.listingType === LISTING_TYPE.C
                        ? " width-for-interest listing_booking_card_bottom"
                        : "listing_booking_card_bottom"
                    }
                  >
                    {detailsData?.listingType === "S" ? (
                      <>
                        <div className="row align-center">

                          {detailsData.price && (
                            <p style={{ fontSize: '24px', width: '40%' }}>
                              {(detailsData.price == "0.00") ? "Free" : <>
                                ${detailsData.price}
                                <span>/ Per Class</span>
                              </>}

                            </p>
                          )}
                          <button onClick={handleBookingError}>
                            {detailsData?.isListingBookedByMe
                              ? "Book Another Events"
                              : "Book Now"}
                          </button>
                        </div>

                        <div className="row">
                          {/* <h4>Cancellation Policy</h4> */}
                          {detailsData?.isCancellationAllowed === true ? (
                            <>
                              <img src={img3} className="cancel-img" />
                              <p className="cancel-text">
                                You can cancel your booking up until {"{"}
                                {detailsData?.cancellationAllowedTillHours}
                                {"}"}hours of your event
                                <span> Click here</span> to know more
                              </p>
                            </>
                          ) : (
                            <p className="cancel-text">
                              <img src={img3} className="cancel-img" />
                              No cancellation allowed after booking</p>
                          )}
                        </div>
                      </>
                    ) : detailsData?.listingType === "P" ? (
                      <>
                        <div className="row align-center" >
                          {detailsData.price && (
                            <p>
                              ${detailsData.price} <span>/Package</span>
                            </p>
                          )}
                          {detailsData.events && (
                            <p className="booking_card_sessions">
                              ${detailsData.events.length} Sessions Included
                            </p>
                          )}
                          <button onClick={handleBookingError}>
                            {detailsData?.isListingBookedByMe
                              ? "Book Another Events"
                              : "Book Now"}
                          </button>
                        </div>

                        <div>
                          {/* <h4>Cancellation Policy</h4> */}
                          {detailsData?.isCancellationAllowed === true ? (
                            <p className="cancel-text">
                              <img src={img3} className="cancel-img" /> You can cancel your booking up until {"{"}
                              {detailsData?.cancellationAllowedTillHours}
                              {"}"}hours of your event
                              <span> Click here</span> to know more
                            </p>
                          ) : (
                            <p className="cancel-text">
                              <img src={img3} className="cancel-img" />
                              No cancellation allowed after booking</p>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {detailsData.price && <p>$ {detailsData.price}</p>}
                        {detailsData?.listingType === LISTING_TYPE.C ? (
                          <>
                            <button onClick={handleBookingError}>
                              Register Interest
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="row align-center">

                              <p className="booking_card_team">
                                Total Team Participants
                              </p>
                              <button onClick={handleBookingError}>
                                Book Now
                              </button>
                            </div>
                            <div>
                              {/* <h4>Cancellation Policy</h4> */}
                              {detailsData?.isCancellationAllowed === true ? (
                                <p className="cancel-text">
                                  <img src={img3} className="cancel-img" /> You can cancel your booking up until {"{"}
                                  {detailsData?.cancellationAllowedTillHours}
                                  {"}"}hours of your event
                                  <span> Click here</span> to know more
                                </p>
                              ) : (
                                <p className="cancel-text">
                                  <img src={img3} className="cancel-img" />No cancellation allowed after booking</p>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                : null
              }
              {
                mapSectionIsVisible == true ?
                  <div
                    className={
                      detailsData?.listingType === LISTING_TYPE.C
                        ? " width-for-interest listing_booking_card_bottom"
                        : "listing_booking_card_bottom"
                    }
                  >

                    {detailsData?.listingType === "S" ? (
                      <>
                        <div className="row align-center">

                          {detailsData.price && (
                            <p style={{ fontSize: '24px', width: '40%' }}>
                              {(detailsData.price == "0.00") ? "Free" : <>
                                ${detailsData.price}
                                <span>/ Per Class</span>
                              </>}

                            </p>
                          )}
                          <button onClick={handleBookingError}>
                            {detailsData?.isListingBookedByMe
                              ? "Book Another Events"
                              : "Book Now"}
                          </button>
                        </div>

                        <div className="row">
                          {/* <h4>Cancellation Policy</h4> */}
                          {detailsData?.isCancellationAllowed === true ? (
                            <>
                              <img src={img3} className="cancel-img" />
                              <p className="cancel-text">
                                You can cancel your booking up until {"{"}
                                {detailsData?.cancellationAllowedTillHours}
                                {"}"}hours of your event
                                <span> Click here</span> to know more
                              </p>
                            </>
                          ) : (
                            <p className="cancel-text">
                              <img src={img3} className="cancel-img" />
                              No cancellation allowed after booking</p>
                          )}
                        </div>
                      </>
                    ) : detailsData?.listingType === "P" ? (
                      <>
                        <div className="row align-center" >
                          {detailsData.price && (
                            <p>
                              ${detailsData.price} <span>/Package</span>
                            </p>
                          )}
                          {detailsData.events && (
                            <p className="booking_card_sessions">
                              ${detailsData.events.length} Sessions Included
                            </p>
                          )}
                          <button onClick={handleBookingError}>
                            {detailsData?.isListingBookedByMe
                              ? "Book Another Events"
                              : "Book Now"}
                          </button>
                        </div>

                        <div>
                          {/* <h4>Cancellation Policy</h4> */}
                          {detailsData?.isCancellationAllowed === true ? (
                            <p className="cancel-text">
                              <img src={img3} className="cancel-img" /> You can cancel your booking up until {"{"}
                              {detailsData?.cancellationAllowedTillHours}
                              {"}"}hours of your event
                              <span> Click here</span> to know more
                            </p>
                          ) : (
                            <p className="cancel-text">
                              <img src={img3} className="cancel-img" />
                              No cancellation allowed after booking</p>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {detailsData.price && <p>$ {detailsData.price}</p>}
                        {detailsData?.listingType === LISTING_TYPE.C ? (
                          <>
                            <button onClick={handleBookingError}>
                              Register Interest
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="row align-center">

                              <p className="booking_card_team">
                                Total Team Participants
                              </p>
                              <button onClick={handleBookingError}>
                                Book Now
                              </button>
                            </div>
                            <div>
                              {/* <h4>Cancellation Policy</h4> */}
                              {detailsData?.isCancellationAllowed === true ? (
                                <p className="cancel-text">
                                  <img src={img3} className="cancel-img" /> You can cancel your booking up until {"{"}
                                  {detailsData?.cancellationAllowedTillHours}
                                  {"}"}hours of your event
                                  <span> Click here</span> to know more
                                </p>
                              ) : (
                                <p className="cancel-text">
                                  <img src={img3} className="cancel-img" />No cancellation allowed after booking</p>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div> : null
              }
              {/* sticky bottom ends */}




              {
                detailsData?.listingEventType === "V" ?

                  <div className="listing_detail_map" ref={mapSectionRef} >
                  </div>
                  :
                  <div className="listing_detail_map"  >
                    <h4>Search in map</h4>
                    <div style={{ display: "flex" }}>
                      <h5>
                        Address: {detailsData?.scheduleDetails?.address?.address}
                      </h5>
                      <CopyToClipboard
                        onCopy={() => {
                          showToast("Copied!!", "success");
                        }}
                        text={
                          detailsData?.listingType === LISTING_TYPE.M
                            ? "https://www.google.com/maps/place/" +
                            Number(
                              detailsData?.scheduleDetails?.[0]?.address?.location
                                ?.split("POINT ")?.[1]
                                ?.split(" ")?.[1]
                                .slice(0, -1)
                            ) +
                            "," +
                            Number(
                              detailsData?.scheduleDetails?.[0]?.address?.location
                                ?.split("POINT ")?.[1]
                                ?.split(" ")?.[0]
                                ?.substring(1)
                            )
                            : "https://www.google.com/maps/place/" +
                            Number(
                              detailsData?.scheduleDetails?.address?.location
                                ?.split("POINT ")?.[1]
                                ?.split(" ")?.[1]
                                .slice(0, -1)
                            ) +
                            "," +
                            Number(
                              detailsData?.scheduleDetails?.address?.location
                                ?.split("POINT ")?.[1]
                                ?.split(" ")?.[0]
                                ?.substring(1)
                            )
                        }
                      >
                        <span style={{ cursor: "pointer" }}>
                          <img src="/static/link.svg" alt="Link" />
                          Copy location link
                        </span>
                      </CopyToClipboard>
                    </div>
                    <div className="d-sm-block d-none" ref={mapSectionRef}>

                      <GoogleMapContainer
                        center={center}
                        height={"500px"}
                        packageEvents={detailsData?.events}
                      />
                    </div>
                    <div className="d-sm-none d-block">

                      <GoogleMapContainer
                        center={center}
                        height={"450px"}
                        packageEvents={detailsData?.events}
                      />
                    </div>
                  </div>}


              <div className="listing_detail_reviews" ref={mapReviewsRefMobile}  >
                <h4>Reviews ({reviewCount})</h4>
                {reviewCount > 0 ? (
                  <>
                    <div>
                      {reviewData.slice(0, 6).map((review, index) => (
                        <div className="review_box" key={index}>
                          <div>
                            <span>
                              <img
                                style={{ borderRadius: '100px', maxWidth: '50px', border: '2px solid #BDBDBD' }}
                                src={review.user.profilePicture}
                                alt="Photo"
                              />
                            </span>
                            <div>
                              <h5>{review.user.name}</h5>
                              <p>
                                ⭐ {review.star} stars <span>(5)</span>
                              </p>
                            </div>
                          </div>
                          <p>{review.review}</p>
                        </div>
                      ))}
                    </div>
                    <Link to={`/events/${id}/review`}>
                      <button>Show all {reviewCount} Reviews</button>
                    </Link>
                  </>
                ) : (
                  <p>No Review Found Yet</p>
                )}
              </div>
              <div className="listing_detail_listner" >
                <h4>Know the Lister</h4>
                <div className="listner_box">
                  <div>
                    <div>
                      <Link to={`/user/${detailsData?.createdBy?.id}`}>
                        {isProfileImage ? (
                          <img
                            style={{ borderRadius: '100px' }}
                            src={detailsData?.createdBy?.profilePicture}
                            alt="Picture"
                            width={102}
                            height={102}
                          />
                        ) : isProfileVideo ? (
                          <video
                            style={{ borderRadius: '100px' }}
                            src={detailsData?.createdBy?.profilePicture}
                            // autoPlay
                            controls
                            disablePictureInPicture
                            controlsList="nodownload noplaybackrate nofullscreen"
                            playsInline
                            muted
                            loop
                          >
                            <source
                              src={detailsData?.createdBy?.profilePicture}
                            />{" "}
                          </video>
                        ) : (
                          <img src={"/static/dummyimg.svg"} alt="" />
                        )}
                      </Link>
                    </div>
                    <div>
                      {detailsData?.createdBy?.name && (
                        <h5>{detailsData?.createdBy?.name}</h5>
                      )}
                      <p style={{ fontSize: '20px' }}>{detailsData?.createdBy?.userType}</p>
                    </div>
                  </div>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {detailsData?.createdBy?.bio}
                  </p>
                </div>
              </div>
            </>
          )
          }
        </div >
      )}
      {
        location ? (
          <div className="fixed-sidebar-left">
            <div className="sidebar-box" style={{ maxWidth: "50%" }}>
              <img
                src="/static/cross-black.svg"
                className="close-btn hide-large"
                alt=""
                onClick={() => {
                  setLocation(null);
                }}
              />
              <GooglePlacesSearchBarFilterSidebar location={location} />
            </div>
          </div>
        ) : (
          ""
        )
      }
      <GlobalFooter />
      {/* cancel popups */}

      {
        showCancelByUser &&
          detailsData?.listingType !== "SPECIAL" &&
          detailsData?.isPopupToBeDisplayed === null &&
          showCancelByAdmin === null &&
          showRemoveByAdmin === null ? (
          <div className="modal-cancel-event show">
            <div className="modal-section">
              <div className="modal-top-part">
                <h5>Event Cancelled</h5>
                <div className="info-box-wrap">
                  <div className="info_box" style={{ boxShadow: "none" }}>
                    <img src="/static/greyinfo.svg" alt="" /> The event has been
                    cancelled, and the refund for the cancelled event will be
                    processed within 7-14 working days. You can check the refund
                    status from cancelled events section under my bookings page.
                  </div>
                </div>
              </div>
              <CustomButton
                btnTxt="See all cancelled events"
                className={"backtologin"}
                onClick={() =>
                  navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
                }
              />
              <CustomButton
                btnTxt="Go back"
                className={"goback backtologin"}
                onClick={() => setShowCancelByUser(false)}
              />
              <div className="terms">
                <span>Please read the </span>
                <span
                  className="yellow-text"
                  onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                  style={{ cursor: "pointer" }}
                >
                  t&C for cancellation terms{" "}
                </span>
              </div>
            </div>
          </div>
        ) : null
      }

      {
        showCancelByAdmin === "L" &&
          detailsData?.listingType !== "SPECIAL" &&
          showCancelListingPopup === false &&
          detailsData?.isPopupToBeDisplayed === null ? (
          <div className="modal-cancel-event show">
            <div className="modal-section">
              <div className="modal-top-part">
                <h5>Event Cancelled</h5>
                <div className="info-box-wrap">
                  <div className="info_box" style={{ boxShadow: "none" }}>
                    <img src="/static/greyinfo.svg" alt="" /> The event has been
                    cancelled, and the refund for the cancelled event will be
                    processed within 7-14 working days. You can check the refund
                    status from cancelled events section under my bookings page.
                  </div>
                </div>
              </div>
              <CustomButton
                btnTxt="See all cancelled events"
                className={"backtologin"}
                onClick={() =>
                  navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
                }
              />
              <CustomButton
                btnTxt={`${detailsData?.createdBy?.id ===
                  getItemFromStore("userProfile")?.id
                  ? "ok"
                  : "Go back"
                  }`}
                className={"goback backtologin"}
                onClick={() => {
                  if (
                    detailsData?.createdBy?.id ===
                    getItemFromStore("userProfile")?.id
                  ) {
                    setShowCancelListingPopup(true);
                  } else {
                    navigate(ROUTE_CONSTANTS.my_booking);
                  }
                }}
              />
              <div className="terms">
                <span>Please read the </span>
                <span
                  className="yellow-text"
                  onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                  style={{ cursor: "pointer" }}
                >
                  t&C for cancellation terms{" "}
                </span>
              </div>
            </div>
          </div>
        ) : null
      }

      {
        showCancelByAdmin === "A" &&
          detailsData?.listingType !== "SPECIAL" &&
          detailsData?.isPopupToBeDisplayed === null ? (
          <div className="modal-cancel-event show">
            <div className="modal-section">
              <div className="modal-top-part">
                <h5>Event Cancelled by admin</h5>
                <div className="info-box-wrap">
                  <div className="info_box" style={{ boxShadow: "none" }}>
                    <img src="/static/greyinfo.svg" alt="" /> The event has been
                    cancelled by the admin, and the refund for the cancelled event
                    will be processed within 7-14 working days. You can check the
                    refund status from cancelled events section under my bookings
                    page.
                  </div>
                </div>
              </div>

              <CustomButton
                btnTxt="See all cancelled events"
                className={"backtologin"}
                onClick={() =>
                  navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
                }
              />
              <CustomButton
                btnTxt="Go back"
                className={"goback backtologin"}
                onClick={() => {
                  if (
                    detailsData?.createdBy?.id ===
                    getItemFromStore("userProfile")?.id
                  ) {
                    setShowCancelListingPopup(true);
                  } else {
                    navigate(ROUTE_CONSTANTS.my_booking);
                  }
                }}
              />
              <div className="terms">
                <span>Please read the </span>
                <span
                  className="yellow-text"
                  onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                  style={{ cursor: "pointer" }}
                >
                  t&C for cancellation terms{" "}
                </span>
              </div>
            </div>
          </div>
        ) : null
      }
      {
        detailsData?.listingType === "SPECIAL" &&
          detailsData?.canBookSpecialListing === false &&
          detailsData?.isPopupToBeDisplayed === null ? (
          <div className="modal-cancel-event show">
            <div className="modal-section">
              <div className="modal-top-part">
                <h5>Admin removed you</h5>
                <div className="info-box-wrap">
                  <div className="info_box" style={{ boxShadow: "none" }}>
                    <img src="/static/greyinfo.svg" alt="" />
                    It seems like the admin has removed you, so you won't be part
                    of the event any more. The refund for the cancelled event will
                    be processed within 7-14 working days. To track the refund
                    status, please check the cancelled events section on the "My
                    Bookings" page.
                  </div>
                </div>
              </div>

              <CustomButton
                btnTxt="See all cancelled events"
                className={"backtologin"}
                onClick={() =>
                  navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
                }
              />
              <CustomButton
                btnTxt="Go back"
                className={"goback backtologin"}
                onClick={() => {
                  if (
                    detailsData?.createdBy?.id ===
                    getItemFromStore("userProfile")?.id
                  ) {
                    navigate(ROUTE_CONSTANTS.view_listing);
                  } else {
                    navigate(ROUTE_CONSTANTS.my_booking);
                  }
                }}
              />
              <div className="terms">
                <span>Please read the </span>
                <span
                  className="yellow-text"
                  onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                  style={{ cursor: "pointer" }}
                >
                  t&C for cancellation terms{" "}
                </span>
              </div>
            </div>
          </div>
        ) : null
      }
    </>
  );
};
export default ListingDetailTwo;
