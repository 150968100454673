import Footer from "./Footer";
import Header from "./Header";
import AboutUs from "./AboutUs";
import GetEarly from "./GetEarly";
import Hero from "./Hero";
import StartedToday from "./StartedToday";
import Promote from "./Promote";
import Journey from "./Journey";
import AboutFounder from "./AboutFounder";
import Mission from "./Mission";
import BookEvents from "./BookEvents";

const Landing = () => {
  return (
    <div className="landing-new-page">
      <Header />
      <Hero />
      <GetEarly />
      <AboutUs />
      <StartedToday />
      <Promote />
      <Journey />
      <AboutFounder />
      <Mission />
      <BookEvents />
      <Footer />
    </div>
  );
};

export default Landing;
