import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
} from "@react-google-maps/api";
import { googleKey } from "../../utils/Constants";
import { MapConnectCard } from "./MapConnectCard";
import { makePostCall } from "../../utils/Requests";
import { showToast } from "../../utils";

const MapContainer = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });
  const navigate = useNavigate();
  const { profiles } = props;
  const [activeProfile, setActiveProfile] = useState([]);
  const [, setMap] = React.useState(null);
  const [connectid, setConnectid] = useState<any>(null);
  const [center, setCenter] = useState<any>({
    lat: -31.397,
    lng: 151.644,
  });

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    setCenter({
      lat: props.latitude,
      lng: props.longitude,
    });
  }, [props.latitude]);

  const onMarkerClick = (profile) => {
    setActiveProfile([profile]);
    setCenter({
      lat: profile.latitude,
      lng: profile.longitude,
    });
  };

  const connectUser = (connectid) => {
    makePostCall({
      url: `auth/${connectid}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setConnectid(null);
        } else {
          showToast(res.status.message || res.error.message, "error");
          setConnectid(null);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <>
      {isLoaded ? (
        <>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100vh" }}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
            center={center}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: true,
              panControl: false,
              rotateControl: false,
              streetViewControl: false,
            }}
          >
            <MarkerClusterer
              minimumClusterSize={2}
              onClick={(clust) => {
                let temp_active_profile = [];
                // eslint-disable-next-line array-callback-return
                clust.getMarkers().map((itemdata) => {
                  temp_active_profile.push(itemdata.customInfo);
                });
                if (activeProfile.length === 0) {
                }
                setActiveProfile([...temp_active_profile]);
              }}
            >
              {(clusterer) => (
                <div>
                  {profiles.map((item) => (
                    <Marker
                      clusterer={clusterer}
                      key={item.id}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      onClick={() => {
                        setConnectid(item.id);
                        onMarkerClick(item);
                      }}
                      icon={
                        item?.profilePicture
                          ? {
                              url: item.profilePicture,
                              scaledSize: new window.google.maps.Size(48, 48),
                            }
                          : null
                      }
                      options={{ customInfo: item }}
                    />
                  ))}
                </div>
              )}
            </MarkerClusterer>
          </GoogleMap>
          <>
            {activeProfile?.length === 1 && (
              <div
                style={{
                  width: "350px",
                  background: "#fff",
                  borderRadius: "12px",
                  border: "1px solid #F2F2F2",
                  boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.04)",
                  position: "relative",
                }}
                className="cluster-group-data-individual"
              >
                <MapConnectCard
                  status={activeProfile[0].connectionStatus.status}
                  onCloseClick={() => setActiveProfile([])}
                  mapview={true}
                  item={activeProfile[0]}
                  onConnectClick={() => {
                    connectUser(connectid);
                  }}
                  onClickProfile={() => {
                    navigate(`/user/${activeProfile[0].id}`);
                  }}
                />
              </div>
            )}
            {activeProfile?.length > 1 && (
              <div
                style={{
                  position: "relative",
                  overflowX: "scroll",
                  display: "flex",
                  gap: "24px",
                }}
                className="cluster-group-data"
              >
                {activeProfile.map((item) => {
                  return (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        border: "1px solid #F2F2F2",
                        boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.04)",
                        flex: "1",
                      }}
                    >
                      <MapConnectCard
                        status={item.connectionStatus.status}
                        onCloseClick={() => {
                          setActiveProfile([]);
                        }}
                        mapview={true}
                        item={item}
                        onConnectClick={() => {
                          connectUser(item.id);
                        }}
                        onClickProfile={() => {
                          navigate(`/user/${item.id}`);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        </>
      ) : (
        <div>Loading....</div>
      )}
    </>
  );
};

export default MapContainer;
