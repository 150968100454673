const AboutFounder = () => {
  return (
    <section className="founder-section">
      <div className="content-box">
        <div className="title">
          About our <span className="orange-span">co-founders</span>
        </div>
        <div className="founder-top">
          <div className="image-box">
            <img alt="" src="/static/jeremylatimore.png" />
          </div>
          <div className="about-founder">
            Jeremy Latimore is a true embodiment of resilience and perseverance
            in the world of rugby league. This former prop forward carved out a
            remarkable career spanning over a decade, playing for five different
            clubs in the National Rugby League (NRL). Latimore's journey is a
            testament to his unwavering dedication and adaptability, making him
            a respected figure in the sport. Latimore's journey is a testament
            to his unwavering commitment and adaptability. Despite constantly
            changing clubs, he consistently delivered solid performances,
            earning the respect of teammates and opponents alike. His ability to
            seamlessly transition between teams and maintain a high level of
            play is a rare feat in the modern era of rugby league. Off the
            field, Latimore has been actively involved in education and
            community initiatives. During his time with the St. George Dragons
            and Penrith Panthers, he served as the Education Ambassador,
            inspiring young minds and promoting the importance of education
            alongside athletic pursuits.
          </div>
        </div>
        <div className="founder-bottom">
          <div className="about-founder">
            Luke Lewis is a true icon of Australian rugby league, having left an
            indelible mark on the sport through his exceptional talent,
            versatility, and unwavering determination. This website celebrates
            the remarkable journey of a player who defied conventions and
            inspired generations of fans with his grit and passion. Luke Lewis'
            impact extends far beyond the rugby league field. Through his book
            "Cool Hand Luke," he courageously shared his personal experiences
            with domestic violence, shedding light on this important issue and
            encouraging others to speak out. His involvement with various
            charities and community initiatives has made him a role model for
            young athletes and individuals alike. Lewis' dedication to giving
            back and making a positive difference in the lives of others is a
            testament to his character and the values he upholds.
          </div>
          <div className="image-box">
            <img alt="" src="/static/lukelewis.png" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFounder;
