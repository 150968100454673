import { showToast, handleVideoClick } from "../../utils";
import Modal from "react-bootstrap/Modal";
import GlobalHeader2 from "./GlobalHeader2";

import { useEffect, useState, useRef } from "react";
import { isUserAuthenticated, getItemFromStore } from "../../utils";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import FlagListingPopup from "./Popups/FlagListingPopup";
import endpoints from "../../utils/endpoints";

import React from "react";
import ImageGallery from "react-image-gallery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeGetCall } from "../../utils/Requests";

const ListingDetailGallery = () => {
  const location = useLocation();

  const { id } = useParams();
  const navigate = useNavigate();
  const [showFlagListingPopup, setShowFlagListingPopup] = useState(false);
  const [galleryViewEnabled, setGalleryViewEnabled] = useState(false);
  const [startIndex, setStartIndex] = useState(0)
  const [detailsData, setDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [galleryImages, setGalleryImageqs] = useState([]);
  const imageGalleryRef = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const open_gallery = queryParams.get("open_gallery");
    if (open_gallery) {
      setGalleryViewEnabled(true)
    }
  }, [])
  useEffect(() => {
    makeGetCall({
      url: endpoints.mylisting_new + `${id}`,
    })
      .then((res) => {
        setDetailsData(res.data);
        console.log(res, "........");
        setIsLoading(false);

        if (res.data.documents.length > 0) {
          res.data.documents.map((dc, index) => {
            if (/\.(mp4|mov|avi)$/.test(dc.uploadDocumentUrl.toLowerCase())) {
              setVideoExist(index);
            }
          })
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setIsLoading(false);

        showToast(err.message, "error");
      });
  }, [id]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className="image-carousel-button"
        style={{
          background: "white",
          borderRadius: "50%",
          border: "none",
          cursor: "pointer",
          height: 24,
          width: 24,
          position: "absolute",
          zIndex: "0",
          top: "50%",
          right: "2px",
          translate: "0 -50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
        alt=""
        src="/static/chevron-black-right.svg"
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className="image-carousel-button"
        style={{
          background: "white",
          borderRadius: "50%",
          border: "none",
          cursor: "pointer",
          height: 24,
          width: 24,
          position: "absolute",
          zIndex: "99",
          top: "50%",
          left: "16px",
          translate: "0 -50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
        alt=""
        src="/static/chevron-black-left.svg"
      />
    );
  }

  function checkIsProfileCompleted() {
    const userDetails = getItemFromStore("userProfile");
    if (userDetails) {
      if (!userDetails?.name) {
        navigate(ROUTE_CONSTANTS.manage_profile);
      }
    }
    return true;
  }
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: false,
    // centerPadding: 0,
    // centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const renderVideo = (item) => {
    return (
      // <div className="video-wrapper">
      <video
        src={item.embedUrl}
        className="video-grid-special box-new-shadow"
        //autoPlay
        playsInline
        loop
        muted
        controls
        controlsList="nodownload"
      >
        <source src={item.embedUrl} />
      </video>
      // </div>
    );
  };
  useEffect(() => {
    let imageArray = [];
    detailsData?.documents.map((item) => {
      if ((/\.(mp4|mov|avi)$/.test(item.uploadDocument.toLowerCase()))) {
        imageArray.push({
          embedUrl:
            item.uploadDocumentUrl,
          original:
            item.uploadDocumentUrl,
          // thumbnail:
          //   item.uploadDocumentUrl,
          renderItem: renderVideo.bind(this),
        })
      } else {
        imageArray.push({
          original: item.uploadDocumentUrl,
          thumbnail: item.uploadDocumentUrl
        })
      }

    })
    setGalleryImageqs(imageArray)
    //imageGalleryRef.current.fullScreen();
  }, [])

  const [showShare, setShowShare] = useState(false);
  const handleNotShowShare = () => {
    setShowShare(false);
  };
  const handleShowShare = () => {
    setShowShare(true);
  };



  const displayImages = (photos) => {
    let arr = [1, 2, 3, 4, 5];
    let array_data = [];
    arr.map((a, i) => {
      if (photos[i] !== undefined && photos[i]) {
        array_data.push(

          <div className={photos.length == 5 ? 'list_five' + " image-style-" : photos.length == 4 ? 'list_img' : "listing_img"}
            onClick={() => {
              setGalleryViewEnabled(true)
              setStartIndex(i)
            }}>
            {
              (/\.(mp4|mov|avi)$/.test(photos[i]["uploadDocument"].toLowerCase())) ?
                <video
                  src={photos[i]["uploadDocumentUrl"]}
                  className={"video-grid-special box-new-shadow" + " image-style-" + i}
                  // autoPlay
                  //   controls
                  controlsList="nodownload"
                  playsInline
                  loop
                  muted
                  id={`listing_video${i}`}
                >
                  <source src={photos[i]["uploadDocumentUrl"]} />
                </video> :
                <img
                  className={"image-style-" + i}

                  src={photos[i]["uploadDocumentUrl"]} alt="Image 02" />}


          </div>
        );
      }
    })
    // let number = 5;
    // for (var i = 0; i < number; i++) {
    //   if (photos[i] !== undefined && photos[i]) {
    //     array_data.push(

    //       <div className={photos.length == 5 ? 'list_five' : photos.length == 4 ? 'list_img' : "listing_img"}
    //         onClick={() => console.log(`listing_video${i}`)}>
    //         {
    //           (/\.(mp4|mov|avi)$/.test(photos[i]["uploadDocument"].toLowerCase())) ?
    //             <video
    //               src={photos[i]["uploadDocumentUrl"]}
    //               className="video-grid-special box-new-shadow"
    //               // autoPlay
    //               //   controls
    //               controlsList="nodownload"
    //               playsInline
    //               loop
    //               muted
    //               id={`listing_video${i}`}
    //             >
    //               <source src={photos[i]["uploadDocumentUrl"]} />
    //             </video> : <img src={photos[i]["uploadDocumentUrl"]} alt="Image 02" />}


    //       </div>
    //     );
    //   }
    // }
    return array_data;
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(detailsData?.dynamicLink);

      showToast('Copied!!', 'success');
    } catch (err) {
      showToast('Failed to copy text.', 'error');
    }
  }
  const encodedMessage = encodeURIComponent('Check out this link!');
  const encodedLink = encodeURIComponent(detailsData?.dynamicLink);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleSmsShare = () => {
    const smsUrl = `sms:?body=${encodedMessage}%20${encodedLink}`;
    window.open(smsUrl, '_blank');
  };

  const handleMessengerShare = () => {
    const messengerUrl = `fb-messenger://share?link=${encodedLink}`;
    window.open(messengerUrl, '_blank');
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
    window.open(twitterUrl, '_blank');
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
    window.open(facebookUrl, '_blank');
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
    window.open(emailUrl, '_blank');
  };
  return (
    <>

      {!galleryViewEnabled && <GlobalHeader2 />}
      {detailsData && !galleryViewEnabled && <>
        <div className="listing_two_layout">
          <div className="listing_gallery_content">
            <div className="d-flex justify-content-between">
              <div className="listing_detail_title">
                <h4>{detailsData.title} </h4>
              </div>
              <div className="listing_detail_buttons">
                <button onClick={handleShowShare}>
                  <img src="/static/heart.svg" alt="Heart_icon" style={{ height: '20px', width: '20px' }} />
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Save</span>
                </button>

                <button onClick={handleShowShare}>
                  <img src="/static/Share_black.svg" alt="Share_icon" style={{ height: '20px', width: '20px' }} />
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Share</span>
                </button>
                <Modal
                  show={showShare}
                  onHide={handleNotShowShare}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <div className="share_modal_layout">
                      <img
                        src="/static/close.svg"
                        alt="Close"
                        style={{ cursor: "pointer" }}
                        onClick={handleNotShowShare}
                        className="share_modal_close_btn"
                      />
                      <h4>Share Listing</h4>
                      <div className="share_modal_details">
                        {
                          detailsData?.documents.length > 0 ?

                            /\.(mp4|mov|avi)$/.test(detailsData?.documents[0].documentName?.toLowerCase()) ? (
                              <>
                                <video
                                  style={{
                                    width: "110px",
                                    height: "86px",
                                    borderRadius: "12px",
                                  }}
                                  src={detailsData?.documents[0].uploadDocumentUrl}
                                  className="event_img"
                                  //preload="none"
                                  // autoPlay
                                  controls
                                  controlsList="nodownload"
                                  // playsInline
                                  // loop
                                  muted
                                  onClick={(e) => {
                                    e.preventDefault();
                                    scrollToTop();
                                    navigate(`listing-two/${detailsData.id}`);
                                  }}
                                >
                                  <source src={detailsData?.documents[0].uploadDocumentUrl} />
                                </video>

                              </>
                            ) : (

                              <img
                                src={detailsData?.documents[0].uploadDocumentUrl || "/static/expertise2.png"}
                                className="event_img"
                                alt=""
                                style={{
                                  width: "110px",
                                  height: "86px",
                                  borderRadius: "12px",
                                }}
                                loading="lazy"
                                onClick={(e) => {
                                  e.preventDefault();
                                  scrollToTop();
                                  navigate(`listing-two/${detailsData.id}`);
                                }}
                              />

                            )
                            :
                            (
                              <img
                                style={{
                                  width: "110px",
                                  height: "86px",
                                  borderRadius: "12px",
                                }}
                                src="/static/expertise2.png"
                                alt=""
                              />
                            )
                        }
                        <div>
                          {detailsData.title && (
                            <h5>{detailsData.title}</h5>
                          )}
                          <p>
                            Address: {detailsData?.scheduleDetails?.address?.address}
                          </p>
                        </div>
                        <div>
                          {detailsData?.listingType === "S" || detailsData?.listingType === "SPECIAL" ? (
                            <button className="session_btn_listing" style={{ padding: '10px' }}>
                              <img src="/static/Sport-white.svg" alt="Sport Balls" />
                              Session
                            </button>
                          ) : detailsData?.listingType === "P" ? (
                            <button className="package_btn_listing" style={{ padding: '10px' }}>
                              <img src="/static/box-white.svg" alt="Sport Balls" />
                              Package
                            </button>
                          ) : (
                            <button className="team_btn_listing" style={{ padding: '10px' }}>
                              <img src="/static/people-white.svg" alt="Sport Balls" />
                              Team
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="share_options">
                        <span onClick={() => handleCopy()}>
                          <img src="/static/copy.svg" alt="Copy icon" />
                          Copy
                        </span>
                        <span className="d-block d-sm-none d-flex" onClick={handleEmailShare}>
                          <img src="/static/mail.svg" alt="Mail icon" />
                          Email
                        </span>
                        <span onClick={handleSmsShare}>
                          <img
                            src="/static/message.svg"
                            alt="Message icon"
                          />
                          Message
                        </span>
                        <span onClick={handleWhatsAppShare}>
                          <img
                            src="/static/whatsapp.svg"
                            alt="Whatsapp icon"
                          />
                          Whatsapp
                        </span>
                        <span onClick={handleMessengerShare}>
                          <img
                            src="/static/messenger.svg"
                            alt="Messenger icon"
                          />
                          Messenger
                        </span>
                        <span onClick={handleFacebookShare}>
                          <img
                            src="/static/facebook.svg"
                            alt="Facebook icon"
                          />
                          Facebook
                        </span>
                        <span onClick={handleTwitterShare}>
                          <img
                            src="/static/twitter.svg"
                            alt="Twitter icon"
                          />
                          Twitter
                        </span>
                      </div>
                      <button
                        className="mt-5 d-lg-none"
                        onClick={handleNotShowShare}
                      >
                        Back
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <button onClick={() => {
                  isUserAuthenticated() && checkIsProfileCompleted()
                    ? setShowFlagListingPopup(true)
                    : handleFlagClick();
                }}>
                  <img src="/static/Flag-black.svg" alt="Flag_icon" style={{ height: '20px', width: '20px' }} />
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Flag</span>
                </button>
                {showFlagListingPopup && (
                  <FlagListingPopup
                    show={showFlagListingPopup}
                    onHide={() => {
                      setShowFlagListingPopup(false);
                    }}
                    listing_id={id}
                    type={detailsData?.listingType || "S"}
                  />
                )}
              </div>
            </div>
            {
              !galleryViewEnabled && <><div className="listing_detail_sec_img" style={{
                gridTemplateColumns: detailsData?.documents.length == 1 ? 'repeat(1, 1fr)' : detailsData?.documents.length == 5 ? 'repeat(3, 1fr)' : detailsData?.documents.length == 4 ? 'repeat(3, 1fr)' : detailsData?.documents.length == 2 ? 'repeat(2, 1fr)' : detailsData?.documents.length == 3 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',

                gridTemplateRows: detailsData?.documents.length == 5 ? 'repeat(2, 220px)' : detailsData?.documents.length == 4 ? 'repeat(2, 220px)' : detailsData?.documents.length == 1 ? 'repeat(1, 1fr)' : detailsData?.documents.length == 2 ? 'repeat(1, 1fr)' : detailsData?.documents.length == 3 ? 'repeat(1, 450px)' : 'repeat(2, 220px)'
              }}>
                {
                  // listingImages(detailsData?.documents)
                  displayImages(detailsData?.documents)
                }

              </div>
                {/* show agllery btn  */}
                {/* <div className="" style={{ marginTop: '20px', cursor: 'pointer', textAlign: 'right', position: 'sticky', bottom: '0' }} onClick={e => {
        setGalleryViewEnabled(true)
      }}>
        <img src="/static/Show-All-Photos.png"
          style={{ width: '20%', height: 'auto', alignItems: 'right' }} />
      </div> */}
              </>
            }

          </div>
        </div>

      </>}
      {
        galleryViewEnabled &&
        <div className="image-gallery-div ">
          <img
            onClick={() => {
              setGalleryViewEnabled(false)
            }}
            src="/static/close.svg" alt="Close" style={{
              cursor: "pointer", backgroundColor: "white", borderRadius: '30px',
              position: 'absolute', top: '14px', right: '14px', zIndex: 5,
              width: '25px', height: '25px'
            }} className="share_modal_close_btn" />
          <Slider {...settings}>
            {detailsData?.documents.map((ph) =>
              !/\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                <div className="dynamic-content-slick">
                  <img
                    src={ph?.uploadDocumentUrl || "/static/expertise2.png"}
                    className="event_img"
                    style={{ cursor: "pointer", margin: 'auto' }}
                  />
                </div>
              )
                :
                (
                  <div className="dynamic-content-slick">
                    <video
                      src={ph?.uploadDocumentUrl}
                      className="event_img"
                      controls
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate nofullscreen"
                      muted
                      style={{ cursor: "pointer", margin: 'auto', display: 'block' }}
                    >
                      <source src={ph?.uploadDocumentUrl} />
                    </video>
                  </div>
                ))
            }
          </Slider>
        </div>
      }
    </>

  );
};
export default ListingDetailGallery;
