import { useState } from "react";
import Button from "../../global/Button";
import CommonOutlineButton from "../../global/CommonOutlineButton";
import {
  makePostCall,
  makeDeleteCall,
  makePatchCall,
} from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { getItemFromStore, showToast } from "../../utils";
import { LikeIconGrey } from "../../utils/Icons/svgs";

const UserProfile = ({
  userProfile,
  setShowFlagUser,
  setShowRemoveConnection,
  fetchUserProfile,
}: any) => {
  const profile = getItemFromStore("userProfile");
  const [, setLoading] = useState(false);
  const [showButtonOption, setShowButtonOption] = useState(false);

  const connectionStatus = userProfile?.connectionStatus?.status;

  const handleLike = async () => {
    const apiPayload = {
      user: userProfile?.id,
      markedBy: profile?.id,
    };
    setLoading(true);
    makePostCall({ url: endpoints.favourite, apiPayload }).then((res) => {
      setLoading(false);
      showToast(res.status.message, "success");
      fetchUserProfile();
    });
  };
  const handleDislike = () => {
    const apiPayload = {
      user: userProfile?.id,
    };
    setLoading(true);
    makeDeleteCall({ url: endpoints.favourite, apiPayload }).then((res) => {
      setLoading(false);
      showToast(res.status.message, "success");
      fetchUserProfile();
    });
  };

  const sendRequest = () => {
    setLoading(true);
    makePostCall({ url: `auth/${userProfile?.id}/connections/` }).then(
      (res) => {
        setLoading(false);
        fetchUserProfile();
      }
    );
  };
  const acceptRequest = () => {
    setLoading(true);
    makePatchCall({
      url: `${endpoints.accept_connection_request}/${userProfile?.connectionStatus?.connectionId}`,
      apiPayload: { status: "A" },
    }).then((res) => {
      setLoading(false);
      fetchUserProfile();
    });
  };
  const rejectRequest = () => {
    setLoading(true);
    makePatchCall({
      url: `${endpoints.accept_connection_request}/${userProfile?.connectionStatus?.connectionId}`,
      apiPayload: { status: "R" },
    }).then((res) => {
      setLoading(false);
      fetchUserProfile();
    });
  };

  return (
    <div className="left-container">
      <div
        style={{ right: 72, top: 24 }}
        className={`${
          userProfile.isFlagged === true
            ? "grey-round-button"
            : userProfile?.isMarkedFavourite
            ? "orange-round-button"
            : "grey-round-button"
        }`}
        onClick={() => {
          if (userProfile.isFlagged === true) {
            return;
          }
          if (userProfile?.isMarkedFavourite) {
            handleDislike();
          } else {
            handleLike();
          }
        }}
      >
        {userProfile.isFlagged === true ? (
          <LikeIconGrey />
        ) : (
          <img src="/static/LikeIconWhite.svg" alt="" />
        )}
      </div>
      <div
        onClick={() => {
          if (userProfile.isFlagged) {
            showToast("User is already flagged", "error");
          } else setShowFlagUser(true);
        }}
        style={{ right: 24, top: 24 }}
        className="grey-round-button"
      >
        {userProfile.isFlagged ? (
          <img src="/static/flag.svg" alt="" />
        ) : (
          <img src="/static/FlagIconWhite.svg" alt="" />
        )}
      </div>
      <div
        className="profile-image-big"
        style={{
          height: 220,
          width: "100%",
          backgroundImage: userProfile?.profilePicture
            ? ["mp4", "mov", "avi"].includes(
                userProfile?.profilePicture
                  ?.split(".")
                  .splice(-1)[0]
                  .toLowerCase()
              )
              ? `url(${userProfile?.thumbnail})`
              : `url(${userProfile?.profilePicture})`
            : "url(/static/dummyimg.svg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="profile-information"
        onMouseEnter={() => {
          setShowButtonOption(false);
        }}
      >
        {["mp4", "mov", "avi"].includes(
          userProfile?.profilePicture?.split(".").splice(-1)[0].toLowerCase()
        ) ? (
          <>
            <div style={{ position: "absolute", top: "-51px" }}>
              <video
                src={userProfile?.profilePicture}
                autoPlay
                playsInline
                loop
                muted
                style={{
                  cursor: "pointer",
                  width: "101px",
                  height: "101px",
                  objectFit: "cover",
                  verticalAlign: "middle",
                  borderRadius: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <source src={userProfile?.profilePicture} />
              </video>
            </div>
          </>
        ) : (
          <div style={{ position: "absolute", top: "-51px" }}>
            <img
              style={{
                height: 101,
                width: 101,
                top: -50.5,
                border: "5px solid #FFFFFF",
                borderRadius: "50%",
              }}
              src={userProfile?.profilePicture || "/static/dummyimg.svg"}
              alt=""
            />
          </div>
        )}
        <div className="user-name">{userProfile?.name}</div>
        {userProfile?.distance && (
          <div className="flex-row sub-text">
            <img
              style={{ width: 15, height: 15 }}
              src="/LocationPin.png"
              alt=""
            />{" "}
            {userProfile?.distance} kms away...
          </div>
        )}
        <div
          className="personal-info-card"
          onMouseEnter={() => {
            setShowButtonOption(false);
          }}
        >
          <div
            className=""
            style={{ borderRight: "1px solid #8F939A ", width: "35%" }}
          >
            <img
              style={{ marginRight: 5, marginBottom: 5 }}
              src="/static/CakeBlack.svg"
              alt=""
            />
            24
          </div>
          <div className="" style={{}}>
            <img
              style={{ marginRight: 5 }}
              src="/static/FemaleBlack.svg"
              alt=""
            />
            {userProfile?.gender === "F" ? "Female" : "Male"}
          </div>
        </div>
        {connectionStatus === "Connect" ? (
          <Button
            btnImg={"/static/Connect.svg"}
            btnTxt="Connect"
            disabled={userProfile.isFlagged}
            onClick={sendRequest}
          />
        ) : connectionStatus === "Accept request" ? (
          <div className="flex-row" style={{ justifyContent: "space-evenly" }}>
            <CommonOutlineButton
              image=""
              text="Cancel"
              className={"common_btn_grey"}
              width="45%"
              onClick={rejectRequest}
            />
            <CommonOutlineButton
              image=""
              text="Accept"
              className={"common_btn_orange"}
              width="45%"
              onClick={acceptRequest}
            />
          </div>
        ) : connectionStatus === "Pending" ? (
          <div className="flex-row" style={{ justifyContent: "space-evenly" }}>
            <CommonOutlineButton
              text="Requested"
              image={"/static/FitnessMate.svg"}
              className={"outline-button"}
              width="100%"
            />
          </div>
        ) : (
          <div
            className="flex-row"
            style={{ justifyContent: "space-evenly", position: "relative" }}
          >
            <CommonOutlineButton
              text="Fitness Mate"
              image={"/static/FitnessMate.svg"}
              className={"outline-button"}
              width="100%"
              onClick={() => {
                setShowButtonOption(true);
              }}
            />
            {showButtonOption && (
              <div
                className="button-option"
                onClick={() => setShowRemoveConnection(true)}
              >
                Remove Connection
              </div>
            )}
          </div>
        )}

        <div
          className="user-profile-description"
          onMouseEnter={() => {
            setShowButtonOption(false);
          }}
        >
          <div>
            <div className="bio-section-title">Bio</div>
            <p className="mb-0">{userProfile?.bio}</p>
          </div>
          <div className="interest-section">
            Interests
            <div className="interest-tag-section">
              {userProfile?.interest?.map((p: any) => {
                return (
                  <div key={p} className="interest-tag">
                    {p.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
