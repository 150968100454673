import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import {
  dateFormatter,
  handleVideoClick,
  scrollToTop,
  showToast,
} from "../../utils";
import { makeGetCall, makeNextPageCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import ReactPaginate from "react-paginate";
import GlobalFooter from "../Dashboard/GlobalFooter";

const PromotionsResult = () => {
  const navigate = useNavigate();
  const [promotion, setPromotion] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit] = useState<number>(16);
  const [offset, setOffset] = useState<number>(0);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (nextUrl) {
      makeNextPageCall({ url: nextUrl })
        .then((res) => {
          const tempData = [...promotion, ...res.results];
          setNextUrl(res?.next);
          setPageCount(res?.count);
          setPromotion(tempData as never);
        })
        .catch((err) => {
          showToast(err, "error");
        });
      scrollToTop();
    }
  };

  const getPromotionData = () => {
    setPromotion([]);
    setIsLoading(true);
    makeGetCall({
      url: `${endpoints.listings_promotion}?&limit=${limit}&offset=${offset}`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setPromotion(res?.results);
          setPageCount(res?.count);
          setNextUrl(res?.next || null);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getPromotionData();
  }, []);

  return (
    <div className="promotion-page">
      <GlobalHeader2 />
      <div className="page-header">
        <div className="content-box">
          <div className="content-title">
            Check out our exclusive promotions & news
          </div>
          <div className="content-subtitle">
            Unlock incredible offers and discounts tailored just for you. Check
            out the latest promotional & news ads below!
          </div>
        </div>
      </div>
      <hr />

      <div className="card-list-wrap">
        {isLoading ? (
          <div className="page_loader">
            <ApiLoader />
          </div>
        ) : promotion.length > 0 ? (
          <div className="listings-card">
            {promotion?.map((item: any, index: number) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  className="card-wrap"
                  key={index}
                  onClick={() => navigate(`${item.id}`)}
                >
                  <div className="card-media">
                    {["mp4", "mov", "avi"].includes(
                      item?.media?.split(".").splice(-1)[0].toLowerCase()
                    ) ? (
                      <>
                        <Link to={`${item.id}`}>
                          <video
                            src={item?.image}
                            className="event_img"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleVideoClick(`eventvideo${index}`)
                            }
                            id={`eventvideo${index}`}
                          >
                            <source src={item?.image} />
                          </video>
                        </Link>
                      </>
                    ) : (
                      <Link to={`${item.id}`}>
                        <img
                          src={item?.image || "/static/expertise2.png"}
                          className="event_img"
                          alt=""
                        />
                      </Link>
                    )}
                  </div>
                  <div className="card-content">
                    <div className="title">
                      <Link className="event-link-title" to={`${item.id}`}>
                        {item?.title}
                      </Link>
                    </div>
                    <div className="others">
                      <Link className="event-link-title" to={`${item.id}`}>
                        <div className="time">
                          <img src="/static/calendar.svg" alt="" />
                          {dateFormatter(
                            item?.startDate,
                            "DD MMM, YYYY"
                          )} - {dateFormatter(item?.endDate, "DD MMM, YYYY")}
                        </div>
                      </Link>
                    </div>
                    <Link className="event-link-title" to={`${item.id}`}>
                      <div className="desc">{item?.description}</div>
                    </Link>
                  </div>
                  <div className="find-out-box">
                    <div className="click-here">
                      <Link
                        className="promo-link-title"
                        to={`${item?.promotionUrl}`}
                        target="_blank"
                      >
                        <span>
                          <img alt="" src="/static/openfind.svg" />
                        </span>
                        <span>Click here to find out</span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-promotion-box">
            <img alt="" src="/static/giftcardmoney.svg" />
            <div className="no-promotion-title">
              No active promotions & news
            </div>
            <div className="no-promotion-desc">
              Currently, there are no active promotions & news available. Check
              back later for exciting offers and discounts. Stay tuned!
            </div>
          </div>
        )}

        <div className="paination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <div className="previous-button">
                  Next <img src="/static/u_angle-left-b(1).svg" alt="" />
                </div>
              </>
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            forcePage={currentPage}
            pageCount={pageCount / limit}
            previousLabel={
              <>
                <div className="previous-button">
                  Previous <img src="/static/u_angle-left-b.svg" alt="" />
                </div>
              </>
            }
            renderOnZeroPageCount={null}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      <GlobalFooter />
    </div>
  );
};
export default PromotionsResult;
