import Button from "../../../global/Button";
import {
  emptyFunction, formatNumber, scrollToTop,
} from "../../../utils";
import moment from "moment";
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSimplyCarousel from "react-simply-carousel";


export const EventsMapCard = ({ showEvent, onClickOutside }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  function EventImageCarousel({ item }: any) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    return (
      <div>
        {item?.photos.length > 0 ? (
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            dotsNav={{
              show: true,
              containerProps: {
                className: "dots-nav-container",
                style: {
                  position: "absolute",
                  bottom: "30%",
                  gap: "6px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              itemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "rgba(255, 255, 255, 0.4)",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "white",
                },
              },
            }}
            forwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",

              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "0",
                top: "50%",
                right: "16px",
                translate: "0 -50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-right.svg"
                />
              ),
            }}
            backwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",
              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "99",
                top: "50%",
                left: "16px",
                translate: "0 -50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-left.svg"
                />
              ),
            }}
            speed={400}
            easing="linear"
          >
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

            {item?.photos.map((ph) =>
              /\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                <>
                  <video
                    src={ph?.uploadDocument}
                    className="event_img"
                    //preload="none"
                    //autoPlay
                    controls
                    controlsList="nodownload"
                    // playsInline
                    // loop
                    muted
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      navigate(`listing-two/${item.id}`);
                    }}
                  >
                    <source src={ph?.uploadDocument} />
                  </video>
                </>
              ) : (
                <img
                  src={ph?.uploadDocument || "/static/expertise2.png"}
                  className="event_img"
                  alt=""
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    navigate(`listing-two/${item.id}`);
                  }}
                />
              )
            )}
          </ReactSimplyCarousel>
        ) : /\.(mp4|mov|avi)$/.test(item?.media?.toLowerCase()) ? (
          <>
            <video
              src={item?.media}
              className="event_img"
              //preload="none"
              // autoPlay
              controls
              controlsList="nodownload"
              // playsInline
              // loop
              muted
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
                navigate(`listing-two/${item.id}`);
              }}
            >
              <source src={item?.media} />
            </video>
          </>
        ) : (
          <img
            src={item?.media || "/static/expertise2.png"}
            className="event_img"
            alt=""
            loading="lazy"
            onClick={(e) => {
              e.preventDefault();
              scrollToTop();
              navigate(`listing-two/${item.id}`);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div
      ref={ref}

    >
      <div className="event-link-title">
        <div className="listing_box">
          <div className="crousel-container">
            <EventImageCarousel item={showEvent} />
            {/* {
            item?.listingType === LISTING_TYPE.SPECIAL && (
              <div className="special-box">
                <img
                  src={img2}
                  alt=""
                  style={{
                    height: "30px",
                    width: "30px",
                    marginRight: "auto",
                  }}
                />
                <div className="exc-text">
                  Exclusive for Members
                </div>
              </div>
            )
            } */}
          </div>
          <div className="span_box"
            onClick={(e) => {
              e.preventDefault();
              navigate(`listing-two/${showEvent.id}`);
            }}
          >
            {showEvent.distance ? <span className="distance">{formatNumber(showEvent.distance.toFixed(2))} km away...</span> : ""}


            <span className="price">$ {showEvent.price}</span>
          </div>

          <div
            className="limited-event-info"
            onClick={(e) => {
              e.preventDefault();
              navigate(`listing-two/${showEvent.id}`);
            }}
          >
            <div className="title item">{showEvent.title}</div>
            <div className="item">{showEvent.address}</div>
            <div className="item">
              Upcoming event date: <span>{moment(showEvent.upcomingEventDate).format('D MMM, YYYY')}</span>
            </div>
            <div className="item">
              Participants:<span> {(!showEvent.slotsAvailable) ? "0" : showEvent.slotsAvailable} booked out of {showEvent.maxParticipants}</span>
            </div>
            <div className="item">
              Event Type: <span className="event-type">{showEvent.listingEventType === "P" ? "Physical" : "Virtual"}</span>
            </div>
          </div>

          <div className="name" onClick={() => {
            navigate(`/user/${showEvent?.createdBy?.id}`);
          }}>
            <img src={(showEvent?.createdBy?.profilePicture) ? showEvent?.createdBy?.profilePicture : "/static/dummyimg.svg"} alt="" loading="lazy" />

            <span> {(showEvent?.createdBy?.tradingName) ? showEvent?.createdBy?.tradingName : showEvent?.createdBy?.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
