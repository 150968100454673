import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { useState } from "react";
import FooterPartnerPopup from "../FooterPopup/FooterPartnersPopup";

const Footer = () => {
  const navigate = useNavigate();
  const [showAffiliate, setShowAffiliate] = useState(false);
  const [modalData, setModalData] = useState("affiliate");

  const handleOpenModal = (modalType) => {
    setModalData(modalType);
    setShowAffiliate(true);
  };
  const handleCloseModal = () => {
    setShowAffiliate(false);
  };
  return (
    <div className="global-footer">
      <div className="footer-container">
        <div className="footer-first-part row gx-0">
          <div className="links-box quick-links col-12 col-sm-6 col-md-3">
            <h4>Quick Links</h4>
            <ul>
              <li
                onClick={() => {
                  scrollToTop();
                }}
              >
                Home
              </li>

              <li onClick={() => handleOpenModal("program")}>Careers</li>
            </ul>
          </div>
          <div className="links-box locate-us col-12 col-sm-6 col-md-3">
            <h4>Locate us</h4>
            <div className="locate-item-container">
              <div className="locate-item">
                <img src="/static/outline-email.svg" alt="" />
                <a href="mailto:info@fitnessmates.com.au">
                  info@fitnessmates.com.au
                </a>
              </div>
              <div className="locate-item">
                <img src="/static/outline-call.svg" alt="" />
                <p>1300 783 001</p>
              </div>
              <div className="locate-item">
                <img src="/static/outline-location.svg" alt="" />
                <p>Sydney, Australia</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3"></div>
          <div className="links-box follow-us col-12 col-sm-6 col-md-3">
            <h4>Follow us</h4>
            <div className="follow-icon-container">
              <div
                className="img-container"
                onClick={() => {
                  window.open("https://twitter.com/fitnessmatesau", "_blank");
                }}
              >
                <img src="/static/twitter-white.svg" alt="" />
                <img src="/static/twitter-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.tiktok.com/@fitnessmatesau",
                    "_blank"
                  );
                }}
              >
                <img src="/static/tiktok-white.svg" alt="" />
                <img src="/static/tiktok-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open("https://instagram.com/fitnessmatesau", "_blank");
                }}
              >
                <img src="/static/insta-white.svg" alt="" />
                <img src="/static/insta-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/@fitnessmatesau",
                    "_blank"
                  );
                }}
              >
                <img src="/static/youtube-white.svg" alt="" />
                <img src="/static/youtube-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/fitnessmates1",
                    "_blank"
                  );
                }}
              >
                <img src="/static/facebook-white.svg" alt="" />
                <img src="/static/facebook-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/fitness-mates",
                    "_blank"
                  );
                }}
              >
                <img src="/static/linkedin-white.svg" alt="" />
                <img src="/static/linkedin-or.svg" alt="" />
              </div>
            </div>
            <div className="follow-store-container">
              <img
                src="/static/app-store-whole.svg"
                style={{ cursor: "pointer" }}
                alt=""
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/app/fitness-mates/id6449068527",
                    "_blank"
                  );
                }}
              />
              <img
                src="/static/play-store-whole.svg"
                style={{ cursor: "pointer" }}
                alt=""
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.fitnessmates.mobile.app",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer-second-part">
          <div>© 2023 FitnessMates Pvt. Ltd. All Rights Reserved.</div>
          <div className="footer-terms">
            <Link to="/policy">
              <p>Terms & Conditions</p>
            </Link>
            <span>|</span>
            <Link to="/policy">
              <p>Privacy Policy</p>
            </Link>
          </div>
        </div>
      </div>
      <FooterPartnerPopup
        show={showAffiliate}
        onHide={handleCloseModal}
        modalType={modalData}
      />
    </div>
  );
};

export default Footer;
