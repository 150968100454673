import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import {
  downloadFile,
  formatAmount,
  setLocalStorage,
  showToast,
} from "../../utils";
import endpoints from "../../utils/endpoints";
import { makeGetCall } from "../../utils/Requests";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import DashboardWrapper from "./DashboardWrapper";
import EarningCard from "./Popups/EarningCard";
import ApiLoader from "../../global/ApiLoader";
import Button from "../../global/Button";

export default function Earnings() {
  const navigate = useNavigate();
  const [userUpdate, setuserUpdate] = useState<boolean>(false);
  const [earningList, setEarningList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [earningInfo, setearningInfo] = useState<any>({});
  const [showCsvPopup, setShowCsvPopup] = useState<boolean>(false);

  useEffect(() => {
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
            ...res.data?.trainerData?.[0],
          };
          let decoUserData;

          if (data) {
            decoUserData = {
              ...data,
              businessContactNumber: data.businessContactNumber?.slice(3),
              dob: moment(data.dob, "YYYY-MM-DD")?.toDate(),
            };
          }
          setLocalStorage("userProfile", decoUserData);
        } else {
          showToast(STRING_CONSTANTS.wrong_data_message, "error");
        }
        setuserUpdate(false);
      })
      .catch((err) => showToast(err, "error"));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_earning })
      .then((res) => {
        if (res.status.code === 200) {
          setEarningList(res.data.listings);
          setearningInfo(res.data);
          setIsLoading(false);
        } else {
          showToast(res?.status?.message, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {showToast(err, "error");
        setIsLoading(false);
      });
  }, []);

  const downloadCsv = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_earning_csv })
      .then((res) => {
        //not getting any status code yet
        downloadFile(res);
        setShowCsvPopup(false);
        setIsLoading(false);
        if (res.status.code === 200) {
          setShowCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  return (
    <DashboardWrapper userUpdate={userUpdate} showTab={true}>
      <div className="main-section-right profile-managment-right business_interest manage_profile update-interests left-sdie earning-page-wrap">
        <div className="profile_box earning_box">
          <div className="title">
            My Earnings
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow d-md-none"
              onClick={() => navigate(-1)}
            />
          </div>
          <img
            alt=""
            src="/static/document-right.svg"
            className="document-download"
            onClick={() => setShowCsvPopup(true)}
          />
          <div className="earning-title-box">
            <div>
              <div className="content">
                <img alt="" src="/static/money-tag.svg" />
                Total earnings
              </div>
              <div className="number">
                {earningInfo.totalEarnings
                  ? `$ ${
                      earningInfo.totalEarnings
                        ? formatAmount(earningInfo.totalEarnings)
                        : 0
                    }`
                  : 0}
              </div>
            </div>
            <div>
              <div className="content">
                <img alt="" src="/static/total-tag.svg" />
                Total Listings
              </div>
              <div className="number">{earningInfo.totalListings || 0}</div>
            </div>
          </div>
          {isLoading ? (
            <ApiLoader />
          ) : earningList.length > 0 ? (
            <>
              {earningList?.map((item) => (
                <EarningCard key={item.id} item={item} />
              ))}
            </>
          ) : (
            <div className="empty-data">
              <img src="/static/EmptyBooking.svg" alt="" />
              <p>No earnings yet</p>
              <p style={{ fontSize: 12 }}>
                Wait to get bookings and start earning
              </p>
            </div>
          )}
        </div>
      </div>

      {showCsvPopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal csv-modal-wrap">
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowCsvPopup(false);
                }}
                className="close-btn hide-large"
                alt=""
              />
            </div>
            <div className="modal-top-part">
              <div>
                <img alt="" src="/static/file-type-excel.svg" />
              </div>

              <h5>Export Your Listings</h5>
              <p>
                Effortlessly transfer your listings to an Excel spreadsheet for
                easy organization and analysis.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Download as Excel(.csv)"
                className={"backtologin"}
                onClick={downloadCsv}
              />
              <Button
                btnTxt="Not now"
                className={"backtologin notnow"}
                onClick={() => setShowCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </DashboardWrapper>
  );
}
