import { useNavigate } from "react-router-dom";

const GetEarly = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="get-early-section">
        <div className="content-box">
          <div className="content-left">
            <img alt="" src="/static/getearly.png" />
          </div>
          <div className="content-right">
            <div className="title">
              So, what is {""}
              <span className="orange-span">Fitness mates?</span>
            </div>
            <div className="description">
              Fitness Mates is a revolutionary online platform that serves as a
              comprehensive fitness marketplace for active Aussies. At its core,
              Fitness mates aims to unite the entire fitness industry under one
              roof, making it easier than ever before for individuals to access
              a wide range of fitness services and experiences.
              <br />
              <br />
              Our mission is to revolutionise the fitness industry by bringing
              together a diverse range of offerings in one platform: from
              boutique studios to renowned sports athletes, from personalised
              training sessions to group classes.
            </div>
            <div className="button-box">
              <div className="orange-btn" onClick={() => navigate("/register")}>
                Get early access
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetEarly;
