import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalHeader from "../Dashboard/GlobalHeader";
import { EventListingType, EventListingTypeGuest } from "../../utils/Constants";
import { LISTING_TYPE } from "../../types";
import DraftCard from "./DraftCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import { getItemFromStore, showToast } from "../../utils";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
} from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import Button from "../../global/Button";
import moment from "moment";

const Drafts = () => {
  const Navigate = useNavigate();
  const [listingType, setListingType] = useState(LISTING_TYPE.S);
  const [eventListingType, setEventListingType] = useState(
    getItemFromStore("userProfile")?.userType !== "Guest"
      ? EventListingType
      : EventListingTypeGuest
  );
  const [draftList, setDraftList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<String>(null);

  const getdraftData = () => {
    setDraftList([]);
    let params = {
      type: listingType,
    };
    setIsLoading(true);
    makeGetCall({ url: endpoints.draft_listing, params })
      .then((res) => {
        if (res.status.code === 200) {
          setDraftList(res?.results);
          setNextUrl(res?.next || null);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  const deleteDraftItem = () => {
    setShowDeletePopup(false);
    if (deleteId) {
      makeDeleteCall({
        url: endpoints.listing + `${deleteId}`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            getdraftData();
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  useEffect(() => {
    getdraftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingType]);

  const fetchMoreEvents = () => {
    makeNextPageCall({ url: nextUrl })
      .then((res) => {
        const tempData = [...draftList, ...res.results];
        setNextUrl(res?.next);
        setDraftList(tempData as never);
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <>
      <GlobalHeader />
      <div className="page-draft">
        <div className="wrap-box">
          <div className="title-box">
            <img
              src="/static/leftarrow.svg"
              alt=""
              className="back_arrow"
              onClick={() => Navigate("/events")}
            />
            <h3>Drafts</h3>
          </div>
          <div>
            <ul className="select_listing_type">
              {eventListingType?.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    onClick={() => setListingType(item.type)}
                    className={listingType === item.type ? "active" : ""}
                  >
                    <img
                      src={
                        listingType === item.type
                          ? item.inactiveImg
                          : item.activeImg
                      }
                      alt=""
                    />
                    <p className="mt-1">{item.title_draft}</p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tooltip-box">
            <div className="info_box">
              <img src={"/static/greyinfo.svg"} alt="" />
              <div>
                <div className="tip-title">
                  You can only access drafts created within the past 30 days.
                  <br />
                </div>
                <div className="tip-subtitle">
                  You can only edit one draft listing at a time.
                </div>
                <div className="tip-subtitle">
                  Please publish or discard this listing before creating another
                  draft.
                </div>
              </div>
            </div>
          </div>

          <div className="card-list-results">
            <InfiniteScroll
              dataLength={draftList?.length}
              next={() => fetchMoreEvents()}
              hasMore={nextUrl ? true : false}
              loader={<ApiLoader />}
            >
              {isLoading ? (
                <div className="page_loader">
                  <ApiLoader />
                </div>
              ) : (
                <div className="listings">
                  {draftList?.map((item: any, index: number) => {
                    return (
                      <DraftCard
                        onClick={() => Navigate(`/edit-listing/${item.id}`)}
                        key={item.id}
                        title={item?.title}
                        extension={item?.extension}
                        url={item.image}
                        date={moment(item.createdAt).format("ll")}
                        onDeleteClick={(e) => {
                          e.stopPropagation();
                          setDeleteId(item.id);
                          setShowDeletePopup(true);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </InfiniteScroll>
          </div>

          {showDeletePopup ? (
            <div className={"modal show"}>
              <div className="modal-section log_out_modal">
                <div className="modal-top-part">
                  <h5>Are you sure?</h5>
                  {/* <p>{STRING_CONSTANTS.logout_desc}</p> */}
                </div>
                <div className="button_option">
                  <Button
                    btnTxt="Delete"
                    className={"backtologin"}
                    onClick={deleteDraftItem}
                  />
                  <Button
                    btnTxt="Cancel"
                    className={"backtologin cancel"}
                    onClick={() => setShowDeletePopup(false)}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Drafts;
