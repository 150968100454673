/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import {
  FREQUENCY_TYPE,
  GendersTypeSpecific,
  GENDER_PREFERENCE,
  LISTING_TYPE,
  RECURRING_TYPE,
  SCHEDULE_TYPE,
  USER_TYPE,
} from "../../../types";
import {
  allowedFormats,
  convertBytesToMB,
  dateFormatter,
  fetchMoreIntrestList,
  getIntrestList,
  getItemFromStore,
  handleFileUpload,
  handleLocation,
  isEmptyOrNullOrUndefined,
  isObjEmpty,
  setLocalStorage,
  showToast,
} from "../../../utils";
import {
  apiConstants,
  difficultyLevelPreferenceNew,
  filterOption,
  ListingTypesForGuest,
  ListingTypesForMember,
  meetingLinkRegex,
} from "../../../utils/Constants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import useDebounce from "../../../utils/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../../global/ApiLoader";
import ApiLoaderListing from "../../../global/ApiLoaderListing";

import ScheduleListingModal from "./ScheduleListingModal";
import ScheduleListingSidebar from "./ScheduleListingSidebar";
import {
  makeGetCall,
  makePatchCall,
  makePostCall,
} from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import ListingCard from "./ListingCard";
import { ListingFormDefaultValue } from "../../../utils/FormConfig";
import DraftPopup from "./DraftPopup";
import SaveEventPopup from "./SaveEventPopup";
import { DateObject } from "react-multi-date-picker";
import { OutlineYellowButton } from "../../../global/OutlineButton";
import AddIconYellow from "../../../assets/img/icons/AddIconYellow";
import { useOutsideClick } from "../../../hooks";
import LocationDeniedPopup from "./LocationDeniedPopup";
import { CrossIcon, ErrorInfo } from "../../../utils/Icons/svgs";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import { Table } from "react-bootstrap";
import ImageCropper from "../../../global/ImageCropper";
import FilesUploadComponent from "../FilesUploadComponent";
import GlobalHeader2 from "../GlobalHeader2";
import "../../../assets/scss/createListing.scss";
import PaymentOption from "./PaymentOption";
import SelectPayout from "./SelectPayout";
import MeetingLink from "../../ListingComponents/MeetingLink/MeetingLink";
import LocationSection from "../../ListingComponents/LocationSection/LocationSection";
import EventCardSection from "../../ListingComponents/EventsCardSection/EventCardSection";
import InfoCard from "../../ListingComponents/InfoCard/InfoCard";

const CreateListing = () => {
  const Navigate = useNavigate();
  const [isDraft, setIsDraft] = useState(false);
  const [isCreateListingLoading, setIsCreateListingLoading] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
    trigger,
  } = useForm({
    defaultValues: ListingFormDefaultValue,
    // resolver: yupResolver(isDraft ? NoValidation : CreateListingValidationNew),
    mode: "onChange",
  });

  const [scheduleListing, setScheduleLisiting] = useState(false);
  const [multipleScheduleListing, setMultipleScheduleLisiting] =
    useState(false);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [showDraftPopUp, setShowDraftPopUp] = useState(false);
  const [filter, setFilter] = useState("popularity");
  const [showFilter, setShowFilter] = useState(false);
  const [isLimitOnInterest, setIsLimitOnInterest] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [intrestList, setIntrestList] = useState<any>([]);
  const [draftList, setDrafttList] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [, setAddEventOpen] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<any>({
    isDraft: false,
    isCreate: false,
  });

  const [images, setImages] = useState([]);

  const [cropperOpen, setCropperOpen] = useState(false);
  const [imageFile, setImageFile] = useState(false);
  const [cropedImage, setCropedImage] = useState("");

  const [isListingInfo, setIsListingInfo] = useState<any>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(false);
  const [isBookingInfo, setIsisBookingInfo] = useState<any>(false);
  const [showAddEventSidebar, setShowAddEventSidebar] = useState<any>(false);
  const [, setScheduleDate] = useState<any>("");
  const [editEvent, setEditEvent] = useState<any>(false);
  const [isFeeInfoShown, setIsFeeInfoShown] = useState(false);

  const [editEventId, setEditEventId] = useState<any>(null);
  const [userType] = useState(getItemFromStore("userProfile")?.userType);
  const userDetail = useState(getItemFromStore("userProfile"));
  const [showEventListingsSidebar, setShowEventListingsSidebar] =
    useState<boolean>(false);
  const [listingTypes, setListingType] = useState(
    userType === USER_TYPE.GUEST ? ListingTypesForGuest : ListingTypesForMember
  );
  const [isLocationAccessible, setIsLocationAccessible] = useState<any>(false);
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [cancellationTill, setCancellationTill] = useState<string>("");
  const [isPaymentOpen, setIsPaymentOpen] = useState<any>(false);
  const [paymentMode, setPaymentMode] = useState<string>(null);
  const [isRecommend, setIsRecommend] = useState<boolean>(true);
  const [isAddEventInfo, setIsAddEventInfo] = useState<boolean>(false);
  const [showStripeError, setShowStripeError] = useState<boolean>(false);
  const [commisionAmounts, setCommisionAmounts] = useState<any>(null);
  const [showGuestLstingInfo, setShowGuestLstingInfo] =
    useState<boolean>(false);

  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };
  const ref = useRef(null);
  const refInfo = useRef(null);
  const [currUserInfo, setCurrUserInfo] = useState(
    getItemFromStore("userProfile")
  );

  const { isMobile } = useCheckDeviceScreen();

  // create temp array for multiple event

  useEffect(() => {
    handleSetValue(apiConstants.user_type, userType);
    setShowGuestLstingInfo(userType === USER_TYPE.GUEST);
  }, [userType]);

  useEffect(() => {
    setIsLoading(true);
    if (searchTerm) {
      getIntrestList(
        { search: searchTerm, sort: filter },
        setIntrestList,
        setNextUrl,
        setIsLoading
      );
    }
  }, [debouncedSearchTerm, filter]);

  useEffect(() => {
    makeGetCall({ url: endpoints.draft })
      .then((res) => {
        if (res.status.code === 200) {
          setDrafttList(res.results);
          setListingType(
            listingTypes.map((type) => {
              const matchingItem = res.data.find(
                (matchedItem: any) => matchedItem.listingType === type?.type
              );
              if (matchingItem) {
                return { ...type, ...matchingItem, hasDraft: true };
              } else {
                return { ...type, hasDraft: false };
              }
            })
          );
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  }, [watch(apiConstants.listingDetails), userType]);

  useEffect(() => {
    if (watch(apiConstants.selectedInterest)?.length < 4 && isLimitOnInterest) {
      setIsLimitOnInterest(false);
    }
  }, [watch(apiConstants.selectedInterest)]);

  const handleIntrest = (intrest: any) => {
    const tempList = watch(apiConstants.selectedInterest) || [];
    let index = tempList.findIndex((intr: any) => intr.id === intrest.id);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(intrest);
    }

    handleSetValue(apiConstants.selectedInterest, [...tempList]);
  };

  useEffect(() => {
    if (!searchTerm) {
      setIntrestList([]);
    }
  }, [searchTerm]);

  const handleNext = ({ isDraft }: any) => {
    if (!isDraft) {
      if (watch(apiConstants.media).length < 1) {
        showToast("Minimum 1 media file is required", "error");
        return;
      } else if (!watch(apiConstants.listing_name)) {
        showToast("Listing name is required", "error");
        return;
      } else if (watch(apiConstants.listing_name)?.length < 3) {
        showToast("Listing name must be at least 3 characters long", "error");
        return;
      } else if (!watch(apiConstants.listing_description)) {
        showToast("Description is required", "error");
        return;
      } else if (watch(apiConstants.listing_description)?.length < 10) {
        showToast("Description must be at least 10 characters long", "error");
        return;
      } else if (
        !watch(apiConstants.selectedInterest) ||
        watch(apiConstants.selectedInterest)?.length < 1
      ) {
        showToast("Please add at least 1 interest", "error");
        return;
      }
      //  else if (
      //   watch(apiConstants.listing_event_type) === "V" &&
      //   (watch(apiConstants.multiple_virtual_links_allowed) !== true ||
      //     watch(apiConstants.multiple_virtual_links_allowed) !== false)
      // ) {
      //   showToast("Please select same virtual link or not", "error");
      //   return;
      // }
      else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        !watch(apiConstants.virtual_links_single)
      ) {
        showToast("Virtual meeting link is required", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        !watch(apiConstants.virtual_links_single) &&
        !meetingLinkRegex.googleMeetRegex.test(
          watch(apiConstants.virtual_links_single)
        ) &&
        !meetingLinkRegex.zoomRegex.test(
          watch(apiConstants.virtual_links_single)
        ) &&
        !meetingLinkRegex.teamsRegex.test(
          watch(apiConstants.virtual_links_single)
        )
      ) {
        showToast("Virtual meeting link is not valid", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "P" &&
        watch(apiConstants.listingDetails)?.type === LISTING_TYPE.S &&
        !watch(apiConstants.address)
      ) {
        showToast("Location is required", "error");
        return;
      } else if (
        !watch(apiConstants.max_participants) ||
        watch(apiConstants.max_participants) < 1
      ) {
        showToast("Minimum 1 participant is required", "error");
        return;
      } else if (
        watch(apiConstants.max_participants) > 4 &&
        currUserInfo?.userType === "Guest"
      ) {
        showToast("Max 4 participant is allowed", "error");
        return;
      } else if (
        (!watch(apiConstants.listing_cost) ||
          watch(apiConstants.listing_cost) < 1) &&
        currUserInfo?.userType !== "Guest" &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        showToast("Minimum cost allowed is 1", "error");
        return;
      } else if (
        watch(apiConstants.multipleEvents)?.length < 2 &&
        watch(apiConstants.listingDetails)?.type === LISTING_TYPE.M
      ) {
        showToast("Please add minimum 2 events", "error");
        return;
      } else if (
        !watch(apiConstants.has_schedule_date) &&
        watch(apiConstants.listingDetails)?.type === LISTING_TYPE.S
      ) {
        showToast("Schedule dates is required", "error");
        return;
      } else if (
        !watch(apiConstants.payment_mode) &&
        currUserInfo?.userType !== "Guest" &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        showToast("Please select the required payment method", "error");
        return;
      } else if (
        watch(apiConstants.isCancellationAllowed) &&
        !watch(apiConstants.cancellation_allowed_till_hours) &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        showToast("Please select allowed cancellation range", "error");
        return;
      } else if (
        watch(apiConstants.is_specific_age) &&
        (!watch(apiConstants.min_age) || !watch(apiConstants.max_age))
      ) {
        showToast("Min and max age is required", "error");
        return;
      } else if (
        !watch(apiConstants.selectedGender) ||
        watch(apiConstants.selectedGender)?.length < 1
      ) {
        showToast("Gender is required", "error");
        return;
      } else if (
        !watch(apiConstants.selecteddifficulty) ||
        watch(apiConstants.selecteddifficulty)?.length < 1
      ) {
        showToast("Difficulty level is required", "error");
        return;
      }
    }
    let tempIntrestList: any = [];
    if (watch(apiConstants.selectedInterest)?.length > 0) {
      tempIntrestList = watch(apiConstants.selectedInterest).map(
        (item: any) => {
          return item.id;
        }
      );
      handleSetValue(apiConstants.interests, tempIntrestList);
    }

    let tempSelectedInstruction: any = [];
    if (watch(apiConstants.selectedInstruction)?.length > 0) {
      tempSelectedInstruction = watch(apiConstants.selectedInstruction).map(
        (item: any) => {
          return item;
        }
      );
      handleSetValue(apiConstants.selectedInstruction, tempSelectedInstruction);
    }
    if (
      currUserInfo?.userType !== "Guest" &&
      !watch(apiConstants.payment_mode) &&
      !isDraft
    ) {
      setShowStripeError(true);
    }

    handleSubmit(async (data) => {
      if (
        currUserInfo?.userType !== "Guest" &&
        !watch(apiConstants.payment_mode) &&
        !isDraft &&
        !Object.keys(errors).length &&
        watch(apiConstants.listingDetails)?.type !== LISTING_TYPE.C
      ) {
        setShowStripeError(true);
        showToast("connect your stripe account first", "error", "stripeErr");
      } else {
        let reqData: any = {};
        let addressObject: any = {};
        let scheduleDetails: any = {};
        reqData["isActive"] = !watch(apiConstants.isDraft);
        reqData["listingType"] = getValues(apiConstants.listingDetails)?.type;

        if (dirtyFields["isListingPrivate"]) {
          reqData["isPrivate"] =
            getValues(apiConstants.is_listing_private) || false;
        }

        reqData["isDraft"] = watch(apiConstants.isDraft);

        if (dirtyFields["title"]) {
          reqData["title"] = data?.title;
        }
        if (dirtyFields["description"]) {
          reqData["description"] = data?.description;
        }

        if (dirtyFields["selectedInterest"]) {
          reqData["interests"] = getValues("interests");
        }
        if (getValues("selected_instruction")?.length > 0) {
          reqData["special_instruction"] = getValues("selected_instruction");
        }

        if (dirtyFields["lat"]) {
          addressObject["location"] = `POINT (${getValues(
            apiConstants.lng
          )} ${getValues(apiConstants.lat)})`;
        }
        if (dirtyFields["city"]) {
          addressObject["city"] = "";
        }
        if (dirtyFields["state"]) {
          addressObject["state"] = "";
        }
        if (dirtyFields["country"]) {
          addressObject["country"] = "";
        }
        if (dirtyFields["postCode"]) {
          addressObject["postCode"] = "";
        }
        if (dirtyFields["address"]) {
          addressObject["address"] = data?.address || null;
        }
        if (getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.M) {
          scheduleDetails["title"] =
            getValues(apiConstants.event_title) || null;
        }
        if (watch("eventListingType") === "P") {
          scheduleDetails["address"] = addressObject;
        }

        if (watch(apiConstants.selectedGender)?.length) {
          reqData["gender"] = getValues(apiConstants.selectedGender);
        }

        if (watch(apiConstants.selecteddifficulty)?.length) {
          reqData["difficultyLevel"] = watch(
            apiConstants.selecteddifficulty
          ).map(([key]) => key);
        }

        reqData["is_age_limit"] = data.minAge ? true : false;
        reqData["listing_event_type"] = getValues("eventListingType");

        if (dirtyFields["minAge"] && getValues(apiConstants.min_age) > 0) {
          reqData["minAge"] = data?.minAge ? parseInt(data?.minAge) : null;
        }
        if (dirtyFields["maxAge"] && getValues(apiConstants.max_age) > 0) {
          reqData["maxAge"] = data?.maxAge ? parseInt(data?.maxAge) : null;
        }

        setLoadingState({ isDraft, isCreate: !isDraft });
        setIsCreateListingLoading(true);
        setIsLoadingDraft(isDraft);

        const media = await getValues(apiConstants.media);
        const uploadResponse = await handleUploadFiles(media);
        reqData["document_details"] = uploadResponse;

        if (
          watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.C &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.M
        ) {
          scheduleDetails["single_listing_type"] =
            watch(apiConstants.recurring_type) === RECURRING_TYPE.R ? "R" : "C";
        } else if (
          watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.M
        ) {
          scheduleDetails["single_listing_type"] = "O";
        }

        if (getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.M) {
          if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === true
          ) {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData["virtual_links"] = getValues(
              apiConstants.virtual_links_single
            );
          } else if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          ) {
            const newData = getValues(apiConstants.multipleEvents)?.map(
              ({ virtual_links_package, date, ...others }) => {
                return { virtual_link: virtual_links_package, date };
              }
            );
            reqData["multiple_virtual_links_allowed"] = true;
            reqData["virtual_links"] = newData;
          }
        } else if (
          getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.S
        ) {
          if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === true
          ) {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData["virtual_links"] = getValues(
              apiConstants.virtual_links_single
            );
          } else if (
            watch("eventListingType") === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          ) {
            const newData = getValues(apiConstants.virtual_links_multiple);
            reqData["multiple_virtual_links_allowed"] = true;
            reqData["virtual_links"] = newData.map((val) => {
              const { event_start_time, event_end_time, ...rest } = val;
              return { ...rest };
            });
          }
        } else {
          if (watch("eventListingType") === "V") {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData["virtual_links"] = getValues(
              apiConstants.virtual_links_single
            );
          }
        }

        if (
          dirtyFields[apiConstants.event_date] ||
          dirtyFields["event_start_date"] ||
          dirtyFields["custom_dates"]
        ) {
          if (watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R) {
            if (watch(apiConstants.recurring_type) === RECURRING_TYPE.R) {
              scheduleDetails["start_date"] = moment(
                getValues(apiConstants.event_start_date)
              ).format("YYYY-MM-DD");
            } else if (
              watch(apiConstants.recurring_type) === RECURRING_TYPE.C
            ) {
              scheduleDetails["dates"] = getValues(
                apiConstants.custom_dates
              ).map((i) => moment(i.start).format("YYYY-MM-DD"));
            }
          } else if (watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O) {
            scheduleDetails["date"] = dateFormatter(
              getValues(apiConstants.event_start_date),
              "YYYY-MM-DD"
            );
          }
        }

        if (
          dirtyFields[apiConstants.event_date] ||
          dirtyFields["event_end_date"] ||
          dirtyFields["custom_dates"]
        ) {
          if (
            watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
            watch(apiConstants.recurring_type) === RECURRING_TYPE.R
          ) {
            scheduleDetails["end_date"] = moment(
              getValues(apiConstants.event_end_date)
            ).format("YYYY-MM-DD");
          }
        }

        if (dirtyFields["event_start_time"]) {
          scheduleDetails["start_time"] =
            moment(getValues(apiConstants.event_start_time)).format(
              "HH:mm:ss.SSSSSS"
            ) || null;
        }
        if (dirtyFields["event_end_time"]) {
          scheduleDetails["end_time"] =
            moment(getValues(apiConstants.event_end_time)).format(
              "HH:mm:ss.SSSSSS"
            ) || null;
        }

        if (
          dirtyFields["totalParticipants"] &&
          getValues(apiConstants.max_participants) > 0
        ) {
          reqData["max_participants"] = data.totalParticipants;
        }
        if (
          dirtyFields["price"] &&
          userType !== USER_TYPE.GUEST &&
          getValues(apiConstants.listing_cost) > 0
        ) {
          reqData["price"] = data.price;
        }

        if (
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.C &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.M
        ) {
          if (watch(apiConstants.recurring_type) === RECURRING_TYPE.R) {
            scheduleDetails["isRecurring"] =
              watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O
                ? false
                : true;
            scheduleDetails["frequency_number"] =
              watch(apiConstants.repeat_every) || null;
            scheduleDetails["frequency"] =
              getValues(apiConstants.recur_frequency) === FREQUENCY_TYPE.D
                ? "D"
                : getValues(apiConstants.recur_frequency) === FREQUENCY_TYPE.M
                ? "M"
                : getValues(apiConstants.recur_frequency) === FREQUENCY_TYPE.W
                ? "W"
                : "";

            scheduleDetails["recurringType"] =
              getValues(apiConstants.recurring_type) === "recurring"
                ? "R"
                : "C";
            scheduleDetails["monthly_frequency"] = /on\s+Day/i.test(
              getValues(apiConstants.monthly_frequency)
            )
              ? "day"
              : "week" || "";
            scheduleDetails["monthly_frequency_desc"] = getValues(
              apiConstants.monthly_frequency
            );
            scheduleDetails["week_days"] = watch(apiConstants.selected_days);
          }
        }
        if (
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.C &&
          getValues(apiConstants.listingDetails)?.type !== LISTING_TYPE.M
        ) {
          reqData["schedule_details"] = scheduleDetails;
        } else if (
          getValues(apiConstants.listingDetails)?.type === LISTING_TYPE.M
        ) {
          let newEvents = getValues(apiConstants.multipleEvents)?.map(
            ({ virtual_links_package, address, ...others }) => {
              if (watch("eventListingType") === "V") {
                return { ...others };
              } else {
                return { ...others, address };
              }
            }
          );
          reqData["schedule_details"] = newEvents;
        } else {
          reqData["schedule_details"] = scheduleDetails;
        }
        if (
          dirtyFields["cancellation_allowed_till_hours"] &&
          getValues(apiConstants.cancellation_allowed_till_hours)
        ) {
          reqData["cancellation_allowed_till_hours"] = getValues(
            apiConstants.cancellation_allowed_till_hours
          );
        }

        reqData["is_cancellation_allowed"] =
          getValues(apiConstants.isCancellationAllowed) || false;

        if (getValues(apiConstants.payment_mode)) {
          if (
            getValues(apiConstants.payment_mode) === "Through Stripe" &&
            getValues(apiConstants.stripe_account_id)
          ) {
            reqData["existing_or_new"] =
              getValues(apiConstants.existing_or_new) || null;
            reqData["stripe_connect_bank"] = getValues(
              apiConstants.stripe_account_id
            );
            reqData["payment_mode"] = getValues(apiConstants.payment_mode);
          } else if (
            getValues(apiConstants.payment_mode) === "Direct Bank Transfer" &&
            getValues(apiConstants.bsb_number)
          ) {
            reqData["payment_mode"] = getValues(apiConstants.payment_mode);
            reqData["direct_bank_account_name"] = getValues(
              apiConstants.bank_name
            );
            reqData["direct_bank_bsb_number"] = getValues(
              apiConstants.bsb_number
            );
            reqData["direct_bank_account_number"] = getValues(
              apiConstants.account_number
            );
            reqData["existing_or_new"] = getValues(
              apiConstants.existing_or_new
            );
          } else {
            reqData["payment_mode"] = getValues(apiConstants.payment_mode);
          }
        }

        if (getValues(apiConstants.listingDetails).id) {
          setLoadingState({ isDraft, isCreate: !isDraft });
          setIsDisable(true);
          makePatchCall({
            url: `${endpoints.listing}?listing_id=${
              getValues(apiConstants.listingDetails).id
            }`,
            apiPayload: reqData,
            content_type: "application/json",
          })
            .then((res) => {
              setShowDraftPopUp(false);
              if (res?.status.code === 200) {
                showToast(res.status.message, "success", "resSuccess");
                handleListingType({});
              } else {
                showToast(res.status.message, "error", "resErr");
              }
              setLoadingState({ isDraft: false, isCreate: false });
              setIsCreateListingLoading(false);
              setIsLoadingDraft(false);
              setIsDraft(false);
              setIsDisable(false);
              setImages([]);
              if (!isDraft) {
                Navigate("/view-listing");
              } else {
                Navigate("/drafts");
              }
            })
            .catch((err) => {
              setLoadingState({ isDraft: false, isCreate: false });
              setIsCreateListingLoading(false);
              setIsLoadingDraft(false);
              setShowDraftPopUp(false);
              setIsDisable(false);
              showToast(err?.status?.message, "error", "resSuccess1");
            });
        } else {
          handleSetValue(apiConstants.isDraft, false);
          setLoadingState({ isDraft, isCreate: !isDraft });
          setIsDisable(true);
          // alert('hi');
          makePostCall({
            url: endpoints.listing,
            apiPayload: reqData,
            content_type: "application/json",
          })
            .then((res) => {
              setShowDraftPopUp(false);
              if (res?.status.code === 200) {
                showToast(res.status.message, "success", "resSuccess2");
                handleListingType({});
              } else {
                showToast(res?.status?.message, "error", "resErr2");
              }
              setLoadingState({ isDraft: false, isCreate: false });
              setIsCreateListingLoading(false);
              setIsLoadingDraft(false);
              setIsDraft(false);
              setIsDisable(false);
              setImages([]);
              if (!isDraft) {
                Navigate("/view-listing");
              } else {
                Navigate("/drafts");
              }
            })
            .catch((err) => {
              setShowDraftPopUp(false);
              setLoadingState({ isDraft: false, isCreate: false });
              setIsCreateListingLoading(false);
              setIsLoadingDraft(false);
              setIsDisable(false);
              showToast(err?.status?.message, "error");
            });
        }
      }
    })();
  };

  const handleListingType = (item: any) => {
    handleSetValue(apiConstants.listingDetails, item);
    if (item?.hasDraft) {
      handleGetDraftData(item);
    } else {
      reset({
        ...ListingFormDefaultValue,
        [apiConstants.listingDetails]: item,
      });
      handleSetValue(apiConstants.user_type, userType);
    }
  };

  const handleInstructions = (inst: any) => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    tempList.push(inst);
    handleSetValue(apiConstants.selectedInstruction, [...tempList]);
    setValue(apiConstants.eventInstruction, "");
  };
  const handleRemoveInstructions = (indexToRemove: any) => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    tempList = tempList.filter((item, index) => index !== indexToRemove);
    handleSetValue(apiConstants.selectedInstruction, [...tempList]);
    setValue(apiConstants.eventInstruction, "");
  };

  const handleConnectStripe = () => {
    makePostCall({ url: endpoints.connect_stripe })
      .then((res) => {
        if (res?.status.code === 200) {
          const newWindow = window.open(res?.data, "_blank", "rel=noopener ");
          newWindow["closeWindow"] = newWindow;
          showToast(res.status.message, "success");
        }
      })
      .catch((err) => {
        showToast(err?.status?.message, "error", "resErr3");
      });
  };

  const handleGetDraftData = (item: any) => {
    if (item.id) {
      const params = { id: item.id };
      makeGetCall({ url: endpoints.listing, params })
        .then((res) => {
          const temp: any = {};
          temp[apiConstants.is_listing_private] = res.data.isListingPrivate;
          temp[apiConstants.listing_event_type] = res.data.listingEventType;
          temp[apiConstants.existing_or_new] = res.data.existingOrNew;
          temp["listingType"] = getValues(apiConstants.listingDetails)?.type;
          temp[apiConstants.listingDetails] = res.data;
          temp[apiConstants.isDraft] = res.data?.isDraft;
          temp[apiConstants.listing_name] = res.data?.eventDetails?.title;
          temp[apiConstants.listing_description] =
            res.data?.eventDetails?.description;
          temp[apiConstants.selectedInterest] =
            res.data?.eventDetails?.interests;
          temp[apiConstants.listing_location] =
            res.data?.eventDetails?.location;
          temp[apiConstants.max_participants] =
            item.type !== LISTING_TYPE.C
              ? res?.data.schedulePaymentDetails.totalParticipants
              : res.data?.clubScheduleDetails?.slot;
          temp[apiConstants.lat] = null;
          temp[apiConstants.lng] = null;
          temp[apiConstants.city] = res.data?.eventDetails?.city;
          temp[apiConstants.state] = res.data?.eventDetails?.state;
          temp[apiConstants.country] = res.data?.eventDetails?.country;
          temp[apiConstants.postCode] = res.data?.eventDetails?.postCode;
          temp[apiConstants.gender_preference] = res.data?.eventDetails?.gender;
          temp[apiConstants.difficulty_level] =
            res.data?.eventDetails?.difficultyLevel;

          temp[apiConstants.listing_cost] =
            item.type !== LISTING_TYPE.C
              ? res.data?.schedulePaymentDetails?.price
              : res.data?.clubScheduleDetails?.monthlyCost;
          temp[apiConstants.upload_image] = res.data?.eventDetails?.image;
          setImages(res.data?.documents || []);
          temp[apiConstants.address] = res.data?.eventDetails?.address;
          temp[apiConstants.image] = res.data?.eventDetails?.image;
          temp[apiConstants.min_age] = res.data?.eventDetails?.minAge;
          temp[apiConstants.max_age] = res.data?.eventDetails?.maxAge;
          temp[apiConstants.is_specific_age] =
            res.data?.eventDetails?.minAge || res.data?.eventDetails?.maxAge
              ? true
              : false;

          if (item.type !== LISTING_TYPE.C) {
            temp[apiConstants.has_schedule_date] =
              handleShowScheduleDate() !== null || undefined ? true : false;
            temp[apiConstants.event_date] =
              res.data?.scheduleDetails.startDate !== null
                ? moment(res.data?.schedulePaymentDetails?.startDate).isBefore(
                    moment()
                  )
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.startDate)
                : "";
            temp[apiConstants.event_start_date] =
              res.data?.scheduleDetails.startDate !== null
                ? moment(res.data?.schedulePaymentDetails?.startDate).isBefore(
                    moment()
                  )
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.startDate)
                : undefined;
            temp[apiConstants.event_end_date] =
              res.data?.scheduleDetails.endDate !== null
                ? moment(res.data?.scheduleDetails?.endDate).isBefore(moment())
                  ? new Date()
                  : new Date(res.data?.scheduleDetails?.endDate)
                : undefined;

            temp[apiConstants.recurring_type] =
              res.data?.schedulePaymentDetails?.recurringType === "C"
                ? RECURRING_TYPE.C
                : RECURRING_TYPE.R;

            if (
              res.data?.scheduleDetails?.recurringType === "R" &&
              res.data?.scheduleDetails?.isRecurring
            ) {
              temp[apiConstants.repeat_every] =
                res.data?.scheduleDetails?.frequencyNumber;
              temp[apiConstants.selected_days] =
                res.data?.scheduleDetails?.weekDays;
              temp[apiConstants.monthly_frequency] =
                res.data?.scheduleDetails?.monthlyFrequency;
              temp[apiConstants.recur_frequency] =
                res.data?.scheduleDetails?.frequency === "D"
                  ? FREQUENCY_TYPE.D
                  : res.data?.scheduleDetails?.frequency === "M"
                  ? FREQUENCY_TYPE.M
                  : FREQUENCY_TYPE.W;
            }

            temp[apiConstants.event_end_time] = res.data?.scheduleDetails
              ?.endTime
              ? new Date(
                  res.data?.scheduleDetails?.startDate +
                    " " +
                    res.data?.scheduleDetails?.endTime
                )
              : "";

            temp[apiConstants.custom_dates] =
              res.data?.scheduleDetails?.customDates;

            if (res.data?.scheduleDetails?.customDates) {
              temp[apiConstants.event_custom_date] =
                res.data?.scheduleDetails?.customDates.map((item: any) => {
                  return new DateObject()
                    .setDay(Number(dateFormatter(item.start, "DD")))
                    .add(
                      !moment(item.start).isSame(moment().toDate(), "month")
                        ? moment(item.start).diff(moment(), "months") + 1
                        : 0,
                      "month"
                    );
                });
            }
            temp[apiConstants.selected_month_dates] = {};
            temp[apiConstants.schedule_type] = res.data?.scheduleDetails
              ?.isRecurring
              ? SCHEDULE_TYPE.R
              : SCHEDULE_TYPE.O;

            temp[apiConstants.on_the_basis] = "";

            temp[apiConstants.event_start_time] = res.data?.scheduleDetails
              ?.startTime
              ? new Date(
                  res.data?.scheduleDetails.startDate +
                    " " +
                    res.data?.scheduleDetails.startTime
                )
              : "";
          }

          reset(temp);
        })
        .catch((err) => showToast(err, "error"));
    }
  };

  const handleImageUpload = (e: any, isCropped = false) => {
    const file = isCropped ? e : e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        showToast(
          `The file format .${fileExtension} is not allowed.`,
          "error"
          // "filetypeError"
        );
        return;
      } else {
        handleSetValue(apiConstants.image, file);
        const fileSize = Number(convertBytesToMB(file.size));
        if (fileSize <= 100) {
          if (watch(apiConstants.image)?.name) {
            handleSetValue("image_type", file.type.split("/")[0] || "image");
            if (file.type.split("/")[1] === "heic") {
            }
            handleSetValue(
              apiConstants.upload_image,
              URL.createObjectURL(watch(apiConstants.image))
            );
          } else {
            if (watch(apiConstants.image)) {
              if (
                ["mp4", "mov", "avi"].includes(
                  watch(apiConstants.image).split(".").splice(-1).toLowerCase()
                )
              ) {
                handleSetValue("image_type", "video");
              } else {
                handleSetValue("image_type", "image");
              }
              handleSetValue(
                apiConstants.upload_image,
                watch(apiConstants.image)
              );
            }
          }
        } else {
          showToast(
            "photo/video should be less than 100mb",
            "error",
            "photoerr"
          );
        }
      }
    }
  };

  const handleShowScheduleDate = () => {
    if (
      watch(apiConstants.event_start_time) &&
      watch(apiConstants.event_end_time)
    ) {
      if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O &&
        watch(apiConstants.event_start_date)
      ) {
        return (
          <>
            <p>
              {dateFormatter(
                watch(apiConstants.event_start_date),
                "MMMM Do, YYYY"
              )}
            </p>
            <small>
              {`${moment(
                getValues(apiConstants.event_start_time),
                "HH:mm"
              ).format("hh:mm A")} - ${moment(
                getValues(apiConstants.event_end_time),
                "HH:mm"
              ).format("hh:mm A")}`}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.C &&
        watch(apiConstants.event_custom_date)
      ) {
        return (
          <>
            <p>
              {getValues(apiConstants.event_custom_date)?.length > 1
                ? `${
                    getValues(apiConstants.event_custom_date)?.length
                  } days selected`
                : getValues(apiConstants.event_custom_date)?.length === 1
                ? `${
                    getValues(apiConstants.event_custom_date)?.length
                  } day selected`
                : ""}
            </p>
            <small>
              {dateFormatter(
                getValues(apiConstants.event_start_time),
                "hh:mm A"
              )}{" "}
              -
              {dateFormatter(getValues(apiConstants.event_end_time), "hh:mm A")}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.R &&
        watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.W &&
        watch(apiConstants.event_start_date) &&
        watch(apiConstants.event_end_date) &&
        watch(apiConstants.recur_frequency) &&
        watch(apiConstants.selected_days) &&
        watch(apiConstants.repeat_every)
      ) {
        return (
          <>
            <p>
              Every {getValues(apiConstants.repeat_every)}{" "}
              {getValues(apiConstants.recur_frequency)}{" "}
            </p>
            <small>
              {watch(apiConstants.selected_days)?.length > 0
                ? watch(apiConstants.selected_days)?.map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {" "}
                          {item.slice(0, 3) + " "}
                        </Fragment>
                      );
                    }
                  )
                : null}
              {dateFormatter(
                getValues(apiConstants.event_start_time),
                "hh:mm A"
              )}{" "}
              -
              {dateFormatter(getValues(apiConstants.event_end_time), "hh:mm A")}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.R &&
        watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.D &&
        watch(apiConstants.event_start_date) &&
        watch(apiConstants.event_end_date) &&
        watch(apiConstants.recur_frequency) &&
        watch(apiConstants.selected_days) &&
        watch(apiConstants.repeat_every)
      ) {
        return (
          <>
            <p>
              Every {getValues(apiConstants.repeat_every)}{" "}
              {getValues(apiConstants.recur_frequency)}{" "}
            </p>
            <small>
              {watch(apiConstants.selected_days)?.length > 0
                ? watch(apiConstants.selected_days)?.map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {" "}
                          {item.slice(0, 3) + " "}
                        </Fragment>
                      );
                    }
                  )
                : null}
              {dateFormatter(
                getValues(apiConstants.event_start_time),
                "hh:mm A"
              )}{" "}
              -
              {dateFormatter(getValues(apiConstants.event_end_time), "hh:mm A")}
            </small>
          </>
        );
      } else if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R &&
        watch(apiConstants.recurring_type) === RECURRING_TYPE.R &&
        watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.M &&
        watch(apiConstants.event_start_date) &&
        watch(apiConstants.event_end_date) &&
        watch(apiConstants.monthly_frequency) &&
        watch(apiConstants.repeat_every)
      ) {
        return (
          <>
            <p>
              Every {getValues(apiConstants.repeat_every)}{" "}
              {getValues(apiConstants.recur_frequency)}{" "}
              {getValues(apiConstants.monthly_frequency)
                ?.split(" ")
                ?.splice(1)
                ?.join(" ")}
            </p>
            <small>
              {dateFormatter(
                getValues(apiConstants.event_start_time),
                "hh:mm A"
              )}{" "}
              -
              {dateFormatter(getValues(apiConstants.event_end_time), "hh:mm A")}
            </small>
          </>
        );
      } else {
        return null;
      }
    } else {
      return (
        <p style={{ color: "#8F939A" }}>
          {STRING_CONSTANTS.schedule_date_placeholder}
        </p>
      );
    }
  };

  const handleResetListing = () => {
    reset({
      ...ListingFormDefaultValue,
      [apiConstants.listingDetails]: getValues(apiConstants.listingDetails),
      [apiConstants.max_participants]: 0,
      [apiConstants.listing_cost]: 0,
    });
    setShowDraftPopUp(false);
    setImages([]);
  };

  useOutsideClick(ref, () => {
    if (searchTerm) {
      setSearchTerm("");
    }
  });

  useOutsideClick(refInfo, () => {
    setIsListingInfo(false);
    setPaymentInfo(false);
    setIsisBookingInfo(false);
    setIsAddEventInfo(false);
  });

  const tempObj = {
    id: new Date().getTime(),
    title: watch(apiConstants?.event_title),
    address: {
      location: `POINT (${watch(apiConstants.lng)} ${watch(apiConstants.lat)})`,
      address: watch(apiConstants?.address),
      city: watch(apiConstants?.city),
      state: watch(apiConstants?.state),
      country: watch(apiConstants?.country),
      post_code: watch(apiConstants?.postCode),
    },
    date: dateFormatter(getValues(apiConstants.event_start_date), "YYYY-MM-DD"),
    start_time:
      moment(watch(apiConstants.event_start_time)).format("HH:mm:ss.SSSSSS") ||
      null,
    end_time:
      moment(watch(apiConstants.event_end_time)).format("HH:mm:ss.SSSSSS") ||
      null,
    virtual_links_package: watch(apiConstants?.virtual_links_package),
  };

  const onDeleteEvent = (index) =>
    setValue(
      apiConstants.multipleEvents,
      getValues(apiConstants.multipleEvents).filter((_, idx) => idx !== index)
    );

  function isItInt(n) {
    return Number(n) % 1 === 0;
  }

  function calculatePercentageOfNumber(percentage: any, number: any) {
    const value = (percentage / 100) * number;

    const stringValue = value.toString();
    return Number(parseFloat(stringValue).toFixed(2));
  }

  const handleUploadFiles = async (files = []) => {
    let finalMedia = [];

    for (const val of files) {
      const res = await makeGetCall({
        url: `${endpoints.signed_url}/${val.file.name}`,
      });

      if (res.status.code === 200) {
        const {
          AWSAccessKeyId = "",
          key = "",
          policy = "",
          signature = "",
        } = res?.data?.fields;
        const uploadedResponse = await handleFileUpload(
          val.file,
          res?.data?.url,
          key,
          AWSAccessKeyId,
          policy,
          signature
        );
        finalMedia.push(uploadedResponse);
      }
    }

    return finalMedia;
  };

  const handleMediaFIles = (media: any) => {
    setValue(apiConstants.media, media);
  };

  useEffect(() => {
    if (watch(apiConstants.image)) {
      delete errors["image"];
    }
    if (watch(apiConstants.selectedInterest)) {
      delete errors["selectedInterest"];
    }
    if (watch(apiConstants.selectedInstruction)) {
      delete errors["selectedInstruction"];
    }
    if (
      watch(apiConstants.selectedGender) &&
      watch(apiConstants.gender_preference) !== GENDER_PREFERENCE.A
    ) {
      delete errors["selectedGender"];
    }
    if (watch(apiConstants.selecteddifficulty)) {
      delete errors["selecteddifficulty"];
    }
  }, [
    watch(apiConstants.image),
    watch(apiConstants.selectedInterest),
    watch(apiConstants.selectedGender),
    watch(apiConstants.selecteddifficulty),
    watch(apiConstants.gender_preference),
    watch(apiConstants.selectedInstruction),
  ]);

  const getStripeInfo = () => {
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
          };
          setLocalStorage("userProfile", data);
          setCurrUserInfo(data);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const selectedPreferences = watch("gender_preference");
  const difficultyPreferences = watch("difficulty_level");

  const handleGender = (gender: any) => {
    let tempList = watch(apiConstants.selectedGender) || [];

    let index = tempList.findIndex((gen: any) => gen === gender);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(gender);
    }

    handleSetValue(apiConstants.selectedGender, [...tempList]);
  };
  const handleDifficulty = (level: any) => {
    const tempList = watch(apiConstants.selecteddifficulty) || [];
    let index = tempList.findIndex((lev: any) => lev === level);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(level);
    }
    handleSetValue(apiConstants.selecteddifficulty, [...tempList]);
  };

  useEffect(() => {
    makeGetCall({ url: endpoints.commission_amounts })
      .then((res) => {
        if (res.status.code === 200) {
          setCommisionAmounts(res.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  useEffect(() => {
    handleImageUpload(cropedImage, true);
  }, [cropedImage]);

  const handleEventEditClick = (i) => {
    setEditEvent(true);
    setEditEventId(i.id);
    setValue(apiConstants.event_title, i?.title);
    setValue(apiConstants.city, i?.address?.city);
    setValue(apiConstants.state, i?.address?.state);
    setValue(apiConstants.country, i?.address?.country);
    setValue(apiConstants.postCode, i?.address?.postCode);
    setValue(apiConstants.address, i?.address?.address);
    setValue(apiConstants.listing_location, i?.address?.location);
    setValue(apiConstants.event_start_date, i.date);
    setValue(apiConstants.virtual_links_package, i.virtual_links_package);
    setValue(
      apiConstants.event_start_time,
      new Date(i?.date + " " + i?.start_time)
    );
    setValue(
      apiConstants.event_end_time,
      new Date(i?.date + " " + i?.end_time)
    );

    setShowEventListingsSidebar(false);
    setShowAddEventSidebar(true);
  };
  const validateMeetingLink = (link) => {
    if (
      meetingLinkRegex?.googleMeetRegex.test(link) ||
      meetingLinkRegex?.zoomRegex.test(link) ||
      meetingLinkRegex?.teamsRegex.test(link)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdateOrSaveEvent = () => {
    let meetingError = false;
    if (
      watch(apiConstants.listing_event_type) === "V" &&
      watch(apiConstants.multiple_virtual_links_allowed) === false &&
      watch(apiConstants.virtual_links_package)
    ) {
      const data = validateMeetingLink(
        watch(apiConstants.virtual_links_package)
      );
      if (!data) {
        meetingError = true;
      }
    }
    //
    if (!watch(apiConstants.event_title)) {
      showToast("Please enter event title", "error");
    } else if (
      watch(apiConstants.listing_event_type) === "P" &&
      !watch(apiConstants.address)
    ) {
      showToast("Please enter event location", "error");
    } else if (
      watch(apiConstants.listing_event_type) === "V" &&
      watch(apiConstants.multiple_virtual_links_allowed) === false &&
      !watch(apiConstants.virtual_links_package)
    ) {
      showToast("Please enter virtual event meeting link", "error");
    } else if (meetingError) {
      showToast("Please enter valid meeting link", "error");
    } else if (
      !watch(apiConstants.event_start_time) ||
      !watch(apiConstants.event_end_time)
    ) {
      showToast("Please enter schedule dates", "error");
    } else {
      setShowAddEventSidebar(false);
      setShowEventPopup(true);
      // setAddEventOpen(true);
    }
  };

  return (
    <>
      <GlobalHeader2 />
      {isPaymentOpen ? (
        <PaymentOption
          setIsPaymentOpen={setIsPaymentOpen}
          isRecommend={isRecommend}
          setIsRecommend={setIsRecommend}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          setValue={setValue}
          control={control}
          errors={errors}
          handleConnectStripe={handleConnectStripe}
        />
      ) : (
        <div className="user-profile create_listing create_listing_main">
          {isCreateListingLoading ? (
            <ApiLoaderListing isLoadingDraft={isLoadingDraft} />
          ) : (
            ""
          )}
          <div
            className="user-white-part-width"
            style={{
              marginTop: isMobile && showGuestLstingInfo ? "80px" : "auto",
              padding: 0,
            }}
          >
            <div
              className="user-white-part-large listing_types"
              style={{ position: "relative", paddingTop: "12px" }}
            >
              {!watch(apiConstants.listingDetails)?.type ? (
                <>
                  <div
                    className="listing-type listing-type-mobile"
                    style={{ paddingTop: "5px" }}
                  >
                    <img
                      src="/static/leftarrow.svg"
                      alt=""
                      className="back_arrow"
                      onClick={() => Navigate(-1)}
                      style={{ marginTop: "0" }}
                    />
                    <h6 style={{ marginTop: "10px" }}>
                      {STRING_CONSTANTS.select_listing_type}
                    </h6>
                    <ul style={{ marginTop: "20px" }}>
                      {listingTypes.map((item, index) => {
                        return (
                          <ListingCard
                            item={item}
                            key={index}
                            onClick={() => handleListingType(item)}
                          />
                        );
                      })}
                    </ul>
                    <div className="or-divider-wrap">
                      <div className="divide-line" />
                      <div> OR</div>
                      <div className="divide-line" />
                    </div>
                    <button
                      className={
                        draftList.length > 0
                          ? "backtologin"
                          : "backtologin disabled"
                      }
                      style={{
                        color: draftList.length > 0 ? "#fff" : "#25272d",
                        background:
                          draftList.length > 0
                            ? "linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%)"
                            : "",
                      }}
                      onClick={() => Navigate("/drafts")}
                    >
                      Continue from draft
                    </button>
                  </div>
                </>
              ) : (
                <div className="lsiting-detials">
                  {userType === USER_TYPE.GUEST && showGuestLstingInfo && (
                    <div className="infoguestlisting">
                      <div className="dl-email-verified_listing">
                        <div>
                          <ErrorInfo />
                        </div>
                        <div>
                          <p className=" dl-title mb-0">
                            Being a guest user, you can only create 1 listing
                            per week.
                          </p>
                          <p
                            className="dl-subtitle mb-0 cursor-pointer"
                            onClick={() =>
                              Navigate("/subscription?SubscriptionType=true")
                            }
                          >
                            Click here to upgrade as a member.
                          </p>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setShowGuestLstingInfo(false);
                          }}
                        >
                          <CrossIcon width="16" height="16" fill="white" />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-between d-md-none mb-1">
                    <img
                      style={{ cursor: "pointer" }}
                      src="/static/leftarrow.svg"
                      alt=""
                      className=""
                      onClick={() => {
                        if (Object.keys(dirtyFields).length > 1) {
                          setShowDraftPopUp(true);
                        } else {
                          setShowDraftPopUp(false);
                          handleListingType({});
                        }
                      }}
                    />
                    <div
                      className=""
                      style={{
                        cursor:
                          Object.keys(dirtyFields).length < 2
                            ? "pointer"
                            : "none",
                      }}
                    >
                      <span
                        onClick={() => handleResetListing()}
                        className=""
                        style={{ color: "#ee7830", paddingRight: "5px" }}
                      >
                        Reset Listing
                      </span>
                      <img
                        onClick={() => {
                          setIsDraft(true);
                          handleSetValue(apiConstants.isDraft, true);
                          setTimeout(() => {
                            handleNext({ isDraft: true });
                          }, 500);
                        }}
                        alt=""
                        src={
                          Object.keys(dirtyFields).length < 2
                            ? "/static/saveResDraftGray.svg"
                            : "/static/saveResDraft.svg"
                        }
                      />
                    </div>
                  </div>

                  <img
                    src="/static/leftarrow.svg"
                    alt=""
                    className="back_arrow d-none d-md-block"
                    onClick={() => {
                      if (Object.keys(dirtyFields).length > 1) {
                        setShowDraftPopUp(true);
                      } else {
                        setShowDraftPopUp(false);
                        handleListingType({});
                      }
                    }}
                  />
                  <div className="listing_heading_main">
                    {watch(apiConstants.listingDetails)?.type === LISTING_TYPE.M
                      ? "Create a package"
                      : watch(apiConstants.listingDetails)?.type ===
                        LISTING_TYPE.C
                      ? "Create a team"
                      : "Create a single session"}
                    <span
                      onClick={() => handleResetListing()}
                      className="reset-button d-none d-md-inline"
                      style={{ cursor: "pointer" }}
                    >
                      Reset Listing
                    </span>
                  </div>

                  <FilesUploadComponent
                    handleMediaFIles={handleMediaFIles}
                    images={images}
                    setImages={setImages}
                  />
                  {watch(apiConstants.media).length < 1 && errors?.media ? (
                    <div className="mb-4">
                      <span className="text-danger">{`${errors?.media?.message}`}</span>
                    </div>
                  ) : null}

                  <div className="main-heaidng">
                    <h6>
                      {watch(apiConstants.listingDetails)?.type ===
                      LISTING_TYPE.S
                        ? STRING_CONSTANTS.listing_details
                        : watch(apiConstants.listingDetails)?.type ===
                          LISTING_TYPE.M
                        ? STRING_CONSTANTS.multiple_details
                        : STRING_CONSTANTS.team_details}
                    </h6>
                    <img
                      src="/static/info.svg"
                      alt=""
                      onClick={() => setIsListingInfo(!isListingInfo)}
                    />
                  </div>
                  {/* <Button
                    btnImg="/static/savebtn.svg"
                    btnTxt="Save to draft"
                    className="draft_btn d-none d-md-block"
                    isLoading={loadingState.isDraft}
                    onClick={() => {
                      setIsDraft(true);
                      handleSetValue(apiConstants.isDraft, true);
                      setTimeout(() => {
                        handleNext({ isDraft: true });
                      }, 500);
                    }}
                    disabled={isDisable}
                  /> */}
                  <InputField
                    type={"text"}
                    name={apiConstants.listing_name}
                    inputLabel={"Listing Name"}
                    placeholder={"Name your listing"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                  />
                  <InputField
                    isListingTextArea={true}
                    type={"textarea"}
                    name={apiConstants.listing_description}
                    inputLabel={
                      watch(apiConstants.listingDetails)?.type ===
                      LISTING_TYPE.C
                        ? "Team Description & Benefits"
                        : "Listing Description"
                    }
                    className={"listing_desc"}
                    placeholder={"Tell others more about this listing"}
                    control={control}
                    maxlength={"300"}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                  />
                  {isListingInfo && (
                    <div className="info_box" ref={refInfo}>
                      <img src={"/static/greyinfo.svg"} alt="" />{" "}
                      {STRING_CONSTANTS.listing_info}
                    </div>
                  )}
                  <div
                    className="wth-dorpwodn"
                    ref={ref}
                    style={{ marginTop: isListingInfo ? "64px" : "auto" }}
                  >
                    <div className="form_group with-button">
                      <div className="search-interest input_box">
                        <span className="input_label">Interest Category</span>

                        <input
                          type="text"
                          value={searchTerm}
                          placeholder="Search for an interest"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                        <img
                          src="/static/cross-circle-gray.svg"
                          alt=""
                          onClick={() => setSearchTerm("")}
                          className={"corss-icon"}
                        />
                      </div>

                      <div
                        className="filter-outer"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={
                            filter
                              ? "/static/filterbtnselect.svg"
                              : "/static/filterbtnunselect.svg"
                          }
                          alt=""
                          className="filter-icon"
                          onClick={() => setShowFilter(!showFilter)}
                        />
                        {showFilter ? (
                          <ul className="filter-dropdown">
                            {filterOption.map((item, index) => {
                              return (
                                <li
                                  className={
                                    filter === item.value ? "active" : ""
                                  }
                                  key={index}
                                  onClick={() => {
                                    setFilter(
                                      filter === item.value ? "" : item.value
                                    );
                                    setShowFilter(!showFilter);
                                  }}
                                >
                                  <div>
                                    {filter === item.value ? (
                                      <img src="/static/victory.svg" alt="" />
                                    ) : (
                                      <img
                                        src="/static/recently-added.svg"
                                        alt=""
                                      />
                                    )}
                                    <span>{item.label}</span>
                                    {filter === item.value ? (
                                      <img
                                        src={"/static/orange-radio-btn.svg"}
                                        alt=""
                                        className="select_img"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                    {errors?.selectedInterest?.message && (
                      <span className="text-danger interest-error">
                        {STRING_CONSTANTS.please_add_interest}
                      </span>
                    )}

                    {watch(apiConstants.selectedInterest)?.length > 0 && (
                      <ul className="selected_title">
                        {watch(apiConstants.selectedInterest).map(
                          (item: any, index: number) => {
                            return index < 3 ? (
                              <li key={index}>
                                {item.title}{" "}
                                <img
                                  src={"static/cross-circle-gray-dark.svg"}
                                  alt=""
                                  onClick={() => {
                                    handleIntrest(item);
                                  }}
                                />{" "}
                              </li>
                            ) : isLimitOnInterest ? (
                              <li key={index}>
                                {item.title}{" "}
                                <img
                                  src={"static/cross-circle-gray-dark.svg"}
                                  alt=""
                                  onClick={() => {
                                    handleIntrest(item);
                                  }}
                                />
                              </li>
                            ) : null;
                          }
                        )}

                        {watch(apiConstants.selectedInterest)?.length > 3 &&
                          !isLimitOnInterest && (
                            <span
                              className="more cursor-pointer"
                              onClick={() => setIsLimitOnInterest(true)}
                            >
                              +{" "}
                              {watch(apiConstants.selectedInterest)?.length -
                                3 +
                                " " +
                                "more"}
                            </span>
                          )}
                      </ul>
                    )}
                    {!isEmptyOrNullOrUndefined(searchTerm) ? (
                      <div
                        className="data-setup"
                        style={{
                          maxHeight: "500px",
                          overflowY: "scroll",
                          paddingRight: "6px",
                        }}
                        id="scrollableDiv"
                      >
                        {isLoading ? (
                          <ApiLoader />
                        ) : intrestList?.length > 0 ? (
                          isLoading ? (
                            <ApiLoader />
                          ) : (
                            <InfiniteScroll
                              dataLength={intrestList?.length}
                              next={() =>
                                fetchMoreIntrestList(
                                  nextUrl,
                                  intrestList,
                                  setNextUrl,
                                  setIntrestList,
                                  setIsLoading
                                )
                              }
                              hasMore={nextUrl ? true : false}
                              loader={<ApiLoader />}
                              scrollableTarget="scrollableDiv"
                            >
                              <ul>
                                {intrestList?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          handleIntrest(item);
                                        }}
                                        className={
                                          watch(
                                            apiConstants.selectedInterest
                                          )?.some(
                                            (intr: any) => intr.id === item.id
                                          )
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <img src={item?.image} alt="" />

                                        <p>
                                          {item.title}{" "}
                                          {watch(
                                            apiConstants.selectedInterest
                                          )?.some(
                                            (intr: any) => intr.id === item.id
                                          ) ? (
                                            <img
                                              className="tick"
                                              src="/static/checked.svg"
                                              alt=""
                                            />
                                          ) : (
                                            ""
                                          )}{" "}
                                        </p>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </InfiniteScroll>
                          )
                        ) : (
                          <div className="no_data">
                            <img src="/static/Search.svg" alt="" />
                            <h3>{STRING_CONSTANTS.no_result_found}</h3>
                            <p>{STRING_CONSTANTS.please_try_another_term}</p>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="listing_type_container">
                    <div className="listing_type_heading">Listing Type</div>
                    <div>
                      <div className="listing_type_radio">
                        <img
                          src={
                            watch("eventListingType") === "P"
                              ? "/static/radiocheck.svg"
                              : "/static/radiouncheck.svg"
                          }
                          alt=""
                          onClick={() =>
                            handleSetValue("eventListingType", "P")
                          }
                        />
                        <label
                          className={
                            watch("eventListingType") === "P"
                              ? "listing_ype_label active"
                              : "listing_ype_label"
                          }
                          onClick={() =>
                            handleSetValue("eventListingType", "P")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Physical
                        </label>
                        <img
                          src={
                            "/static/createListingIcon/physicalListingType.svg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="listing_type_radio">
                        <img
                          src={
                            watch("eventListingType") === "V"
                              ? "/static/radiocheck.svg"
                              : "/static/radiouncheck.svg"
                          }
                          alt=""
                          onClick={() =>
                            handleSetValue("eventListingType", "V")
                          }
                        />
                        <label
                          className={
                            watch("eventListingType") === "V"
                              ? "listing_ype_label active"
                              : "listing_ype_label"
                          }
                          onClick={() =>
                            handleSetValue("eventListingType", "V")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Virtual
                        </label>
                        <img
                          src={
                            "/static/createListingIcon/virtualListingType.svg"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  {watch(apiConstants.listing_event_type) === "V" && (
                    <MeetingLink
                      watch={watch}
                      handleSetValue={handleSetValue}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      isTeam={watch(apiConstants.listingDetails)?.type === "C"}
                    />
                  )}
                  <div className="main-had mt-3">
                    <h3 className="headis">
                      {STRING_CONSTANTS.special_instructions}
                    </h3>
                    <div
                      className="add-upto"
                      onClick={() => {
                        if (
                          getValues(apiConstants.eventInstruction)?.length !==
                            0 &&
                          watch(apiConstants.selectedInstruction)?.length < 5
                        ) {
                          handleInstructions(
                            getValues(apiConstants.eventInstruction)
                          );
                        }
                      }}
                    >
                      <h3
                        className={`${
                          watch(apiConstants.selectedInstruction)?.length >= 5
                            ? "headis-grey"
                            : "headis-orange"
                        }`}
                      >
                        Add upto 5
                      </h3>
                      {watch(apiConstants.selectedInstruction)?.length >= 5 ? (
                        <img src="/static/grey_plus.png" alt="" />
                      ) : (
                        <img src="/static/orange_plus.png" alt="" />
                      )}
                    </div>
                  </div>

                  <InputField
                    isSpecialInstruction={true}
                    type={"textarea"}
                    name={apiConstants.eventInstruction}
                    className={"input-special"}
                    placeholder={"Type event instructions here"}
                    control={control}
                    value={watch(apiConstants.eventInstruction)}
                    getValues={getValues}
                    errors={errors}
                    maxlength={"50"}
                    setValue={setValue}
                    disabled={
                      watch(apiConstants.selectedInstruction)?.length >= 5 ||
                      false
                    }
                  />

                  {watch(apiConstants.selectedInstruction)?.length > 0 && (
                    <div className="instructions-wrapper">
                      {watch(apiConstants.selectedInstruction).map(
                        (item: any, index: number) => {
                          return (
                            <div key={index} className="instruction ">
                              {item}{" "}
                              <img
                                src={"static/cross-circle-gray-dark.svg"}
                                alt=""
                                onClick={() => {
                                  handleRemoveInstructions(index);
                                }}
                              />{" "}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}

                  {watch(apiConstants.listingDetails)?.type ===
                  LISTING_TYPE.M ? (
                    <>
                      <div className="main-had" style={{ marginTop: "24px" }}>
                        <h3 className="headis">Add Event</h3>
                        <img
                          src="/static/info.svg"
                          alt=""
                          onClick={() => setIsAddEventInfo(!isAddEventInfo)}
                        />
                      </div>

                      {getValues(apiConstants.multipleEvents)?.length > 0 && (
                        <>
                          <EventCardSection
                            data={getValues(apiConstants.multipleEvents)?.slice(
                              0,
                              2
                            )}
                            onDeleteEvent={onDeleteEvent}
                            handleEventEditClick={handleEventEditClick}
                            setShowEventListingsSidebar={
                              setShowEventListingsSidebar
                            }
                            totalData={
                              getValues(apiConstants.multipleEvents)?.length
                            }
                            watch={watch}
                          />
                        </>
                      )}

                      <div>
                        <OutlineYellowButton
                          style={{
                            border: "1px solid #8f939a",
                          }}
                          text="Add Event"
                          icon={<AddIconYellow />}
                          onClick={() => {
                            setShowEventListingsSidebar(false);
                            setShowAddEventSidebar(true);
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                  {isAddEventInfo && (
                    <div style={{ marginTop: "36px" }} ref={refInfo}>
                      <div className="info_box">
                        <img src={"/static/greyinfo.svg"} alt="" />{" "}
                        {STRING_CONSTANTS.listing_info}
                      </div>
                    </div>
                  )}

                  {watch("eventListingType") === "P" && (
                    <LocationSection
                      watch={watch}
                      setIsLocationAccessible={setIsLocationAccessible}
                      setShowLocationPopup={setShowLocationPopup}
                      isLocationAccessible={isLocationAccessible}
                      handleLocation={handleLocation}
                      handleSetValue={handleSetValue}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                    />
                  )}

                  <div className="main-had" style={{ marginTop: "28px" }}>
                    <h3 className="headis">
                      {userType !== USER_TYPE.GUEST &&
                      watch(apiConstants.listingDetails)?.type !==
                        LISTING_TYPE.C
                        ? "Payment and scheduling"
                        : "Participants"}
                    </h3>
                    <img
                      src="/static/info.svg"
                      alt=""
                      className="info_icon"
                      onClick={() => setPaymentInfo(!paymentInfo)}
                    />
                  </div>

                  <div
                    className={`${
                      userType !== USER_TYPE.GUEST &&
                      watch(apiConstants.listingDetails)?.type !==
                        LISTING_TYPE.C
                        ? ""
                        : "two-row"
                    }no_cross`}
                  >
                    <div
                      style={{
                        position: "relative",
                        marginBottom: paymentInfo ? "64px" : "auto",
                        flexGrow: 1,
                      }}
                    >
                      <InputField
                        type={"number"}
                        name={apiConstants.max_participants}
                        inputLabel={"Max Participants"}
                        placeholder={"Add your max participants"}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                      />
                      {paymentInfo && (
                        <div className="info_box" ref={refInfo}>
                          <img src={"/static/greyinfo.svg"} alt="" />{" "}
                          {STRING_CONSTANTS.max_participant_info}
                        </div>
                      )}
                    </div>
                    {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingDetails)?.type !==
                      LISTING_TYPE.C ? (
                      <div
                        style={{
                          position: "relative",
                          display: userType === USER_TYPE.GUEST ? "none" : "",
                          marginBottom: paymentInfo ? "64px" : "auto",
                          flexGrow: 1,
                        }}
                      >
                        <InputField
                          type={"number"}
                          name={apiConstants.listing_cost}
                          inputLabel={
                            watch(apiConstants.listingDetails)?.type ===
                            LISTING_TYPE.C
                              ? "Team Listing Cost"
                              : "Event Cost"
                          }
                          placeholder={"Add your joining cost"}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                        />
                        {paymentInfo && (
                          <div className="info_box" ref={refInfo}>
                            <img src={"/static/greyinfo.svg"} alt="" />{" "}
                            {STRING_CONSTANTS.listing_cost_info}
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                  {userType !== USER_TYPE.GUEST &&
                  watch(apiConstants.listingDetails)?.type !==
                    LISTING_TYPE.C ? (
                    <>
                      {watch(apiConstants.listing_cost) ? (
                        <>
                          <div className="earning-box-wrap">
                            <div className="earning-title">
                              Your Earnings per Participant
                            </div>
                            <div className="amount_earning">
                              $
                              {(Number(watch(apiConstants.listing_cost)) || 0) -
                                calculatePercentageOfNumber(
                                  Number(currUserInfo?.merchantFee || 0),
                                  Number(watch(apiConstants.listing_cost)) || 0
                                )}
                            </div>
                            <div className="info_main">
                              <div>
                                <img alt="" src="/static/smallinfo.svg" />
                              </div>
                              <div>
                                Your earnings per participants will be dependent
                                on number of attendees added per booking.
                              </div>
                            </div>
                            <div className="divider" />
                            <div className="fee-box">
                              <div>
                                <div className="fee-title">Merchant Fee: </div>
                                <div className="desc">
                                  $
                                  {calculatePercentageOfNumber(
                                    currUserInfo?.merchantFee || 0,
                                    watch(apiConstants.listing_cost)
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="info">
                              <span>Note:</span> Your earnings are subjective to
                              deduction of platform fee as well as merchant fee,
                              in order to gain more knowledge{" "}
                              <Link
                                to="#"
                                //  target="_blank"
                                className="link_terms"
                                onClick={() =>
                                  setIsFeeInfoShown(!isFeeInfoShown)
                                }
                              >
                                {isFeeInfoShown ? "Hide" : "Click here."}
                              </Link>
                            </div>
                            {isFeeInfoShown ? (
                              <Table responsive borderless bsPrefix="pay-table">
                                <thead className="pay-t-header">
                                  <tr>
                                    <th className="first">
                                      Number of percipients
                                    </th>
                                    <th className="second">
                                      Platform fixed Fee per booking per person
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {commisionAmounts?.map((item, index) => {
                                    return (
                                      <tr>
                                        <td>
                                          {item.minimumSlots}{" "}
                                          {item.minimumSlots ===
                                          item.maximumSlots
                                            ? ""
                                            : item.maximumSlots === null
                                            ? "plus"
                                            : `to ${item.maximumSlots}`}
                                        </td>
                                        <td>${item.commissionAmount}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : (
                              ""
                            )}
                            {isFeeInfoShown ? (
                              <div className="fee-box">
                                <div>
                                  <div className="fee-title">
                                    Merchant Fee:{" "}
                                  </div>
                                  <div className="desc">
                                    $
                                    {calculatePercentageOfNumber(
                                      currUserInfo?.merchantFee || 0,
                                      watch(apiConstants.listing_cost)
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {isFeeInfoShown ? (
                              <>
                                <div className="divider" />
                                <div className="info">
                                  <span>Note:</span>
                                  {watch(apiConstants.listingDetails)?.type ===
                                  LISTING_TYPE.C
                                    ? " You will be charged on monthly basis per booking"
                                    : watch(apiConstants.listingDetails)
                                        ?.type === LISTING_TYPE.M
                                    ? "You will be charged per package booking"
                                    : "You will be charged as per the number of events"}
                                </div>
                                <div className="terms-info">
                                  To gain a better understanding of the platform
                                  fees charges, please review the{" "}
                                  <Link
                                    className="terms-link"
                                    to="/policies"
                                    target="_blank"
                                  >
                                    terms and conditions.
                                  </Link>
                                </div>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {watch(apiConstants.listingDetails)?.type !==
                    LISTING_TYPE.C &&
                  watch(apiConstants.listingDetails)?.type !==
                    LISTING_TYPE.M ? (
                    <div className="form_group with-button">
                      <div className="input_box">
                        <span className="input_label">Schedule Dates</span>
                        <div
                          className="schedule_date_input"
                          onClick={() => setScheduleLisiting(true)}
                        >
                          {handleShowScheduleDate()}
                        </div>
                        {errors[apiConstants.has_schedule_date] ? (
                          <span className="text-danger">
                            {`${
                              errors[apiConstants.has_schedule_date]?.message
                            }`}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {userType !== USER_TYPE.GUEST &&
                  watch(apiConstants.listingDetails)?.type !==
                    LISTING_TYPE.C ? (
                    <div style={{ marginBottom: "28px" }}>
                      <div className="main-had">
                        <h3 className="headis">
                          How do you want to receive the payment
                        </h3>
                        <img
                          src="/static/info.svg"
                          alt=""
                          className="info_icon"
                        />
                      </div>

                      <SelectPayout
                        type={
                          watch(apiConstants.payment_mode) &&
                          watch(apiConstants.stripe_account_id)
                            ? "stripe"
                            : watch(apiConstants.payment_mode) &&
                              watch(apiConstants.bsb_number)
                            ? "direct"
                            : ""
                        }
                        getValues={getValues}
                        setIsPaymentOpen={setIsPaymentOpen}
                      />

                      {paymentInfo && (
                        <div className="info_box" ref={refInfo}>
                          <img src={"/static/greyinfo.svg"} alt="" />{" "}
                          {STRING_CONSTANTS.listing_cost_method}
                        </div>
                      )}
                    </div>
                  ) : null}

                  {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingDetails)?.type !==
                      LISTING_TYPE.C && (
                      <div className="main-heaidng">
                        <h6>Allow booking to cancel</h6>
                        <img
                          src="/static/info.svg"
                          alt=""
                          onClick={() => setIsisBookingInfo(!isBookingInfo)}
                        />
                      </div>
                    )}
                  {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingDetails)?.type !==
                      LISTING_TYPE.C && (
                      <div className="all-radio-button">
                        <div className="form_radio_button">
                          <img
                            className="cursor-pointer"
                            src={
                              !watch(apiConstants.isCancellationAllowed)
                                ? "/static/radiocheck.svg"
                                : "/static/radiouncheck.svg"
                            }
                            alt=""
                            onClick={() => {
                              handleSetValue(
                                apiConstants.isCancellationAllowed,
                                false
                              );
                              setCancellationTill("");
                              handleSetValue(
                                apiConstants.cancellation_allowed_till_hours,
                                null
                              );
                            }}
                          />
                          <label
                            className={
                              watch(apiConstants.isCancellationAllowed) ===
                              false
                                ? "form-check-label cursor-pointer active"
                                : "form-check-label cursor-pointer"
                            }
                            htmlFor="bookingdontallow"
                            onClick={() => {
                              handleSetValue(
                                apiConstants.isCancellationAllowed,
                                false
                              );
                              setCancellationTill("");
                              handleSetValue(
                                apiConstants.cancellation_allowed_till_hours,
                                null
                              );
                            }}
                          >
                            Don’t allow{" "}
                            <span className="radio-recommended">
                              Recommended
                            </span>
                          </label>
                        </div>
                        <div className="form_radio_button">
                          <img
                            className="cursor-pointer"
                            src={
                              watch(apiConstants.isCancellationAllowed)
                                ? "/static/radiocheck.svg"
                                : "/static/radiouncheck.svg"
                            }
                            alt=""
                            onClick={() =>
                              handleSetValue(
                                apiConstants.isCancellationAllowed,
                                true
                              )
                            }
                          />
                          <label
                            className={
                              watch(apiConstants.isCancellationAllowed) === true
                                ? "form-check-label cursor-pointer active"
                                : "form-check-label cursor-pointer"
                            }
                            htmlFor="bookingallow"
                            onClick={() =>
                              handleSetValue(
                                apiConstants.isCancellationAllowed,
                                true
                              )
                            }
                          >
                            Allow
                          </label>
                        </div>
                        {watch(apiConstants.isCancellationAllowed) === true &&
                        cancellationTill ? (
                          <div className="booking-chip">
                            Allowed till {cancellationTill} hours
                            <span
                              onClick={() => {
                                setCancellationTill("");
                                handleSetValue(
                                  apiConstants.cancellation_allowed_till_hours,
                                  null
                                );
                              }}
                            >
                              <img alt="" src="/static/dismisscircle.svg" />
                            </span>
                          </div>
                        ) : null}
                        {watch(apiConstants.isCancellationAllowed) === true ? (
                          <div
                            className="form_group"
                            style={{ marginBottom: "0" }}
                          >
                            <div className="select-box-booking">
                              <span className="input_label">
                                Allow cancellation till
                              </span>
                              <select
                                value={cancellationTill}
                                onChange={(e) => {
                                  setCancellationTill(e.target.value);
                                  handleSetValue(
                                    apiConstants.cancellation_allowed_till_hours,
                                    e.target.value
                                  );
                                }}
                                className="select-wrap"
                              >
                                <option value={""} disabled>
                                  select an option
                                </option>
                                <option value={24}>Allow till 24 hours</option>
                                <option value={48}>Allow till 48 hours</option>
                                <option value={72}>Allow till 72 hours</option>
                                <option value={96}>Allow till 96 hours</option>
                                <option value={120}>
                                  Allow till 120 hours
                                </option>
                              </select>
                              <img
                                src={
                                  cancellationTill
                                    ? "/static/dismisscircle.svg"
                                    : "/static/cross-circle-gray.svg"
                                }
                                className="clear-icon"
                                alt=""
                                onClick={() => {
                                  setCancellationTill("");
                                  handleSetValue(
                                    apiConstants.cancellation_allowed_till_hours,
                                    null
                                  );
                                }}
                              />
                            </div>
                            {
                              <span className="text-danger">
                                <>
                                  {errors?.cancellation_allowed_till_hours
                                    ? errors.cancellation_allowed_till_hours
                                        ?.message
                                    : ""}
                                </>{" "}
                              </span>
                            }
                          </div>
                        ) : null}
                        <div className="mb_24 terms-cancel-allow">
                          Please read{" "}
                          <Link
                            to="/policies?activeTab=terms"
                            target="_blank"
                            style={{ color: "#ee762f" }}
                          >
                            {" "}
                            T&C
                          </Link>{" "}
                          to understand the{" "}
                          <Link
                            to="/policies?activeTab=privacy"
                            target="_blank"
                            style={{ color: "#ee762f" }}
                          >
                            Cancellation Terms{" "}
                          </Link>
                        </div>
                      </div>
                    )}

                  {isBookingInfo && (
                    <div className="info_box" ref={refInfo}>
                      <img src={"/static/greyinfo.svg"} alt="" />{" "}
                      {STRING_CONSTANTS.booking_info}
                    </div>
                  )}

                  <div
                    className="main-had"
                    style={{ marginTop: isBookingInfo ? "48px" : "0" }}
                  >
                    <h3 className="headis">{STRING_CONSTANTS.age_prefrence}</h3>
                  </div>
                  <div className="all-radio-button">
                    <div className="form_radio_button ">
                      <img
                        className="cursor-pointer"
                        src={
                          !watch(apiConstants.is_specific_age)
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() =>
                          handleSetValue(apiConstants.is_specific_age, false)
                        }
                      />
                      <label
                        className={
                          watch(apiConstants.is_specific_age) === false ||
                          !watch(apiConstants.is_specific_age)
                            ? "form-check-label cursor-pointer active"
                            : "form-check-label cursor-pointer"
                        }
                        onClick={() =>
                          handleSetValue(apiConstants.is_specific_age, false)
                        }
                        htmlFor="allages"
                      >
                        {STRING_CONSTANTS.all_age_listing}
                      </label>
                    </div>
                    <div className="form_radio_button mb-2">
                      <img
                        className="cursor-pointer"
                        src={
                          watch(apiConstants.is_specific_age)
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() =>
                          handleSetValue(apiConstants.is_specific_age, true)
                        }
                      />
                      <label
                        className={
                          watch(apiConstants.is_specific_age) === true
                            ? "form-check-label cursor-pointer active"
                            : "form-check-label cursor-pointer"
                        }
                        onClick={() =>
                          handleSetValue(apiConstants.is_specific_age, true)
                        }
                        htmlFor="specificage"
                      >
                        {STRING_CONSTANTS.specific_age_listing}
                      </label>
                    </div>
                  </div>
                  {watch(apiConstants.is_specific_age) && (
                    <>
                      <div className="two-row no_cross">
                        <InputField
                          type={"number"}
                          name={apiConstants.min_age}
                          inputLabel={"Minimum Age"}
                          placeholder={"Minimum Age"}
                          control={control}
                          errors={errors}
                        />
                        <InputField
                          type={"number"}
                          name={apiConstants.max_age}
                          inputLabel={"Maximum Age"}
                          placeholder={"Maximum Age"}
                          control={control}
                          errors={errors}
                          disabled={watch(apiConstants.min_age) ? false : true}
                        />
                      </div>
                    </>
                  )}
                  <div className="main-had ">
                    <h3 className="headis">
                      {STRING_CONSTANTS.gender_preference}
                    </h3>
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div className="listing_type_radio">
                      <img
                        src={
                          watch(apiConstants.selectedGender)?.length === 3
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() =>
                          handleSetValue(apiConstants.selectedGender, [
                            "M",
                            "F",
                            "N",
                          ])
                        }
                      />
                      <label
                        className={
                          watch(apiConstants.selectedGender)?.length === 3
                            ? "listing_ype_label cursor-pointer active"
                            : "listing_ype_label cursor-pointer"
                        }
                        onClick={() =>
                          handleSetValue(apiConstants.selectedGender, [
                            "M",
                            "F",
                            "N",
                          ])
                        }
                      >
                        This listing is for all genders
                      </label>
                    </div>
                    <div className="listing_type_radio">
                      <img
                        src={
                          watch(apiConstants.selectedGender)?.length < 3
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() =>
                          handleSetValue(apiConstants.selectedGender, [])
                        }
                      />
                      <label
                        className={
                          watch(apiConstants.selectedGender)?.length < 3
                            ? "listing_ype_label cursor-pointer active"
                            : "listing_ype_label cursor-pointer"
                        }
                        onClick={() =>
                          handleSetValue(apiConstants.selectedGender, [])
                        }
                      >
                        This listing is for a specific gender
                      </label>
                    </div>
                  </div>
                  <div className="all-checkbox-button_with-check m-0">
                    {watch(apiConstants.selectedGender)?.length < 3 &&
                      GendersTypeSpecific?.map((g) => (
                        <div
                          key={g.value}
                          className="form_checkbox_button_with_check"
                        >
                          <Controller
                            name={`gender_preference.${g.value}`}
                            control={control}
                            render={({ field }) => (
                              <div className="form_group">
                                <input
                                  {...field}
                                  type="checkbox"
                                  id={g.title}
                                  value={g.value}
                                  checked={watch(
                                    apiConstants.selectedGender
                                  ).includes(g.value)}
                                  onClick={(e: any) =>
                                    handleGender(e?.target?.value)
                                  }
                                />
                                <label
                                  htmlFor={g.title}
                                  style={{ justifyContent: "normal" }}
                                  className={
                                    watch(apiConstants.selectedGender).includes(
                                      g.value
                                    )
                                      ? "checked"
                                      : "checkbox-gender-label"
                                  }
                                >
                                  <img
                                    src={g.activeImg}
                                    style={{
                                      background: "#ffffff",
                                      padding: "8px 8.5px",
                                      borderRadius: "50%",
                                    }}
                                    alt=""
                                  />
                                  <span>{g.title}</span>
                                  {watch(apiConstants.selectedGender).includes(
                                    g.value
                                  ) && (
                                    <img
                                      src="/static/checkmark-circle.svg"
                                      className="checkmark-circle"
                                      alt=""
                                    />
                                  )}
                                </label>
                              </div>
                            )}
                          />
                        </div>
                      ))}
                    <span
                      className="text-danger"
                      style={{ position: "absolute", bottom: "0" }}
                    >
                      <>
                        {errors?.selectedGender
                          ? errors.selectedGender?.message
                          : ""}
                      </>
                    </span>
                  </div>

                  {/* <div
                    className="mb_24 terms-cancel-allow "
                    style={{ marginTop: "12px" }}
                  >
                    You can select multiple genders as per your event's
                    requirement.
                  </div> */}

                  <div className="main-had ">
                    <h3 className="headis">Difficulty level</h3>
                  </div>
                  <div className="all-checkbox-button_with-check">
                    {difficultyLevelPreferenceNew?.map((g) => (
                      <div
                        key={g?.value}
                        className="form_checkbox_button_with_check"
                      >
                        <Controller
                          name={`difficulty_level.${g.value}`}
                          control={control}
                          render={({ field }) => (
                            <div className="form_group">
                              <input
                                {...field}
                                type="checkbox"
                                id={g.title}
                                value={g.value}
                                checked={field.value}
                                onClick={(e: any) =>
                                  handleDifficulty(e.target.value)
                                }
                              />
                              <label
                                htmlFor={g.title}
                                style={{ justifyContent: "normal" }}
                                className={
                                  difficultyPreferences?.[g.value]
                                    ? "checked"
                                    : "checkbox-gender-label"
                                }
                              >
                                <img
                                  src={
                                    difficultyPreferences?.[g.value]
                                      ? g.activeImg
                                      : g.img
                                  }
                                  style={{
                                    background: "#ffffff",
                                    padding: "8px 8.5px",
                                    borderRadius: "50%",
                                  }}
                                  alt=""
                                />
                                <span>{g?.title}</span>
                                {difficultyPreferences?.[g.value] && (
                                  <img
                                    src="/static/checkmark-circle.svg"
                                    className="checkmark-circle"
                                    alt=""
                                  />
                                )}
                              </label>
                            </div>
                          )}
                        />
                      </div>
                    ))}
                  </div>

                  <Button
                    btnTxt="Create listing"
                    onClick={async () => {
                      if (watch(apiConstants.listingDetails).type === "P") {
                        if (
                          getValues(apiConstants.multipleEvents).length < 2 &&
                          !watch(apiConstants.isDraft)
                        ) {
                          showToast(
                            "Package listings must include a minimum of two scheduled events.",
                            "error",
                            "pacakgelist"
                          );
                          return;
                        }
                        if (isObjEmpty(errors)) {
                          handleSetValue(apiConstants.isDraft, false);
                          handleNext({ isDraft: false });
                        } else {
                          await getStripeInfo();
                          await handleNext({ isDraft: false });
                          setIsDraft(false);
                        }
                      } else {
                        if (isObjEmpty(errors)) {
                          handleSetValue(apiConstants.isDraft, false);
                        }
                        await getStripeInfo();
                        await handleNext({ isDraft: false });
                        setIsDraft(false);
                      }
                    }}
                    isLoading={loadingState.isCreate}
                    disabled={isDisable}
                  />
                </div>
              )}

              {watch(apiConstants.listingDetails)?.type ? (
                <div className="d-flex justify-content-end">
                  <Button
                    btnImg="/static/savebtn.svg"
                    btnTxt="Save to draft"
                    className="draft_btn d-none d-md-block"
                    style={{
                      background:
                        Object.keys(dirtyFields).length < 2 ? "#d9d9d9" : "",
                    }}
                    isLoading={loadingState.isDraft}
                    onClick={() => {
                      setIsDraft(true);
                      handleSetValue(apiConstants.isDraft, true);
                      if (watch(apiConstants.listingDetails).type === "P") {
                        if (
                          getValues(apiConstants.multipleEvents).length < 2 &&
                          !watch(apiConstants.isDraft)
                        ) {
                          showToast(
                            "Package listings must include a minimum of two scheduled events.",
                            "error",
                            "pacakgelist"
                          );
                        }
                      }
                      setTimeout(() => {
                        handleNext({ isDraft: true });
                      }, 500);
                    }}
                    disabled={
                      Object.keys(dirtyFields).length < 2 ||
                      loadingState.isDraft ||
                      isDraft
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      {scheduleListing && (
        <ScheduleListingModal
          setScheduleLisiting={setScheduleLisiting}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          setScheduleDate={setScheduleDate}
          dirtyFields={dirtyFields}
        />
      )}
      {showDraftPopUp && (
        <DraftPopup
          onClose={() => setShowDraftPopUp(false)}
          discard={() => Navigate("/view-listing")}
          showDiscard={true}
          draft={() => {
            handleSetValue(apiConstants.isDraft, true);
            setIsDraft(true);
            setTimeout(() => {
              handleNext({ isDraft: true });
            }, 500);
          }}
        />
      )}

      {/* Right sidebar for multiple listing type  */}

      {showAddEventSidebar && !showEventListingsSidebar ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box-event">
            <div className="event-title">
              <img
                src="/static/cross-black.svg"
                onClick={() => setShowAddEventSidebar(false)}
                className="close-btn d-none d-md-block"
                alt=""
              />

              <span
                className="d-md-none"
                onClick={() => {
                  setShowAddEventSidebar(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M8.73464 16.7917C9.03557 17.0763 9.51026 17.0631 9.79489 16.7621C10.0795 16.4612 10.0663 15.9865 9.76536 15.7019L4.52632 10.7468L17.25 10.7468C17.6642 10.7468 18 10.411 18 9.99678C18 9.58256 17.6642 9.24678 17.25 9.24678L4.52405 9.24678L9.76536 4.28952C10.0663 4.00489 10.0795 3.53021 9.79489 3.22927C9.51026 2.92834 9.03557 2.91512 8.73464 3.19974L2.31741 9.26918C2.15112 9.42647 2.05112 9.62658 2.01741 9.83537C2.00601 9.88736 2 9.94136 2 9.99678C2 10.0544 2.00649 10.1104 2.01878 10.1643C2.05377 10.3701 2.15331 10.567 2.31741 10.7222L8.73464 16.7917Z"
                    fill="#25272D"
                  />
                </svg>
              </span>
              <div className="filter-heading pe-3">
                {editEvent ? "Update Event" : "Add Event"}
              </div>
            </div>
            <div className="inner-contet">
              <>
                <InputField
                  type={"text"}
                  name={apiConstants.event_title}
                  inputLabel={"Event Name"}
                  placeholder={"Type event name"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />

                {watch(apiConstants.listing_event_type) === "V" &&
                  watch(apiConstants.multiple_virtual_links_allowed) ===
                    false && (
                    <>
                      <div>
                        <InputField
                          type={"text"}
                          name={apiConstants.virtual_links_package}
                          inputLabel={"Add Meeting URL"}
                          placeholder={"Enter URL"}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          style={{ marginBottom: "12px" }}
                        />
                      </div>
                      <div className="mb-4">
                        <InfoCard
                          title="
              Please include only web URLs for Google Meet, Zoom, and Microsoft Teams here."
                        />
                      </div>
                    </>
                  )}
                {watch(apiConstants.listing_event_type) === "P" && (
                  <GooglePlacesSearchBar
                    setIsLocationAccessible={(val) =>
                      setIsLocationAccessible(val)
                    }
                    setShowLocationPopup={(val) => setShowLocationPopup(val)}
                    isLocationAccessible={isLocationAccessible}
                    hideMap={true}
                    onChangeLocation={(
                      lat: string,
                      lng: string,
                      address: string,
                      address_components: any
                    ) => {
                      let location = handleLocation(
                        lat,
                        lng,
                        address,
                        address_components
                      );

                      handleSetValue(apiConstants.address, address);
                      handleSetValue(apiConstants.lat, lat);
                      handleSetValue(apiConstants.lng, lng);
                      handleSetValue(apiConstants.postCode, location.zipcode);
                      handleSetValue(apiConstants.state, location.state);
                      handleSetValue(apiConstants.city, location.locality);
                      handleSetValue(apiConstants.country, location.country);
                    }}
                    setValue={setValue}
                    handleLocation={handleLocation}
                    control={control}
                    name={apiConstants.address}
                    label={"Search location"}
                    placeholder="Include a location"
                  />
                )}

                <div className="form_group with-button">
                  <div className="input_box">
                    <span className="input_label">Schedule Dates</span>
                    <div
                      className="schedule_date_input"
                      onClick={() => {
                        setShowAddEventSidebar(false);
                        setMultipleScheduleLisiting(true);
                      }}
                    >
                      {handleShowScheduleDate()}
                    </div>
                    {errors[apiConstants.has_schedule_date] ? (
                      <span className="text-danger">
                        {`${errors[apiConstants.has_schedule_date]?.message}`}
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
            </div>

            <div>
              <Button
                btnTxt={editEvent ? "Update Event" : "Save Event"}
                className="save_btn d-none d-md-block"
                onClick={handleUpdateOrSaveEvent}
              />
              <Button
                btnTxt={editEvent ? "Update Event" : "Save Event"}
                className=" d-md-none"
                onClick={handleUpdateOrSaveEvent}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showEventListingsSidebar ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box-event">
            <div className="event-title mt-0">
              <img
                src="/static/cross-black.svg"
                onClick={() => setShowEventListingsSidebar(false)}
                className="close-btn"
                alt=""
              />
              <div className="filter-heading">Events</div>
            </div>
            <div className="inner-contet">
              <Fragment>
                {getValues(apiConstants.multipleEvents)?.length > 0 && (
                  <EventCardSection
                    data={getValues(apiConstants.multipleEvents)}
                    onDeleteEvent={onDeleteEvent}
                    handleEventEditClick={handleEventEditClick}
                    setShowEventListingsSidebar={setShowEventListingsSidebar}
                    viewAll={false}
                    watch={watch}
                  />
                )}
                {getValues(apiConstants.multipleEvents)?.length > 0 ? (
                  <div>
                    <OutlineYellowButton
                      style={{
                        border: "1px solid #8f939a",
                      }}
                      text="Add Event"
                      icon={<AddIconYellow />}
                      onClick={() => {
                        setShowEventListingsSidebar(false);
                        setShowAddEventSidebar(true);
                      }}
                    />
                  </div>
                ) : null}
              </Fragment>
            </div>
          </div>
        </div>
      ) : null}

      {multipleScheduleListing ? (
        <ScheduleListingSidebar
          setMultipleScheduleLisiting={() => {
            setMultipleScheduleLisiting(false);
            setShowAddEventSidebar(true);
          }}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          setScheduleDate={setScheduleDate}
          dirtyFields={dirtyFields}
        />
      ) : null}

      {showEventPopup ? (
        <SaveEventPopup
          title="Save Event"
          onClose={() => {
            setShowEventPopup(false);
            setEditEvent(false);
          }}
          reCheck={() => {
            setShowEventPopup(false);
            setShowAddEventSidebar(true);
          }}
          onContinue={() => {
            setShowEventPopup(false);
            if (editEvent) {
              setEditEvent(false);
              const newMultipleEvents = watch(apiConstants.multipleEvents).map(
                (event) => (event.id === editEventId ? tempObj : event)
              );
              setValue(apiConstants.multipleEvents, newMultipleEvents);
              setEditEventId(null);
            } else {
              if (getValues(apiConstants.multipleEvents).length > 0) {
                setValue(apiConstants.multipleEvents, [
                  ...getValues(apiConstants.multipleEvents),
                  tempObj,
                ]);
              } else {
                setValue(apiConstants.multipleEvents, [tempObj]);
              }
            }
            setValue(apiConstants.event_title, "");
            setValue(apiConstants.city, "");
            setValue(apiConstants.state, "");
            setValue(apiConstants.country, "");
            setValue(apiConstants.postCode, "");
            setValue(apiConstants.address, "");
            setValue(apiConstants.listing_location, "");
            setValue(apiConstants.event_start_date, "");
            setValue(apiConstants.event_start_time, "");
            setValue(apiConstants.event_end_time, "");
            setValue(apiConstants.event_end_date, "");
            setValue(apiConstants.schedule_date, "");
            setValue(apiConstants.event_date, "");
            setValue(apiConstants.virtual_links_package, "");
          }}
        />
      ) : null}
      {showLocationPopup && (
        <LocationDeniedPopup onClose={() => setShowLocationPopup(false)} />
      )}
      {cropperOpen ? (
        <div className="modal show">
          <div
            className="modal-section"
            style={{ maxWidth: "450px", padding: "0" }}
          >
            <ImageCropper
              getImage={imageFile}
              setCropedImage={setCropedImage}
              setModal={setCropperOpen}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateListing;
