import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import FullLoader from "../../global/FullLoader";
import InputField from "../../global/InputField";
import {
  removeAllItemFromStore,
  setLocalStorage,
  showToast,
} from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import { registrationForm } from "../../utils/FormConfig";
import { makePostCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { RegistrationValidations } from "./AuthValidation";
import SocialLogin from "./SocialLogin";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../assets/img/icons/Logo";
const signupBg1 = require("../../assets/img/signup-bg-1.jpg");
const signupBg2 = require("../../assets/img/signup-bg-2.jpg");
const signupBg3 = require("../../assets/img/signup-bg-3.jpg");

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [passType, setPassType] = useState("password");
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: registrationForm,
    resolver: yupResolver(RegistrationValidations),
    mode: "onSubmit",
  });

  useEffect(() => {
    // we are removing all the items from the localstorage because there is a goback functionality and the user account created at the first step so we are removing the user data from local storage if user click on back and come to login or sign up page so the can login there account here.
    removeAllItemFromStore();
  }, []);
  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };

  const handleLogin = () => {
    handleSubmit((data) => {
      let formData: any = new FormData();
      formData.append("email", data.email.toLowerCase());
      formData.append("password", data.password);
      if (localStorage.getItem('attribute')) {
        formData.append("attribution", localStorage.getItem('attribute'));
      }
      //for pre registration form
      if (process.env.REACT_APP_IS_PROD === "true") {
        formData.append("pre_launch_user", true);
      }

      formData.append(
        "client_id",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_ID
          : process.env.REACT_APP_IS_LOCAL === "true"
            ? process.env.REACT_APP_LOCAL_CLIENT_ID
            : process.env.REACT_APP_STAGING_CLIENT_ID
      );
      formData.append(
        "client_secret",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_SECRET
          : process.env.REACT_APP_IS_LOCAL === "true"
            ? process.env.REACT_APP_LOCAL_CLIENT_SECRET
            : process.env.REACT_APP_STAGING_CLIENT_SECRET
      );
      setIsLoading(true);
      makePostCall({
        url: endpoints.profile,
        apiPayload: formData,
        content_type: "multipart/form-data",
      })
        .then((res) => {
          if (res.status.code === 200) {
            setLocalStorage("access_token", res?.token.accessToken);
            setLocalStorage("refresh_token", res?.token.refreshToken);
            showToast(res.status.message, "success");
            setIsLoading(false);

            if (process.env.REACT_APP_IS_PROD === "true") {
              navigate(ROUTE_CONSTANTS.manage_profile, { replace: true });
              setLocalStorage("userType", "Member");
            } else {
              navigate(ROUTE_CONSTANTS.welcome, { replace: true });
              setLocalStorage("userType", res.data.userType || "Guest");
            }
          } else {
            showToast(res.status.message, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          showToast(err.status.message, "error");
          setIsLoading(false);
        });
    })();
  };
  const imgchange = [0, 1, 2];
  return (
    <>
      <div className=" d-md-none logo_nav ">
        <Link to="/">
          <img src="/static/logo_small.svg" alt="" />
        </Link>
      </div>
      <div className="login constiner-fluid p-0 m-0 overflow-hidden">
        <div style={{ height: "100vh" }} className="row p-0 m-0 ">
          <div
            className="col1 d-none d-md-block col-5  overflow-hidden"
            style={{ paddingLeft: "0px", paddingRight: "0px", height: "100vh" }}
          >
            <div className="img-slider">
              <Slider {...settings} ref={sliderRef}>
                <div className="slider-item">
                  <img src={signupBg1} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg3} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg2} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
              </Slider>
            </div>
            <div className="txtb" style={{ width: "100%" }}>
              <div className="logo">
                <Logo fill="#FFFFFF" />
                <p style={{ color: "#FFFFFF" }}>
                  {currentSlide === 0
                    ? "Find your ideal fitness mates and activities, anywhere anytime."
                    : currentSlide === 1
                      ? "Connecting fitness and health enthusiasts to join classes and activities together"
                      : "Create or join a health or fitness activity that suits your needs today."}
                </p>
              </div>
              <div className="guest-button">
                <div className="slider-box">
                  <div className="slider-bg"></div>
                  {imgchange.map((index) => (
                    <div
                      key={index}
                      className={`slider ${currentSlide === index ? "active" : ""
                        }`}
                      style={{ transform: `translateX(${index * 43}px)` }}
                    ></div>
                  ))}
                </div>
                {
                  // process.env.REACT_APP_IS_PROD !== "true" ? (
                  //   <NavLink
                  //     to={ROUTE_CONSTANTS.events}
                  //     style={{ color: "#FFFFFF" }}
                  //   >
                  //     Continue as Guest
                  //   </NavLink>
                  // ) : null
                }
              </div>
            </div>
          </div>
          <div className="col2 col-12 col-md-7 mx-auto login-right-container overflow-y-auto vh-100">
            {socialLoading ? (
              <FullLoader />
            ) : (
              <form onSubmit={handleSubmit(handleLogin)}>
                <h1>Let’s get started!</h1>
                <p>
                  Whether you're looking to find workout partners or create
                  fitness events, we got you covered!
                </p>

                <InputField
                  type={"email"}
                  name={apiConstants.email}
                  placeholder={"Enter Email"}
                  inputLabel={"Email Address"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <div className="password">
                  <InputField
                    type={passType}
                    name={apiConstants.password}
                    placeholder={"Enter password"}
                    inputLabel={"Password"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    extramsg={"Minimum 8 characters."}
                  />

                  <img
                    src={
                      passType === "password"
                        ? "/static/closeeye.svg"
                        : "/static/openeye.svg"
                    }
                    alt=""
                    onClick={() =>
                      setPassType(passType === "password" ? "text" : "password")
                    }
                  />
                </div>
                <div className="note">
                  By pressing “Create account” you agree to our
                  <Link to={ROUTE_CONSTANTS.policy} target={"_blank"}>
                    {" "}
                    Terms & Privacy{" "}
                  </Link>
                </div>

                <Button
                  type="submit"
                  btnTxt="Create account"
                  isLoading={isLoading}
                />

                <div className="continue_container">
                  <div className="line"></div>
                  <div className="continue_with ">or continue with</div>
                  <div className="line"></div>
                </div>
                <SocialLogin setSocialLoading={setSocialLoading} />
                <div className="no_account">
                  Already have an account?
                  {process.env.REACT_APP_IS_PROD !== "true" ? (
                    <Link to={ROUTE_CONSTANTS.login}> Sign in.</Link>
                  ) : null}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
