/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalHeader from "../Dashboard/GlobalHeader";
import inactive from "../../assets/img/Inactive.svg";
import communication from "../../assets/img/Communication.svg";
import noinactive from "../../assets/img/no-inactive.svg";
import noactive from "../../assets/img/no-active.svg";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
} from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { dateFormatter, formatAmount, showToast } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import InActiveListIcon from "../../assets/img/icons/InActiveListIcon";
import ActiveListIcon from "../../assets/img/icons/ActiveListIcon";
import { LISTING_TYPE } from "../../types";
import DeleteIcon from "../../assets/img/icons/DeleteIcon";
import ActivateIcon from "../../assets/img/icons/ActivateIcon";
import EditIcon from "../../assets/img/icons/EditIcon";
import { Link } from "react-router-dom";
import MoreIcon from "../../assets/img/icons/MoreIcon";
import { useOutsideClick } from "../../hooks";
import Button from "../../global/Button";

const MyListings = () => {
  const [active, setActive] = useState<any>("active");
  const [listing, setListing] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [openIndex, setOpenIndex] = useState<any>(null);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showInactivePopup, setShowInactivePopup] = useState<boolean>(false);
  const ref = useRef<any>(null);
  const navigate = useNavigate();

  const getListingData = () => {
    setListing([]);
    setIsLoading(true);
    makeGetCall({
      url:
        endpoints.mylisting_new +
        `?is_active=${active === "active" ? true : false}`,
    })
      .then((res) => {
        if (res?.results) {
          setListing(res?.results);
          setNextUrl(res?.next || null);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getListingData();
  }, [active]);

  const fetchMorelistings = () => {
    makeNextPageCall({ url: nextUrl })
      .then((res) => {
        const tempData = [...listing, ...res.results];
        setNextUrl(res?.next);
        setListing(tempData as never);
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  useOutsideClick(ref, () => {
    setOpenIndex(null);
  });

  const deleteDraftItem = () => {
    setShowDeletePopup(false);
    if (deleteId) {
      makeDeleteCall({
        url: endpoints.listing + `${deleteId}`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);
            getListingData();
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const makeInactiveList = () => {
    if (deleteId) {
      makePatchCall({
        url: endpoints.listing + `${deleteId}/mark-inactive/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);
            getListingData();
            setShowInactivePopup(false);
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  return (
    <>
      <GlobalHeader />
      <div className="my-created-page">
        <div className="my-created-content-box">
          <div className="top-section">
            <img
              style={{ cursor: "pointer" }}
              src="/static/leftarrow.svg"
              alt=""
              onClick={() => navigate(-1)}
            />
            <span className="page-title">My Created Listings</span>
            <span></span>
          </div>
          <div className="active-inactive-box">
            <div
              className="item cursor-pointer"
              onClick={() => setActive("active")}
            >
              <div>
                <img alt="" src={communication} />
              </div>
              <div className={active === "active" ? "active" : ""}>Active</div>
            </div>
            <div
              className="item cursor-pointer"
              onClick={() => setActive("inactive")}
            >
              <div>
                <img alt="" src={inactive} />
              </div>
              <div className={active === "inactive" ? "active" : ""}>
                Inactive
              </div>
            </div>
          </div>

          {isLoading ? (
            <ApiLoader />
          ) : listing?.length > 0 ? (
            isLoading ? (
              <ApiLoader />
            ) : (
              <div className="active-card-listings-box">
                <InfiniteScroll
                  dataLength={listing?.length}
                  next={() =>
                    fetchMorelistings(
                      nextUrl,
                      listing,
                      setNextUrl,
                      setListing,
                      setIsLoading
                    )
                  }
                  hasMore={nextUrl ? true : false}
                >
                  <div className="listing-wrapper">
                    {listing?.map((item, index) => {
                      return (
                        <div
                          className="carousel-card "
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/events/${item?.id}`);
                          }}
                        >
                          <div
                            className="listing_box"
                            key={index}
                            style={{
                              marginLeft: "7px",
                            }}
                          >
                            {item.isSpecialListing ? (
                              <img
                                src="/static/fitfest.svg"
                                alt=""
                                className="tag"
                              />
                            ) : item.listingType === LISTING_TYPE.M ? (
                              <img
                                src="/static/package-label.svg"
                                alt=""
                                className="tag"
                              />
                            ) : item.listingType === LISTING_TYPE.C ? (
                              <img
                                src="/static/team-label.svg"
                                alt=""
                                className="tag"
                              />
                            ) : item.listingType === LISTING_TYPE.S ? (
                              <img
                                src="/static/session-label.svg"
                                alt=""
                                className="tag"
                              />
                            ) : null}
                            {["mp4", "mov", "avi"].includes(
                              item?.media
                                ?.split(".")
                                .splice(-1)[0]
                                .toLowerCase()
                            ) ? (
                              <>
                                <video
                                  src={item?.media}
                                  className="event_img"
                                  style={{ cursor: "pointer" }}
                                  id={`eventvideo${index}`}
                                  autoPlay
                                  playsInline
                                  loop
                                  muted
                                >
                                  <source src={item?.media} />
                                </video>
                              </>
                            ) : (
                              <img
                                src={item?.media || "/static/expertise2.png"}
                                className="event_img"
                                alt=""
                              />
                            )}
                            <div
                              className="more-popup-listing"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (openIndex === index) {
                                  setOpenIndex(null);
                                } else {
                                  setOpenIndex(index);
                                }
                              }}
                            >
                              <MoreIcon />
                            </div>
                            {openIndex === index ? (
                              <div
                                ref={ref}
                                className="more-popup-item-wrap "
                                style={{ zIndex: "999999" }}
                              >
                                {item.isListingEditable ? (
                                  <Link
                                    className="event-link-title"
                                    to={`/edit-listing/${item.id}`}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <div className="more-icon-item">
                                      <span>
                                        <EditIcon />
                                      </span>
                                      Edit Listing
                                    </div>
                                  </Link>
                                ) : null}
                                {item.isActive ? (
                                  <div
                                    className="more-icon-item"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteId(item.id);
                                      setShowInactivePopup(true);
                                      setOpenIndex(null);
                                    }}
                                  >
                                    <span>
                                      <ActivateIcon />
                                    </span>
                                    {item.isActive ? "Inactive" : "Activate"}
                                  </div>
                                ) : null}
                                {item.isActive ? null : (
                                  <div
                                    style={{ marginBottom: "0" }}
                                    className="more-icon-item"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteId(item.id);
                                      setShowDeletePopup(true);
                                      setOpenIndex(null);
                                    }}
                                  >
                                    <span>
                                      <DeleteIcon />
                                    </span>
                                    Delete Listing
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="span_box">
                              {item?.distance ? (
                                <span className="distance">
                                  {item.distance?.toFixed(2)} km away...
                                </span>
                              ) : null}
                              {item?.price || item?.monthlyCost ? (
                                <span className="price">
                                  ${" "}
                                  {formatAmount(
                                    item?.price || item?.monthlyCost
                                  )}
                                </span>
                              ) : (
                                <span className="price">$$$</span>
                              )}
                            </div>
                            <div className="title">{item?.title}</div>
                            <div
                              className="others"
                              style={{
                                minHeight: !item.isActive ? "43px" : "",
                                justifyContent: !item.isActive
                                  ? "end"
                                  : "space-between",
                              }}
                            >
                              {item.isActive && (
                                <div className="time">
                                  <img src="/static/calendar.svg" alt="" />
                                  {dateFormatter(item?.createdAt)}
                                </div>
                              )}
                              <div className="remaining">
                                {
                                  <>
                                    <img src="/static/countlogo.svg" alt="" />
                                    <span>
                                      {item?.maxParticipants -
                                        (item?.listingType === LISTING_TYPE.S
                                          ? item?.events?.[0]?.slotsAvailable ||
                                            0
                                          : item?.slotsAvailable)}
                                    </span>
                                    / {item?.maxParticipants || item?.slot}
                                  </>
                                }
                              </div>
                            </div>
                            {item.isActive ? (
                              <div className="total-session">
                                Total Sessions:{" "}
                                {item?.totalSession ||
                                  item?.events?.length ||
                                  1}
                              </div>
                            ) : null}
                            {item.isActive ? (
                              <div className="activeList">
                                <span>
                                  <ActiveListIcon />
                                </span>
                                Active
                              </div>
                            ) : (
                              <div className="inactiveList">
                                <span>
                                  <InActiveListIcon />
                                </span>
                                Inactive
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            )
          ) : (
            <div className="no_data">
              <img alt="" src={active === "active" ? noactive : noinactive} />
            </div>
          )}
        </div>
      </div>

      {showDeletePopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal">
            <div className="modal-top-part">
              <h5>Are you sure?</h5>
              {/* <p>{STRING_CONSTANTS.logout_desc}</p> */}
            </div>
            <div className="button_option">
              <Button
                btnTxt="Delete"
                className={"backtologin"}
                onClick={deleteDraftItem}
              />
              <Button
                btnTxt="Cancel"
                className={"backtologin cancel"}
                onClick={() => setShowDeletePopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showInactivePopup ? (
        <div className="modal-cancel-event show">
          <div className="modal-section" style={{ gap: "20px" }}>
            <div className="modal-top-part">
              <button className="close">
                <img
                  src="/static/dismiss-cross-round-black.svg"
                  alt=""
                  onClick={() => setShowInactivePopup(false)}
                />
              </button>

              <h5>Are You Sure</h5>
              <p>
                Once the listing is inactivated we won’t be able to recover the
                information.
              </p>
            </div>

            <Button
              btnTxt="Yes, Inative"
              className={"backtologin"}
              onClick={() => makeInactiveList()}
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => setShowInactivePopup(false)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MyListings;
