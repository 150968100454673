import { useState } from "react";
import BookEventPopup from "./BookEventPopup";

const Promote = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <section>
      <div className="promote-section">
        <div className="content-box">
          <div className="content-left">
            <div className="small-heading">For Businesses</div>
            <div className="title">
              <span className="orange-span"> Promote & Elevate</span> your
              business with<span className="orange-span"> Fitness Mates</span>
            </div>
            <div className="button-box">
              <div className="orange-btn" onClick={() => setShowModal(true)}>
                Enquire with us
              </div>
            </div>
          </div>
          <div className="content-right">
            <div>
              <div className="title">
                <span className="orange-span">Create & Manage </span>Listings
              </div>
              <div className="description">
                Create and manage all listing with in the app, track and view
                all your history.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                Manage your
                <span className="orange-span"> Team</span>
              </div>
              <div className="description">
                Promote and find additional players for you teams and teams by
                listing your team as a group.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                Promote your
                <span className="orange-span"> Business</span>
              </div>
              <div className="description">
                Avenue for Personal trainers, gyms' studios and teams to
                showcase themselves and what they have to offer to the world and
                generate additional income.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                For
                <span className="orange-span"> Personal Trainers</span>
              </div>
              <div className="description">
                Promote your sessions and or groups to a greater client base,
                let fitness mates find you customers. Find additional and new
                customers by creating and offering new services anywhere
                anytime.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                Earn
                <span className="orange-span"> Unlimited </span> Income
              </div>
              <div className="description">
                Offer your expertise, services and specialty today by creating a
                group or activity users can join anywhere anytime. Create your
                activity, set your fee and off you go.
              </div>
            </div>
            <div className="button-box">
              <div className="orange-btn" onClick={() => setShowModal(true)}>
                Enquire with us
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookEventPopup show={showModal} onHide={handleCloseModal} />
    </section>
  );
};

export default Promote;
