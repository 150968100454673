import { STRING_CONSTANTS } from "../../../utils/StringConstant";
interface ListingCardProps {
  item: any;
  onClick: any;
}
const ListingCard = (props: ListingCardProps) => {
  const { item, onClick } = props;

  return (
    <li onClick={onClick}>
      <div className="card">
        <div className="card-body">
          <img src={item.icon} alt="" />
          <h4>{item.title}</h4>
          <p>{item.desc}</p>
          {item.hasDraft ? (
            <p className="draft_available">
              <img src="/static/greydocs.svg" alt="" />
              {STRING_CONSTANTS.draft_available}
            </p>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default ListingCard;
