import React from "react";
import Mail from "../assets/img/icons/Mail";
import Call from "../assets/img/icons/Call";

export const BookingTable = ({ columns = [], rows = [] }) => {
  return (
    <div className="custom_table">
      <div className="custom_table-row heading ">
        {columns?.map((item, index) => (
          <div
            key={index + "table"}
            className={`custom_table-header ${
              item?.align === "center" ? "text-center" : "text-start "
            }`}
          >
            {item?.label}
          </div>
        ))}
      </div>

      {rows.map((rowData, index) => {
        return (
          <div className="custom_table-row" key={"rows" + index}>
            {columns.map((colData, idx) => {
              return (
                <div
                  className={`custom_table-cell ${
                    colData?.highlight ? "highlight" : ""
                  }
                  ${
                    colData?.align === "center" ? "text-center" : "text-start "
                  }`}
                >
                  {rowData[colData?.id]}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const BookingTableResponsive = ({ rows = [] }) => {
  return (
    <div className="responsive_table">
      {rows.map((rowData, index) => {
        return (
          <div key={"rows_resp" + index}>
            <div className="container_data">
              <div className="data_name">{rowData?.name}</div>
              <div className="d-flex align-items-center gap-2">
                <div className="text_light">{rowData?.gender}</div>
                <div className="separator" />
                <div className="text_light">{rowData?.age}</div>
              </div>
            </div>
            <div className="container_data">
              <div className="d-flex align-items-center gap-2">
                <Mail />
                <span className="text_light">{rowData?.email}</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Call />
                <span className="text_light">{rowData?.phone}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
