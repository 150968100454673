import { Link, useNavigate } from "react-router-dom";
import { USER_TYPE } from "../../types";
import { getItemFromStore, isUserAuthenticated } from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="new-landing-header">
      <div>
        <div className="left-logo d-none d-md-block">
          <Link to={ROUTE_CONSTANTS.landing}>
            <img src="/static/fitness-logo.svg" alt="" />
          </Link>
        </div>
        <div className=" d-md-none">
          <Link to={ROUTE_CONSTANTS.landing}>
            <img src="/static/logo_small.svg" alt="" />
          </Link>
        </div>
      </div>
      <div className="right-bar">
        {getItemFromStore("userProfile")?.userType === USER_TYPE.GUEST && (
          <div
            className="join-as-a-member"
            onClick={() => navigate(ROUTE_CONSTANTS.subscription)}
          >
            Become a Member
          </div>
        )}
        {!isUserAuthenticated() && (
          <div
            className="join-as-a-member"
            onClick={() => navigate(ROUTE_CONSTANTS.register)}
          >
            Become a Member
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
