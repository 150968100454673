import React, { useEffect, useRef, useState } from "react";
import MediaContainer from "./MediaContainer";
import { getItemFromStore, handleLocation, showToast } from "../../../utils";
import { apiConstants, genderOption } from "../../../utils/Constants";
import InputField from "../../../global/InputField";
import DateSelecter from "../../../global/DateSelecter";
import MobileInput from "../../../global/MobileInput";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import Button from "../../../global/Button";
import moment from "moment";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";
import { makeGetCall } from "../../../utils/Requests";

type Props = {
  [key: string]: any;
};

const UserDetails = ({
  handleNext,
  control,
  errors,
  isLoading,
  setValue,
  getValues,
  watch,
}: Props) => {
  const coverRef = useRef(null);
  const profileRef = useRef(null);
  const [dobError, setDobError] = useState<boolean>(false);
  const [dobTwoError, setDobTwoError] = useState<boolean>(false);
  const [abnResponse, setAbnResponse] = useState<any>("");

  const handleUploadClick = (target = "cover") => {
    if (target === "profile") {
      profileRef.current.click();
    } else {
      coverRef.current.click();
    }
  };

  const setFileValue = (target, file) => {
    if (target === "profile") {
      setValue(apiConstants.profileImage, file, { shouldDirty: true });
      setValue(apiConstants.profileImageExtension, file.type);
    } else {
      setValue(apiConstants.coverPhoto, file, { shouldDirty: true });
      setValue(apiConstants.coverExtension, file.type);
    }
  };

  const handleFileChange = async (event, target = "cover") => {
    const file = event?.target?.files[0];
    if (!file) {
      return;
    }
    const maxSize = 5 * 1024 * 1024; // 5 MB
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const allowedVideoTypes = ["video/mp4", "video/quicktime"]; // .MOV MIME type is video/quicktime
    const isValidImageType = allowedImageTypes.includes(file.type);
    const isValidVideoType = allowedVideoTypes.includes(file.type);
    const isValidType = isValidImageType || isValidVideoType;

    if (target === "profile" && !isValidImageType) {
      showToast(
        "You can only upload a JPG, JPEG, or PNG image for the profile photo.",
        "error"
      );
      return;
    }

    if (target === "cover" && !isValidType) {
      showToast(
        "You can only upload JPG, JPEG, PNG images, or MP4, MOV videos for the cover photo.",
        "error"
      );
      return;
    }

    if (file.size > maxSize) {
      showToast("You can only upload a maximum 5MB file size.", "error");
      return;
    }

    if (isValidVideoType && target === "cover") {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);
        if (video.duration > 30) {
          showToast("Video cannot be longer than 30 seconds.", "error");
        } else {
          setFileValue(target, file);
        }
      };
    } else {
      setFileValue(target, file);
    }
  };

  useEffect(() => {
    if (watch(apiConstants.abn)?.length === 14) {
      makeGetCall({
        url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            if (res.data.AbnStatus === "Active") {
              setAbnResponse(res.data);
              setValue(apiConstants.abnDetails, res.data);
            } else {
              setAbnResponse(res.data.Message);
            }
          } else {
            setAbnResponse(STRING_CONSTANTS.invalid_abn);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else {
      setAbnResponse(null);
    }
  }, [watch(apiConstants.abn)]);

  const handleSubmit = () => {
    handleNext();
    // if (
    //   abnResponse !== STRING_CONSTANTS.invalid_abn &&
    //   abnResponse !== STRING_CONSTANTS.invalid_abn_msg
    // ) {
    // if (watch(apiConstants.dob)) {
    //   // if (moment(watch(apiConstants.dob)).isAfter(moment())) {
    //   //   setDobError(true);
    //   // } else {
    //   //   setDobError(false);
    //   // }
    // } else {
    //   setDobError(false);
    // }
    // if (getValues(apiConstants.dob) === null) {
    //   setDobTwoError(true);
    //   handleNext(ROUTE_CONSTANTS.business_intrest, true);
    // } else {
    //   setDobTwoError(false);
    //   handleNext(ROUTE_CONSTANTS.business_intrest, false);
    // }
    // }
  };

  return (
    <div className="mp_form_wrapper">
      <div className="mp_form_container">
        <div className="mp_cover_wrapper">
          <div
            className="mp_cover_container"
            onClick={() => handleUploadClick("cover")}
          >
            {watch(apiConstants.coverExtension) ? (
              <MediaContainer
                file={watch(apiConstants.coverPhoto)}
                type={watch(apiConstants.coverExtension)}
                className="cover_media_file"
              />
            ) : (
              <div className="mp_cover_empty">
                <img
                  className="add_cover_icon"
                  src="/static/addFilledPrimary.svg"
                  alt=""
                />
                <div className="add_cover_title">Add cover photo/video</div>
              </div>
            )}
            <input
              type="file"
              accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime"
              ref={coverRef}
              style={{ display: "none" }}
              onInput={(e) => handleFileChange(e, "cover")}
            />
          </div>
          <div className="mp_profile_wrapper">
            <div className="user_profile_container">
              {watch(apiConstants.profileImageExtension) ? (
                <MediaContainer
                  file={watch(apiConstants.profileImage)}
                  type={watch(apiConstants.profileImageExtension)}
                  className="mp_profile_media"
                />
              ) : (
                <img
                  className="mp_profile_media"
                  src="/static/Group 1437254547.svg"
                  alt=""
                />
              )}

              <img
                className="mp_profile_icon"
                src="/static/addProfileIcon.svg"
                alt=""
                onClick={() => handleUploadClick("profile")}
              />
              <input
                type="file"
                ref={profileRef}
                accept="image/jpeg,image/jpg,image/png"
                style={{ display: "none" }}
                onInput={(e) => handleFileChange(e, "profile")}
              />
            </div>
          </div>
        </div>
        <div className="mp_form_fill_container">
          <div className="mp_section">
            <InputField
              type="text"
              name={apiConstants.name}
              control={control}
              errors={errors}
              inputLabel={"Name*"}
              placeholder={"Add your preferred name"}
              setValue={setValue}
              maxlength={250}
            />
          </div>
          <div className="mp_section">
            <label className="mp_section_label mb-2">
              Which closely describes your gender?
            </label>
            <div className="gender_wrapper">
              {genderOption.map((item, index) => {
                return (
                  <div className="gen-opt-cont" key={"gen" + index}>
                    <label
                      htmlFor={item.value}
                      className={
                        watch(apiConstants.gender) === item.value
                          ? "gender_option active"
                          : "gender_option"
                      }
                    >
                      <img src={item.img} alt="" />
                      <InputField
                        key={index}
                        name={apiConstants.gender}
                        type="radio"
                        control={control}
                        id={item.value}
                        inputLabel={item.label}
                        setValue={setValue}
                      />

                      <div className="check-icon"></div>
                    </label>
                  </div>
                );
              })}
            </div>
            {errors?.gender && (
              <span className="text-danger mp_error">
                {errors?.gender?.message}
              </span>
            )}
          </div>
          <div className="mp_section">
            <label className="mp_section_label mb-2">
              Personal date of birth
            </label>
            <div className="dob_wrapper another-dob">
              <DateSelecter
                name={apiConstants.dob}
                errors={errors}
                control={control}
                dobError={dobError}
                dobTwoError={dobTwoError}
              />
            </div>
          </div>
          <div className="mp_section">
            <InputField
              type={"textarea"}
              name={apiConstants.bio}
              control={control}
              errors={errors}
              inputLabel={"Bio"}
              className={"textarea-label"}
              placeholder={"Share your fitness passion"}
              maxlength="300"
              setValue={setValue}
              getValues={getValues}
            />
          </div>
          <div className="mp_section"></div>

          {getItemFromStore("userData")?.userType !== "Guest" && (
            <>
              <div className="mp_section">
                <div className="hr_line" />
              </div>
              <div className="mp_section">
                <label className="mp_section_label">
                  Enter your business details below.
                </label>
              </div>
              <div className="mp_section">
                <InputField
                  type="tradingname"
                  name={apiConstants.tradingName}
                  control={control}
                  errors={errors}
                  inputLabel={"Trading Name"}
                  placeholder={"Enter your trading name"}
                  setValue={setValue}
                />
              </div>
              <div className="mp_section">
                {" "}
                <MobileInput
                  name={apiConstants.abn}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  inputLabel={"ABN Number (11 Digits)"}
                  placeholder={"Add your ABN number"}
                  mask={"99 999 999 999"}
                  errors={errors}
                />
              </div>
              <div className="mp_section">
                Enter business contact details below
              </div>
              <div className="mp_section">
                <MobileInput
                  name={apiConstants.businessContactNumber}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  className={"mobile"}
                  errors={errors}
                />
              </div>
              <div className="mp_section">
                <InputField
                  type="email"
                  name={apiConstants.businessContactEmail}
                  control={control}
                  errors={errors}
                  inputLabel={"Contact Email"}
                  placeholder={"Add your email address"}
                  setValue={setValue}
                />
              </div>
              <div className="mp_section">
                <GooglePlacesSearchBar
                  onChangeLocation={(
                    lat: string,
                    lng: string,
                    address: string,
                    address_components: any
                  ) => {
                    let location = handleLocation(
                      lat,
                      lng,
                      address,
                      address_components
                    );
                    setValue(apiConstants.address, address);
                    setValue(apiConstants.lat, lat);
                    setValue(apiConstants.lng, lng);
                    setValue(apiConstants.postCode, location.zipcode);
                    setValue(apiConstants.state, location.state);
                    setValue(apiConstants.city, location.locality);
                    setValue(apiConstants.country, location.country);
                  }}
                  setValue={setValue}
                  handleLocation={handleLocation}
                  control={control}
                  errors={errors}
                  name={apiConstants.address}
                  label={"Business Address"}
                  placeholder="Address"
                />
              </div>
              <div className="mp_section">
                <label className="pt_checkbox" htmlFor="trainer_type_checkbox">
                  <label
                    className="pt_label cursor-pointer"
                    htmlFor="trainer_type_checkbox"
                  >
                    {STRING_CONSTANTS.sign_up_trainer_txt}
                  </label>
                  <InputField
                    type={"checkbox"}
                    name={apiConstants.isTrainer}
                    control={control}
                    errors={errors}
                    id={"trainer_type_checkbox"}
                    setValue={setValue}
                  />
                </label>
              </div>
            </>
          )}

          <div className="mp_section">
            <Button
              btnTxt="Continue"
              onClick={() => {
                handleSubmit();
              }}
              className={"submit_btn"}
              isLoading={isLoading}
            />
          </div>
          <div className="mp_section"></div>
          <div className="mp_section"></div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
