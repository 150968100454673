import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
  OverlayView,
} from "@react-google-maps/api";
import { googleKey } from "../../../utils/Constants";
import { EventsMapCard } from "./EventsMapCard";
import { makePostCall } from "../../../utils/Requests";
import { scrollToBottom, scrollToTop, showToast } from "../../../utils";

const EventsMapContainer = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });
  const [, setMap] = React.useState(null);
  const [center, setCenter] = useState<any>({
    lat: -31.397,
    lng: 151.644,
  });

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    setCenter({
      lat: props.latitude || -25.397,
      lng: props.longitude || 134.644,
    });
  }, [props.latitude]);

  const [showEvent, setShowEvent] = useState(false);

  const data = [
    {
      city: "Melbourne",
      lat: -37.8142,
      lng: 144.9631,
      country: "Australia",
      iso2: "AU",
      admin_name: "Victoria",
      capital: "admin",
      population: "5031195",
      population_proper: "5031195",
    },
    {
      city: "Sydney",
      lat: -33.8678,
      lng: 151.21,
      country: "Australia",
      iso2: "AU",
      admin_name: "New South Wales",
      capital: "admin",
      population: "4840600",
      population_proper: "4840600",
    },
    {
      city: "Brisbane",
      lat: -27.4678,
      lng: 153.0281,
      country: "Australia",
      iso2: "AU",
      admin_name: "Queensland",
      capital: "admin",
      population: "2360241",
      population_proper: "2360241",
    },
    {
      city: "Perth",
      lat: -31.9559,
      lng: 115.8606,
      country: "Australia",
      iso2: "AU",
      admin_name: "Western Australia",
      capital: "admin",
      population: "2141834",
      population_proper: "2141834",
    },
    {
      city: "Adelaide",
      lat: -34.9275,
      lng: 138.6,
      country: "Australia",
      iso2: "AU",
      admin_name: "South Australia",
      capital: "admin",
      population: "1295714",
      population_proper: "1295714",
    },
    {
      city: "Gold Coast",
      lat: -28.0167,
      lng: 153.4,
      country: "Australia",
      iso2: "AU",
      admin_name: "Queensland",
      capital: "",
      population: "607665",
      population_proper: "607665",
    },
    {
      city: "Newcastle",
      lat: -32.9167,
      lng: 151.75,
      country: "Australia",
      iso2: "AU",
      admin_name: "New South Wales",
      capital: "",
      population: "152984",
      population_proper: "152984",
    },
    {
      city: "Cairns",
      lat: -16.92,
      lng: 145.78,
      country: "Australia",
      iso2: "AU",
      admin_name: "Queensland",
      capital: "",
      population: "146778",
      population_proper: "146778",
    },
  ];

  return (
    <>
      {isLoaded ? (
        <>
          <div
            style={{ position: "relative" }}
            className="events-map-container"
          >
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "calc(100vh - 100px)",
              }}
              zoom={6}
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={center}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: true,
                panControl: false,
                rotateControl: false,
                streetViewControl: false,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.TOP_LEFT
                }
              }}
            >
              <MarkerClusterer minimumClusterSize={20}>
                {(clusterer) => (
                  <div>
                    {props.eventMap.map((item, index) => (
                      <Marker
                        clusterer={clusterer}
                        key={index}
                        position={{
                          lat: item.coordinates[1],
                          lng: item.coordinates[0],
                        }}
                        onClick={() => setShowEvent(item)}
                        icon={{
                          url: "/static/chat-theme-2.svg",
                          scaledSize: new window.google.maps.Size(48, 48),
                        }}
                      // options={{ customInfo: item }}
                      />
                    ))}
                  </div>
                )}
              </MarkerClusterer>
              <div className="abs-btn"
                onClick={() => scrollToBottom()}
                style={{
                  width: '47px',
                  height: ' 47px',
                  background: '#fff',
                  position: 'fixed',
                  zIndex: 9,
                  bottom: '30px',
                  right: '20px',
                  borderRadius: '50px',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}>
                <img src="/static/chevron-black-left.svg" alt="" style={{
                  width: '70%',
                  height: '70%',
                  transform: 'rotate(270deg)'
                }} />
              </div>
            </GoogleMap>

            {showEvent && (
              <div style={{ position: "absolute", bottom: "10%", left: "40%" }}>
                <EventsMapCard showEvent={showEvent} onClickOutside={() => { setShowEvent(false) }} />
              </div>
            )}
          </div >
        </>
      ) : (
        <div>Loading....</div>
      )}
    </>
  );
};

export default EventsMapContainer;
