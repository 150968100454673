import React from "react";

const SubscriptionPlanCard = ({
  activeSubscription,
  currentSelection,
}: any) => {
  return (
    <div className="center-container" style={{ marginBottom: "20px" }}>
      <div className="subscription-card">
        {activeSubscription?.metadata?.discount && (
          <div className="discount-label">
            {activeSubscription?.metadata?.discount}% Off
          </div>
        )}
        <div className="plan-details" style={{height:'auto' , gap:'5px'}}>
          <div className="plan-name" style={{fontSize:'16px' , paddingTop : '15px'}}>Pay {currentSelection}</div>
          <div className="plan-value"  style={{fontSize:'16px'}}>
            AUD {activeSubscription?.unitAmount / 100}
          </div>
          {currentSelection === "Yearly" && (
            <div className="plan-value-gray "  style={{fontSize:'16px'}}>
              (AUD {activeSubscription?.metadata?.price / 12}.00/mo)
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
