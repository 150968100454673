import { useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import { useState } from "react";
import { makeDeleteCall, makePostCall } from "../../utils/Requests";
import { getItemFromStore, showToast } from "../../utils";
import { USER_TYPE } from "../../types";
import endpoints from "../../utils/endpoints";

export const ConnectCard = ({
  item,
  onConnectClick,
  onLikeClick,
  className,
  status,
}: any) => {
  const navigate = useNavigate();
  const { id, profilePicture, isMarkedFavourite, name, distance, interest } =
    item;

  const [isFav, setIsFav] = useState(isMarkedFavourite);
  const [connectStatus, setConnectStatus] = useState(status);

  const AddActivity = (id: string, markBy: string, url: string) => {
    let apiPayload = {
      user: id,
      markedBy: markBy,
    };
    makePostCall({ url, apiPayload, content_type: "multipart/form-data" })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  const removeActivity = (id: string, markBy: string, url: string) => {
    let apiPayload = {
      user: id,
      markedBy: markBy,
    };
    makeDeleteCall({ url, apiPayload, content_type: "multipart/form-data" })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const connectUser = (connectid) => {
    makePostCall({
      url: `auth/${connectid}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");

          setConnectStatus("Pending");
        } else {
          showToast(res.status.message || res.error.message, "error");

          setConnectStatus("Pending");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <div className={`connect-card ${className}`}>
      <div className="card-content-wrap">
        <div
          className="background-image"
          style={{
            backgroundImage: profilePicture
              ? ["mp4", "mov", "avi"].includes(
                profilePicture?.split(".").splice(-1)[0].toLowerCase()
              )
                ? `url(${item.thumbnail})`
                : `url(${profilePicture})`
              : "url(/static/avatar-dummy-1.png)",
          }}
        ></div>
        <div
          className="like-btn"
          onClick={() => {
            if (isFav) {
              removeActivity(
                item.id,
                getItemFromStore("userProfile").userType === USER_TYPE.MEMBER &&
                  getItemFromStore("userProfile").isTrainer
                  ? getItemFromStore("userProfile")?.user
                  : getItemFromStore("userProfile")?.id,
                endpoints.favourite
              );
            } else {
              AddActivity(
                item.id,
                getItemFromStore("userProfile").userType === USER_TYPE.MEMBER &&
                  getItemFromStore("userProfile").isTrainer
                  ? getItemFromStore("userProfile")?.user
                  : getItemFromStore("userProfile")?.id,
                endpoints.favourite
              );
            }
            setIsFav(!isFav);
          }}
          style={{
            background: isFav ? "#ee7830" : "rgba(0, 0, 0, 0.32)",
          }}
        >
          <img alt="" src="/static/LikeIconWhite.svg" />
        </div>
        <div className="content">
          {["mp4", "mov", "avi"].includes(
            profilePicture?.split(".").splice(-1)[0].toLowerCase()
          ) ? (
            <>
              <div className="card-img-wrap">
                <video
                  src={profilePicture}
                  autoPlay
                  playsInline
                  loop
                  muted
                  style={{
                    cursor: "pointer",
                    width: "130px",
                    height: "130px",
                    objectFit: "cover",
                    verticalAlign: "middle",
                    borderRadius: "100%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <source src={profilePicture} />
                </video>
              </div>
            </>
          ) : (
            <div className="card-img-wrap">
              <img
                className="img"
                src={profilePicture || "/static/dummyimg.svg"}
                alt=""
              />
            </div>
          )}

          <div className="username">{name}</div>
          <div className="location-info">
            📍 {distance?.toFixed(2)} km’s away...
          </div>
          <div className="interest-chip-container">
            <div className="interest-chip-box">
              {interest?.slice(0, 3)?.map((item) => {
                return (
                  <div className="chip" key={item.id}>
                    {item.title}
                  </div>
                );
              })}
              {interest?.length > 3 ?
                <div className="chip"
                  onClick={() => {
                    navigate(`/user/${id}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  +{interest?.slice(3)?.length} more
                </div> : null}
            </div>
          </div>
          <div className="button-box">
            <Button
              btnTxt="Profile"
              className={"backtologin profile"}
              btnImg={"/static/profile-circle-btn.svg"}
              onClick={() => {
                navigate(`/user/${id}`);
              }}
            />
            <Button
              disabled={connectStatus === "Pending"}
              btnTxt={connectStatus === "Pending" ? "Request sent" : "Connect"}
              className={
                connectStatus === "Pending"
                  ? "disabled-connect"
                  : "backtologin connect"
              }
              btnImg={"/static/person-add-btn.svg"}
              onClick={() => {
                connectUser(id);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
