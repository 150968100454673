import { useState } from "react";
import BookEventPopup from "./BookEventPopup";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <section>
      <div className="hero-section">
        <div className="register-info-box">
          <span>
            <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
          </span>
          <span>6 months free advertising</span>
          <span>
            <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
          </span>
          <span>Register your business now</span>
          <span>
            <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
          </span>
        </div>
        <div className="video-background">
          <video autoPlay muted loop className="video">
            <source
              src="https://fitness-mates.s3.ap-southeast-2.amazonaws.com/websitevideo/Video+to+put+on+website.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="overlay"></div>
          <div className="content">
            <div className="logo-box">
              <img alt="dumbellwhite" src="/static/fitnessf.svg" />
            </div>
            <div className="title">
              <span>Connect with</span> like-minded Health & Fitness Enthusiasts
            </div>
            <div className="sub-title">
              Your go to app for all things health fitness and experiences
            </div>
            <div className="button-box">
              <button
                className="hero-btn get-started"
                onClick={() => navigate("/register")}
              >
                Get started
              </button>
              <button
                className="hero-btn book-demo"
                onClick={() => setShowModal(true)}
              >
                Book demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <BookEventPopup show={showModal} onHide={handleCloseModal} />
    </section>
  );
};

export default Hero;
