import React, { useEffect, useState } from "react";
import InputField from "../../../global/InputField";
import PaymentButton from "../../../global/PaymentButton";
import { apiConstants } from "../../../utils/Constants";
import ConfirmStripePopup from "./ConfirmStripePopup";
import "../../../assets/scss/stripePage.scss";
import Button from "../../../global/Button";
import AddBankAccountPopup from "./AddBankAccountPopup";
import { makeGetCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import moment from "moment";
import WaitingStripeNewAccountPopup from "./WaitingStripeNewAccountPopup";

type Props = {
  setIsPaymentOpen: (value: boolean) => void;
  isRecommend: boolean;
  setIsRecommend: (value: boolean) => void;
  paymentMode?: string;
  setPaymentMode?: any;
  setValue?: any;
  control?: any;
  errors?: any;
  handleConnectStripe?: any;
};

interface ListingData {
  [key: string]: any;
}

const PaymentOption = ({
  setIsPaymentOpen,
  paymentMode,
  setPaymentMode,
  setValue,
  control,
  errors,
  handleConnectStripe,
}: Props) => {
  const [confirmStripeModal, setConfirmStripeModal] = useState(false);
  const [bankDetailsModal, setBankDetailsModal] = useState(false);
  const [waitingNewStripeAccountModal, setWaitingNewStripeAccountModal] =
    useState(false);
  const [listingType, setListingType] = useState("");
  const [method, setMethod] = useState("");
  const [listingData, setListingData] = useState<ListingData>({});
  const [selectedData, setSelectedData] = useState<ListingData>({});
  const [stripeAccount, setStripeAccount] = useState("");

  const handleExisting = async (type) => {
    setListingType(type);
    setConfirmStripeModal(false);
  };

  const handleNew = async (type) => {
    setConfirmStripeModal(false);
    if (type === "bank") {
      setValue(
        apiConstants.account_number,
        ""
      );
      setValue(apiConstants.bsb_number, "");
      setValue(apiConstants.bank_name, "");

      setBankDetailsModal(true);


    } else {
      handleConnectStripe();
      setWaitingNewStripeAccountModal(true);
    }
  };

  const handleBankDetails = () => {
    setValue("existing_or_new", "N");
    setPaymentMode("Direct Bank Transfer");
    setIsPaymentOpen(false);
  };

  const getSavedPayment = () => {
    makeGetCall({ url: apiConfig.saved_payments })
      .then((res) => {
        if (res.status.code === 200) {
          setListingData(res?.data);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getSavedPayment();
  }, []);

  const handleBankSelectContinue = () => {
    setValue(
      apiConstants.account_number,
      selectedData?.directBankAccountNumber
    );
    setValue(apiConstants.bsb_number, selectedData?.directBankBsbNumber);
    setValue(apiConstants.bank_name, selectedData?.directBankAccountName);
    setValue(apiConstants.existing_or_new, "E");
    setPaymentMode("Direct Bank Transfer");
    setIsPaymentOpen(false);
  };

  const handleStripeSelectContinue = () => {
    setValue(apiConstants.stripe_account_id, selectedData?.id);
    setValue(apiConstants.existing_or_new, "E");
    setValue(apiConstants.payment_mode, "Through Stripe");
    setIsPaymentOpen(false);
  };

  const handleNewStripeAccount = (id: any) => {
    setValue(apiConstants.stripe_account_id, id);
    setValue(apiConstants.existing_or_new, "E");
    setValue(apiConstants.payment_mode, "Through Stripe");
    setIsPaymentOpen(false);
  };

  const BankTransferInfo = () => {
    return (
      <div className="bank_info">
        <img src="/static/info.svg" alt="" />
        <div>
          Payment will be transferred to your specified bank account within 7
          business days after the booking is completed.
        </div>
      </div>
    );
  };

  const NoBankListing = () => {
    return (
      <div className="no_listing">
        <div>
          <img src="/static/cardWhite.svg" alt="" />
        </div>
        <div className="no_listing_desc">
          No bank details added here, <br /> click on add button
        </div>
        <div>
          <Button
            btnTxt="Add"
            className={"stripe_acc_add"}
            onClick={() => setBankDetailsModal(true)}
          />
        </div>
      </div>
    );
  };

  const NoStripAccount = () => {
    return (
      <div className="no_listing">
        <div>
          <img src="/static/cardWhite.svg" alt="" />
        </div>

        <div className="no_listing_desc">
          No stripe accounts added here, <br /> click on add button
        </div>
        <div>
          <Button
            btnTxt="Add"
            className={"stripe_acc_add"}
            onClick={handleConnectStripe}
          />
        </div>
      </div>
    );
  };

  const StripeListingCard = ({ data, index }) => {
    return (
      <div
        className="acc_wrapper"
        onClick={() => {
          setStripeAccount(index);
          setSelectedData(data);
        }}
      >
        <div className="acc_content">
          <div className="acc_number">
            Stripe number: {data?.stripeAccountId}
          </div>
          <div className="acc_added">
            Added on: {moment(data?.created_at).format("Do MMMM YYYY")}
          </div>
        </div>
        <div>
          <img
            src={
              stripeAccount === index
                ? "/static/radiocheck.svg"
                : "/static/radiouncheck.svg"
            }
            alt=""
          />
        </div>
      </div>
    );
  };

  const BankListingCard = ({ data, index }) => {
    return (
      <div
        className="acc_wrapper"
        onClick={() => {
          setStripeAccount(index);
          setSelectedData(data);
        }}
      >
        <div className="acc_content">
          <div className="acc_number">
            Account Number: {data?.directBankAccountNumber}
          </div>
          <div className="acc_added">
            BSB Number: {data?.directBankBsbNumber}
          </div>
          <div className="acc_added">
            Account Name: {data?.directBankAccountName}
          </div>
        </div>
        <div>
          <img
            src={
              stripeAccount === index
                ? "/static/radiocheck.svg"
                : "/static/radiouncheck.svg"
            }
            alt=""
          />
        </div>
      </div>
    );
  };

  const handleBack = () => {
    if (listingType) {
      setListingType("");
    } else {
      setIsPaymentOpen(false);
    }
  };

  return (
    <div className="user-profile create_listing payment-mode-wrapper create_listing_main wrap_stripe_payment">
      <div className="user-white-part-width" style={{ padding: 0 }}>
        <img
          src="/static/leftarrow.svg"
          alt=""
          className="handle_back_arrow"
          onClick={handleBack}
          style={{ marginTop: "0" }}
        />
        {listingType === "stripe" ? (
          <div className="container_stripe_payment_listing">
            <div className="payment_heading">
              <img
                src="/static/leftarrow.svg"
                alt=""
                className="handle_back_arrow_resp"
                onClick={handleBack}
                style={{ marginTop: "0" }}
              />
              <div className="heading_transfer">Existing Stripe Account</div>
            </div>
            <div className="height_listing">
              {listingData?.stripeDetails.length > 0 ? (
                <div className="stripe_acc_listing">
                  {listingData?.stripeDetails?.map((data, index) => (
                    <StripeListingCard data={data} index={index} />
                  ))}
                </div>
              ) : (
                <NoStripAccount />
              )}
            </div>

            {listingData?.stripeDetails.length > 0 ? <div className="acc_btn_container">
              <Button
                btnTxt="Continue"
                className={"stripe_acc_continue"}
                disabled={Object.keys(selectedData).length < 1}
                onClick={handleStripeSelectContinue}
              />

              <Button
                btnTxt="Didn’t find account, create new?"
                className={"stripe_acc_new"}
                onClick={handleConnectStripe}
              />
            </div> : ""}

          </div>
        ) : listingType === "bank" ? (
          <div className="container_stripe_payment_listing">
            <div className="payment_heading">
              <img
                src="/static/leftarrow.svg"
                alt=""
                className="handle_back_arrow_resp"
                onClick={handleBack}
                style={{ marginTop: "0" }}
              />
              <div className="heading_transfer">Direct Bank Transfer</div>
            </div>
            <BankTransferInfo />
            <div className="height_listing bank_transfer">
              {listingData?.directBankDetails?.length > 0 ? (
                <div className="stripe_acc_listing">
                  {listingData?.directBankDetails?.map((data, index) => (
                    <BankListingCard data={data} index={index} />
                  ))}
                </div>
              ) : (
                <>
                  <NoBankListing />
                </>
              )}
            </div>

            {
              listingData?.directBankDetails?.length > 0 ?
                <div className="acc_btn_container">
                  <Button
                    btnTxt="Continue"
                    disabled={Object.keys(selectedData).length < 1}
                    className={"stripe_acc_continue"}
                    onClick={handleBankSelectContinue}
                  />

                  <Button
                    btnTxt="Didn’t find bank account, Add new?"
                    className={"stripe_acc_new"}
                    onClick={() => setBankDetailsModal(true)}
                  />
                </div>
                : ""
            }


          </div>
        ) : (
          <div className="container_stripe_payment">
            <div className="payment_heading">
              <img
                src="/static/leftarrow.svg"
                alt=""
                className="handle_back_arrow_resp"
                onClick={handleBack}
                style={{ marginTop: "0" }}
              />
              How do you want to receive the payment?
            </div>
            <div className="payment_subheading">
              Please select the preferred payment method for receiving the
              earnings from your organized event.
            </div>

            <div>
              <PaymentButton
                className={paymentMode === "Through Stripe" ? "active" : null}
                isRecommend
                btnTxt={
                  paymentMode === "Through Stripe"
                    ? "Through Stripe"
                    : "Connect Stripe Account"
                }
                btnImg="/static/stripe-btn.svg"
                onClick={() => {
                  setValue(apiConstants.payment_mode, "Through Stripe");
                  setConfirmStripeModal(true);
                  setMethod("stripe");
                }}
              />
            </div>
            <div className="or_hr"> or</div>
            <div>
              <PaymentButton
                className={
                  paymentMode === "Direct Bank Transfer" ? "active" : null
                }
                btnTxt="Direct Bank Transfer"
                btnImg="/static/bank-btn.svg"
                onClick={() => {
                  setValue(apiConstants.payment_mode, "Direct Bank Transfer");
                  setConfirmStripeModal(true);
                  setMethod("bank");
                }}
              />
            </div>
          </div>
        )}
      </div>

      {confirmStripeModal && (
        <ConfirmStripePopup
          onClose={() => setConfirmStripeModal(false)}
          handleExisting={handleExisting}
          handleNew={handleNew}
          method={method}
        />
      )}
      {bankDetailsModal && (
        <AddBankAccountPopup
          onClose={() => setBankDetailsModal(false)}
          handleBankDetails={handleBankDetails}
          control={control}
          errors={errors}
          setValue={setValue}
        />
      )}

      {waitingNewStripeAccountModal && (
        <WaitingStripeNewAccountPopup
          onClose={() => setWaitingNewStripeAccountModal(false)}
          handleNewStripeAccount={handleNewStripeAccount}
        />
      )}
    </div>
  );
};

export default PaymentOption;
