import bgLeft from "../../assets/img/backgroundLeft.svg";
import bgRight from "../../assets/img/backgroundRight.svg";
import checked from "../../assets/img/signupWelcome/checked.svg";
import dismiss from "../../assets/img/signupWelcome/dismiss.svg";
import backBtn from "../../assets/img/signupWelcome/backBtn.svg";
import "./styleMembership.scss";
import Button from "../../global/Button";
import BasicButton from "../../global/BasicButton";
import CompareTable from "./CompareTable";
import ResponsiveCompareTable from "./ResponsiveCompareTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import { makeGetCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { USER_TYPE } from "../../types";

const UserTypeSelector = ({ isLoading, setShowPlan }: any) => {
  const [userTypeData, setUserTypeData] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("Member");
  const [memberData, setMemberData] = useState(null);
  const [guestData, setGuestData] = useState(null);

  const navigate = useNavigate();
  const { isMobile } = useCheckDeviceScreen();

  useEffect(() => {
    const fetchUserType = () => {
      makeGetCall({
        url: endpoints.get_user_type,
      })
        .then((res) => {
          if (res.results[0]) {
            setMemberData(res.results[0]);
          }
          if (res.results[1]) {
            setGuestData(res.results[1]);
          }
          console.log(res.results);
          // alert('hi');
          setUserTypeData(res.results);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchUserType();
  }, []);

  const compareData = [
    {
      name: "Browse for free",
      guest: "Free",
      member: (
        <>
          <sup>AUD</sup>5/month
        </>
      ),
      isGuestFree: true,
      key: "browseForFree",
    },
    {
      name: "Book Sessions",
      guest: <img src={checked} alt="Yes" />,
      member: <img src={checked} alt="Yes" />,
      key: "bookSessions",
    },
    {
      name: "Connect & Chat",
      guest: "1 per week",
      member: "Unlimited",
      key: "connectAndChat",
    },
    {
      name: "Create listings",
      guest: "Maximum 4",
      member: "Unlimited",
      key: "createListings",
    },
    {
      name: "Promote Business",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "promoteBusiness",
    },
    {
      name: "Sell Paid Listings",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "sellPaidListings",
    },
    {
      name: "Access to VIP Events",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "accessToVipEvents",
    },
    {
      name: "Personalized Dashboard",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "personalizedDashboard",
    },
    {
      name: "Event Creation Limit",
      guest: "1 per week",
      member: "Unlimited",
      key: "eventCreationLimit",
    },
    {
      name: "Max Listing Attendees",
      guest: "4",
      member: "Unlimited",
      key: "maxListingAttendees",
    },
    {
      name: "Revenue Generation",
      guest: <img src={dismiss} alt="No" />,
      member: "Unlimited",
      key: "revenueGeneration",
    },
    {
      name: "Lead Generation",
      guest: <img src={dismiss} alt="No" />,
      member: "Unlimited",
      key: "leadGeneration",
    },
  ];

  return (
    <>
      <div className="user_type_wrapper">
        <img src={bgLeft} alt="" className="background_left" />
        <img src={bgRight} alt="" className="background_right" />
        <div className="user_container">
          <div className="user_content" style={{ paddingTop: '7px' }}>
            {/* heading */}
            <div className="d-flex justify-content-center gap-1 flex-column">
              <div className="main_heading" style={{ fontSize: '24px' }}>Welcome to Fitness Mates</div>
              <div className="main_sub_heading" style={{ fontSize: '16px' }}>
                Whether you're looking to find workout partners or create
                fitness events, we got you covered!
              </div>
            </div>
            {/* membership_compare */}
            <div className="compare_container">
              <CompareTable
                compareData={compareData}
                guestData={guestData}
                memberData={memberData}
              />
            </div>
            <div className="compare_container_resp">
              <ResponsiveCompareTable
                compareData={compareData}
                guestData={guestData}
                memberData={memberData}
              />
            </div>
            <div
              className="btn_container"
              style={{
                position: "sticky",
                bottom: "12px",
                zIndex: "10",
                flexDirection: "row",
              }}
            >
              <Button
                btnTxt="Join as a Member"
                onClick={() => {
                  setSelectedUserType(USER_TYPE.MEMBER);
                  setShowPlan(true);
                  navigate("?showPlanTab=true");
                }}
                // isLoading={isLoading}
                className="member_btn"
              />
              <BasicButton
                style={{ backgroundColor: '#dbdcde', opacity: '0.8', textAlign: "center" }}
                btnTxt="Continue as a Guest"
                onClick={() => {
                  navigate(ROUTE_CONSTANTS?.manage_profile);
                }}
                // isLoading={isLoading}

                className="guest_btn"
              />
            </div>
            <img src={backBtn} alt="" className="back_btn" onClick={e => navigate('/')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTypeSelector;
