import React from "react";
import Policies from "../Legal/Policies";
import DashboardWrapper from "./DashboardWrapper";

const PolicyPage = () => {
  return (
    <DashboardWrapper showTab={true}>
      <div className="main-section-right profile-managment-right inner-policy-pages-setup rounded-5 inner-polcy-bg" >
        <Policies showTopBar={false} />
      </div>
    </DashboardWrapper>
  );
};

export default PolicyPage;
