import { showToast, handleVideoClick, scrollToTop } from "../../utils";
import Modal from "react-bootstrap/Modal";

import { useEffect, useState } from "react";
import React from "react";
import moment from "moment";
import { SpDateIcon, ExclusiveMembericon } from "../../utils/Icons/svgs";
import GooglePlacesSearchBarFilterSidebar from '../../global/GooglePlacesSearchBarFilterSidebar'

const ListingDetailAllSlots = ({ showListing, detailsData }) => {
  const [active, setActive] = useState<any>("upcoming");
  const [location, setLocation] = useState<any>(null);
  const [archiveData, setArchiveData] = useState([])
  const [upcomingData, setUpcomingData] = useState([])
  const [showShare, setShowShare] = useState(false);
  const handleNotShowShare = () => {
    setShowShare(false);
  };
  const handleShowShare = () => {
    setShowShare(true);
  };

  const isImage = /\.(jpg|jpeg|gif|png|svg|webp|avif|heic)$/.test(
    detailsData?.media?.toLowerCase()
  );
  const isVideo = /\.(mp4|mov|avi)$/.test(detailsData?.media?.toLowerCase());
  const listingImages = (photos) => {
    let array_data = [];
    for (var i = 0; i < 4; i++) {
      if (photos[i]['uploadDocumentUrl']) {
        array_data.push(<img src={photos[i]['uploadDocumentUrl']} alt="Image 02" />
        )
      } else {
        array_data.push(<img src="/static/gallery02.png" alt="Image 02" />
        )
      }

    }
    return array_data;
  }
  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 1000)

    // console.log(detailsData)
    // alert('hi');
  }, [])

  const getDetailsData = () => {
    var tempUpcoming = []
    var tempArchive = []
    detailsData?.events.map((a) => {
      if (moment() < moment(a?.date)) {
        tempUpcoming.push(a)
      }
      if (moment() > moment(a?.date)) {
        tempArchive.push(a)
      }
    })
    setArchiveData(tempArchive)
    setUpcomingData(tempUpcoming)
  }

  useEffect(() => {
    getDetailsData()
  }, [])
  return (
    <>
      <div className="listing_gallery_content">
        <img
          src="/static/arrow-back.svg"
          alt="back_arrow"
          className="listing_detail_back_arrow"
          style={{ cursor: "pointer" }}
          onClick={showListing}
        />
        <div className="d-flex justify-content-between">
          <div className="listing_detail_title">
            <h4>{detailsData.title} </h4>
          </div>

        </div>
        {detailsData.listingType == "P" &&
          <div className="d-flex justify-content-between">
            <div className="listing_detail_title">
              <h3 style={{ fontSize: '22px', marginTop: '20px', fontWeight: '600' }}>Total Events Included: <span style={{ color: "#EE7830" }}>{detailsData?.events?.length}</span> </h3>
            </div>
          </div>}
        {/* */}
        {detailsData.listingType == "P" ? <div className="session_detail_dates">
          <div>
            {detailsData?.events.map((item, index) => (
              <span
                key={index}
                className={
                  "111" === index + 1 ? "active-date" : ""
                }
                style={{ cursor: "pointer" }}
              //  onClick={() => setSelectDate(index + 1)}
              >
                <div className="row">
                  <h5 className="col-9">{item?.title}</h5>
                  <div className="col-2 " style={{ padding: 0, justifyContent: 'end' }}>
                    <div className="number-circle">
                      {++index}
                    </div>
                  </div>
                </div>
                <p>
                  <img src="/static/location-shape.svg" alt="" />
                  <p style={{ width: '60%' }}>
                    {item?.address?.address}
                  </p>
                  <h4 className="open-map" onClick={() => {
                    setLocation(item?.address?.location)
                  }}>Open Maps</h4>
                </p>

                <div>
                  <p>
                    <SpDateIcon />
                    {moment(item?.date).date()}{" "}
                    {moment(item?.date).format("MMM")},{" "}
                    {moment(item?.date).year()}{" "}
                  </p>
                  <p>
                    <img
                      src="/static/clock.svg"
                      alt="clock"
                      width={16}
                      height={16}
                    />
                    {moment(item?.startTime?.substring(0, 5), [
                      "HH:mm",
                    ]).format("hh:mmA")}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), [
                      "HH:mm",
                    ]).format("hh:mmA")}
                  </p>
                </div>
              </span>
            ))}
          </div>

        </div> : <>
          <div className="emodal_wrapper">
            <div className="edates_wrapper" style={{ padding: '16px' }}>
              <div className="e_all_dates_container row gx-0 gap-3">

                <div className="active-inactive-box">
                  <div
                    className="item cursor-pointer"
                    onClick={() => {
                      scrollToTop();
                      setActive("upcoming")
                    }}
                  >
                    <div className={active === "upcoming" ? "active" : "non-active"} style={{ marginRight: '5px' }}>
                      Upcoming
                    </div>
                  </div>
                  <div
                    className="item cursor-pointer"
                    onClick={() => {
                      scrollToTop();
                      setActive("archived")
                    }}
                  >
                    <div className={active === "archived" ? "active" : "non-active"} style={{ marginLeft: '5px' }}>
                      Archived
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="listing_detail_dates">
            <div>
              {active == "upcoming" ?
                upcomingData.length > 0 ?
                  upcomingData.map((item, index) => (
                    <span key={index}>
                      <h5>
                        {moment(item?.date).format("ddd")},{" "}
                        {moment(item?.date).date()}{" "}
                        {moment(item?.date).format("MMMM")}
                      </h5>
                      <p>
                        <img
                          src="/static/clock.svg"
                          alt="clock"
                          width={16}
                          height={16}
                        />
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}{" "}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </p>
                      <p>
                        <img
                          src="/static/seat.svg"
                          alt="seat"
                          width={16}
                          height={16}
                        />
                        <b>{item?.slotsAvailable} left</b> out of{" "}
                        {detailsData?.maxParticipants} spots
                      </p>
                    </span>
                  ))
                  : <span style={{ margin: 'auto', border: 0 }}>No Events</span>
                : active == "archived" ?
                  archiveData.length > 0 ?
                    archiveData.map((item, index) => (
                      <span key={index}>
                        <h5>
                          {moment(item?.date).format("ddd")},{" "}
                          {moment(item?.date).date()}{" "}
                          {moment(item?.date).format("MMMM")}
                        </h5>
                        <p>
                          <img
                            src="/static/clock.svg"
                            alt="clock"
                            width={16}
                            height={16}
                          />
                          {moment(item?.startTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}{" "}
                          -{" "}
                          {moment(item?.endTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}
                        </p>
                        <p>
                          <img
                            src="/static/seat.svg"
                            alt="seat"
                            width={16}
                            height={16}
                          />
                          <b>{item?.slotsAvailable} left</b> out of{" "}
                          {detailsData?.maxParticipants} spots
                        </p>
                      </span>
                    ))
                    : <span style={{ margin: 'auto', border: 0 }}>No Events</span>
                  : ""
              }
              {/* {
                detailsData?.events?.map((item, index) => (
                  active == "upcoming" && moment() < moment(item?.date) ?
                    <span key={index}>
                      <h5>
                        {moment(item?.date).format("ddd")},{" "}
                        {moment(item?.date).date()}{" "}
                        {moment(item?.date).format("MMMM")}
                      </h5>
                      <p>
                        <img
                          src="/static/clock.svg"
                          alt="clock"
                          width={16}
                          height={16}
                        />
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}{" "}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </p>
                      <p>
                        <img
                          src="/static/seat.svg"
                          alt="seat"
                          width={16}
                          height={16}
                        />
                        <b>{item?.slotsAvailable} left</b> out of{" "}
                        {detailsData?.maxParticipants} spots
                      </p>
                    </span>
                    :
                    active == "archived" && moment() > moment(item?.date) ?
                      <span key={index}>
                        <h5>
                          {moment(item?.date).format("ddd")},{" "}
                          {moment(item?.date).date()}{" "}
                          {moment(item?.date).format("MMMM")}
                        </h5>
                        <p>
                          <img
                            src="/static/clock.svg"
                            alt="clock"
                            width={16}
                            height={16}
                          />
                          {moment(item?.startTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}{" "}
                          -{" "}
                          {moment(item?.endTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}
                        </p>
                        <p>
                          <img
                            src="/static/seat.svg"
                            alt="seat"
                            width={16}
                            height={16}
                          />
                          <b>{item?.slotsAvailable} left</b> out of{" "}
                          {detailsData?.maxParticipants} spots
                        </p>
                      </span> :
                      <span style={{ margin: 'auto', border: 0 }}>No Events</span>
                )
                )
              } */}
            </div>

          </div>
        </>}

      </div>
      {
        (location) ? <div className="fixed-sidebar-left">
          <div className="sidebar-box" style={{ maxWidth: '50%' }}>
            <img src="/static/cross-black.svg" className="close-btn hide-large" alt="" onClick={() => {
              setLocation(null)
            }} />
            <GooglePlacesSearchBarFilterSidebar location={location} />
          </div>
        </div> : ""
      }

    </>
  );
};
export default ListingDetailAllSlots;
