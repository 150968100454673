/* eslint-disable react-hooks/exhaustive-deps */
import { act, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GENDER_PREFERENCE, LISTING_TYPE, USER_TYPE } from "../../../types";

import {
  getEventsList,
  getItemFromStore,
  showToast,
  getIntrestListFeatured,
  getEventsListMap,
  getSearchList,
  scrollToTop,
  formatNumber,
} from "../../../utils";
import {
  EventListingType,
  ResponsiveEventListingType,
} from "../../../utils/Constants";
import ReactPaginate from "react-paginate";
import ReactSimplyCarousel from "react-simply-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Button from "../../../global/Button";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";
import GlobalHeader2 from "../GlobalHeader2";
import GlobalFooter from "../GlobalFooter";
import { Pagination } from "react-bootstrap";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import MapContainer from "../../Connect/MapContainer";
import FilterPopup from "./FilterPopup";
import EventsMapContainer from "./EventsMapContainer";
import moment from "moment";
import img from "../../../assets/img/fitness.png";
import img2 from "../../../assets/img/star.png";
import Reactangleimg from "../../../assets/img/reactangle.svg";
import Ellipse from "../../../assets/img/ellipse.svg";
import { ClassNames } from "@emotion/react";
import endpoints from "../../../utils/endpoints";

import {
  makeDeleteCall,
  makeGetCall,
  makePostCall,
} from "../../../utils/Requests";
const Vector2 = require("../../../assets/img/Physical.svg").default;

const EventsSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [event, setEvent] = useState<any>([]);
  const [eventMap, setEventMap] = useState<any>([]);

  const [nextUrl, setNextUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentLat, setCurrentLat] = useState<any>(null);
  const [currentLng, setCurrentLng] = useState<any>(null);
  const [chipType, setChipType] = useState<any>("closest");
  const [distance, setDistance] = useState<any>(5000);
  const [isLocationAccessible, setIslocationAccessible] = useState<any>(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [showMap, setShowMap] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [limit, setLimit] = useState(16);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [desktopInterestList, setDesktopInterestList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [applyCount, setApplyCount] = useState(0);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [gender, setGender] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventType, setEventType] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");
  const [searchEvents, setSearchEvents] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [listingType, setListingType] = useState('S,SPECIAL,P,T');
  const [counter, setCounter] = useState(0);

  const [suggstionmodel, setsuggstionmodel] = useState(false);
  const { isMobile } = useCheckDeviceScreen();

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchValue(search);
    }, 1500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [search, 1500]);

  useEffect(() => {
    setChipType("closest");
    setInterests([]);
    setLimit(16);
    setOffset(0);
    setGender("");
    setDebouncedSearchValue("");
    setEventType("");
    setMaxPrice("");
    setMinPrice("");
    setStartDate("");
    setEndDate("");
    setListingType(
      'S,SPECIAL,P,T');
    setShowMap(false);
  }, [location]);


  useEffect(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
          setIslocationAccessible(true);
        },
        (err) => {
          setIslocationAccessible(false);
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      setIslocationAccessible(false);
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);


  useEffect(() => {
    setIsLoading(true);
    let params = {
      listing_type: listingType,
      interests: interests.toString(),
      sort: chipType,
      distance_filter: false,
      limit: limit,
      offset: offset,
      search_title: debouncedSearchValue,
      gender: gender,
      event_type: eventType,
      min_price: minPrice,
      max_price: maxPrice,
    };
    if (
      listingType !== LISTING_TYPE.C &&
      currentLng &&
      currentLat &&
      chipType === "closest"
    ) {
      params["dist"] = distance;
      params["point"] = `${currentLng},${currentLat}`;
    }

    if (long && lat) {
      params["dist"] = distance;
      params["point"] = `${long},${lat}`;
    }
    if (minAge) {
      params["age"] = minAge + "," + maxAge;
      // params["max_age"] = maxAge || minAge;
    }
    if (startDate && endDate) {
      params["dates"] =
        moment(startDate).format("YYYY-MM-DD") +
        "," +
        moment(endDate).format("YYYY-MM-DD");
      //dates: moment(startDate).format("YYYY-MM-DD") + "," + moment(endDate).format("YYYY-MM-DD"),
    }
    if (showMap) {
      getEventsListMap(
        params,
        setEventMap,
        setNextUrl,
        setIsLoading,
        setPageCount
      );
    } else {
      if (isLocationAccessible === true && currentLng && currentLat) {
        getEventsList(params, setEvent, setNextUrl, setIsLoading, setPageCount);
      } else if (isLocationAccessible === false) {
        getEventsList(params, setEvent, setNextUrl, setIsLoading, setPageCount);
      }
    }
  }, [
    chipType,
    distance,
    currentLat,
    currentLng,
    isLocationAccessible,
    listingType,
    offset,
    interests,
    debouncedSearchValue,
    applyCount,
    showMap,
  ]);

  useEffect(() => {
    if (!search) {
      setsuggstionmodel(false);
    } else {
      getSearchList({
        search: search,
        tab: activeTab === "tab1" ? "Events" : "Users"
      }, setSearchEvents, setSearchUsers);
    }
  }, [search, activeTab, counter])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search_string = queryParams.get("search");
    const tab = queryParams.get("tab");
    // alert(search_string)
    if (search_string && tab) {
      let newActiveTab = tab === "Events" ? "tab1" : "tab2"
      setSearch(search_string)
      // alert(newActiveTab)
      setActiveTab(newActiveTab)
    }
  }, [])
  function EventImageCarousel({ item }: any) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    return (
      <div className="carousal-item-custom">
        {
          item?.photos.length > 1 ? (
            <ReactSimplyCarousel
              activeSlideIndex={activeSlideIndex}
              onRequestChange={setActiveSlideIndex}
              itemsToShow={1}
              itemsToScroll={1}
              dotsNav={{
                show: true,
                containerProps: {
                  className: "dots-nav-container",
                  style: {
                    position: "absolute",
                    bottom: "10%",
                    gap: "6px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                itemBtnProps: {
                  style: {
                    height: 6,
                    width: 6,
                    borderRadius: "50%",
                    border: 0,
                    background: "rgba(255, 255, 255, 0.4)",
                  },
                },
                activeItemBtnProps: {
                  style: {
                    height: 6,
                    width: 6,
                    borderRadius: "50%",
                    border: 0,
                    background: "white",
                  },
                },
              }}
              forwardBtnProps={{
                //here you can also pass className, or any other button element attributes
                className: "image-carousel-button",

                style: {
                  background: "rgba(255, 255, 255, 0.4)",
                  borderRadius: "50%",
                  border: "none",
                  cursor: "pointer",
                  height: 24,
                  width: 24,
                  position: "absolute",
                  zIndex: "0",
                  top: "50%",
                  right: "16px",
                  translate: "0 -50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                children: (
                  <img
                    style={{ width: "16px", height: "16px" }}
                    alt=""
                    src="/static/chevron-black-right.svg"
                  />
                ),
              }}
              backwardBtnProps={{
                //here you can also pass className, or any other button element attributes
                className: "image-carousel-button",
                style: {
                  background: "rgba(255, 255, 255, 0.4)",
                  borderRadius: "50%",
                  border: "none",
                  cursor: "pointer",
                  height: 24,
                  width: 24,
                  position: "absolute",
                  zIndex: "99",
                  top: "50%",
                  left: "16px",
                  translate: "0 -50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                children: (
                  <img
                    style={{ width: "16px", height: "16px" }}
                    alt=""
                    src="/static/chevron-black-left.svg"
                  />
                ),
              }}
              speed={400}
              easing="linear"
            >
              {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

              {item?.photos.map((ph) =>
                /\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                  <>
                    <video
                      src={ph?.uploadDocument}
                      className="event_img"
                      //preload="none"
                      //autoPlay
                      controls
                      controlsList="nodownload"
                      // playsInline
                      // loop
                      muted
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToTop();
                        navigate(`listing-two/${item.id}`);
                      }}
                    >
                      <source src={ph?.uploadDocument} />
                    </video>
                  </>
                ) : (
                  <img
                    src={ph?.uploadDocument || "/static/expertise2.png"}
                    className="event_img"
                    alt=""
                    loading="lazy"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      navigate(`listing-two/${item.id}`);
                    }}
                  />
                )
              )}
            </ReactSimplyCarousel>
          ) :
            item?.photos.length == 1 ?
              /\.(mp4|mov|avi)$/.test(item?.photos[0].documentName?.toLowerCase()) ? (
                <>
                  <div className="individual-image-video-block">
                    <div className="individual-image-video-block-2">
                      <video
                        src={item?.photos[0].uploadDocument}
                        className="event_img"
                        //preload="none"
                        // autoPlay
                        controls
                        controlsList="nodownload"
                        // playsInline
                        // loop
                        muted
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToTop();
                          navigate(`listing-two/${item.id}`);
                        }}
                      >
                        <source src={item?.photos[0].uploadDocument} />
                      </video>
                    </div>

                  </div>
                </>
              ) : (
                <div className="individual-image-video-block">
                  <div className="individual-image-video-block-2">
                    <img
                      src={item?.photos[0].uploadDocument || "/static/expertise2.png"}
                      className="event_img"
                      alt=""
                      loading="lazy"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToTop();
                        navigate(`listing-two/${item.id}`);
                      }}
                    />
                  </div>
                </div>
              )
              :
              /\.(mp4|mov|avi)$/.test(item?.media?.toLowerCase()) ? (
                <>
                  <div className="individual-image-video-block">
                    <div className="individual-image-video-block-2">
                      <video
                        src={item?.media}
                        className="event_img"
                        //preload="none"
                        // autoPlay
                        controls
                        controlsList="nodownload"
                        // playsInline
                        // loop
                        muted
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToTop();
                          navigate(`listing-two/${item.id}`);
                        }}
                      >
                        <source src={item?.media} />
                      </video>
                    </div>
                  </div>
                </>
              ) : (
                <div className="individual-image-video-block">
                  <div className="individual-image-video-block-2">
                    <img
                      src={item?.media || "/static/expertise2.png"}
                      className="event_img"
                      alt=""
                      loading="lazy"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToTop();
                        navigate(`listing-two/${item.id}`);
                      }}
                    />
                  </div>
                </div>
              )
        }
      </div>
    );
  }

  const handleClearClick = (e: any) => {
    setsuggstionmodel(false);
    setSearch("");
    setSearchEvents([]);
    setActiveTab("tab1")
  };

  const handlePageClick = (event) => {
    scrollToTop();
    let page = event.selected + 1;
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };

  const AddActivity = (id: string, markBy: string, url: string) => {
    let apiPayload = {
      user: id,
      markedBy: markBy,
    };
    makePostCall({ url, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          let temp_counter = counter;
          setCounter(++temp_counter)
          // if (url === endpoints.favourite) {
          // }
          // if (url === endpoints.flag) {
          // }
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const connectUser = (connectid) => {
    makePostCall({
      url: `auth/${connectid}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          let temp_counter = counter;
          setCounter(++temp_counter)
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  console.log(searchUsers, "----------------")
  return (
    <>
      <GlobalHeader2 />
      <div className="events-page">
        <div className="selected-listing-type-container d-none d-md-flex">
          <div className="selected-listing-type-wrapper">
            {/* <div className="listing-type-left">
              {
              EventListingType?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIsLoading(true);
                      setOffset(0);
                      setCurrentPage(0);
                      setListingType(item.type);
                    }}
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                    className={`selected-type ${listingType === item.type ? "active" : ""
                      }`}
                  >
                    <img
                      src={
                        listingType === item.type
                          ? item.inactiveImg
                          : item.activeImg
                      }
                      alt=""
                    />
                    <img src={item.inactiveImg} alt="" />
                    <p className="mb-0">{item.title}</p>
                  </div>
                );
              })
              }
            </div> */}
            <div className="listing-type-right" style={{ width: '100%', marginRight: '20px' }}>
              <img src="static/Search.svg" alt="" />
              <input
                placeholder="Search for any Event or Individual"
                style={{ width: "100%" }}
                value={search}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    let activeTabVar = activeTab === "tab1" ? "Events" : "Users"
                    setsuggstionmodel(false)
                    navigate('/search?search=' + search + '&tab=' + activeTabVar)
                  }
                }}
                onChange={(e) => {
                  setSearch(e.target.value)
                  if (e.target.value) {
                    setsuggstionmodel(true)
                  } else {
                    setsuggstionmodel(false)
                  }
                }}
              />
              {
                search &&
                <span onClick={handleClearClick} className="clearstyle">
                  Clear
                </span>
              }

              {suggstionmodel && (
                <div className="automodel">
                  {/* Suggestion model */}
                  <div className="Tab-section">
                    <div className="tabcontent">
                      <div>
                        <button
                          className={`eventsstyle ${activeTab === "tab1" ? "active" : ""
                            }`}
                          onClick={() => setActiveTab("tab1")}
                        >
                          Events
                        </button>
                        <hr
                          className={`eventbottom ${activeTab === "tab1" ? "active" : ""
                            }`}
                        />
                      </div>
                      <div>
                        <button
                          className={`Peopleeventstyle ${activeTab === "tab2" ? "active" : ""
                            }`}
                          onClick={() => setActiveTab("tab2")}
                        >
                          People
                        </button>
                        <hr
                          className={`peoplebottom ${activeTab === "tab2" ? "active" : ""
                            }`}
                        />
                      </div>
                    </div>
                    <div>
                      <hr
                        className={`main-divider ${activeTab === "tab1"
                          ? "eventborder"
                          : activeTab === "tab2"
                            ? "peopleborder"
                            : ""
                          }`}
                      />
                    </div>
                  </div>
                  {activeTab === "tab1" && (
                    <div>
                      {searchEvents.map((item, index) => (
                        <div
                          className="conatinerview" key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open('/listing-two/' + item.id)}
                        >
                          <div className="main-section">
                            <div>
                              <img
                                className="eventimg"
                                src={item.thumbnail ? item.thumbnail : "/static/expertise2.png"}
                                alt=""
                              />
                            </div>
                            <div className="innterdata">
                              <span className="eventname">{item.title}</span>
                              <span className="createdat">
                                Created by: {item?.createdBy?.name}
                              </span>
                              <span className="eventtype">
                                Event Type: {item.listingEventType === "V" ? "Virtual" : "Physical"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {activeTab === "tab2" && (
                    <div>
                      {searchUsers.map((item, index) => (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open('/user/' + item.id)}
                          className="conatinerview"
                          key={index}>
                          <div className="main-section">
                            <div>
                              <img
                                style={{ borderRadius: "60px", width: '50px', height: '50px' }}
                                className="peopleimg" src={item.profilePicture ? item.profilePicture : "/static/dummyimg.svg"} alt="" />
                            </div>
                            <div className="innterdata">
                              <span className="eventname">{item.name}</span>
                              <span className="createdat">
                                {item.userType}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            {
              activeTab === "tab1" ?
                <div className="filter-row-container d-none d-md-block" style={{ padding: '0px' }}>
                  <div className="filter-row-wrapper ">
                    <div className="filter-second ">
                      <div
                        className="filter-sort-btn"
                        onClick={() => {
                          setShowFilter(!showFilter);
                        }}
                      >
                        <div className="inner-info">
                          <img src="/static/filter-sort.svg" alt="" />
                          <p>Filter & Sort</p>
                        </div>
                      </div>
                      <div
                        className={showMap ? "show-map-btn" : "show-map-btn-inactive"}
                        onClick={() => {
                          setShowMap(!showMap);
                        }}
                      >
                        {showMap ? (
                          <div className="inner-info">
                            <img src="/static/tabler_list.svg" alt="" />
                            <p>Show List</p>
                          </div>
                        ) : (
                          <div className="inner-info">
                            <img src="/static/map-icon.svg" alt="" />
                            <p>Show Map</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }


          </div>
        </div>




        {isMobile && (
          <>
            <div className={"heading-and-map-container"}>
              <div className="heading">Events</div>
              <div className="listing-map-container">
                {showMap ? (
                  <div
                    onClick={() => {
                      setShowMap(!showMap);
                    }}
                  >
                    <img src="/static/tabler_list.svg" alt="" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setShowMap(!showMap);
                    }}
                  >
                    <img src="/static/map_with_grey.svg" alt="" />
                  </div>
                )}
              </div>
            </div>

            <div className="res-search-and-sorting-container">
              <div className="search-input">
                <img src="static/Search.svg" alt="" />
                <input
                  placeholder="Search for any Event or Individual1"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      let activeTabVar =
                        activeTab === "tab1" ? "Events" : "Users";
                      setsuggstionmodel(false)
                      navigate(
                        "/search?search=" + search + "&tab=" + activeTabVar
                      );
                    }
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value) {
                      setsuggstionmodel(true)
                    } else {
                      setsuggstionmodel(false)
                    }
                  }}
                />
                {
                  search &&
                  <span onClick={handleClearClick} className="clearstyle">
                    X
                  </span>
                }
                {suggstionmodel && (
                  <div className="automodel">
                    {/* Suggestion model */}
                    <div className="Tab-section">
                      <div className="tabcontent">
                        <div>
                          <button
                            className={`eventsstyle ${activeTab === "tab1" ? "active" : ""
                              }`}
                            onClick={() => setActiveTab("tab1")}
                          >
                            Events
                          </button>
                          <hr
                            className={`eventbottom ${activeTab === "tab1" ? "active" : ""
                              }`}
                          />
                        </div>
                        <div>
                          <button
                            className={`Peopleeventstyle ${activeTab === "tab2" ? "active" : ""
                              }`}
                            onClick={() => setActiveTab("tab2")}
                          >
                            People
                          </button>
                          <hr
                            className={`peoplebottom ${activeTab === "tab2" ? "active" : ""
                              }`}
                          />
                        </div>
                      </div>
                      <div>
                        <hr
                          className={`main-divider ${activeTab === "tab1"
                            ? "eventborder"
                            : activeTab === "tab2"
                              ? "peopleborder"
                              : ""
                            }`}
                        />
                      </div>
                    </div>
                    {activeTab === "tab1" && (
                      <div>
                        {searchEvents.map((item, index) => (
                          <div
                            className="conatinerview"
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              window.open("/listing-two/" + item.id)
                            }
                          >
                            <div className="main-section">
                              <div>
                                <img
                                  className="eventimg"
                                  src={
                                    item.thumbnail
                                      ? item.thumbnail
                                      : "/static/expertise2.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="innterdata">
                                <span className="eventname">{item.title}</span>
                                <span className="createdat">
                                  Created by: {item?.createdBy?.name}
                                </span>
                                <span className="eventtype">
                                  Event Type:{" "}
                                  {item.listingEventType === "V"
                                    ? "Virtual"
                                    : "Physical"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {activeTab === "tab2" && (
                      <div>
                        {searchUsers.map((item, index) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => window.open("/user/" + item.id)}
                            className="conatinerview"
                            key={index}
                          >
                            <div className="main-section">
                              <div>
                                <img
                                  style={{
                                    borderRadius: "60px",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  className="peopleimg"
                                  src={
                                    item.profilePicture
                                      ? item.profilePicture
                                      : "/static/dummyimg.svg"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="innterdata">
                                <span className="eventname">{item.name}</span>
                                <span className="createdat">
                                  {item.userType}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              className="filter-sorting-btn"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <img src="static/filter-res.svg" alt="" />
            </div>
            <div className="res-filter-list-container">
              {desktopInterestList.map((item) => (
                <div
                  className="filter-item"
                  onClick={() => {
                    let temp_interests = [...interests];
                    if (temp_interests.includes(item.id)) {
                      const index = temp_interests.indexOf(item.id);
                      if (index > -1) {
                        // only splice array when item is found
                        temp_interests.splice(index, 1); // 2nd parameter means remove one item only
                        setInterests([...temp_interests]);
                      }
                    } else {
                      temp_interests.push(item.id);
                      setInterests([...temp_interests]);
                    }
                  }}
                >
                  <img
                    src={item.icon ? item.icon : item.image}
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p
                    className={interests.includes(item.id) ? "active-text" : ""}
                  >
                    {item.title}
                  </p>
                </div>
              ))}

            </div>
          </>
        )}

        {isLoading ? (
          <div className="page_loader">
            <div
              className="lds-ring"
              style={{
                margin: "0 auto",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ width: "50px" }}
                  src="/static/spinner.svg"
                  alt="loader"
                />
              </div>

              <div className="text__container">
                <span>Please wait... Loading</span>
              </div>
            </div>
          </div>
        ) : showMap ? (
          <div>
            <EventsMapContainer
              eventMap={eventMap}
            />
          </div>
        ) : (
          <>
            <div className="listing-container">
              <div className="listing-inner-wrapper">
                <div className="Tab-section Tab-Section-Custom-CSS" >
                  <div className="tabcontent">
                    <div style={{ flexBasis: 0, flexGrow: 1, textAlign: 'center' }}>
                      <button
                        className={`eventsstyle ${activeTab === "tab1" ? "active" : ""
                          }`}
                        onClick={() => setActiveTab("tab1")}
                      >
                        Events
                      </button>
                      <hr
                        className={`eventbottom ${activeTab === "tab1" ? "active" : ""
                          }`}
                      />
                    </div>
                    <div style={{ flexBasis: 0, flexGrow: 1, textAlign: 'center' }}>
                      <button
                        className={`Peopleeventstyle ${activeTab === "tab2" ? "active" : ""
                          }`}
                        onClick={() => setActiveTab("tab2")}
                      >
                        People
                      </button>
                      <hr
                        className={`peoplebottom ${activeTab === "tab2" ? "active" : ""
                          }`}
                      />
                    </div>
                  </div>
                  <div>
                    <hr
                      className={`main-divider ${activeTab === "tab1"
                        ? "eventborder"
                        : activeTab === "tab2"
                          ? "peopleborder"
                          : ""
                        }`}
                    />
                  </div>
                </div>
                {
                  activeTab === "tab1" ?
                    event.length == 0 ? (
                      <div style={{ height: "400px", width: "100%" }}>
                        <p className="no-content" style={{ margin: "auto" }}>
                          No Events to display
                        </p>
                      </div>
                    ) : (
                      <div className="listings">
                        {event?.map((item: any, index: number) => {
                          return (
                            <div>
                              <div className="event-link-title">
                                <div className="listing_box" key={index}>

                                  <div className="crousel-container">
                                    <div className="span_box">
                                      {
                                        item.listingEventType !== "V" ?
                                          <>
                                            <img
                                              className="event_type"
                                              src="/static/event-type2.svg"
                                              alt=""
                                            />

                                          </>
                                          :
                                          <>
                                            <span className="event_type">
                                              <img
                                                src="/static/event-type.svg"
                                                alt=""
                                              />
                                            </span>

                                          </>
                                      }
                                    </div>
                                    {
                                      item.listingEventType !== "V" ?
                                        <div className="span_box">
                                          {item?.distance ? (
                                            <span className="distance">
                                              {formatNumber(item.distance?.toFixed(2))}{" "}
                                              kms away...
                                            </span>
                                          ) : null}
                                        </div>
                                        : ""
                                    }

                                    <EventImageCarousel item={item} />
                                    {item?.listingType === LISTING_TYPE.SPECIAL && (
                                      <div className="special-box">
                                        <img
                                          src={img2}
                                          alt=""
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            marginRight: "auto",
                                          }}
                                        />
                                        <div className="exc-text">
                                          Exclusive for Members
                                        </div>
                                      </div>
                                    )}

                                    <div className="span_box">
                                      {item?.listingType === LISTING_TYPE.C ? (
                                        <span className="price">Join</span>
                                      ) : item?.price || item?.monthlyCost ? (
                                        <span className="price">
                                          {item?.price == "0.00" ? (
                                            "Free"
                                          ) : (
                                            <> AUD {item?.price || item?.monthlyCost}</>
                                          )}
                                        </span>
                                      ) : (
                                        <span className="price">$$$</span>
                                      )}
                                    </div>


                                  </div>


                                  <div
                                    className="limited-event-info"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      scrollToTop();
                                      navigate(`/listing-two/${item.id}`);
                                    }}
                                  >
                                    <div className="title item">
                                      <Link
                                        className="event-link-title"
                                        to={`listing-two/${item.id}`}
                                      >
                                        {item?.title}
                                      </Link>
                                    </div>
                                    <div className="item" style={{ fontSize: '14px', fontWeight: '400' }}>
                                      <img
                                        src="/static/ion_location-outline.svg"
                                        alt=""
                                      />
                                      {item?.scheduleDetails?.address?.address}
                                    </div>
                                    {item?.listingType === LISTING_TYPE.C ? null : (
                                      <div className="item" style={{ fontSize: '14px', fontWeight: '400' }}>

                                        <img
                                          src="/static/calendar-2.svg"
                                          alt=""
                                        />
                                        <span style={{ paddingLeft: '4px' }}>
                                          {moment(item?.events?.[0]?.date).format(
                                            "DD MMM, YYYY"
                                          )}
                                        </span>
                                      </div>
                                    )}
                                    {/* <div className="item">
                                  Participants:
                                  <span>
                                    {" "}
                                    {item?.listingType === LISTING_TYPE.S ||
                                      item?.listingType === LISTING_TYPE.SPECIAL
                                      ? item?.maxParticipants -
                                      item?.events?.[0]?.slotsAvailable
                                      : item?.maxParticipants -
                                      item?.teamSlotsAvailable}{" "}
                                    booked out of {item?.maxParticipants}
                                  </span>
                                </div>
                                <div className="item">
                                  Event Type:{" "}
                                  {item.listingEventType == "V" ? (
                                    <span className="event-type-blue">
                                      Virtual{" "}
                                      <img
                                        src="/static/internet-icon.svg"
                                        alt=""
                                      />{" "}
                                    </span>
                                  ) : (
                                    <span className="event-type-orange">
                                      Physical{" "}
                                      <img
                                        src={Vector2}
                                        alt=""
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </span>
                                  )}
                                </div> */}
                                  </div>

                                  {item?.listingType === LISTING_TYPE.SPECIAL ? (
                                    <div className="name name-special">
                                      <img
                                        src={img}
                                        alt=""
                                        style={{
                                          height: "100%",
                                          width: "65%",
                                          marginRight: "auto",
                                        }}
                                      />
                                      {/* <span>Fitness Mates</span> */}
                                    </div>
                                  ) : (
                                    <div
                                      className="name"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        navigate(`/user/${item?.createdBy?.id}`);
                                      }}
                                    >
                                      <Link
                                        className="event-link-title"
                                        to={`listing-two/${item.id}`}
                                      >
                                        {["mp4", "mov", "avi"].includes(
                                          item?.createdBy?.profilePicture
                                            ?.split(".")
                                            .splice(-1)[0]
                                            ?.toLowerCase()
                                        ) ? (
                                          <video
                                            src={item?.createdBy?.profilePicture}
                                            //preload="none"
                                            className="event_img"
                                            autoPlay
                                            //controls
                                            // playsInline
                                            // loop
                                            muted
                                          >
                                            <source
                                              src={item?.createdBy?.profilePicture}
                                            />
                                          </video>
                                        ) : (
                                          <img
                                            src={
                                              item?.createdBy?.profilePicture ||
                                              "/static/dummyimg.svg"
                                            }
                                            alt=""
                                            loading="lazy"
                                          />
                                        )}
                                      </Link>
                                      <span>
                                        {" "}
                                        {item?.createdBy?.tradingName
                                          ? item?.createdBy?.tradingName
                                          : item?.createdBy?.name}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )
                    :
                    searchUsers.length == 0 ?
                      (
                        <div style={{ height: "400px", width: "100%" }}>
                          <p className="no-content" style={{ margin: "auto" }}>
                            No Users to display
                          </p>
                        </div>
                      )
                      :
                      <div className="listings row" >
                        {searchUsers.map((item, index) => (
                          <div className={`connect-card col-md-3`}>

                            <div className="card-content-wrap" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                              <div
                                className="background-image backk"
                                style={{

                                  backgroundImage: item?.thumbnail
                                    ? `url(${item.thumbnail})`
                                    :
                                    item?.profilePicture ?
                                      `url(${item?.profilePicture})`
                                      :
                                      "url(/static/avatar-dummy-1.png)",
                                }}
                              >

                                {
                                  ["mp4", "mov", "avi"].includes(
                                    item?.coverPhotoVideo?.split(".").splice(-1)[0].toLowerCase()
                                  ) ? (

                                    <video
                                      src={item?.coverPhotoVideo}
                                      autoPlay
                                      playsInline
                                      loop
                                      muted
                                      style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        verticalAlign: "middle"
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <source src={item?.coverPhotoVideo} />
                                    </video>
                                  ) : null
                                }
                              </div>

                              <div className="content" style={{ width: '100%' }}>
                                {["mp4", "mov", "avi"].includes(
                                  item?.profilePicture?.split(".").splice(-1)[0].toLowerCase()
                                ) ? (
                                  <>
                                    <div className="card-img-wrap shadow-profile">
                                      <video
                                        src={item?.profilePicture}
                                        autoPlay
                                        playsInline
                                        loop
                                        muted
                                        style={{
                                          cursor: "pointer",
                                          width: "130px",
                                          height: "130px",
                                          objectFit: "cover",
                                          verticalAlign: "middle",
                                          borderRadius: "100%",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <source src={item?.profilePicture} />
                                      </video>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    onClick={() => {
                                      navigate(`/user/${item.id}`);
                                    }}
                                    style={{ cursor: "pointer" }} className="card-img-wrap shadow-profile">
                                    <img
                                      className="img"
                                      src={item?.profilePicture || "/static/dummyimg.svg"}
                                      alt=""
                                    />
                                  </div>
                                )}

                                <div
                                  onClick={() => {
                                    navigate(`/user/${item.id}`);
                                  }}
                                  className="username" style={{ color: '#25272D', cursor: "pointer" }}>{item.name}</div>
                                <div className="location-info" style={{ color: '#6C6D6F' }}>
                                  📍 {distance?.toFixed(2)} km’s away...
                                </div>
                                {item?.interests && item?.interests.length > 0 &&
                                  <div className="row" style={{
                                    flexWrap: 'wrap', gap: 5, marginTop: 6, paddingLeft: 10
                                  }}>
                                    {item?.interests.map((it) => (
                                      <div className="people-pill">
                                        {it.title}
                                      </div>
                                    ))}

                                  </div>
                                }
                                <div className="button-box">
                                  <div
                                    className="like-btn"
                                    onClick={() => {

                                      if (!getItemFromStore("userProfile")) {
                                        showToast("Please login to mark as favorite.", "error");
                                      } else {
                                        AddActivity(
                                          item.id,
                                          getItemFromStore("userProfile").userType === USER_TYPE.MEMBER &&
                                            getItemFromStore("userProfile").isTrainer
                                            ? getItemFromStore("userProfile")?.user
                                            : getItemFromStore("userProfile")?.id,
                                          endpoints.favourite
                                        );
                                      }

                                      // if (item?.isFav) {
                                      //   removeActivity(
                                      //     item.id,
                                      //     getItemFromStore("userProfile").userType === USER_TYPE.MEMBER &&
                                      //       getItemFromStore("userProfile").isTrainer
                                      //       ? getItemFromStore("userProfile")?.user
                                      //       : getItemFromStore("userProfile")?.id,
                                      //     endpoints.favourite
                                      //   );
                                      // } else {



                                      // }
                                      // setIsFav(!item?.isFav);
                                    }}
                                    style={{
                                      background: item?.isFav ? "#ee7830" : "rgba(0, 0, 0, 0.32)", position: 'initial'
                                    }}
                                  >
                                    <img alt="" src="/static/LikeIconWhite.svg" />
                                  </div>
                                  {/* <Button
                                    btnTxt="Profile"
                                    className={"backtologin profile"}
                                    btnImg={"/static/profile-circle-btn.svg"}
                                    onClick={() => {
                                      navigate(`/user/${id}`);
                                    }}
                                  /> */}
                                  <Button
                                    disabled={"abd" === "Pending"}
                                    btnTxt={"abc" === "Pending" ? "Request sent" : "Connect"}
                                    className={
                                      "abc" === "Pending"
                                        ? "disabled-connect-s"
                                        : "backtologin connect-s"
                                    }
                                    btnImg={"/static/person-add-btn.svg"}
                                    onClick={() => {
                                      if (!getItemFromStore("userProfile")) {
                                        showToast("Please login to connect with user.", "error");
                                      } else {
                                        connectUser(item.id);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                }

                <div className="create-listing-btn">
                  <Button
                    btnImg="/static/plusadd.svg"
                    btnTxt="Create Listing"
                    className="add_listing_btn"
                    onClick={() => {
                      navigate(ROUTE_CONSTANTS.create_listing);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {!showMap && activeTab === "tab1" && (
          <div className="pages-container my-3">
            <div className="pages-inner-container">
              <div className="pagination-box">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <div className="previous-button">
                        {" "}
                        <img
                          src="/static/u_angle-left-b(1).svg"
                          alt=""
                        /> Next{" "}
                      </div>
                    </>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  forcePage={currentPage}
                  pageCount={Math.ceil(pageCount / limit)}
                  previousLabel={
                    <>
                      <div className="previous-button">
                        Previous <img src="/static/u_angle-left-b.svg" alt="" />
                      </div>
                    </>
                  }
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        )}
      </div >
      {/* {!showMap && */}
      < GlobalFooter />
      {/* // } */}
      {
        showFilter && (
          <FilterPopup
            setCurrentPage={setCurrentPage}
            setAddress={setAddress}
            setLat={setLat}
            setLong={setLong}
            address={address}
            setApplyCount={() => {
              let temp_apply = applyCount;
              setApplyCount(temp_apply + 1);
            }}
            setInterests={setInterests}
            setMinAge={setMinAge}
            setMaxAge={setMaxAge}
            setGender={setGender}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setEventType={setEventType}
            setChipType={setChipType}
            chipType={chipType}
            eventType={eventType}
            show={showFilter}
            minPrice={minPrice}
            maxPrice={maxPrice}
            startDate={startDate}
            endDate={endDate}
            minAge={minAge}
            maxAge={maxAge}
            gender={gender}
            onHide={() => setShowFilter(false)}
          />
        )
      }
    </>
  );
};

export default EventsSearch;
