const MissionCard = ({ icon, title, desc }) => {
  return (
    <div className="mission-card-wrap">
      <div className="icon">{icon}</div>
      <div className="title">{title}</div>
      <div className="desc">{desc}</div>
    </div>
  );
};

const Mission = () => {
  return (
    <section>
      <div className="mission-section">
        <div className="content-box">
          <div className="title">
            Our {""}
            <span className="orange-span"> mission</span> {""}& {""}
            <span className="orange-span">core values</span>
          </div>
          <div className="sub-title">
            <img
              alt=""
              src="/static/quote-down.svg"
              style={{
                right: 0,
                top: "8px",
                position: "absolute",
              }}
            />
            To inspire and empower individuals to embrace a healthy, active
            lifestyle by providing a supportive,
            <img
              alt=""
              src="/static/quote-up.svg"
              style={{ left: 0, top: "8px", position: "absolute" }}
            />
            engaging, and personalised fitness experience.
          </div>

          <div className="mission-card-list">
            <MissionCard
              icon={<img alt="" src="/static/trend-up.svg" />}
              title="Focused on Growth"
              desc="We're not just another fitness platform – we're a community! Connect, share your story, find your passion and together, we can build something incredible. We're not just another fitness platform – we're a community! Connect, share your story, find your passion and together, we can build something incredible."
            />
            <MissionCard
              icon={<img alt="" src="/static/heart.svg" />}
              title="Empowering you to be your best YOU"
              desc="At our core, we believe in uplifting and empowering each other to reach new heights. We’re not just here to provide fitness services; we’re here to be
              your biggest cheerleaders, motivating you to push past your limits and unlock your full potential."
            />

            <MissionCard
              icon={<img alt="" src="/static/global.svg" />}
              title="Connect & Build"
              desc="Growth is a journey you don’t have to take alone. Whether you’re chasing gains in the gym or personal growth in your mindset, we’ve got the tools,
              resources, and community to help you level up."
            />
            <MissionCard
              icon={<img alt="" src="/static/empty-wallet.svg" />}
              title="Learn & earn "
              desc="Knowledge is power and good health is priceless. Get ready to soak up valuable insights from industry experts while exploring opportunities to turn
              your passion into a side hustle or even a full-time gig. The world is your oyster, and we&#39;re here to help you crack it open."
            />
            <MissionCard
              icon={<img alt="" src="/static/peoplelanding.svg" />}
              title="Bringing Convenience to You!"
              desc="In today’s fast-paced world, convenience is king, and we’re here to serve it up on a silver platter. Say goodbye to the hassle of juggling multiple
              apps and platforms – we’re bringing the entire fitness experience right to your fingertips. Seamless, streamlined, and tailored to your needs – that’s our promise to you."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
