// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_line {
  width: 100%;
  height: 1px;
  background: #8f939a; }
`, "",{"version":3,"sources":["webpack://./src/components/ListingComponents/HorizontalLine/lineStyle.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,WAAW;EACX,mBAAmB,EAAA","sourcesContent":[".hr_line {\n    width: 100%;\n    height: 1px;\n    background: #8f939a;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
