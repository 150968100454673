import React from "react";

const Toggle = ({ onClick, option1, option2, currentSelection }: any) => {
  return (
    <div
      className="toggle-container"
      style={{ marginBottom: "15px", marginTop: "15px" }}
      onClick={() => {
        if (currentSelection === option1) {
          onClick(option2);
        } else {
          onClick(option1);
        }
      }}
    >
      {option1}
      <div className="toggle">
        <div
          className={`${
            currentSelection === option1 ? "switch" : "switch-right"
          }`}
        ></div>
      </div>
      {option2}
    </div>
  );
};

export default Toggle;
